
import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ResponseObject } from 'src/app/shared/model/response.model';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/admin/user/user.service';
import { AuthService } from 'src/app/shared/auth.service';
import { TrainingRegisterService } from './training-register.service';

@Component({
    selector: 'app-user',
    templateUrl: './training-register.component.html',
    styleUrls: ['./training-register.component.css']
})
export class TrainingRegisterComponent {

    public error_message: string = "";
    public success_message: string = "";
    public training_list: any = [];
    public received_quantity: number = 0;


    public trainingRegisterFormGroup = new FormGroup({
        date: new FormControl("", [Validators.required]),
        location_id: new FormControl(this.activatedroute.snapshot.queryParams["l"], [Validators.required]),
        company_id: new FormControl(this.activatedroute.snapshot.queryParams["c"], [Validators.required]),
        company_providing: new FormControl("", [Validators.required]),
        person_hosted_name: new FormControl("", [Validators.required]),
        person_hosted_designation: new FormControl("", [Validators.required]),
        topic_of_training: new FormControl("", [Validators.required]),
        count_of_participants: new FormControl("", [Validators.required])
    });

    constructor(private trainingRegisterService: TrainingRegisterService,
        private activatedroute: ActivatedRoute) { }

    private getRegisterContent() {
        this.trainingRegisterService.get(this.activatedroute.snapshot.queryParams["l"])
            .subscribe((res: ResponseObject) => {
                this.error_message = "";
                if (res.statusCode === 200) {
                    this.training_list = res.data;
                }
                else {
                    this.error_message = res.message;
                }
            }, err => {
                if (err.status === 400) this.error_message = err.error.message[0];
                else this.error_message = err.error.message;
            });
    }

    ngOnInit() {
        this.getRegisterContent();
    }

    private createdepartment(data: any) {
        this.trainingRegisterService.create(data)
            .subscribe((res: ResponseObject) => {
                this.error_message = "";
                if (res.statusCode === 201) {
                    this.getRegisterContent();
                }
                else {
                    this.error_message = res.message;
                }
                this.trainingRegisterFormGroup.reset();
            });
    }

    save() {
        this.error_message = "";
        this.success_message = "";

        if (!this.trainingRegisterFormGroup.valid) {
            this.error_message = "Invalid form inputs";
            return;
        }
        let data = {
            ... this.trainingRegisterFormGroup.value,
        }
        this.createdepartment(data);
    }
}