import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { WalkInService } from './walk-in.service';
import { ResponseObject } from 'src/app/shared/model/response.model';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/admin/user/user.service';
import { AuthService } from 'src/app/shared/auth.service';
import { OHCAppointmentService } from 'src/app/patient/ohc-appointment/ohc-appointment.service';
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-user',
  templateUrl: './walk-in.component.html',
  styleUrls: ['./company.component.css']
})
export class WalkInComponent {

  public error_message: string = "";
  public success_message: string = "";

  public walkin_list: any = [];
  public today_appointment_list: any = [];
  public op_type: string = "Create";
  public company_id: string = "";
  public user: any = {};

  public walkInFormGroup = new FormGroup({
    phone: new FormControl("", [Validators.required]),
    name: new FormControl("", [Validators.required]),
    age: new FormControl("", [Validators.required]),
    patient_id: new FormControl("", [Validators.required]),
    gender: new FormControl("", [Validators.required]),
    email: new FormControl(""),
    description: new FormControl("", [Validators.required]),
    bp: new FormControl(""),
    spo2: new FormControl(""),
    temperature: new FormControl(""),
    pulse: new FormControl(""),
    glucose: new FormControl(""),
    hr: new FormControl(""),
    rr: new FormControl(""),
    height: new FormControl(""),
    weight: new FormControl(""),
    is_pregnant: new FormControl(false),
    is_emergency: new FormControl(false),
    nature_of_accident: new FormControl(""),
    body_part_injured: new FormControl(""),
    machine_involved: new FormControl(""),
    severity_grade: new FormControl(""),
    complaint_type: new FormControl(""),
    is_ambulance_consumed: new FormControl(false),
    bmi: new FormControl(),
    appointment_id: new FormControl()
  });

  updateModal(company: any) {
    this.op_type = "Update";
    this.company_id = company._id;
    delete company._id;
    this.walkInFormGroup.setValue(company);
  }

  unSetCompanyIdContext() {
    this.op_type = "Create";
    this.company_id = "";
    this.error_message = "";
    this.success_message = "";
    this.walkInFormGroup.reset({ gender: "" });
  }

  constructor(private walkInService: WalkInService,
    private router: Router,
    private activatedroute: ActivatedRoute,
    private toastr: ToastrService,
    private appointmentService: OHCAppointmentService,
    private userService: UserService,
    private authService: AuthService) { }

  private getWalkIns() {
    this.walkInService.getWalkInsForDay(this.activatedroute.snapshot.queryParams["l"],
      (new Date().toISOString()).split('T')[0].toString()
    )
      .subscribe((res: ResponseObject) => {
        this.error_message = "";
        if (res.statusCode === 200) {
          this.walkin_list = res.data;
          this.appointmentService.getBySlot(this.activatedroute.snapshot.queryParams["l"],
            new Date().toISOString())
            .subscribe((res: ResponseObject) => {
              if (res.statusCode === 200) {
                this.today_appointment_list = res.data;

                for (let i = 0; i < this.today_appointment_list.length; i++) {
                  for (let j = 0; j < this.walkin_list.length; j++) {
                    if (this.today_appointment_list[i]._id == this.walkin_list[j].appointment_id) {
                      this.today_appointment_list.splice(i, 1);
                    }
                  }
                }

              }
            });

        }
      });
  }

  ngOnInit() {
    this.getWalkIns();

    this.walkInFormGroup.controls.height.valueChanges.subscribe((val: any) => {
      if (this.walkInFormGroup.controls.height.value || this.walkInFormGroup.controls.weight.value) {
        this.walkInFormGroup.controls.bmi.patchValue(Math.round((
          <any>this.walkInFormGroup.controls.weight.value / Math.pow((<any>this.walkInFormGroup.controls.height.value / 100) || 1, 2)
        ) * 100) / 100);
      }
    });

    this.walkInFormGroup.controls.weight.valueChanges.subscribe((val: any) => {
      if (this.walkInFormGroup.controls.height.value || this.walkInFormGroup.controls.weight.value) {
        this.walkInFormGroup.controls.bmi.patchValue(Math.round((
          <any>this.walkInFormGroup.controls.weight.value / Math.pow((<any>this.walkInFormGroup.controls.height.value / 100) || 1, 2)
        ) * 100) / 100);
      }
    });
  }

  private createWalkIn(data: any) {
    this.walkInService.createWalkIn({
      ...data,
      company_id: this.activatedroute.snapshot.queryParams["c"],
      location_id: this.activatedroute.snapshot.queryParams["l"],
      in: new Date().toISOString(),
      paramedic_id: JSON.parse(this.authService.getLoggedInUserDetails() || '{}')._id,
      is_dispensed: false
    })
      .subscribe((res: ResponseObject) => {
        this.error_message = "";
        if (res.statusCode === 201) {
          this.success_message = "WalkIn Created Successfuly."
          this.getWalkIns();
        }
        else {
          this.error_message = res.message;
        }
      });
  }

  private updateWalkIn(id: string, data: any) {
    this.walkInService.updateWalkIn(id, data)
      .subscribe((res: ResponseObject) => {
        this.error_message = "";
        if (res.statusCode === 200) {
          this.success_message = "WalkIn Updated Successfuly."
          this.getWalkIns();
        }
        else {
          this.error_message = res.message;
        }
      });
  }

  save() {
    this.error_message = "";
    this.success_message = "";

    if (!this.walkInFormGroup.valid) {
      this.error_message = "Invalid form inputs";
      return;
    }

    let data = {
      ... this.walkInFormGroup.value,
      is_active: true
    }

    if (this.op_type === "Create")
      this.createWalkIn(data);
    else if (this.op_type === "Update")
      this.updateWalkIn(this.company_id, data)

  }

  searchUser(phone: string) {
    this.userService.getUsersForWalkin(phone, this.activatedroute.snapshot.queryParams["l"])
      .subscribe((res: ResponseObject) => {
        this.error_message = "";
        if (res.statusCode === 200) {
          this.user = res.data[0];
          if (!this.user) {
            this.toastr.error("No user found!", 'Oh, Snap!', {
              progressAnimation: 'decreasing',
              disableTimeOut: 'extendedTimeOut',
              positionClass: 'toast-bottom-right',
              progressBar: true,
              timeOut: 10000
            });
            return;
          }
          else {
            this.walkInFormGroup.patchValue({
              phone: this.user.phone,
              name: this.user.name,
              email: this.user.email,
              gender: this.user.gender,
              patient_id: this.user._id,
              description: "",
              bp: "",
              spo2: "",
              temperature: "",
              pulse: "",
              glucose: "",
              hr: "",
              rr: "",
              age: this.calculateAge(this.user.dob).toString(),
              height: "",
              weight: "",
              is_pregnant: false,
              is_emergency: false,
              nature_of_accident: "",
              body_part_injured: "",
              machine_involved: "",
              severity_grade: "",
              complaint_type: "",
              is_ambulance_consumed: false,
              bmi: null
            })
          }
        }
        else {
          this.error_message = res.message;
        }
      });
  }

  private calculateAge(user_dob: string) {
    let dob = new Date(user_dob);
    //calculate month difference from current date in time
    var month_diff = Date.now() - dob.getTime();

    //convert the calculated difference in date format
    var age_dt = new Date(month_diff);

    //extract year from date
    var year = age_dt.getUTCFullYear();

    //now calculate the age of the user
    return Math.abs(year - 1970);
  }

  viewPrescription(p: any) {
    this.router.navigate(['/detail-prescription'], { queryParams: { prescription: p } });
  }

  markOut(walkin_id: string) {
    this.walkInService.markOut(walkin_id)
      .subscribe((res: ResponseObject) => {
        this.error_message = "";
        if (res.statusCode === 200) {
          this.getWalkIns();
        }
        else {
          this.error_message = res.message;
        }
      });
  }

  checkInAppointment(appointment: any) {
    this.walkInFormGroup.get("appointment_id")?.setValue(appointment._id);
    this.searchUser(appointment.booked_by.phone);
  }

}

@Component({
  selector: 'app-user',
  templateUrl: './walk-in-log.component.html',
  styleUrls: ['./company.component.css']
})
export class WalkInLogComponent {

  public error_message: string = "";
  public success_message: string = "";

  public walkin_list: any = [];
  public op_type: string = "Create";
  public company_id: string = "";
  public user: any = {};
  public today: number = (new Date).setHours(0, 0, 0, 0);

  public page_size: number = 5;
  public page_number: number = 0;

  constructor(private walkInService: WalkInService,
    private router: Router,
    private activatedroute: ActivatedRoute) { }

  private getWalkIns() {
    this.walkInService.getWalkInsLog(this.activatedroute.snapshot.queryParams["l"],
      this.page_size, this.page_number)
      .subscribe((res: ResponseObject) => {
        this.error_message = "";
        if (res.statusCode === 200) {
          this.walkin_list = res.data;
        }
        else {
          this.error_message = res.message;
        }
      });
  }

  getNextPage() {
    this.page_number += 1;
    this.getWalkIns()
  }

  getPrevPage() {
    this.page_number -= 1;
    this.getWalkIns()
  }

  ngOnInit() {
    this.getWalkIns();
  }

  viewPrescription(p: any) {
    this.router.navigate(['/detail-prescription'], { queryParams: { prescription: p } });
  }

  markOut(walkin_id: string) {
    this.walkInService.markOut(walkin_id)
      .subscribe((res: ResponseObject) => {
        this.error_message = "";
        if (res.statusCode === 200) {
          this.getWalkIns();
        }
        else {
          this.error_message = res.message;
        }
      });
  }

  isFollowUpDue(date: Date) {
    if (date && typeof date == 'object') {
      let abs_date: number = date.setHours(0, 0, 0, 0);
      if (abs_date <= this.today) return true;
    }
    return false;
  }

}
