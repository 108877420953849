import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseObject } from 'src/app/shared/model/response.model';
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class UserService {

    constructor(private http: HttpClient) { }

    getCallCenterUsers(page_size: number = 0, page_number: number = 0) {
        return this.http.get<ResponseObject>(environment.apiEndpoint + "/user?user_type=Doctor,Admin,Ops,Paramedic,Pharmacy&page_size="
            + page_size + "&page_number=" + page_number);
    }

    getLocationBackendUsers(location_id: string,
        page_size: number = 0, page_number: number = 0) {
        return this.http.get<ResponseObject>(environment.apiEndpoint + "/user?location_id=" + location_id +
            "&user_type=Doctor,Admin,Ops,Paramedic,Pharmacy&page_size="
            + page_size + "&page_number=" + page_number);
    }

    getLocationUsers(location_id: string) {
        return this.http.get<ResponseObject>(environment.apiEndpoint + "/user?location_id=" + location_id + "&user_type=Patient,Client Admin");
    }

    getLocationUsersAll(location_id: string) {
        return this.http.get<ResponseObject>(environment.apiEndpoint + "/user?location_id=" + location_id);
    }

    getUsersByPhone(phone: string) {
        return this.http.get<ResponseObject>(environment.apiEndpoint + "/user?phone=" + phone);
    }

    getUsersForWalkin(phone: string, location_id: string) {
        return this.http.get<ResponseObject>(environment.apiEndpoint + "/user/search/" + location_id + "?query=" + phone);
    }

    createCallCenterUser(data: any) {
        return this.http.post<ResponseObject>(environment.apiEndpoint + "/user", data);
    }

    updateCallCenterUser(id: string, data: any) {
        return this.http.put<ResponseObject>(environment.apiEndpoint + "/user/" + id, data);
    }

    getParamedics(location_id: string) {
        return this.http.get<ResponseObject>(environment.apiEndpoint +
          `/user?location_id=${location_id}&user_type=Paramedic`);
    }

    getDoctors(location_id: string) {
        return this.http.get<ResponseObject>(environment.apiEndpoint +
          `/user?location_id=${location_id}&user_type=Doctor`);
    }

  mapShiftToUser(location_id: string,
                 user_id: string,
                 shift_id: string) {
    return this.http.get<ResponseObject>(environment.apiEndpoint +
      `/user/${location_id}/map-shift?user_id=${user_id}&shift_id=${shift_id}`);
  }
}
