<!-- Start Content-->
<div class="container-fluid">

    <!-- start page title -->
    <div class="row">
        <div class="col-12">
            <div class="page-title-box page-title-box-alt">
                <h4 class="page-title">Vaccination Requests</h4>
                <div class="page-title-right">
                    <ol class="breadcrumb m-0">

                        <li class="breadcrumb-item"><a href="javascript: void(0);">Vaccination</a></li>
                        <li class="breadcrumb-item active">Location Bookings</li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
    <!-- end page title -->


    <div class="row">
        <div class="col-xl-6">
            <label>Filter:</label>
            <div class="input-group mb-2">
                <select class="form-control" [(ngModel)]="filter_status" (change)="updateFilter($event)">
                    <option value="Pending Payment">Pending Payment</option>
                    <option value="Rescheduled">Rescheduled</option>
                    <option value="Requested">Requested</option>
                    <option value="Confirmed">Confirmed</option>
                    <option value="Cancelled">Cancelled</option>
                    <option value="Awaiting Report">Awaiting Report</option>
                    <option value="Concluded">Concluded</option>
                </select>
                <button class="btn btn-primary" type="button" (click)="downloadFile()">
                    Export Data As CSV
                </button>
                <select class="form-control" [(ngModel)]="filter_delivery_type" (change)="updateFilterDelivery($event)">
                    <option value="Lab">Lab</option>
                    <option value="Home">Home</option>
                    <option value="Lab,Home">All</option>
                </select>
            </div>
        </div>
    </div>

    <hr />

    <div class="row">
        <div class="col-xl-12 col-md-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <table class="table table-responsive table-striped table-hover table-bordered">
                            <thead>
                                <tr>
                                    <th>Sr. No.</th>
                                    <th>Employee</th>
                                    <th>Vaccine</th>
                                    <th>Centre</th>
                                    <th>Status</th>
                                    <th>Booked On</th>
                                    <th>Last Updated</th>
                                    <th *ngIf="!is_client_admin">Reports</th>
                                    <th class="text-center"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style="text-align: center;" *ngIf="!booking_list.length">
                                    <td colspan="100">No Records</td>
                                </tr>
                                <tr *ngFor="let p of booking_list; let i = index">
                                    <td class="col-1">{{i + 1}}</td>
                                    <td>{{p.user_id?.name}} ({{p.user_id?.organization_identifier}})</td>
                                    <td>{{p.vaccine_id.name}}</td>
                                    <td>{{p.lab_id?.name || "-- Home Pickup --"}}</td>
                                    <td>{{p.status}}</td>
                                    <td>{{p.createdAt| date: 'short'}}</td>
                                    <td>{{p.updatedAt | date: 'short'}}</td>
                                    <td *ngIf="!is_client_admin">
                                        <span *ngFor="let r of p.report_files; let j = index">
                                            <a href="{{s3BaseUrl}}/{{r.url}}" target="_blank">Report #{{j+1}}</a> <br />
                                        </span>
                                    </td>
                                    <td class="col-1">
                                        <div class="input-group">
                                            <button
                                                class="btn btn-primary waves-effect btn-xs waves-light dropdown-toggle"
                                                type="button" data-bs-toggle="dropdown" aria-haspopup="true"
                                                aria-expanded="false">Actions <i
                                                    class="mdi mdi-chevron-down"></i></button>
                                            <div class="dropdown-menu">
                                                <a class="dropdown-item" href="#" data-bs-toggle="modal"
                                                    data-bs-target="#updateAppointmentModal"
                                                    (click)="openBookingForm(p)">Update</a>
                                                <a *ngIf="!is_client_admin" class="dropdown-item" data-bs-toggle="modal"
                                                    data-bs-target="#reportUploadModal" (click)="openBookingForm(p)"
                                                    href="#">Upload Report</a>
                                                <div role="separator" class="dropdown-divider"></div>
                                                <a class="dropdown-item" data-bs-toggle="modal"
                                                    data-bs-target="#paymentDetailsModal" (click)="openPaymentModal(p)"
                                                    href="#">Payment Details</a>

                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="btn-group mb-2 float-end">
                        <button type="button" (click)="getPrevPage()"
                            class="btn btn-secondary btn-bordered waves-effect" [disabled]="page_number == 0">
                            <i class="fas fa-arrow-left"></i> &nbsp; Prev
                        </button>
                        <button [disabled]="!(booking_list.length >= page_size)" type="button" (click)="getNextPage()"
                            class="btn btn-secondary btn-bordered waves-effect">
                            Next &nbsp; <i class="fas fa-arrow-right"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div><!-- end col -->
    </div>
</div> <!-- container -->
<!-- Dashboard init-->


<!-- Center modal content -->
<div class="modal fade" id="updateAppointmentModal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-md">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="myCenterModalLabel">Update Appointment</h4>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <h4 style="display: inline;">{{booking?.user_id?.name}}</h4> &nbsp;
                <small>{{booking?.vaccine_id?.name}}</small>
                <hr />
                <p><strong>Requested Date: </strong> &nbsp; {{booking?.proposed_date | date: 'medium'}}</p>
                <p><strong>Booking ID: </strong> &nbsp; {{booking?._id}}</p>
                <p><strong>Delivery Type: </strong> &nbsp; {{booking?.delivery_type}}</p>
                <table class="table table-bordered">
                    <thead>
                        <th>Sr. No.</th>
                        <th>Name</th>
                        <th>Phone</th>
                        <th>Address</th>
                    </thead>
                    <tbody>
                        <tr *ngIf="booking.is_me_included">
                            <td>1.</td>
                            <td>Me</td>
                            <td>{{booking.user_id?.phone}}</td>
                            <td>
                                {{booking.user_id?.address?.line1}}, &nbsp;
                                {{booking.user_id?.address?.line1}}, &nbsp;
                                {{booking.user_id?.address?.landmark}}, &nbsp;
                                {{booking.user_id?.address?.state}}, &nbsp;
                                {{booking.user_id?.address?.city}}, &nbsp;
                                {{booking.user_id?.address?.pincode}}, &nbsp;
                            </td>
                        </tr>
                        <tr *ngFor="let d of booking.dependent_ids; let i = index;">
                            <td *ngIf="booking.is_me_included">{{i + 2}}.</td>
                            <td *ngIf="!booking.is_me_included">{{i + 1}}.</td>
                            <td>{{d.name}}</td>
                            <td>{{d.phone}}</td>
                            <td>
                                {{d.address?.line1}}, &nbsp;
                                {{d.address?.line1}}, &nbsp;
                                {{d.address?.landmark}}, &nbsp;
                                {{d.address?.state}}, &nbsp;
                                {{d.address?.city}}, &nbsp;
                                {{d.address?.pincode}}, &nbsp;
                            </td>
                        </tr>
                    </tbody>
                </table>
                <hr />
                <form [formGroup]="updateBookingFormGroup">
                    <div class="col-md-12 mb-2">
                        <label>Rescheduling Date: &nbsp; <span style="color: red;">*</span></label>
                        <input class="form-control" type="date" formControlName="counter_date" />
                    </div>
                    <div class="col-md-12 mb-2">
                        <label>Status: &nbsp; <span style="color: red;">*</span></label>
                        <select class="form-control" formControlName="status">
                            <option value="">-- Choose One --</option>
                            <option value="Pending Payment">Pending Payment</option>
                            <option value="Requested">Requested</option>
                            <option value="Rescheduled">Rescheduled</option>
                            <option value="Confirmed">Confirmed</option>
                            <option value="Cancelled">Cancelled</option>
                            <option value="Awaiting Report">Awaiting Report</option>
                            <option value="Concluded">Concluded</option>
                        </select>
                    </div>
                    <div class="col-md-12 mb-2">
                        <label>Vaccine: &nbsp; <span style="color: red;">*</span></label>
                        <select class="form-control" formControlName="vaccine_details">
                            <option value="">-- Choose One --</option>
                            <option *ngFor="let p of vaccine_list;"
                                value="{{p.vaccine_id._id}}~~{{p.lab_id?._id}}~~{{p._id}}">
                                {{p.vaccine_id.name}} - {{p.lab_id?.name || "No Lab / Home Pickup"}}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-12 mb-2">
                        <label>Change Reason: &nbsp; <span style="color: red;">*</span></label>
                        <input class="form-control" formControlName="change_reason" />
                    </div>
                    <div class="col-md-12 mb-2">
                        <button class="btn btn-primary" (click)="update()" [disabled]="!updateBookingFormGroup.valid"
                            data-bs-dismiss="modal" aria-label="Close">Update</button>
                    </div>
                </form>
            </div>
        </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

<!-- Center modal content -->
<div class="modal fade" id="paymentDetailsModal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-md">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="myCenterModalLabel">Payment Attempts</h4>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <h4 style="display: inline;">{{booking?.user_id?.name}}</h4> &nbsp;
                <small>{{booking?.vaccine_id?.name}}</small>
                <div class="mb-2">
                    <strong>Calculated Amount: </strong> &nbsp; {{booking?.payment_id?.amount}}
                </div>
                <hr />
                <div *ngFor="let a of payment_list; let i = index;">
                    <div class="mb-2">
                        <small>Attempt #{{i + 1}}</small>
                    </div>
                    <div class="mb-2">
                        <strong *ngIf="!a.gateway_response">Gateway Not Responded OR Window Closed Without
                            Payment.</strong>
                    </div>
                    <div *ngIf="a.gateway_response">
                        <div class="mb-2">
                            <strong>Mode: </strong> &nbsp; {{a.gateway_response?.mode}}
                        </div>
                        <div class="mb-2">
                            <strong>Status: </strong> &nbsp; {{a.gateway_response?.status}}
                        </div>
                        <div class="mb-2">
                            <strong>TxnId: </strong> &nbsp; {{a.gateway_response?.txnid}}
                        </div>
                        <div class="mb-2">
                            <strong>Net Amount Debit on Gateway: </strong> &nbsp;
                            {{a.gateway_response?.net_amount_debit}}
                        </div>
                        <div class="mb-2">
                            <strong>Bank Reference: </strong> &nbsp; {{a.gateway_response?.bank_ref_num}}
                        </div>
                        <div class="mb-2">
                            <strong>Bank Code: </strong> &nbsp; {{a.gateway_response?.bankcode}}
                        </div>
                        <div class="mb-2">
                            <strong>Error Message: </strong> &nbsp; {{a.gateway_response?.error_Message}}
                        </div>
                    </div>
                    <hr />
                </div>
            </div>
        </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
</div><!-- /.modal -->


<!-- Center modal content -->
<div class="modal fade" id="reportUploadModal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-md">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="myCenterModalLabel">Upload Report</h4>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <h4 style="display: inline;">{{booking?.user_id?.name}}</h4> &nbsp;
                <small>{{booking?.package_id?.name}}</small>
                <div class="mb-2">
                    <input class="form-control" type="file" (input)="newFileSelect($event)" />
                </div>
                <div class="mb-2">
                    <button class="btn btn-primary btn-xs" type="button" (click)="uploadReport()">
                        Upload Report
                    </button>
                </div>
            </div>
        </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
