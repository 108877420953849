<!-- Start Content-->
<div class="container-fluid">

    <!-- start page title -->
    <div class="row">
        <div class="col-12">
            <div class="page-title-box page-title-box-alt">
                <h4 class="page-title">Create Prescription</h4>
                <div class="page-title-right">
                    <ol class="breadcrumb m-0">

                        <li class="breadcrumb-item"><a href="javascript: void(0);">Doctor</a></li>
                        <li class="breadcrumb-item active">Create Prescription</li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
    <!-- end page title -->

    <div class="row">
        <div class="col-xl-12 col-md-12">
            <div class="card">
                <div class="card-header">
                    Current Patient Queue
                </div>
                <div class="card-body table-responsive">
                    <div class="row">
                        <table class="table table-striped table-hover table-bordered">
                            <thead>
                                <tr>
                                    <th class="col-1">Sr. No.</th>
                                    <th>Name</th>
                                    <th>Sex / Age</th>
                                    <th>Description</th>
                                    <th>UID</th>
                                    <th>̦Paramedic</th>
                                    <th>In Time</th>
                                    <th class="col-1"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style="text-align: center;" *ngIf="!walkin_list.length">
                                    <td colspan="100">No Walkins</td>
                                </tr>
                                <tr *ngFor="let walkin of walkin_list; let i = index">
                                    <td class="col-1">{{i + 1}}</td>
                                    <td>{{walkin.patient_id.name}}</td>
                                    <td>{{walkin.patient_id.gender}} / {{walkin.age}}</td>
                                    <td>{{walkin.description}}</td>
                                    <td>
                                        {{walkin.patient_id.phone}}
                                    </td>
                                    <td>
                                        {{walkin.paramedic_id.name}}
                                    </td>
                                    <td>
                                        {{walkin.in | date: 'short'}}
                                    </td>
                                    <td class="col-1">
                                        <div class="dropdown">
                                            <button class="btn btn-danger btn-sm dropdown-toggle" type="button"
                                                data-bs-toggle="dropdown" aria-expanded="false">
                                                Actions &nbsp; <i class="ri-arrow-down-fill"></i>
                                            </button>
                                            <ul class="dropdown-menu">

                                                <li><a class="dropdown-item"
                                                        (click)="startPrescribe(walkin)">Checkin</a></li>
                                                <li><a class="dropdown-item"
                                                        (click)="viewPastPrescriptions(walkin.patient_id?._id)">Past
                                                        Prescriptions</a></li>
                                            </ul>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div><!-- end col -->
    </div>

    <div class="row">
        <div class="col-xl-12 col-md-12">
            <div class="card">
                <div class="card-header">
                    <h5>Dr. {{user.name}}</h5>
                    <h6 class="card-subtitle">{{user.doctor_details?.specialization}}</h6>
                    <br />
                    <h6 class="card-subtitle text-muted">Registeration: {{user.doctor_details?.registeration_number}}
                    </h6>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-xs-12">
                            <form [formGroup]="prescriptionFormGroup" autocomplete="off" (ngSubmit)="save()">
                                <div class="alert alert-danger" *ngIf="error_message">{{error_message}}</div>
                                <div class="alert alert-success" *ngIf="success_message">{{success_message}}</div>

                                <h5>Demographics</h5>
                                <div class="row">
                                    <div class="col-md-3">
                                        <div class="mb-2">
                                            <label for="name" class="form-label">Name <span
                                                    style="color: red;">*</span></label>
                                            <input readonly class="form-control" type="text" id="name"
                                                placeholder="Enter user's full name"
                                                value="{{ctx_walkin?.patient_id?.name}}">
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="mb-2">
                                            <label for="email" class="form-label">Email <span
                                                    style="color: red;">*</span></label>
                                            <input readonly class="form-control" type="email" id="email"
                                                placeholder="Enter user's email"
                                                value="{{ctx_walkin?.patient_id?.email}}">
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="mb-2">
                                            <label for="gender" class="form-label">Gender <span
                                                    style="color: red;">*</span></label>
                                            <select readonly class="form-control" id="gender"
                                                [value]="ctx_walkin?.patient_id?.gender">
                                                <option value="">-- Select --</option>
                                                <option value="Male">Male</option>
                                                <option value="Female">Female</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="mb-2">
                                            <label for="age" class="form-label">Age <span
                                                    style="color: red;">*</span></label>
                                            <input readonly class="form-control" type="text" id="age"
                                                placeholder="Enter respiratory Rate" value="{{ctx_walkin?.age}}">
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <div class="mb-2">
                                        <label for="description" class="form-label">Paramedic Notes <span
                                                style="color: red;">*</span></label>
                                        <textarea readonly class="form-control" type="text" id="description"
                                            placeholder="Enter complaints, remarks etc.."
                                            value="{{ctx_walkin?.description}}">
                                                </textarea>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="card border border-secondary">
                                            <div class="card-header">
                                                Vitals
                                            </div>
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-md-2">
                                                        <label for="bp" class="form-label">Blood Pressure </label>
                                                        <div class="mb-2">
                                                            <input class="form-control" type="text" id="bp"
                                                                [ngModelOptions]="{standalone: true}" [(ngModel)]="bp"
                                                                placeholder="Blood Pressure" value="{{ctx_walkin?.bp}}">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-2">
                                                        <div class="mb-2">
                                                            <label for="spo2" class="form-label">SpO2 </label>
                                                            <input class="form-control" type="text" id="spo2"
                                                                [ngModelOptions]="{standalone: true}" [(ngModel)]="spo2"
                                                                placeholder="Enter SpO2" value="{{ctx_walkin?.spo2}}">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-2">
                                                        <div class="mb-2">
                                                            <label for="temperature"
                                                                class="form-label">Temperature</label>
                                                            <input class="form-control" type="text" id="temperature"
                                                                [ngModelOptions]="{standalone: true}"
                                                                [(ngModel)]="temperature"
                                                                placeholder="Enter temperature"
                                                                value="{{ctx_walkin?.temperature}}">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-2">
                                                        <div class="mb-2">
                                                            <label for="hr" class="form-label">Heart Rate </label>
                                                            <input class="form-control" type="text" id="hr"
                                                                [ngModelOptions]="{standalone: true}" [(ngModel)]="hr"
                                                                placeholder="Enter Heart Rate"
                                                                value="{{ctx_walkin?.hr}}">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-2">
                                                        <div class="mb-2">
                                                            <label for="rr" class="form-label">Respiratory Rate </label>
                                                            <input class="form-control" type="text" id="rr"
                                                                [ngModelOptions]="{standalone: true}" [(ngModel)]="rr"
                                                                placeholder="Enter respiratory Rate"
                                                                value="{{ctx_walkin?.rr}}">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card-footer">
                                                <button class="btn btn-warning" type="button" (click)="updateVitals()">
                                                    Update
                                                </button>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <h5>Prescription</h5>

                                <input class="form-control" type="hidden" readonly formControlName="walkin_register_id">
                                <input class="form-control" type="hidden" readonly formControlName="patient_id">
                                <input class="form-control" type="hidden" readonly formControlName="prescriber_id">

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="mb-2">
                                            <label for="history" class="form-label">History</label>
                                            <textarea class="form-control" type="text" id="history"
                                                placeholder="Enter patient history" formControlName="history">
                                                    </textarea>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="mb-2">
                                            <label for="complaint_off" class="form-label">C/o <span
                                                    style="color: red;">*</span></label>
                                            <textarea class="form-control" type="text" id="complaint_off"
                                                placeholder="Patient's chief complaints ..."
                                                formControlName="complaint_off">
                                                    </textarea>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="mb-2">
                                            <label for="allergy" class="form-label">Allergy(s)</label>
                                            <textarea class="form-control" type="text" id="allergy"
                                                placeholder="Enter patient allergy(s) if any" formControlName="allergy">
                                                                                    </textarea>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="mb-2">
                                            <label for="investigations" class="form-label">Investigation(s)</label>
                                            <textarea class="form-control" type="text" id="investigations"
                                                placeholder="Patient's lab(s) and investigation(s), if needed"
                                                formControlName="investigations">
                                                                                    </textarea>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="mb-2">
                                            <label for="advice" class="form-label">Diagnosis (ICD)</label>
                                            <div class="input-group mb-2">
                                                <input type="hidden" class="form-control" id="icd_code"
                                                    placeholder="Enter ICD search string" formControlName="icd_code"
                                                    aria-label="ICD Code" aria-describedby="basic-addon2">
                                                <input type="text" class="form-control" type="text" id="icd_code"
                                                    placeholder="Enter ICD search string" [(ngModel)]="icd_showstring"
                                                    aria-label="ICD Code" [ngModelOptions]="{standalone: true}"
                                                    (ngModelChange)="toggleICDSearchableButton(icd_showstring)"
                                                    aria-describedby="basic-addon2">
                                                <input type="text" class="form-control" type="hidden" id="phone"
                                                    placeholder="Enter user's phone" formControlName="patient_id"
                                                    aria-label="User's phone" aria-describedby="basic-addon2">
                                                <div class="input-group-append">
                                                    <button class="btn btn-success" data-bs-toggle="modal"
                                                        data-bs-target="#icdChoice" (click)="searchICD(icd_showstring)"
                                                        type="button" [disabled]="!icd_searchable">Search</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                  <div class="col-md-4">
                                    <div class="mb-2">
                                      <label for="diagnosis" class="form-label">Diagnosis <span
                                        style="color: red;">*</span></label>
                                      <textarea class="form-control" type="text" id="diagnosis"
                                                placeholder="Enter patient diagnosis" formControlName="diagnosis">
                                                    </textarea>
                                    </div>
                                  </div>
                                    <div class="col-md-4">
                                        <div class="mb-2">
                                            <label for="prognosis" class="form-label">Prognosis </label>
                                            <textarea class="form-control" type="text" id="prognosis"
                                                placeholder="Enter patient prognosis" formControlName="prognosis">
                                                    </textarea>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="mb-2">
                                            <label for="advice" class="form-label">Advice </label>
                                            <textarea class="form-control" type="text" id="advice"
                                                placeholder="Advice if any ..." formControlName="advice">
                                                    </textarea>
                                        </div>
                                    </div>
                                </div>


                                <div class="row table-responsive">
                                    <div class="col-md-12">
                                        <div class="card border border-secondary">
                                            <div class="card-header">
                                                In Pharmacy Medication
                                            </div>
                                            <div class="card-body table-responsive">
                                                <div class="row">
                                                    <table class="table table-bordered table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th>Sr. No.</th>
                                                                <th>Medicine</th>
                                                                <th>Frequency</th>
                                                                <th>Duration (Days)</th>
                                                                <th>Instructions</th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let med of med_in_pharma_array; let i = index">
                                                                <td>{{ i + 1 }}.</td>
                                                                <td>{{med.medicine_id.split("~~")[1]?.toUpperCase()}}
                                                                </td>
                                                                <td>{{med.frequency}}</td>
                                                                <td>{{med.duration}} days</td>
                                                                <td>{{med.instructions}}</td>
                                                                <td><button class="btn btn-danger" type="button"
                                                                        (click)="popInPharmaMedicine(med)">Remove</button>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td></td>
                                                                <td>
                                                                    <select class="form-control"
                                                                        [(ngModel)]="medicine_id"
                                                                        [ngModelOptions]="{standalone: true}">
                                                                        <option value="">-- Select One --</option>
                                                                        <option *ngFor="let med of medicine_list"
                                                                            value="{{med.batches[0]._id}}~~{{med.batches[0].name}}">
                                                                            {{med.batches[0].type}} -
                                                                            {{med.batches[0].name.toUpperCase()}}
                                                                            {{med.batches[0].potency}}</option>
                                                                    </select>
                                                                </td>
                                                                <td>
                                                                    <select class="form-control" [(ngModel)]="frequency"
                                                                        [ngModelOptions]="{standalone: true}">
                                                                        <option value="">-- Select One --</option>
                                                                        <option value="Once a Day (OD)">Once a Day
                                                                        </option>
                                                                        <option value="Twice a Day (BD)">Twice a Day
                                                                        </option>
                                                                        <option value="Three Times a Day (TD)">Three
                                                                            Times a Day
                                                                        </option>
                                                                        <option value="Four Times a Day (QD)">Four Times
                                                                            a Day
                                                                            (Quater Die)</option>
                                                                        <option value="At Bedtime (IHS)">At Bedtime
                                                                        </option>
                                                                        <option value="If Needed (SOS)"> If Needed
                                                                        </option>
                                                                    </select>
                                                                </td>
                                                                <td>
                                                                    <input type="number" [(ngModel)]="duration"
                                                                        class="form-control" value="0"
                                                                        [ngModelOptions]="{standalone: true}" />
                                                                </td>
                                                                <td>
                                                                    <select type="text" [(ngModel)]="instructions"
                                                                        class="form-control"
                                                                        placeholder="Valid Instructions .."
                                                                        [ngModelOptions]="{standalone: true}">
                                                                        <option value="">-- Selection One --</option>
                                                                        <option value="Before Breakfast">Before
                                                                            Breakfast</option>
                                                                        <option value="After Breakfast">After Breakfast
                                                                        </option>
                                                                        <option value="Before Meal">Before Meal</option>
                                                                        <option value="After Meal">After Meal</option>
                                                                        <option value="Empty Stomach">Empty Stomach
                                                                        </option>
                                                                        <option value="Night Time Before Sleep">Night
                                                                            Time Before
                                                                            Sleep</option>
                                                                    </select>
                                                                </td>
                                                                <td><button class="btn btn-warning" type="button"
                                                                        (click)="addInPharmaMedicine()">Add</button>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row table-responsive">
                                    <div class="col-md-12">
                                        <div class="card border border-secondary">
                                            <div class="card-header">
                                                Add-on Medication
                                            </div>
                                            <div class="card-body table-responsive">
                                                <div class="row">
                                                    <table class="table table-bordered table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th>Sr. No.</th>
                                                                <th>Medicine</th>
                                                                <th>Frequency</th>
                                                                <th>Duration (Days)</th>
                                                                <th>Instructions</th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr
                                                                *ngFor="let med of med_addon_medicine_array; let i = index">
                                                                <td>{{ i + 1 }}.</td>
                                                                <td>{{med.name}}</td>
                                                                <td>{{med.frequency}}</td>
                                                                <td>{{med.duration}} days</td>
                                                                <td>{{med.instructions}}</td>
                                                                <td><button class="btn btn-danger" type="button"
                                                                        (click)="popAddonMedicine(med)">Remove</button>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td></td>
                                                                <td>
                                                                    <input type="text" [(ngModel)]="name"
                                                                        class="form-control"
                                                                        [ngModelOptions]="{standalone: true}" />
                                                                </td>
                                                                <td>
                                                                    <select class="form-control"
                                                                        [(ngModel)]="frequency_addon"
                                                                        [ngModelOptions]="{standalone: true}">
                                                                        <option value="">-- Select One --</option>
                                                                        <option value="Once a Day (OD)">Once a Day
                                                                        </option>
                                                                        <option value="Twice a Day (BD)">Twice a Day
                                                                        </option>
                                                                        <option value="Three Times a Day (TD)">Three
                                                                            Times a Day
                                                                        </option>
                                                                        <option value="Four Times a Day (QD)">Four Times
                                                                            a Day
                                                                            (Quater Die)</option>
                                                                        <option value="At Bedtime (IHS)">At Bedtime
                                                                        </option>
                                                                        <option value="If Needed (SOS)"> If Needed
                                                                        </option>
                                                                    </select>
                                                                </td>
                                                                <td>
                                                                    <input type="number" [(ngModel)]="duration_addon"
                                                                        class="form-control" value="0"
                                                                        [ngModelOptions]="{standalone: true}" />
                                                                </td>
                                                                <td>
                                                                    <select type="text" [(ngModel)]="instructions_addon"
                                                                        class="form-control"
                                                                        placeholder="Valid Instructions .."
                                                                        [ngModelOptions]="{standalone: true}">
                                                                        <option value="">-- Selection One --</option>
                                                                        <option value="Before Breakfast">Before
                                                                            Breakfast</option>
                                                                        <option value="After Breakfast">After Breakfast
                                                                        </option>
                                                                        <option value="Before Meal">Before Meal</option>
                                                                        <option value="After Meal">After Meal</option>
                                                                        <option value="Empty Stomach">Empty Stomach
                                                                        </option>
                                                                        <option value="Night Time Before Sleep">Night
                                                                            Time Before
                                                                            Sleep</option>
                                                                    </select>
                                                                </td>
                                                                <td><button class="btn btn-warning" type="button"
                                                                        (click)="addAddonMedicine()">Add</button></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-3">
                                        <div class="mb-2">
                                            <label>Referred Outside? </label> <br />
                                            <input type="checkbox" formControlName="is_referred_outside" />
                                        </div>
                                    </div>

                                    <div class="col-md-3"
                                        *ngIf="prescriptionFormGroup.controls.is_referred_outside.value">
                                        <div class="mb-2">
                                            <label for="referred_outside_to" class="form-label">Referred To <span
                                                    style="color: red;">*</span></label>
                                            <input class="form-control" type="text" id="referred_outside_to"
                                                placeholder="Referred to Hospital / Physician"
                                                formControlName="referred_outside_to">
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-3">
                                        <label>Recommend Rest?</label>
                                        <select class="form-control" [ngModelOptions]="{standalone: true}"
                                            [(ngModel)]="recommend_rest">
                                            <option value="No">No</option>
                                            <option value="Yes">Yes</option>
                                        </select>
                                    </div>
                                    <div class="col-md-3" *ngIf="recommend_rest == 'Yes'">
                                        <label>Recommended Duration (In minutes.)</label>
                                        <input class="form-control" value="Add Minutes recommend" type="number"
                                            formControlName="recommend_rest" />
                                    </div>
                                    <div class="col-md-3">
                                        <label>Follow-Up Date</label>
                                        <input class="form-control" type="date" formControlName="followup_on" />
                                    </div>
                                </div>
                                <hr />
                                <div class="mb-2 text-left">
                                    <button class="btn btn-primary" type="submit"
                                        [disabled]="!prescriptionFormGroup.valid">Save
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <b>Disclaimer:</b> Not intended for medico-Legal purposes.
                </div>
            </div>
        </div>
    </div>
</div> <!-- container -->
<!-- Dashboard init-->


<!-- Center modal content -->
<div class="modal fade" id="icdChoice" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="myCenterModalLabel">Choose Code</h4>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <table class="table table-bordered table-hover">
                    <thead>
                        <tr>
                            <th>Sr. No.</th>
                            <th>Code</th>
                            <th>Term</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style="text-align: center;" *ngIf="!icd_array.length">
                            <td colspan="100">No ICD Matches Found</td>
                        </tr>
                        <tr *ngFor="let icd of icd_array; let i = index">
                            <td>{{i + 1}}</td>
                            <td>{{icd.coding_L4}}</td>
                            <td>{{icd.meaning_L4}}</td>
                            <td><button class="btn btn-primary btn-sm" data-bs-dismiss="modal" aria-label="Close"
                                    type="button" (click)="setICDChoice(icd._id + '~~'  + icd.meaning_L4)">Pick</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
