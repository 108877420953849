<!-- Start Content-->
<div class="container-fluid">

    <!-- start page title -->
    <div class="row">
        <div class="col-12">
            <div class="page-title-box page-title-box-alt">
                <h4 class="page-title">Labs Management</h4>
                <div class="page-title-right">
                    <ol class="breadcrumb m-0">

                        <li class="breadcrumb-item"><a href="javascript: void(0);">Health Checkup</a></li>
                        <li class="breadcrumb-item active">Lab Centers</li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
    <!-- end page title -->

    <div class="row">
        <div class="col-xl-12 col-md-12">
            <div class="card">
                <div class="card-header">
                    Lab Tests
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-xs-12">
                            <form [formGroup]="labsFormGroup" class="" (ngSubmit)="save()">
                                <div class="alert alert-danger" *ngIf="error_message">{{error_message}}</div>
                                <div class="alert alert-success" *ngIf="success_message">{{success_message}}</div>

                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="mb-2">
                                            <label for="name" class="form-label">Name <span
                                                    style="color: red;">*</span></label>
                                            <input class="form-control" type="text" id="name"
                                                placeholder="Enter lab name" formControlName="name">
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="mb-2">
                                            <label for="category" class="form-label">Category <span
                                                    style="color: red;">*</span></label>
                                            <input class="form-control" type="text" id="category"
                                                placeholder="Enter category" formControlName="category">
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="mb-2">
                                            <label for="sub_category" class="form-label">Sub-Category <span
                                                    style="color: red;">*</span></label>
                                            <input class="form-control" type="text" id="sub_category"
                                                placeholder="Enter corporate email" formControlName="sub_category">
                                        </div>
                                    </div>
                                </div>

                                <div class="mb-2 text-left">
                                    <button class="btn btn-primary" type="submit" [disabled]="!labsFormGroup.valid">Save
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-xl-12 col-md-12">
            <div class="card">
                <div class="card-header">
                    System Lab Test List
                </div>
                <div class="card-body">
                    <div class="row table-responsive">
                        <table class="table table-striped table-hover table-bordered">
                            <thead>
                                <tr>
                                    <th>Sr. No.</th>
                                    <th>Category</th>
                                    <th>Sub Category</th>
                                    <th>Name</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style="text-align: center;" *ngIf="!labs_list.length">
                                    <td colspan="100">No Content</td>
                                </tr>
                                <tr *ngFor="let s of labs_list; let i = index">
                                    <td class="col-1">{{i + 1}}</td>
                                    <td>{{s.category}}</td>
                                    <td>{{s.sub_category}}</td>
                                    <td>{{s.name}}</td>
                                    <td>
                                        <button class="btn btn-danger btn-sm" type="button"
                                            (click)="updateModal(s)">Update</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div><!-- end col -->
    </div>
    <!-- end row -->

</div> <!-- container -->
<!-- Dashboard init-->