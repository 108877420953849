<!-- Start Content-->
<div class="container-fluid">

    <!-- start page title -->
    <div class="row">
        <div class="col-12">
            <div class="page-title-box page-title-box-alt">
                <h4 class="page-title">Pharmacy Report</h4>
                <div class="page-title-right">
                    <ol class="breadcrumb m-0">

                        <li class="breadcrumb-item"><a href="javascript: void(0);">Clinic</a></li>
                        <li class="breadcrumb-item active">Pharmacy</li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
    <!-- end page title -->

    <div class="row">
        <div class="col-xl-12 col-md-12">
            <div class="card">
                <div class="card-header">
                    Stock ledger
                </div>
                <div class="card-body">
                    <div class="col-xs-12">
                        <form [formGroup]="ledgerFormGroup" class="" (ngSubmit)="searchMedicine()">
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="mb-2">
                                        <label for="brand" class="form-label">Medicine</label>
                                        <select class="form-control" id="brand" formControlName="medicine_id">
                                            <option value=""> -- Choose One -- </option>
                                            <option value="{{medicine.batches[0]._id}}"
                                                *ngFor="let medicine of medicine_list">
                                                {{medicine.batches[0].name}}</option>
                                        </select>
                                    </div>
                                </div>
                                <!-- <div class="col-md-3">
                                    <div class="mb-2">
                                        <label for="stock" class="form-label">From Date <span
                                                style="color: red;">*</span></label>
                                        <input class="form-control" type="date" required
                                            id="stock" formControlName="from_date">
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="mb-2">
                                        <label for="stock" class="form-label">To Date <span
                                            style="color: red;">*</span></label>
                                    <input class="form-control" type="date" required
                                        id="stock" formControlName="to_date">
                                    </div>
                                </div> -->
                                <div class="col-md-3">
                                    <div class="mb-6" style="padding-top: 9%;">
                                        <button type="submit" [disabled]="!(ledgerFormGroup.valid)"
                                            class="btn btn-warning">Fetch</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="row table-responsive">
                        <table class="table table-striped table-hover table-bordered">
                            <thead>
                                <tr>
                                    <th>Sr. No.</th>
                                    <th>Medicine</th>
                                    <th>Brand</th>
                                    <th>Batch</th>
                                    <th>Opening Stock</th>
                                    <th>Stock Change</th>
                                    <th>Closing Stock</th>
                                    <th>Time</th>
                                    <th>Prescription</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style="text-align: center;" *ngIf="!ledger_list.length">
                                    <td colspan="100">No Records</td>
                                </tr>
                                <tr *ngFor="let medicine of ledger_list; let i = index">
                                    <td class="col-1">{{i + 1}}</td>
                                    <td>{{medicine.medicine_id.type}} {{medicine.medicine_id.name.toUpperCase()}}</td>
                                    <td>{{medicine.medicine_id.brand.toUpperCase()}}</td>
                                    <td>{{medicine.medicine_id.batch_number.toUpperCase()}}</td>
                                    <td>
                                        {{medicine.pre_change}}
                                    </td>
                                    <td>
                                        {{medicine.delta}}
                                    </td>
                                    <td>
                                        {{medicine.post_change}}
                                    </td>
                                    <td>
                                        {{medicine.createdAt | date: 'medium'}}
                                    </td>
                                    <td class="col-1"><button *ngIf="medicine.prescription_id"
                                            class="btn btn-sm btn-danger" type="button"
                                            (click)="viewPrescription(medicine.prescription_id)">Select</button></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div><!-- end col -->
    </div>
    <!-- end row -->

</div> <!-- container -->
<!-- Dashboard init-->