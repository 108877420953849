import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CompanyService } from './company.service';
import { ResponseObject } from 'src/app/shared/model/response.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.css']
})
export class CompanyComponent {

  public error_message: string = "";
  public success_message: string = "";

  public company_list: any = [];
  public op_type: string = "Create";
  public company_id: string = "";

  public file: any;
  public s3BaseUrl: string = environment.s3URL;

  public companyFormGroup = new FormGroup({
    _id: new FormControl(),
    name: new FormControl("", [Validators.required]),
    gst: new FormControl("", [Validators.required]),
    legal_name: new FormControl("", [Validators.required]),
    domain: new FormControl("", [Validators.required]),
    is_active: new FormControl(false),
    whitelable_domain: new FormControl(""),
    is_whitelisted: new FormControl(false),
    spoc_details: new FormGroup({
      name: new FormControl("", [Validators.required]),
      phone: new FormControl("", [Validators.required]),
      email: new FormControl("", [Validators.required])
    }),
    billing_address: new FormGroup({
      line1: new FormControl("", [Validators.required]),
      line2: new FormControl(),
      state: new FormControl("", [Validators.required]),
      city: new FormControl("", [Validators.required]),
      pincode: new FormControl("", [Validators.required])
    })
  });

  updateModal(company: any) {
    this.op_type = "Update";
    this.company_id = company._id;
    delete company.__v;
    this.companyFormGroup.patchValue(company);
  }

  unSetCompanyIdContext() {
    this.op_type = "Create";
    this.company_id = "";
    this.error_message = "";
    this.success_message = "";
    this.companyFormGroup.reset({
      billing_address: {
        state: ""
      }
    });
  }

  constructor(private companyService: CompanyService) { }

  private getCompanies() {
    this.companyService.getCompanies()
      .subscribe((res: ResponseObject) => {
        this.error_message = "";
        if (res.statusCode === 200) {
          this.company_list = res.data;
        }
        else {
          this.error_message = res.message;
        }
      });
  }

  ngOnInit() {
    this.getCompanies();
  }

  private createCompany(data: any) {
    this.companyService.createCompanies(data)
      .subscribe((res: ResponseObject) => {
        this.error_message = "";
        if (res.statusCode === 201) {
          this.success_message = "Company Created Successfuly."
          this.getCompanies();
        }
        else {
          this.error_message = res.message;
        }
      }, err => {
        if (err.status === 400) this.error_message = err.error.message[0];
        else this.error_message = err.error.message;
      });
  }

  private updateCompany(id: string, data: any) {
    this.companyService.updateCompanies(id, data)
      .subscribe((res: ResponseObject) => {
        this.error_message = "";
        if (res.statusCode === 200) {
          this.success_message = "Company Updated Successfuly."
          this.getCompanies();
        }
        else {
          this.error_message = res.message;
        }
      }, err => {
        if (err.status === 400) this.error_message = err.error.message[0];
        else this.error_message = err.error.message;
      });
  }

  save() {
    this.error_message = "";
    this.success_message = "";

    if (!this.companyFormGroup.valid) {
      this.error_message = "Invalid form inputs";
      return;
    }

    if (!this.companyFormGroup.value.is_active) this.companyFormGroup.value.is_active = false;
    if (!this.companyFormGroup.value.is_whitelisted) this.companyFormGroup.value.is_whitelisted = false;

    let data = {
      ... this.companyFormGroup.value
    }

    if (this.op_type === "Create")
      this.createCompany(data);
    else if (this.op_type === "Update")
      this.updateCompany(this.company_id, data)

  }

  newFileSelect(e: any) {
    if (e.target.value) {
      this.file = <File>e.target.files[0];
    }
  }

  uploadLogo() {
    let fd = new FormData();
    if (this.file) {
      fd.append('file', this.file, this.file.name);
      this.companyService.uploadLogo(this.company_id, fd)
        .subscribe((res: ResponseObject) => {
          if (res.statusCode === 200) {

          }
        });
    }
  }

}
