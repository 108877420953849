import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseObject } from 'src/app/shared/model/response.model';
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class RosterManagementService {

    constructor(private http: HttpClient) { }

    getRosterShifts(location_id: string,
        page_size: number = 0, page_number: number = 0) {
        return this.http.get<ResponseObject>(environment.apiEndpoint + "/roster-shift/" + location_id
            + "?page_size=" + page_size
            + "&page_number=" + page_number);
    }

    createRosterShift(data: any) {
        return this.http.post<ResponseObject>(environment.apiEndpoint + "/roster-shift", data);
    }
}