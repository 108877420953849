<!-- Start Content-->
<div class="container-fluid">

    <!-- start page title -->
    <div class="row">
        <div class="col-12">
            <div class="page-title-box page-title-box-alt">
                <h4 class="page-title">Health Check History</h4>
                <div class="page-title-right">
                    <ol class="breadcrumb m-0">

                        <li class="breadcrumb-item"><a href="javascript: void(0);">Health Check</a></li>
                        <li class="breadcrumb-item active">Booking History</li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
    <!-- end page title -->

    <div class="row">
        <div class="col-xl-12 col-md-12">
            <div class="card">
                <div class="card-header">
                    Booking list
                </div>
                <div class="card-body">
                    <div class="row">
                        <table class="table table-responsive table-striped table-hover table-bordered">
                            <thead>
                                <tr>
                                    <th>Sr. No.</th>
                                    <th>Package</th>
                                    <th>Centre</th>
                                    <th>Status</th>
                                    <th>Booked On</th>
                                    <th>Last Updated</th>
                                    <th>Payment Details</th>
                                    <th class="text-center"><i class="ri-file-download-fill"></i></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style="text-align: center;" *ngIf="!booking_list.length">
                                    <td colspan="100">No Records</td>
                                </tr>
                                <tr *ngFor="let p of booking_list; let i = index">
                                    <td class="col-1">{{i + 1}}</td>
                                    <td>{{p.package_id.name}}</td>
                                    <td>{{p.lab_id.name}}</td>
                                    <td>{{p.status}}</td>
                                    <td>{{p.createdAt| date: 'short'}}</td>
                                    <td>{{p.updatedAt | date: 'short'}}</td>
                                    <td>
                                        <span *ngIf="p.status !='Pending Payment' ">{{p.payment_id?._id ||
                                            "N/A"}}</span>
                                        <button class="btn btn-warning btn-xs" type="button"
                                            *ngIf="!p.payment_id && p.status =='Pending Payment'"
                                            (click)="retryPayment(p)">Retry Payment</button>
                                        <div *ngIf="!p.payment_id && p.status =='Pending Payment'">
                                            <form target="_blank" method="post" id="payuPaymentForm"
                                                action="https://secure.payu.in/_payment" [formGroup]="paymentFormGroup">
                                                <input type="hidden" formControlName="key" name="key" />
                                                <input type="hidden" formControlName="txnid" name="txnid" />
                                                <input type="hidden" formControlName="amount" name="amount" />
                                                <input type="hidden" formControlName="productinfo" name="productinfo" />
                                                <input type="hidden" formControlName="firstname" name="firstname" />
                                                <input type="hidden" formControlName="email" name="email" />
                                                <input type="hidden" formControlName="phone" name="phone" />
                                                <input type="hidden" formControlName="surl" name="surl" />
                                                <input type="hidden" formControlName="furl" name="furl" />
                                                <input type="hidden" formControlName="udf1" name="udf1" />
                                                <input type="hidden" formControlName="udf2" name="udf2" />
                                                <input type="hidden" formControlName="hash" name="hash" />
                                                <button type="submit" style="display: none;"
                                                    id="btnPaymentSubmit"></button>
                                            </form>
                                        </div>
                                    </td>
                                    <td class="col-1">
                                        <span *ngFor="let r of p.report_files; let j = index">
                                            <a href="{{s3BaseUrl}}/{{r.url}}" target="_blank">Report #{{j+1}}</a> <br />
                                        </span>
                                    </td>
                                    <td class="col-1">
                                        <div class="input-group">
                                            <button
                                                class="btn btn-primary waves-effect btn-xs waves-light dropdown-toggle"
                                                type="button" data-bs-toggle="dropdown" aria-haspopup="true"
                                                aria-expanded="false">Actions <i
                                                    class="mdi mdi-chevron-down"></i></button>
                                            <div class="dropdown-menu">
                                                <a class="dropdown-item" data-bs-toggle="modal"
                                                    data-bs-target="#reportUploadModal"
                                                    (click)="openReportUploadForm(p)" href="#">Upload Report</a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="btn-group mb-2 float-end">
                        <button type="button" (click)="getPrevPage()"
                            class="btn btn-secondary btn-bordered waves-effect" [disabled]="page_number == 0">
                            <i class="fas fa-arrow-left"></i> &nbsp; Prev
                        </button>
                        <button [disabled]="!(booking_list.length >= page_size)" type="button" (click)="getNextPage()"
                            class="btn btn-secondary btn-bordered waves-effect">
                            Next &nbsp; <i class="fas fa-arrow-right"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div><!-- end col -->
    </div>
</div> <!-- container -->
<!-- Dashboard init-->

<!-- Center modal content -->
<div class="modal fade" id="reportUploadModal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-md">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="myCenterModalLabel">Upload Report</h4>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <h4 style="display: inline;">{{booking?.user_id?.name}}</h4> &nbsp;
                <small>{{booking?.package_id?.name}}</small>
                <div class="mb-2">
                    <input class="form-control" type="file" (input)="newFileSelect($event)" />
                </div>
                <div class="mb-2">
                    <button class="btn btn-primary btn-xs" type="button" (click)="uploadReport()">
                        Upload Report
                    </button>
                </div>
            </div>
        </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
</div><!-- /.modal -->