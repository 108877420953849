import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent, SessionExpireComponent } from './login/login.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from './shared/interceptors/jwt-embedding.interceptor';
import { AuthService } from './shared/auth.service';
import { LoginService } from './login/login.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdminDashboardComponent } from './admin/dashboard/admin-dashboard.component';
import { UserComponent, UserManagementRestrictedComponent } from './admin/user/user.component';
import { UserService } from './admin/user/user.service';
import { CompanyComponent } from './admin/company/company.component';
import { LocationComponent } from './admin/location/location.component';
import { CompanyService } from './admin/company/company.service';
import { LocationService } from './admin/location/location.service';
import { WalkInComponent, WalkInLogComponent } from './paramedic/walk-in/walk-in.component';
import { CreatePrescriptionComponent } from './doctor/create-prescription/create-prescription.component';
import { WalkInService } from './paramedic/walk-in/walk-in.service';
import { ViewDetailPrescriptionComponent, ViewPrescriptionComponent } from './doctor/view-prescriptions/view-prescription.component';
import {
  PharmacyComponent,
  PharmacyLedgerComponent,
  PharmacyLedgerMonthlyReportComponent
} from './pharmacy/pharmacy.component';
import { ClientUserManegementComponent } from './client_admin/client-user-management/client-user-management.component';
import { ClientUserManagementService } from './client_admin/client-user-management/client-user-management.service';
import { DepartmentComponent } from './client_admin/department/department.component';
import { DepartmentService } from './client_admin/department/department.service';
import { SterilizationRegisterComponent } from './paramedic/sterilization-register/sterilization-register.component';
import { SterilizationRegisterService } from './paramedic/sterilization-register/sterilization-register.service';
import { LaundryRegisterService } from './paramedic/laundry-register/laundry-register.service';
import { LaundryRegisterComponent } from './paramedic/laundry-register/laundry-register.component';
import { HandoverRegisterComponent } from './paramedic/handover/handover.component';
import { HandoverRegisterService } from './paramedic/handover/handover.service';
import { BMWRegisterComponent } from './paramedic/bmw-register/bmw-register.component';
import { BMWRegisterService } from './paramedic/bmw-register/bmw-register.service';
import { RestRegisterComponent } from './paramedic/rest-register/rest-register.component';
import { RestRegisterService } from './paramedic/rest-register/rest-register.service';
import { LabsComponent, LabsTestsComponent } from './ahc/labs/labs.component';
import { LabsService } from './ahc/labs/labs.service';
import { OHCAppointmentComponent } from './patient/ohc-appointment/ohc-appointment.component';
import { HealtchCheckPackageComponent, ListMyHCBookingsComponent, ListOpsBookingsComponent, PatientHealtchCheckPackageComponent } from './ahc/packages/health-check-packages.component';
import { HealtchCheckPackageService } from './ahc/packages/health-check-packages.service';
import { HCPackageLocationMapComponent } from './ahc/package-lab-map/health-package-location-map.component';
import { PaymentSuccessStaticComponent } from './static_pages/static_pages.component';
import { TrainingRegisterComponent } from './paramedic/training-register/training-register.component';
import { TrainingRegisterService } from './paramedic/training-register/training-register.service';
import { AmbulanceO2RegisterComponent } from './paramedic/ambulance-o2-register/ambulance-o2-register.component';
import { AmbulanceO2RegisterService } from './paramedic/ambulance-o2-register/ambulance-o2-register.service';
import { DependentComponent, UserProfileComponent } from './user/dependent.component';
import { ListMyVaccineBookingsComponent, ListOpsVaccinationBookingsComponent, PatientVaccineComponent, VaccineComponent } from './vaccine/vaccines/vaccination.component';
import { VaccinesService } from './vaccine/vaccines/vaccines.service';
import { VaccineLocationMapComponent } from './vaccine/vaccine-lab-map/vaccine-location-map.component';
import { LocationMasterService } from './shared/location_master.service';
import { NgChartsModule } from 'ng2-charts';
import { RegulatoryDataSubmissionRegisterComponent } from './paramedic/regulatory-data-submission-register/regulatory-data-submission-register.component';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { RosterUserManagementComponent } from './roster/roster-management/roster-user-management.controller';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SessionExpireComponent,
    AdminDashboardComponent,
    UserComponent,
    CompanyComponent,
    LocationComponent,
    WalkInComponent,
    WalkInLogComponent,
    CreatePrescriptionComponent,
    ViewPrescriptionComponent,
    ViewDetailPrescriptionComponent,
    PharmacyComponent,
    ClientUserManegementComponent,
    PharmacyLedgerComponent,
    PharmacyLedgerMonthlyReportComponent,
    UserManagementRestrictedComponent,
    DepartmentComponent,
    SterilizationRegisterComponent,
    LaundryRegisterComponent,
    HandoverRegisterComponent,
    BMWRegisterComponent,
    RestRegisterComponent,
    TrainingRegisterComponent,
    AmbulanceO2RegisterComponent,
    LabsComponent,
    OHCAppointmentComponent,
    HealtchCheckPackageComponent,
    HCPackageLocationMapComponent,
    PatientHealtchCheckPackageComponent,
    ListMyHCBookingsComponent,
    ListOpsBookingsComponent,
    DependentComponent,
    VaccineComponent,
    PatientVaccineComponent,
    ListMyVaccineBookingsComponent,
    ListOpsVaccinationBookingsComponent,
    VaccineLocationMapComponent,
    UserProfileComponent,
    LabsTestsComponent,
    RegulatoryDataSubmissionRegisterComponent,
    RosterUserManagementComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    NgChartsModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    AuthService,
    LoginService,
    UserService,
    CompanyService,
    LocationService,
    WalkInService,
    ClientUserManagementService,
    DepartmentService,
    SterilizationRegisterService,
    LaundryRegisterService,
    HandoverRegisterService,
    BMWRegisterService,
    RestRegisterService,
    TrainingRegisterService,
    AmbulanceO2RegisterService,
    LabsService,
    HealtchCheckPackageService,
    PaymentSuccessStaticComponent,
    DepartmentService,
    VaccinesService,
    LocationMasterService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
