<!-- Start Content-->
<div class="container-fluid">

  <!-- start page title -->
  <div class="row">
    <div class="col-12">
      <div class="page-title-box page-title-box-alt">
        <h4 class="page-title">Pharmacy Monthly Report</h4>
        <div class="page-title-right">
          <ol class="breadcrumb m-0">

            <li class="breadcrumb-item"><a href="javascript: void(0);">Clinic</a></li>
            <li class="breadcrumb-item active">Pharmacy</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
  <!-- end page title -->

  <div class="row">
    <div class="col-xl-12 col-md-12">
      <div class="card">
        <div class="card-header">
          Stock ledger
        </div>
        <div class="card-body">
          <div class="col-xs-12">
            <div class="row">
                <div class="col-md-3">
                  <div class="mb-2">
                    <label for="brand" class="form-label">Month</label>
                    <select class="form-control" [(ngModel)]="month" (ngModelChange)="setNewMonth($event)"  id="brand">
                      <option value="0">January</option>
                      <option value="1">February</option>
                      <option value="2">March</option>
                      <option value="3">April</option>
                      <option value="4">May</option>
                      <option value="5">June</option>
                      <option value="6">July</option>
                      <option value="7">August</option>
                      <option value="8">September</option>
                      <option value="9">October</option>
                      <option value="10">November</option>
                      <option value="11">December</option>
                    </select>
                  </div>
                </div>
              </div>
          </div>
          <div class="row table-responsive">
            <table class="table table-striped table-hover table-bordered">
              <thead>
              <tr>
                <th></th>
                <th>1</th>
                <th>2</th>
                <th>3</th>
                <th>4</th>
                <th>5</th>
                <th>6</th>
                <th>7</th>
                <th>8</th>
                <th>9</th>
                <th>10</th>
                <th>11</th>
                <th>12</th>
                <th>13</th>
                <th>14</th>
                <th>15</th>
                <th>16</th>
                <th>17</th>
                <th>18</th>
                <th>19</th>
                <th>20</th>
                <th>21</th>
                <th>22</th>
                <th>23</th>
                <th>24</th>
                <th>25</th>
                <th>26</th>
                <th>27</th>
                <th>28</th>
                <th>29</th>
                <th>30</th>
                <th>31</th>
                <th><strong>Total</strong></th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let medicine of medicine_list; let i = index">
                <td class="col-1">{{medicine.medicine[0]?.name?.toUpperCase()}}</td>
                <td *ngFor="let d of medicine.days;">{{d.net_dispense}}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div><!-- end col -->
  </div>
  <!-- end row -->

</div> <!-- container -->
<!-- Dashboard init-->
