import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseObject } from 'src/app/shared/model/response.model';
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class OHCAppointmentService {

    constructor(private http: HttpClient) { }

    get(location_id: string, paitent_id: string,
        page_size: number = 0, page_number: number = 0) {
        return this.http.get<ResponseObject>(environment.apiEndpoint + "/ohc-appointment/" + location_id
            + "?booked_by=" + paitent_id
            + "&page_size=" + page_size
            + "&page_number=" + page_number);
    }

    getBySlot(location_id: string, slot: string,
        page_size: number = 0, page_number: number = 0) {
        return this.http.get<ResponseObject>(environment.apiEndpoint + "/ohc-appointment/" + location_id
            + "?slot=" + slot
            + "&page_size=" + page_size
            + "&page_number=" + page_number);
    }

    create(data: any) {
        return this.http.post<ResponseObject>(environment.apiEndpoint + "/ohc-appointment", data);
    }

    cancel(appointment_id: any) {
        return this.http.patch<ResponseObject>(environment.apiEndpoint + "/ohc-appointment/cancel/" + appointment_id, {});
    }
}