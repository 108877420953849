<!-- Start Content-->
<div class="container-fluid">

    <!-- start page title -->
    <div class="row">
        <div class="col-12">
            <div class="page-title-box page-title-box-alt">
                <h4 class="page-title">Vaccination Booking History</h4>
                <div class="page-title-right">
                    <ol class="breadcrumb m-0">

                        <li class="breadcrumb-item"><a href="javascript: void(0);">Vaccination</a></li>
                        <li class="breadcrumb-item active">Booking History</li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
    <!-- end page title -->

    <div class="row">
        <div class="col-xl-12 col-md-12">
            <div class="card">
                <div class="card-header">
                    Booking list
                </div>
                <div class="card-body">
                    <div class="row">
                        <table class="table table-responsive table-striped table-hover table-bordered">
                            <thead>
                                <tr>
                                    <th>Sr. No.</th>
                                    <th>Vaccine</th>
                                    <th>Centre</th>
                                    <th>Status</th>
                                    <th>Booked On</th>
                                    <th>Last Updated</th>
                                    <th>Payment Details</th>
                                    <th>Reports</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style="text-align: center;" *ngIf="!booking_list.length">
                                    <td colspan="100">No Records</td>
                                </tr>
                                <tr *ngFor="let p of booking_list; let i = index">
                                    <td class="col-1">{{i + 1}}</td>
                                    <td>{{p.vaccine_id.name}}</td>
                                    <td>{{p.lab_id?.name || "-- Home Pickup --"}}</td>
                                    <td>{{p.status}}</td>
                                    <td>{{p.createdAt| date: 'short'}}</td>
                                    <td>{{p.updatedAt | date: 'short'}}</td>
                                    <td>
                                        <span *ngIf="p.status !='Pending Payment' ">{{p.payment_id?._id ||
                                            "N/A"}}</span>
                                        <button class="btn btn-warning btn-xs" type="button"
                                            *ngIf="!p.payment_id && p.status =='Pending Payment'"
                                            (click)="retryPayment(p)">Retry Payment</button>
                                        <div *ngIf="!p.payment_id && p.status =='Pending Payment'">
                                            <form target="_blank" method="post" id="payuPaymentForm"
                                                action="https://secure.payu.in/_payment" [formGroup]="paymentFormGroup">
                                                <input type="hidden" formControlName="key" name="key" />
                                                <input type="hidden" formControlName="txnid" name="txnid" />
                                                <input type="hidden" formControlName="amount" name="amount" />
                                                <input type="hidden" formControlName="productinfo" name="productinfo" />
                                                <input type="hidden" formControlName="firstname" name="firstname" />
                                                <input type="hidden" formControlName="email" name="email" />
                                                <input type="hidden" formControlName="phone" name="phone" />
                                                <input type="hidden" formControlName="surl" name="surl" />
                                                <input type="hidden" formControlName="furl" name="furl" />
                                                <input type="hidden" formControlName="udf1" name="udf1" />
                                                <input type="hidden" formControlName="udf2" name="udf2" />
                                                <input type="hidden" formControlName="hash" name="hash" />
                                                <button type="submit" style="display: none;"
                                                    id="btnPaymentSubmit"></button>
                                            </form>
                                        </div>
                                    </td>
                                    <td>
                                        <span *ngFor="let r of p.report_files; let j = index">
                                            <a href="{{s3BaseUrl}}/{{r.url}}" target="_blank">Report #{{j+1}}</a> <br />
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="btn-group mb-2 float-end">
                        <button type="button" (click)="getPrevPage()"
                            class="btn btn-secondary btn-bordered waves-effect" [disabled]="page_number == 0">
                            <i class="fas fa-arrow-left"></i> &nbsp; Prev
                        </button>
                        <button [disabled]="!(booking_list.length >= page_size)" type="button" (click)="getNextPage()"
                            class="btn btn-secondary btn-bordered waves-effect">
                            Next &nbsp; <i class="fas fa-arrow-right"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div><!-- end col -->
    </div>
</div> <!-- container -->
<!-- Dashboard init-->
