<!-- Start Content-->
<div class="container-fluid">

    <!-- start page title -->
    <div class="row">
        <div class="col-12">
            <div class="page-title-box page-title-box-alt">
                <h4 class="page-title">Home</h4>
                <div class="page-title-right">
                    <ol class="breadcrumb m-0">

                        <li class="breadcrumb-item"><a href="javascript: void(0);">Dashboards</a></li>
                        <li class="breadcrumb-item active">Home</li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
    <!-- end page title -->

    <div>
        <div class="row">
            <div class="col-xl-12">
                <div class="card">
                    <div class="card-body text-center">
                        <div class="d-flex">
                            <div class="text-center">
                                <h3 class="text-center">Welcome to {{title}} Portal!</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!-- end col -->
        </div>
        <!-- end row -->

    </div> <!-- container -->

    <div class="row" *ngIf="!(is_patient_only || is_admin_only) && checkService('AHC')">
        <div class="col-xl-3 col-md-6">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex justify-content-between">
                        <div>
                            <h5 class="text-muted fw-normal mt-0 text-truncate" title="Campaign Sent">Active Packages
                            </h5>
                            <h3 class="my-2 py-1"><span>{{activePackageCount}}</span></h3>
                            <!-- <p class="mb-0 text-muted">
                                <span class="text-success me-2"><span class="mdi mdi-arrow-up-bold"></span> 5.27%</span>
                                <span class="text-nowrap">Since last month</span>
                            </p> -->
                        </div>
                        <div class="avatar-sm">
                            <span class="avatar-title bg-soft-primary rounded">
                                <i class="ri-stack-line font-20 text-primary"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div><!-- end col -->

        <div class="col-xl-3 col-md-6">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex justify-content-between">
                        <div>
                            <h5 class="text-muted fw-normal mt-0 text-truncate" title="New Leads">Total Bookings</h5>
                            <h3 class="my-2 py-1"><span>{{totalBookingCount}}</span></h3>
                            <!-- <p class="mb-0 text-muted">
                                <span class="text-danger me-2"><span class="mdi mdi-arrow-down-bold"></span>
                                    3.27%</span>
                                <span class="text-nowrap">Since last month</span>
                            </p> -->
                        </div>
                        <div class="avatar-sm">
                            <span class="avatar-title bg-soft-primary rounded">
                                <i class="ri-slideshow-2-line font-20 text-primary"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div><!-- end col -->

        <div class="col-xl-3 col-md-6">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex justify-content-between">
                        <div>
                            <h5 class="text-muted fw-normal mt-0 text-truncate" title="Deals">Awaiting
                                Reports</h5>
                            <h3 class="my-2 py-1"><span>
                                    {{awaitingReportCount}}</span></h3>
                            <!-- <p class="mb-0 text-muted">
                                <span class="text-success me-2"><span class="mdi mdi-arrow-up-bold"></span> 8.58%</span>
                                <span class="text-nowrap">Since last month</span>
                            </p> -->
                        </div>
                        <div class="avatar-sm">
                            <span class="avatar-title bg-soft-primary rounded">
                                <i class="ri-hand-heart-line font-20 text-primary"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div><!-- end col -->

        <div class="col-xl-3 col-md-6">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex justify-content-between">
                        <div>
                            <h5 class="text-muted fw-normal mt-0 text-truncate" title="Booked Revenue">Cancelled
                            </h5>
                            <h3 class="my-2 py-1"><span>{{cancelledReportCount}}</span></h3>
                            <!-- <p class="mb-0 text-muted">
                                <span class="text-success me-2"><span class="mdi mdi-arrow-up-bold"></span>
                                    34.61%</span>
                                <span class="text-nowrap">Since last month</span>
                            </p> -->
                        </div>
                        <div class="avatar-sm">
                            <span class="avatar-title bg-soft-primary rounded">
                                <i class="ri-close-circle-line font-20 text-primary"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div><!-- end col -->
    </div>

    <div class="row" *ngIf="(user_details.user_type.includes('Client Admin')) && checkService('OHC')">
        <div class="col-xl-6">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex justify-content-between">
                        <canvas style="width: 100%;" baseChart [data]="SevenDayFootfallLineChartOptionsData"
                            [options]="SevenDayFootfallLineChartOptions" [legend]="true" [type]="'line'">
                        </canvas>
                    </div>
                </div>
            </div>

        </div>

        <div class="col-xl-6">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex justify-content-between">
                        <canvas style="width: 100%;" baseChart [data]="SevenDayAvgTimeLineChartOptionsData"
                            [options]="SevenDayAvgTimeLineChartOptions" [legend]="true" [type]="'line'">
                        </canvas>
                    </div>
                </div>
            </div>
        </div><!-- end col -->

        <div class="col-xl-6">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex justify-content-between">
                        <canvas style="width: 100%;" baseChart [data]="ThirtyDaysDoctorBusyCountLineChartOptionsData"
                            [options]="ThirtyDaysDoctorBusyCountLineChartOptions" [legend]="true" [type]="'bar'">
                        </canvas>
                    </div>
                </div>
            </div>
        </div><!-- end col -->
        <div class="col-xl-6">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex justify-content-between">
                        <canvas style="width: 100%;" baseChart
                            [data]="ThirtyDaysDepartmentBusyCountLineChartOptionsData"
                            [options]="ThirtyDaysDepartmentBusyCountLineChartOptions" [legend]="true" [type]="'bar'">
                        </canvas>
                    </div>
                </div>
            </div>
        </div><!-- end col -->

        <div class="col-xl-6">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex justify-content-between">
                        <canvas style="width: 100%;" baseChart
                            [data]="ThirtyDaysPatientDemographicsBarChartStackedOptionsData"
                            [options]="ThirtyDaysPatientDemographicsBarChartStackedOptions" [legend]="true"
                            [type]="'bar'">
                        </canvas>
                    </div>
                </div>
            </div>
        </div><!-- end col -->

        <div class="col-xl-6">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex justify-content-between">
                        <canvas style="width: 100%;" baseChart [data]="Top10DiseaseLineChartOptionsData"
                            [options]="Top10DiseaseLineChartOptions" [type]="'line'">
                        </canvas>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-xl-12">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex justify-content-between">
                        <canvas style="width: 100%;" baseChart [data]="ThirtyDayEmegencyyLineChartOptionsData"
                            [options]="ThirtyDayEmegencyyLineChartOptions" [legend]="true" [type]="'line'">
                        </canvas>
                    </div>
                </div>
            </div>
        </div><!-- end col -->

        <div class="col-xl-12">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex justify-content-between">
                        <canvas style="width: 100%;" baseChart [data]="ThirtyDayDepartmentWiseInjuryData"
                            [options]="ThirtyDayDepartmentWiseInjuryOptions" [legend]="true" [type]="'bar'">
                        </canvas>
                    </div>
                </div>
            </div>
        </div><!-- end col -->

        <div class="col-xl-8">
            <div class="card">
                <div class="card-header">
                    Expiring Medicines
                </div>
                <div class="card-body">
                    <div class="row table-responsive">
                        <table class="table table-striped table-hover table-bordered">
                            <thead>
                                <tr>
                                    <th>Sr. No.</th>
                                    <th>Medicine</th>
                                    <th>Brand</th>
                                    <th>Batch</th>
                                    <th>Stock</th>
                                    <th>Expiry</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style="text-align: center;" *ngIf="!expirey_med_list.length">
                                    <td colspan="100">No Records</td>
                                </tr>
                                <tr *ngFor="let medicine of expirey_med_list; let i = index">
                                    <td class="col-1">{{i + 1}}</td>
                                    <td>{{medicine.type}} {{medicine.name.toUpperCase()}}</td>
                                    <td>{{medicine.brand.toUpperCase()}}</td>
                                    <td>{{medicine.batch_number.toUpperCase()}}</td>
                                    <td>{{medicine.stock}}</td>
                                    <td>
                                        {{medicine.expirey | date: 'medium'}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div><!-- end col -->

        <div class="col-xl-4">
            <div class="card">
                <div class="card-header">
                    Medication Trends: Top 5 Prescribed Medicines
                </div>
                <div class="card-body">
                    <div class="d-flex justify-content-between">
                        <canvas style="width: 100%;" baseChart [data]="Top5MedicationsPieOptionsData"
                            [options]="Top5MedicationsPieOptions" [type]="'pie'">
                        </canvas>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <!-- Dashboard init-->