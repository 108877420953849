<ngx-loading-bar color="#B2DD4B" [includeSpinner]="false" height="2px"></ngx-loading-bar>
<ng-container *ngIf="!isLoggedIn(); then loginTemplate; else dashboardTemplate">
</ng-container>

<ng-template #loginTemplate>
  <app-login></app-login>
</ng-template>

<ng-template #dashboardTemplate>
  <!-- Begin page -->
  <div id="wrapper">


    <!-- Topbar Start -->
    <div class="navbar-custom">
      <div class="container-fluid">

        <ul class="list-unstyled topnav-menu float-end mb-0">

          <li class="dropdown d-none d-lg-inline-block">
            <a class="nav-link dropdown-toggle arrow-none waves-effect waves-light" data-toggle="fullscreen" href="#">
              <i class="fe-maximize noti-icon"></i>
            </a>
          </li>

          <li class="dropdown notification-list topbar-dropdown">
            <a class="nav-link dropdown-toggle nav-user me-0 waves-effect waves-light" data-bs-toggle="dropdown"
              href="#" role="button" aria-haspopup="false" aria-expanded="false">
              <img src="{{s3BaseUrl}}/{{user_details.profile_picture}}" alt="user-image" class="rounded-circle">
              <span class="pro-user-name ms-1">
                {{user_details.name}} <i class="mdi mdi-chevron-down"></i>
              </span>
            </a>
            <div class="dropdown-menu dropdown-menu-end profile-dropdown ">
              <!-- item-->
              <!-- <div class="dropdown-header noti-title">
                <h6 class="text-overflow m-0">{{user_details.user_type}}</h6>
              </div> -->

              <!-- item-->
              <a [routerLink]="['/profile']" class="dropdown-item notify-item">
                <i class="ri-account-circle-line"></i>
                <span>My Profile</span>
              </a>

              <!-- <div class="dropdown-divider"></div> -->

              <!-- item-->
              <a href="javascript:void(0);" (click)="logout()" class="dropdown-item notify-item">
                <i class="ri-logout-box-line"></i>
                <span>Logout</span>
              </a>

            </div>
          </li>

        </ul>

        <!-- LOGO -->
        <div class="logo-box">
          <a href="index.html" class="logo logo-light text-center">
            <span class="logo-sm">
              <img *ngIf="is_parent_site" src="assets/images/avyukt-favicon.png" alt="" height="24">
            </span>
            <span class="logo-lg">
              <img *ngIf="is_parent_site" src="assets/images/avyukt-healthcare-logo-inverse.png" alt="" height="33">
              <img *ngIf="!is_parent_site" src="{{logo_url}}" alt="{{title}}" height="33">
            </span>
          </a>
        </div>

        <ul class="list-unstyled topnav-menu topnav-menu-left m-0">
          <li>
            <button class="button-menu-mobile waves-effect waves-light">
              <i class="fe-menu"></i>
            </button>
          </li>

          <li>
            <!-- Mobile menu toggle (Horizontal Layout)-->
            <a class="navbar-toggle nav-link" data-bs-toggle="collapse" data-bs-target="#topnav-menu-content">
              <div class="lines">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </a>
            <!-- End mobile menu toggle-->
          </li>

          <!-- <li class="dropdown d-none d-xl-block">
            <a class="nav-link dropdown-toggle waves-effect waves-light" data-bs-toggle="dropdown" href="#"
              role="button" aria-haspopup="false" aria-expanded="false">
              Create New
              <i class="mdi mdi-chevron-down"></i>
            </a>
            <div class="dropdown-menu">
          <a href="javascript:void(0);" class="dropdown-item">
            <i class="fe-briefcase me-1"></i>
            <span>New Projects</span>
          </a>

          <a href="javascript:void(0);" class="dropdown-item">
            <i class="fe-user me-1"></i>
            <span>Create Users</span>
          </a>

          <a href="javascript:void(0);" class="dropdown-item">
            <i class="fe-bar-chart-line- me-1"></i>
            <span>Revenue Report</span>
          </a>

          <a href="javascript:void(0);" class="dropdown-item">
            <i class="fe-settings me-1"></i>
            <span>Settings</span>
          </a>

          <div class="dropdown-divider"></div>

          <a href="javascript:void(0);" class="dropdown-item">
            <i class="fe-headphones me-1"></i>
            <span>Help & Support</span>
          </a>

      </div>
      </li> -->

        </ul>
        <div class="clearfix"></div>
      </div>
    </div>
    <!-- end Topbar -->

    <!-- ========== Left Sidebar Start ========== -->
    <div class="left-side-menu">

      <!-- LOGO -->
      <div class="logo-box">
        <a href="index.html" class="logo logo-light text-center">
          <span class="logo-sm">
            <img src="assets/images/avyukt-favicon.png" alt="" height="24">
          </span>
          <span class="logo-lg">
            <img *ngIf="is_parent_site" src="assets/images/avyukt-healthcare-logo-inverse.png" alt="" height="33">
            <img *ngIf="!is_parent_site" src="{{logo_url}}" alt="{{title}}" height="33">
          </span>
        </a>
      </div>

      <div class="h-100" data-simplebar>

        <!-- User box -->
        <div class="user-box text-center">
          <img src="{{s3BaseUrl}}/{{user_details.profile_picture}}" alt="user-img" title="Mat Helme"
            class="rounded-circle avatar-md">
          <div class="dropdown">
            <a href="#" class="text-reset dropdown-toggle h5 mt-2 mb-1 d-block"
              data-bs-toggle="dropdown">{{user_details.name}}</a>
            <div class="dropdown-menu user-pro-dropdown">

              <!-- item-->
              <a [routerLink]="['/profile']" class="dropdown-item notify-item">
                <i class="ri-account-circle-line"></i>
                <span>My Profile</span>
              </a>

              <!-- item-->
              <a href="javascript:void(0);" (click)="logout()" class="dropdown-item notify-item">
                <i class="fe-log-out me-1"></i>
                <span>Logout</span>
              </a>

            </div>
          </div>
          <!-- <p class="text-reset">{{ user_details.user_type.join(", ") }}</p> -->
        </div>

        <!--- Sidemenu -->
        <div id="sidebar-menu">

          <ul id="side-menu">

            <li class="menu-title">Navigation</li>
            <li>
              <a [ngClass]="{'active': checkActiveRoute('/dashboard')}" [routerLink]="['/dashboard']">
                <i class="ri-dashboard-line"></i>
                <span> Dashboard </span>
              </a>
            </li>

            <li *ngIf="user_details.user_type.includes('Patient')">
              <a [ngClass]="{'active': checkActiveRoute('/manage-dependents')}" [routerLink]="['/manage-dependents']">
                <i class="ri-user-add-line"></i>
                <span> Manage Relatives </span>
              </a>
            </li>

            <li *ngIf="user_details.user_type.includes('Admin')">
              <a [ngClass]="{'active': checkActiveRoute('/user')}" [routerLink]="['/user']">
                <i class="ri-user-voice-line"></i>
                <span> Master User Management </span>
              </a>
            </li>

            <li *ngIf="user_details.user_type.includes('Admin')">
              <a [ngClass]="{'active': checkActiveRoute('/lab-tests')}" [routerLink]="['/lab-tests']">
                <i class="ri-flask-line"></i>
                <span> Lab Tests </span>
              </a>
            </li>

            <li *ngIf="user_details.user_type.includes('Admin')">
              <a [ngClass]="{'active': checkActiveRoute('/lab-centers')}" [routerLink]="['/lab-centers']">
                <i class="ri-hospital-line"></i>
                <span> Lab Centers </span>
              </a>
            </li>

            <li *ngIf="user_details.user_type.includes('Admin')">
              <a [ngClass]="{'active': checkActiveRoute('/health-check-packages')}"
                [routerLink]="['/health-check-packages']">
                <i class="ri-layout-masonry-line"></i>
                <span> HC Packages </span>
              </a>
            </li>

            <li *ngIf="user_details.user_type.includes('Admin')">
              <a [ngClass]="{'active': checkActiveRoute('/manage-vaccines')}" [routerLink]="['/manage-vaccines']">
                <i class="ri-drop-line"></i>
                <span> Vaccines </span>
              </a>
            </li>

            <li *ngIf="user_details.user_type.includes('Patient')">
              <a [ngClass]="{'active': checkActiveRoute('/list-prescription')}" [routerLink]="['/list-prescription']">
                <i class="ri-ball-pen-line"></i>
                <span> My Prescriptions </span>
              </a>
            </li>

            <li *ngIf="user_details.user_type.includes('Patient')">
              <a [ngClass]="{'active': checkActiveRoute('/my-health-check-booking')}"
                [routerLink]="['/my-health-check-booking']">
                <i class="ri-booklet-line"></i>
                <span> My Health Checks </span>
              </a>
            </li>

            <li *ngIf="user_details.user_type.includes('Patient')">
              <a [ngClass]="{'active': checkActiveRoute('/my-vaccination-booking')}"
                [routerLink]="['/my-vaccination-booking']">
                <i class="ri-water-flash-line"></i>
                <span> My Vaccinations </span>
              </a>
            </li>

            <li class="menu-title mt-2" *ngIf="user_details.user_type.includes('Admin')">Customers</li>
            <li *ngIf="user_details.user_type.includes('Admin')">
              <a [ngClass]="{'active': checkActiveRoute('/company-management')}" [routerLink]="['/company-management']">
                <i class="ri-building-2-line"></i>
                <span> Company </span>
              </a>
            </li>

            <li *ngIf="user_details.user_type.includes('Admin')">
              <a [ngClass]="{'active': checkActiveRoute('/company-location-management')}"
                [routerLink]="['/company-location-management']">
                <i class="ri-gps-line"></i>
                <span> Location </span>
              </a>
            </li>

            <!-- <li class="menu-title mt-2" *ngIf="user_details.user_type.includes('Ops')">Roster Management</li>

            <li *ngIf="user_details.user_type.includes('Ops')">
              <a [ngClass]="{'active': checkActiveRoute('/company-location-management')}"
                [routerLink]="['/company-location-management']">
                <i class="ri-user-unfollow-line"></i>
                <span> Leaves </span>
              </a>
            </li>
            <li *ngIf="user_details.user_type.includes('Ops')">
              <a [ngClass]="{'active': checkActiveRoute('/company-location-management')}"
                [routerLink]="['/company-location-management']">
                <i class="ri-survey-line"></i>
                <span> Attendance </span>
              </a>
            </li>
            <li *ngIf="user_details.user_type.includes('Ops')">
              <a [ngClass]="{'active': checkActiveRoute('/company-location-management')}"
                [routerLink]="['/company-location-management']">
                <i class="ri-user-add-line"></i>
                <span> Pending Positions </span>
              </a>
            </li>
            <li *ngIf="user_details.user_type.includes('Ops')">
              <a [ngClass]="{'active': checkActiveRoute('/company-location-management')}"
                [routerLink]="['/company-location-management']">
                <i class="ri-user-voice-line"></i>
                <span> Announcements </span>
              </a>
            </li> -->

            <li class="menu-title mt-2">Your Locations</li>
            <li *ngFor="let loc of user_locations_arr; let i = index">
              <a href="#sidebarLocations{{i}}" data-bs-toggle="collapse" aria-expanded="false"
                aria-controls="sidebarLocations" class="collapsed">
                <i class="ri-building-line"></i>
                <span> {{loc[0]?.company_name}} </span>
                <span class="menu-arrow"></span>
              </a>
              <div class="collapse" id="sidebarLocations{{i}}" *ngFor="let l of loc">
                <ul class="nav-second-level">
                  <li class="menu-title"> <strong style="font-weight: 900;">{{l.location_alias}} </strong></li>

                  <li *ngIf="user_details.user_type.includes('Paramedic')
                  && l.services.includes('OHC')">
                    <a [ngClass]="{'active': checkActiveRoute('/walk-in')}" [routerLink]="['/walk-in']"
                      [queryParams]="{ l: l.location_id, c: l.company_id }">
                      <!-- <i class="ri-guide-line"></i> -->
                      <span> OPD </span>
                    </a>
                  </li>
                  <li *ngIf="user_details.user_type.includes('Paramedic')
                  && l.services.includes('OHC')
                  && l.registers?.includes('WalkIn')">
                    <a [ngClass]="{'active': checkActiveRoute('/walk-in-log')}" [routerLink]="['/walk-in-log']"
                      [queryParams]="{ l: l.location_id, c: l.company_id }">
                      <!-- <i class="ri-guide-line"></i> -->
                      <span> OPD Log </span>
                    </a>
                  </li>
                  <li *ngIf="user_details.user_type.includes('Patient')
                  && l.services.includes('OHC')
                  && l.registers?.includes('Appointments')">
                    <a [ngClass]="{'active': checkActiveRoute('/ohc-appointment')}" [routerLink]="['/ohc-appointment']"
                      [queryParams]="{ l: l.location_id, c: l.company_id }">
                      <!-- <i class="ri-guide-line"></i> -->
                      <span> Book Appointment </span>
                    </a>
                  </li>
                  <li *ngIf="(user_details.user_type.includes('Patient') || user_details.user_type.includes('Client Admin'))
                   && l.services.includes('Vaccine')">
                    <a [ngClass]="{'active': checkActiveRoute('/book-vaccination')}"
                      [routerLink]="['/book-vaccination']" [queryParams]="{ l: l.location_id, c: l.company_id }">
                      <!-- <i class="ri-guide-line"></i> -->
                      <span> Active Vaccinations </span>
                    </a>
                  </li>
                  <li *ngIf="(user_details.user_type.includes('Patient') || user_details.user_type.includes('Client Admin'))
                  && l.services.includes('AHC')">
                    <a [ngClass]="{'active': checkActiveRoute('/book-health-checkup')}"
                      [routerLink]="['/book-health-checkup']" [queryParams]="{ l: l.location_id, c: l.company_id }">
                      <!-- <i class="ri-guide-line"></i> -->
                      <span> Active Health Package </span>
                    </a>
                  </li>
                  <li>
                    <a *ngIf="(user_details.user_type.includes('Ops')
                    || user_details.user_type.includes('Client Admin'))
                    && l.services.includes('Vaccine')"
                      [ngClass]="{'active': checkActiveRoute('/ops-vaccination-booking')}"
                      [routerLink]="['/ops-vaccination-booking']" [queryParams]="{ l: l.location_id, c: l.company_id }">
                      <!-- <i class="ri-guide-line"></i> -->
                      <span> Vaccination Requests </span>
                    </a>
                  </li>
                  <li>
                    <a *ngIf="(user_details.user_type.includes('Ops') || user_details.user_type.includes('Client Admin'))
                    && l.services.includes('AHC')"
                      [ngClass]="{'active': checkActiveRoute('/ops-health-check-booking')}"
                      [routerLink]="['/ops-health-check-booking']"
                      [queryParams]="{ l: l.location_id, c: l.company_id }">
                      <!-- <i class="ri-guide-line"></i> -->
                      <span> HC Requests </span>
                    </a>
                  </li>
                  <li *ngIf="user_details.user_type.includes('Paramedic')
                  && l.services.includes('OHC')
                  && l.registers?.includes('Sterilization')">
                    <a [ngClass]="{'active': checkActiveRoute('/sterilization-register')}"
                      [routerLink]="['/sterilization-register']" [queryParams]="{ l: l.location_id, c: l.company_id }">
                      <!-- <i class="ri-guide-line"></i> -->
                      <span> Sterilization </span>
                    </a>
                  </li>
                  <li *ngIf="user_details.user_type.includes('Paramedic')
                  && l.services.includes('OHC')
                  && l.registers?.includes('Laundry')">
                    <a [ngClass]="{'active': checkActiveRoute('/laundry-register')}"
                      [routerLink]="['/laundry-register']" [queryParams]="{ l: l.location_id, c: l.company_id }">
                      <!-- <i class="ri-guide-line"></i> -->
                      <span> Laundry </span>
                    </a>
                  </li>
                  <li *ngIf="user_details.user_type.includes('Paramedic')
                  && l.services.includes('OHC')
                  && l.registers?.includes('Handover')">
                    <a [ngClass]="{'active': checkActiveRoute('/handover-register')}"
                      [routerLink]="['/handover-register']" [queryParams]="{ l: l.location_id, c: l.company_id }">
                      <!-- <i class="ri-guide-line"></i> -->
                      <span> Handover </span>
                    </a>
                  </li>
                  <li *ngIf="(user_details.user_type.includes('Paramedic'))
                                    && l.services.includes('OHC')
                                    && l.registers?.includes('RegulatoryData')">
                    <a [ngClass]="{'active': checkActiveRoute('/regulatory-data-register')}"
                      [routerLink]="['/regulatory-data-register']"
                      [queryParams]="{ l: l.location_id, c: l.company_id }">
                      <!-- <i class="ri-guide-line"></i> -->
                      <span> Regulatory Data </span>
                    </a>
                  </li>
                  <li *ngIf="user_details.user_type.includes('Paramedic')
                  && l.services.includes('OHC')
                  && l.registers?.includes('BMW')">
                    <a [ngClass]="{'active': checkActiveRoute('/bmw-register')}" [routerLink]="['/bmw-register']"
                      [queryParams]="{ l: l.location_id, c: l.company_id }">
                      <!-- <i class="ri-guide-line"></i> -->
                      <span> Medical Waste </span>
                    </a>
                  </li>
                  <li *ngIf="user_details.user_type.includes('Paramedic')
                  && l.services.includes('OHC')
                  && l.registers?.includes('Rest')">
                    <a [ngClass]="{'active': checkActiveRoute('/rest-register')}" [routerLink]="['/rest-register']"
                      [queryParams]="{ l: l.location_id, c: l.company_id }">
                      <!-- <i class="ri-guide-line"></i> -->
                      <span> Rest </span>
                    </a>
                  </li>
                  <li *ngIf="(user_details.user_type.includes('Paramedic')
                   || user_details.user_type.includes('Ops')
                   || user_details.user_type.includes('Doctor'))
                   && l.services.includes('OHC')
                   && l.registers?.includes('Training')">
                    <a [ngClass]="{'active': checkActiveRoute('/training-register')}"
                      [routerLink]="['/training-register']" [queryParams]="{ l: l.location_id, c: l.company_id }">
                      <!-- <i class="ri-guide-line"></i> -->
                      <span> Training </span>
                    </a>
                  </li>
                  <li *ngIf="user_details.user_type.includes('Paramedic')
                  && l.services.includes('OHC')
                  && l.registers?.includes('Ambulance-O2')">
                    <a [ngClass]="{'active': checkActiveRoute('/ambulance-o2-register')}"
                      [routerLink]="['/ambulance-o2-register']" [queryParams]="{ l: l.location_id, c: l.company_id }">
                      <!-- <i class="ri-guide-line"></i> -->
                      <span> Ambulance O2 </span>
                    </a>
                  </li>
                  <li *ngIf="user_details.user_type.includes('Doctor')
                  && l.services.includes('OHC')">
                    <a [ngClass]="{'active': checkActiveRoute('/create-prescription')}"
                      [routerLink]="['/create-prescription']" [queryParams]="{ l: l.location_id, c: l.company_id }">
                      <!-- <i class="ri-guide-line"></i> -->
                      <span> Prescribe </span>
                    </a>
                  </li>
                  <li *ngIf="user_details.user_type.includes('Doctor')
                  && l.services.includes('OHC')">
                    <a [ngClass]="{'active': checkActiveRoute('/list-prescription')}"
                      [routerLink]="['/list-prescription']" [queryParams]="{ l: l.location_id, c: l.company_id }">
                      <!-- <i class="ri-guide-line"></i> -->
                      <span> Past Prescriptions </span>
                    </a>
                  </li>
                  <li *ngIf="user_details.user_type.includes('Pharmacy')
                  && l.services.includes('OHC')">
                    <a [ngClass]="{'active': checkActiveRoute('/pharmacy')}" [routerLink]="['/pharmacy']"
                      [queryParams]="{ l: l.location_id, c: l.company_id }">
                      <!-- <i class="ri-guide-line"></i> -->
                      <span> Pharmacy </span>
                    </a>
                  </li>
                  <li *ngIf="user_details.user_type.includes('Pharmacy')
                  && l.services.includes('OHC')">
                    <a [ngClass]="{'active': checkActiveRoute('/pharmacy-ledger')}" [routerLink]="['/pharmacy-ledger']"
                      [queryParams]="{ l: l.location_id, c: l.company_id }">
                      <!-- <i class="ri-guide-line"></i> -->
                      <span> Pharmacy Ledger </span>
                    </a>
                  </li>
                  <li *ngIf="user_details.user_type.includes('Pharmacy')
                  && l.services.includes('OHC')">
                    <a [ngClass]="{'active': checkActiveRoute('/pharmacy-monthly-report')}" [routerLink]="['/pharmacy-monthly-report']"
                       [queryParams]="{ l: l.location_id, c: l.company_id }">
                      <!-- <i class="ri-guide-line"></i> -->
                      <span> Pharmacy Monthly </span>
                    </a>
                  </li>
                  <li *ngIf="user_details.user_type.includes('Ops')
                  || user_details.user_type.includes('Client Admin')">
                    <a [ngClass]="{'active': checkActiveRoute('/user-management')}" [routerLink]="['/user-management']"
                      [queryParams]="{ l: l.location_id, c: l.company_id }">
                      <!-- <i class="ri-guide-line"></i> -->
                      <span> Location's Users </span>
                    </a>
                  </li>
                  <li *ngIf="user_details.user_type.includes('Ops')
                  || user_details.user_type.includes('Client Admin')">
                    <a [ngClass]="{'active': checkActiveRoute('/department-management')}"
                      [routerLink]="['/department-management']" [queryParams]="{ l: l.location_id, c: l.company_id }">
                      <!-- <i class="ri-guide-line"></i> -->
                      <span> Departments </span>
                    </a>
                  </li>
                  <li *ngIf="(user_details.user_type.includes('Ops'))
                  && l.services.includes('AHC')">
                    <a [ngClass]="{'active': checkActiveRoute('/package-location-map')}"
                      [routerLink]="['/package-location-map']" [queryParams]="{ l: l.location_id, c: l.company_id }">
                      <!-- <i class="ri-guide-line"></i> -->
                      <span> Package <> Location </span>
                    </a>
                  </li>
                  <li *ngIf="(user_details.user_type.includes('Ops'))
                  && l.services.includes('Vaccine')">
                    <a [ngClass]="{'active': checkActiveRoute('/vaccine-location-map')}"
                      [routerLink]="['/vaccine-location-map']" [queryParams]="{ l: l.location_id, c: l.company_id }">
                      <!-- <i class="ri-guide-line"></i> -->
                      <span> Vaccine <> Location </span>
                    </a>
                  </li>
                  <li *ngIf="(user_details.user_type.includes('Ops') || user_details.user_type.includes('Client Admin'))
                  &&
                  l.services.includes('OHC')">
                    <a [ngClass]="{'active': checkActiveRoute('/roster-management')}"
                      [routerLink]="['/roster-management']" [queryParams]="{ l: l.location_id, c: l.company_id }">
                      <span> Roster Schedules </span>
                    </a>
                  </li>
                </ul>
              </div>
            </li>
          </ul>

        </div>
        <!-- End Sidebar -->

        <div class="clearfix"></div>

      </div>
      <!-- Sidebar -left -->

    </div>
    <!-- Left Sidebar End -->

    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->

    <div class="content-page">
      <div class="content">

        <router-outlet></router-outlet>


      </div> <!-- content -->

      <!-- Footer Start -->
      <footer class="footer">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-6">
              <script>document.write(new Date().getFullYear())</script> &copy; Software by <a href="">{{title}}</a>
            </div>
            <div class="col-md-6">
              <div class="text-md-end footer-links d-none d-sm-block">
                <a href="https://www.avyukthealthcare.com/about-avyukt/" target="_blank">About Us</a>
                <!-- <a href="javascript:void(0);">Help</a> -->
                <a href="mailto:info@avyukthealthcare.com">Email Us</a>
                <a href="#"><strong>Toll Free: </strong>0120-314-0913 (10 A.M. - 6 P.M.)</a>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <!-- end Footer -->

    </div>

    <!-- ============================================================== -->
    <!-- End Page content -->
    <!-- ============================================================== -->


  </div>
  <!-- END wrapper -->
</ng-template>

<!-- <ng-template *ngIf="checkActiveRoute('/session-expired')">
  <session-expire></session-expire>
</ng-template> -->
