
import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ResponseObject } from 'src/app/shared/model/response.model';
import { VaccinesService } from './vaccines.service';
import { JSLoader } from 'src/app/shared/js-loader.function';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { UserAPIService } from 'src/app/user/user.service';
import { LocationMasterService } from 'src/app/shared/location_master.service';
import { AuthService } from 'src/app/shared/auth.service';
import { LabsService } from 'src/app/ahc/labs/labs.service';
import { environment } from 'src/environments/environment';
import { ToastrService } from "ngx-toastr";

@Component({
    selector: 'app-user',
    templateUrl: './vaccines.component.html',
    styleUrls: ['./vaccines.component.css']
})
export class VaccineComponent {

    public error_message: string = "";
    public success_message: string = "";
    public vaccine_list: any = [];
    public tests_list: any = [];
    public op_type: string = "Create";
    public vaccine_id: string = "";


    public vaccineFormGroup = new FormGroup({
        name: new FormControl("", [Validators.required]),
        brand: new FormControl("", [Validators.required]),
        delivery_type: new FormControl("", [Validators.required]),
        is_active: new FormControl(false)
    });

    updateModal(lab: any) {
        this.op_type = "Update";
        this.vaccine_id = lab._id
        this.vaccineFormGroup.patchValue(lab);
    }

    unSetCompanyIdContext() {
        this.op_type = "Create";
        this.error_message = "";
        this.success_message = "";
        this.vaccine_id = "";
        this.vaccineFormGroup.reset();
    }

    constructor(private vaccineService: VaccinesService) { }

    private getVaccines() {
        this.vaccineService.getVaccines()
            .subscribe((res: ResponseObject) => {
                this.error_message = "";
                if (res.statusCode === 200) {
                    this.vaccine_list = res.data;
                }
                else {
                    this.error_message = res.message;
                }
            });
    }

    ngOnInit() {
        this.getVaccines();
        JSLoader.load("assets/js/pages/form-advanced.init.js");
    }

    private createVaccine(data: any) {
        this.vaccineService.createVaccine(data)
            .subscribe((res: ResponseObject) => {
                this.error_message = "";
                if (res.statusCode === 201) {
                    this.getVaccines();
                }
                else {
                    this.error_message = res.message;
                }
                this.vaccineFormGroup.reset();
            });
    }

    private update(data: any) {
        this.vaccineService.update(this.vaccine_id, data)
            .subscribe((res: ResponseObject) => {
                this.error_message = "";
                if (res.statusCode === 200) {
                    this.getVaccines();
                }
                else {
                    this.error_message = res.message;
                }
                this.vaccineFormGroup.reset();
            });
    }

    save() {
        this.error_message = "";
        this.success_message = "";

        if (!this.vaccineFormGroup.valid) {
            this.error_message = "Invalid form inputs";
            return;
        }
        let data = {
            ... this.vaccineFormGroup.value,
        }

        if (this.op_type === "Create")
            this.createVaccine(data);
        else if (this.op_type === "Update") {
            if (!this.vaccine_id) {
                this.error_message = "Please select an appropriate vaccine";
                return;
            }
            this.update(data)
        }
    }
}

@Component({
    selector: 'app-user',
    templateUrl: './book-vaccination.component.html',
    styleUrls: ['./vaccines.component.css']
})
export class PatientVaccineComponent {

    public error_message: string = "";
    public success_message: string = "";
    public vaccine_list: any = [];
    public op_type: string = "Create";
    public vaccine_selected: any;
    public date_booking: string = "";
    public vaccine_booking: string = "";
    public dependent_list: Array<any> = [];
    public booking_users: Array<any> = [];
    public state_list: Array<string> = [];
    public city_list: Array<string> = [];
    public lab_list: Array<any> = [];
    public vaccine_map_list: any = [];
    public vaccine_map_selected: any = "";
    public is_client_admin: boolean = false;
    public date_picker_min: string = "";
    public delivery_type: string = "";
    public is_me_included: boolean = false;


    public paymentFormGroup = new FormGroup({
        key: new FormControl(""),
        txnid: new FormControl(""),
        amount: new FormControl(""),
        productinfo: new FormControl(),
        firstname: new FormControl(),
        email: new FormControl(),
        phone: new FormControl(),
        surl: new FormControl(),
        furl: new FormControl(),
        udf1: new FormControl(),
        udf2: new FormControl(),
        hash: new FormControl()
    });

    constructor(private vaccineService: VaccinesService,
        private userAPIService: UserAPIService,
        private activatedroute: ActivatedRoute,
        private router: Router,
        private authService: AuthService,
        private toastr: ToastrService,
        private locationMasterService: LocationMasterService,
        @Inject(DOCUMENT) private document: Document) { }


    ngOnInit() {
        let cur_date = new Date();
        cur_date.setDate(cur_date.getDate() + 3);
        this.date_picker_min = cur_date.toISOString().split("T")[0];

        let user = this.authService.getLoggedInUserDetails();
        if (user) {
            if (JSON.parse(user).user_type.includes("Client Admin")
                &&
                JSON.parse(user).user_type.indexOf("Patient") < 0)
                this.is_client_admin = true;
        }

        this.state_list = this.locationMasterService.getStates();


        this.vaccineService.getLocationVaccines(this.activatedroute.snapshot.queryParams["l"])
            .subscribe((res: ResponseObject) => {
                this.error_message = "";
                if (res.statusCode === 200) {
                    this.vaccine_list = res.data;
                }
                else {
                    this.error_message = res.message;
                }
            });

        this.vaccineService.getMap(this.activatedroute.snapshot.queryParams["l"])
            .subscribe((res: ResponseObject) => {
                this.error_message = "";
                if (res.statusCode === 200) {
                    this.vaccine_map_list = res.data;
                }
                else {
                    this.error_message = res.message;
                }
            });

        this.userAPIService.getDependent()
            .subscribe((res: ResponseObject) => {
                this.error_message = "";
                if (res.statusCode === 200) {
                    this.dependent_list = res.data;
                }
            });

    }

    getCities(state: any) {
        this.city_list = this.locationMasterService.getCities(state.target.value)
    }

    getLabs(city: any) {
        for (let i = 0; i < this.vaccine_map_list.length; i++) {
            if (this.vaccine_map_list[i].vaccine_id._id ==
                this.vaccine_selected._id
                &&
                this.vaccine_map_list[i].lab_id?.address?.city ==
                city.target.value
                && this.vaccine_map_list[i].lab_id
            ) {
                this.lab_list.push(this.vaccine_map_list[i]);
            }
        }
    }

    openBookingForm(vaccine_selected: any) {
        this.vaccine_map_selected = null;
        this.vaccine_selected = vaccine_selected;
        this.vaccine_map_selected = "";
        if (vaccine_selected.delivery_type == 'Lab and Home') {
            this.delivery_type = 'Lab';
        }
        else this.delivery_type = vaccine_selected.delivery_type;
    }

    onCheckBoxToggle(event: any) {
        if (event.target.value == "ME") this.is_me_included = !this.is_me_included;
        else {
            if (this.booking_users.indexOf(event.target.value) == -1)
                this.booking_users.push(event.target.value)
            else
                this.booking_users.splice(this.booking_users.indexOf(event.target.value), 1)
        }
    }


    book() {
        if (!this.vaccine_map_selected && this.delivery_type == "Lab") {
          this.toastr.error("Please select an appropriate lab center", 'Oh, Snap!', {
            progressAnimation: 'decreasing',
            disableTimeOut: 'extendedTimeOut',
            positionClass: 'toast-bottom-right',
            progressBar: true,
            timeOut: 10000
          });
          return;
        }

        if (!this.delivery_type) {
          this.toastr.error("Please select an appropriate delivery type", 'Oh, Snap!', {
            progressAnimation: 'decreasing',
            disableTimeOut: 'extendedTimeOut',
            positionClass: 'toast-bottom-right',
            progressBar: true,
            timeOut: 10000
          });
          return;
        }

        let vaccine_map_obj;
        for (let i: number = 0; i < this.vaccine_map_list.length; i++) {
          if (this.delivery_type == "Lab") {
            if (this.vaccine_map_list[i]._id == this.vaccine_map_selected) {
              vaccine_map_obj = this.vaccine_map_list[i];
            }
          } else {
            for (let i = 0; i < this.vaccine_map_list.length; i++) {
              if (this.vaccine_map_list[i].vaccine_id._id ==
                this.vaccine_selected._id
                &&
                this.vaccine_map_list[i].lab_id === null) {
                vaccine_map_obj = this.vaccine_map_list[i];
              }
            }
          }
        }

        if (!vaccine_map_obj) {
          this.toastr.error("We could not find any delivery partners near your home", 'Oh, Snap!', {
            progressAnimation: 'decreasing',
            disableTimeOut: 'extendedTimeOut',
            positionClass: 'toast-bottom-right',
            progressBar: true,
            timeOut: 10000
          });
          return;
        }
        if (!this.booking_users.length && !this.is_me_included) {
          this.toastr.error("You have to select at least one user for booking.", 'Oh, Snap!', {
            progressAnimation: 'decreasing',
            disableTimeOut: 'extendedTimeOut',
            positionClass: 'toast-bottom-right',
            progressBar: true,
            timeOut: 10000
          });
          return;
        }

        this.vaccineService.book({
            company_id: this.activatedroute.snapshot.queryParams["c"],
            location_id: this.activatedroute.snapshot.queryParams["l"],
            proposed_date: this.date_booking,
            status: "Pending Payment",
            vaccine_id: vaccine_map_obj.vaccine_id._id,
            lab_id: vaccine_map_obj.lab_id?._id || null,
            vaccine_map_id: vaccine_map_obj._id,
            dependent_ids: this.booking_users,
            delivery_type: this.delivery_type,
            is_me_included: this.is_me_included
        })
            .subscribe((res: ResponseObject) => {
                if (res.statusCode === 201) {
                    if (res.data.calculated_amount > 0) {
                        this.vaccineService.attemptPayment({
                            purpose: "Vaccination",
                            purpose_identifer: res.data._id
                        })
                            .subscribe((res2: ResponseObject) => {
                                this.error_message = "";
                                if (res2.statusCode === 201) {
                                    this.paymentFormGroup.patchValue(res2.data.gateway_request);
                                    this.document.getElementById("btnPaymentSubmit")?.click();
                                    this.document.forms[0].submit();
                                }
                                else {
                                    this.error_message = res.message;
                                }
                            });
                    } else this.router.navigate(['/my-vaccination-booking']);
                }
                else {
                    this.error_message = res.message;
                }
            });
    }
}


@Component({
    selector: 'app-user',
    templateUrl: './list-my-vaccine-booking.component.html',
    styleUrls: ['./vaccines.component.css']
})
export class ListMyVaccineBookingsComponent {

    public booking_list: any = [];

    public page_size: number = 5;
    public page_number: number = 0;

    public s3BaseUrl: string = environment.s3URL;

    public paymentFormGroup = new FormGroup({
        key: new FormControl(""),
        txnid: new FormControl(""),
        amount: new FormControl(""),
        productinfo: new FormControl(),
        firstname: new FormControl(),
        email: new FormControl(),
        phone: new FormControl(),
        surl: new FormControl(),
        furl: new FormControl(),
        udf1: new FormControl(),
        udf2: new FormControl(),
        hash: new FormControl()
    });


    constructor(private vaccineService: VaccinesService,
        @Inject(DOCUMENT) private document: Document) { }

    getNextPage() {
        this.page_number += 1;
        this.get()
    }

    getPrevPage() {
        this.page_number -= 1;
        this.get()
    }

    get() {
        this.vaccineService.getMyBooking()
            .subscribe((res: ResponseObject) => {
                if (res.statusCode === 200) {
                    this.booking_list = res.data;
                }
            });
    }

    ngOnInit() {
        this.get();
    }

    download() {

    }

    retryPayment(p: any) {
        this.vaccineService.attemptPayment({
            purpose: "Vaccination",
            purpose_identifer: p._id
        })
            .subscribe((res2: ResponseObject) => {
                if (res2.statusCode === 201) {
                    this.paymentFormGroup.patchValue(res2.data.gateway_request);
                    this.document.getElementById("btnPaymentSubmit")?.click();
                    this.document.forms[0].submit();
                }
            });
    }
}


@Component({
    selector: 'app-user',
    templateUrl: './list-bookings-ops.controller.html',
    styleUrls: ['./vaccines.component.css']
})
export class ListOpsVaccinationBookingsComponent {

    public booking_list: any = [];
    public vaccine_list: any = [];
    public payment_list: any = [];
    public dependent_list: any = [];
    public filter_status: string = "Requested";
    public filter_delivery_type: string = "Lab,Home";
    public s3BaseUrl: string = environment.s3URL;
    public file: any;
    public user_details: any = {};
    public is_client_admin: boolean = false;

    public booking: any = {};

    public page_size: number = 5;
    public page_number: number = 0;

    public updateBookingFormGroup = new FormGroup({
        counter_date: new FormControl(),
        status: new FormControl("", [Validators.required]),
        vaccine_details: new FormControl("", [Validators.required]),
        change_reason: new FormControl("", [Validators.required])
    });


    constructor(private vaccineService: VaccinesService,
        private activatedroute: ActivatedRoute,
        private authService: AuthService,
        private userAPIService: UserAPIService) { }

    public get(status: string) {
        this.vaccineService.getLocationBookingByStatus(this.activatedroute.snapshot.queryParams["l"],
            status,
            this.filter_delivery_type, this.page_size, this.page_number)
            .subscribe((res: ResponseObject) => {
                if (res.statusCode === 200) {
                    this.booking_list = res.data;
                }
            });
    }


    ngOnInit() {

        let user = this.authService.getLoggedInUserDetails();
        this.user_details = JSON.parse(user || "{}");

        if (this.user_details.user_type.indexOf("Client Admin") >= 0) this.is_client_admin = true;

        this.get(this.filter_status);

        this.vaccineService.getMap(this.activatedroute.snapshot.queryParams["l"])
            .subscribe((res: ResponseObject) => {
                if (res.statusCode === 200) {
                    this.vaccine_list = res.data;
                }
            });

    }

    getNextPage() {
        this.page_number += 1;
        this.get(this.filter_status)
    }

    getPrevPage() {
        this.page_number -= 1;
        this.get(this.filter_status)
    }

    openBookingForm(booking: any) {
        this.updateBookingFormGroup.reset();
        this.booking = booking;
        this.updateBookingFormGroup.patchValue({
            vaccine_details: this.booking.vaccine_id._id + "~~" +
                (this.booking.lab_id?._id || null) + "~~" + this.booking.vaccine_map_id,
            status: this.booking.status,
            counter_date: this.booking.proposed_date.split("T")[0]
        })
        if (this.booking.dependent_ids?.length > 0) {
            let dependent_ids: any = [];
            for (let i = 0; i < this.booking.dependent_ids?.length; i++) {
                dependent_ids.push(this.booking.dependent_ids[i].dependent_id);
            }

            this.userAPIService.getDependentByIds(dependent_ids, this.booking.user_id._id)
                .subscribe((res: ResponseObject) => {
                    if (res.statusCode === 200) {
                        this.dependent_list = res.data;
                        for(let i: number = 0; i < this.dependent_list.length; i++) {
                          for(let j: number = 0; j < this.booking.dependent_ids.length; j++) {
                            if (this.dependent_list[i]._id.toString()
                              ===
                              this.booking.dependent_ids[j].dependent_id.toString()) {
                              this.booking.dependent_ids[j]["name"] = this.dependent_list[i].name;
                              this.booking.dependent_ids[j]["phone"] = this.dependent_list[i].phone;
                            }
                          }
                        }
                    }
                });
        }
    }

    update() {
        if (!this.updateBookingFormGroup.valid) return;

        let data = {
            ... this.updateBookingFormGroup.value,
            vaccine_id: this.updateBookingFormGroup.value.vaccine_details?.split("~~")[0],
            lab_id: this.updateBookingFormGroup.value.vaccine_details?.split("~~")[1],
            vaccine_map_id: this.updateBookingFormGroup.value.vaccine_details?.split("~~")[2]
        }

        this.vaccineService.updateBooking(this.booking._id, data)
            .subscribe((res: ResponseObject) => {
                if (res.statusCode === 200) {
                    this.get(this.filter_status);
                }
            });
    }

    openPaymentModal(p: any) {
        this.booking = p;
        this.vaccineService.getPaymentsforPackage(p._id)
            .subscribe((res: ResponseObject) => {
                if (res.statusCode === 200) {
                    this.payment_list = res.data;
                }
            });
    }

    updateFilter(event: any) {
        this.filter_status = event.target.value;
        this.get(this.filter_status);
    }

    updateFilterDelivery(event: any) {
        this.filter_delivery_type = event.target.value;
        this.get(this.filter_status);
    }

    downloadFile() {
      this.vaccineService.getLocationBookingByStatus(this.activatedroute.snapshot.queryParams["l"],
        this.filter_status,
        this.filter_delivery_type, 0, 0)
        .subscribe((res: ResponseObject) => {
          if (res.statusCode === 200) {
            let data = res.data;
            let export_data = [];
            for (let i = 0; i < data.length; i++) {
               let j: number = 0;
                do {
                  export_data.push({
                    BookingID: data[i]._id,
                    EmployeeName: data[i].user_id.name,
                    EmployeePhone: data[i].user_id.phone,
                    EmployeeID: data[i].user_id.organization_identifier,
                    EmployeeEmail: (data[i].user_id.email),
                    EmployeeAddress: (`${data[i].user_id?.address?.line1?.replace(",", " ") || "-"}
                      ${data[i].user_id?.address?.line2?.replace(",", " ") || "-"}
                      ${data[i].user_id?.address?.city}
                      ${data[i].user_id?.address?.state} - ${data[i].user_id?.address?.pincode}`),
                    DoB: data[i].user_id.dob,
                    Vaccine: data[i].vaccine_id.name,
                    Lab: data[i].lab_id?.name || "-- Home --",
                    LabAddress: (`${data[i].lab_id?.address?.line1?.replace(",", " ") || "-"}
                      ${data[i].lab_id?.address?.line2?.replace(",", " ") || "-"}
                      ${data[i].lab_id?.address?.city}
                      ${data[i].lab_id?.address?.state} - ${data[i].lab_id?.address?.pincode}`),
                    Status: data[i].status,
                    BookingDate: data[i].createdAt,
                    AppointmentDate: data[i].proposed_date,
                    BookingForSelf: (data[i].is_me_included) ? "Yes" : "No",
                    BookingType: (data[i].delivery_type),
                    DependentName: (data[i].dependent_ids[j]?.dependent_id?.name),
                    DependentAddress: (`${data[i].dependent_ids[j]?.line1?.replace(",", " ") || "-"}
                      ${data[i].dependent_ids[j]?.line2?.replace(",", " ") || "-"}
                      ${data[i].dependent_ids[j]?.city}
                      ${data[i].dependent_ids[j]?.state} - ${data[i].dependent_ids[j]?.pincode}`),
                  });
                j++;
              } while( j < data[i].dependent_ids.length )
            }
            const replacer = (key: string, value: string) => (value === null ? '' : value); // specify how you want to handle null values here
            const header = Object.keys(export_data[0]);
            const csv = export_data.map((row: any) =>
              header
                .map((fieldName) => JSON.stringify(row[fieldName], replacer))
                .join(',')
            );
            csv.unshift(header.join(','));
            const csvArray = csv.join('\r\n');

            const a = document.createElement('a');
            const blob = new Blob([csvArray], { type: 'text/csv' });
            const url = window.URL.createObjectURL(blob);

            a.href = url;
            a.download = 'health_check_data.csv';
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
          }
        });
    }

    uploadReport() {
        let fd = new FormData();
        if (this.file) {
            fd.append('file', this.file, this.file.name);
            this.vaccineService.uploadReport(this.booking._id, fd)
                .subscribe((res: ResponseObject) => {
                    if (res.statusCode === 200) {
                        this.booking_list = res.data;
                    }
                }, err => {
                });
        }
    }

    newFileSelect(e: any) {
        if (e.target.value) {
            this.file = <File>e.target.files[0];
        }
    }
}

