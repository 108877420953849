
import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DepartmentService } from './department.service';
import { ResponseObject } from 'src/app/shared/model/response.model';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/admin/user/user.service';
import { AuthService } from 'src/app/shared/auth.service';

@Component({
    selector: 'app-user',
    templateUrl: './department.component.html',
    styleUrls: ['./department.component.css']
})
export class DepartmentComponent {

    public error_message: string = "";
    public success_message: string = "";

    public department_list: any = [];
    public op_type: string = "Create";
    public department_id: string = "";
    public user: any = {};

    public departmentFormGroup = new FormGroup({
        name: new FormControl("", [Validators.required]),
        company_id: new FormControl(this.activatedroute.snapshot.queryParams["c"]),
        is_active: new FormControl(true)
    });

    updateModal(department: any) {
        this.op_type = "Update";
        this.department_id = department._id
        delete department._id;
        delete department.__v;
        delete department.createdAt;
        delete department.updatedAt;
        this.departmentFormGroup.setValue(department);
    }

    unSetCompanyIdContext() {
        this.op_type = "Create";
        this.error_message = "";
        this.success_message = "";
        this.department_id = "";
        this.departmentFormGroup.reset();
    }

    constructor(private departmentService: DepartmentService,
        private router: Router,
        private activatedroute: ActivatedRoute,
        private userService: UserService,
        private authService: AuthService) { }

    private getDepartments() {
        this.departmentService.get(this.activatedroute.snapshot.queryParams["c"])
            .subscribe((res: ResponseObject) => {
                this.error_message = "";
                if (res.statusCode === 200) {
                    this.department_list = res.data;
                }
                else {
                    this.error_message = res.message;
                }
            }, err => {
                if (err.status === 400) this.error_message = err.error.message[0];
                else this.error_message = err.error.message;
            });
    }

    ngOnInit() {
        this.getDepartments();
    }

    private createdepartment(data: any) {
        this.departmentService.create(data)
            .subscribe((res: ResponseObject) => {
                this.error_message = "";
                if (res.statusCode === 201) {
                    this.getDepartments();
                }
                else {
                    this.error_message = res.message;
                }
            }, err => {
                if (err.status === 400) this.error_message = err.error.message[0];
                else this.error_message = err.error.message;
            });
    }

    private updateDepartment(id: string, data: any) {
        this.departmentService.update(id, data)
            .subscribe((res: ResponseObject) => {
                this.error_message = "";
                if (res.statusCode === 200) {
                    this.getDepartments();
                }
                else {
                    this.error_message = res.message;
                }
            }, err => {
                if (err.status === 400) this.error_message = err.error.message[0];
                else this.error_message = err.error.message;
            });
    }

    save() {
        this.error_message = "";
        this.success_message = "";

        if (!this.departmentFormGroup.valid) {
            this.error_message = "Invalid form inputs";
            return;
        }
        let data = {
            ... this.departmentFormGroup.value,
        }

        if (this.op_type === "Create")
            this.createdepartment(data);
        else if (this.op_type === "Update") {
            if (!this.department_id) {
                this.error_message = "Please select an appropriate department";
                return;
            }
            this.updateDepartment(this.department_id, data)
        }

    }
}

