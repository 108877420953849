import { Component } from '@angular/core';
import { PrescriptionService } from '../create-prescription/prescription.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/shared/auth.service';
import { ResponseObject } from 'src/app/shared/model/response.model';
import { PharmacyService } from 'src/app/pharmacy/pharmacy.service';

@Component({
  selector: 'app-user',
  templateUrl: './view-prescription.component.html',
  styleUrls: ['./view-prescription.component.css']
})
export class ViewPrescriptionComponent {

  public page_size: number = 5;
  public page_number: number = 0;

  public prescription_list: any = [];

  constructor(private prescriptionService: PrescriptionService,
    private activatedroute: ActivatedRoute,
    private authService: AuthService,
    private router: Router) { }

  get() {
    if (this.activatedroute.snapshot.queryParams["l"]
      && JSON.parse(this.authService.getLoggedInUserDetails() || '{}').user_types?.includes("Doctor")) {
      this.prescriptionService.getPrescriptionsByDoctor(this.activatedroute.snapshot.queryParams["l"],
        JSON.parse(this.authService.getLoggedInUserDetails() || '{}')._id,
        this.page_size, this.page_number)
        .subscribe((res: ResponseObject) => {
          if (res.statusCode === 200) {
            this.prescription_list = res.data;
          }
        });
    } else {
      let user_id = JSON.parse(this.authService.getLoggedInUserDetails() || '{}')._id;
      if (this.activatedroute.snapshot.queryParams["uid"])
        user_id = this.activatedroute.snapshot.queryParams["uid"];
      this.prescriptionService.getPrescriptionsOfMe(user_id,
        this.page_size, this.page_number)
        .subscribe((res: ResponseObject) => {
          if (res.statusCode === 200) {
            this.prescription_list = res.data;
          }
        });
    }
  }

  getNextPage() {
    this.page_number += 1;
    this.get()
  }

  getPrevPage() {
    this.page_number -= 1;
    this.get()
  }


  ngOnInit() {
    this.get();
  }

  viewPrescription(p: any) {
    this.router.navigate(['/detail-prescription'], { queryParams: { prescription: p._id } });
  }

}

@Component({
  selector: 'app-user',
  templateUrl: './detail-prescription.component.html',
  styleUrls: ['./view-prescription.component.css']
})
export class ViewDetailPrescriptionComponent {

  constructor(private prescriptionService: PrescriptionService,
    private activatedroute: ActivatedRoute,
    private pharmacyService: PharmacyService,
    private authService: AuthService,
    private router: Router) { }

  public error_message: string = "";

  public user: any = {};
  public is_pharmacy: Boolean = false;
  public medicine_list: Array<any> = [];

  public p: any = "";

  ngOnInit() {
    this.user = JSON.parse(this.authService.getLoggedInUserDetails() || '{}');
    this.is_pharmacy = this.user.user_type.includes('Pharmacy') && this.activatedroute.snapshot.queryParams["l"];
    this.prescriptionService.getPrescriptionsByID(this.activatedroute.snapshot.queryParams["prescription"])
      .subscribe((res: ResponseObject) => {
        if (res.statusCode === 200) {
          this.p = res.data[0];
          if (this.is_pharmacy) {
            this.pharmacyService.getMedicineStockList(this.activatedroute.snapshot.queryParams["l"])
              .subscribe((res2: ResponseObject) => {
                this.error_message = "";
                if (res2.statusCode === 200) {
                  this.medicine_list = res2.data;
                  for (let i = 0; i < this.medicine_list.length; i++) {
                    for (let j = 0; j < this.p.medicine.length; j++) {
                      if (this.medicine_list[i]._id == this.p.medicine[j].medicine_id.name) {
                        this.p.medicine[j]["batches"] = this.medicine_list[i].batches
                      }
                    }
                  }
                }
                else {
                  this.error_message = res2.message;
                }
              });
          }
        }
        else {
          this.error_message = res.message;
        }
      });
  }

  dispense_all() {
    let med_list = [];
    console.log(this.p.medicine)
    for (let i = 0; i < this.p.medicine.length; i++) {
      for (let j = 0; j < this.p.medicine[i].batches.length; j++) {
        if (!this.p.medicine[i].batches[j].new_stock) {
          this.p.medicine[i].batches[j].new_stock = 0;
        }
        med_list.push({
          medicine_id: this.p.medicine[i].batches[j]._id,
          deduction: this.p.medicine[i].batches[j].new_stock
        });
      }
    }
    this.prescriptionService.dispence(this.p._id, med_list).subscribe((res: ResponseObject) => {
      if (res.statusCode === 200) {
        this.router.navigate(['/pharmacy'], { queryParams: { l: this.p.location_id, c: this.p.company_id } });
      }
      else {
        this.error_message = res.message;
      }
    })
  }

  print() {
    (window as Window).print();
  }
}