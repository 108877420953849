import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseObject } from 'src/app/shared/model/response.model';
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class UserAPIService {

    constructor(private http: HttpClient) { }

    getDependent(related_to: string = '') {
        let related_qp = '';
        if (related_to) related_qp = '?related_to=' + related_to;
        return this.http.get<ResponseObject>(environment.apiEndpoint + "/user/dependent" + related_qp);
    }

    getDependentByIds(_ids: any, related_to: string = '') {
        let related_qp = '';
        if (related_to) related_qp = '&related_to=' + related_to;
        return this.http.get<ResponseObject>(environment.apiEndpoint + "/user/dependent?_id=" + _ids.join(",") + related_qp);
    }

    createDependent(data: any) {
        return this.http.post<ResponseObject>(environment.apiEndpoint + "/user/dependent", data);
    }

    updateDependent(location_id: any, data: any) {
        return this.http.put<ResponseObject>(environment.apiEndpoint + "/user/dependent/" + location_id, data);
    }

    getProfile() {
        return this.http.get<ResponseObject>(environment.apiEndpoint + "/user/profile");
    }

    uploadProfilePicture(fd: any) {
        return this.http.post<ResponseObject>(environment.apiEndpoint + "/user/upload-profile-picture", fd);
    }
}