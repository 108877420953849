<!-- Start Content-->
<div class="container-fluid">

    <!-- start page title -->
    <div class="row">
        <div class="col-12">
            <div class="page-title-box page-title-box-alt">
                <h4 class="page-title">Departments</h4>
                <div class="page-title-right">
                    <ol class="breadcrumb m-0">

                        <li class="breadcrumb-item"><a href="javascript: void(0);">Manage</a></li>
                        <li class="breadcrumb-item active">Deprtments</li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
    <!-- end page title -->

    <div class="row">
        <div class="col-xl-4 col-md-6">
            <div class="card">
                <div class="card-header">
                    <h5>Manage Department</h5>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-xs-12">
                            <form [formGroup]="departmentFormGroup" class="" (ngSubmit)="save()">
                                <div class="alert alert-danger" *ngIf="error_message">{{error_message}}</div>
                                <div class="alert alert-success" *ngIf="success_message">{{success_message}}</div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="mb-2">
                                            <label for="name" class="form-label">Name <span
                                                    style="color: red;">*</span></label>
                                            <input class="form-control" type="text" id="name"
                                                placeholder="Enter department name" formControlName="name">
                                            <input readonly class="form-control" type="hidden"
                                                [ngModelOptions]="{standalone: true}" [(ngModel)]="department_id">
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="mb-2">
                                            <div class="form-check">
                                                <input class="form-check-input" formControlName="is_active"
                                                    type="checkbox" id="is_active">
                                                <label class="form-check-label" for="is_active">
                                                    Active?
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="mb-2 text-left">
                                    <button class="btn btn-primary" type="submit"
                                        [disabled]="!departmentFormGroup.valid">Save
                                    </button> &nbsp;
                                    <button class="btn btn-warning" type="button"
                                        (click)="unSetCompanyIdContext()">Reset
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-xl-8 col-md-6">
            <div class="card">
                <div class="card-body">
                    <div class="row table-responsive">
                        <table class="table table-striped table-hover table-bordered">
                            <thead>
                                <tr>
                                    <th>Sr. No.</th>
                                    <th>Name</th>
                                    <th>Is Active</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style="text-align: center;" *ngIf="!department_list.length">
                                    <td colspan="100">No Departments</td>
                                </tr>
                                <tr *ngFor="let department of department_list; let i = index">
                                    <td class="col-1">{{i + 1}}</td>
                                    <td>{{department.name}}</td>
                                    <td>
                                        <span class="badge bg-success rounded-pill"
                                            *ngIf="department.is_active">Yes</span>
                                        <span class="badge bg-danger rounded-pill"
                                            *ngIf="!department.is_active">No</span>
                                    </td>
                                    <td class="col-1">
                                        <button class="btn btn-danger btn-sm" (click)="updateModal(department)"
                                            type="button">
                                            Update
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div><!-- end col -->
    </div>
    <!-- end row -->

</div> <!-- container -->
<!-- Dashboard init-->