<!-- Start Content-->
<div class="container-fluid">

    <!-- start page title -->
    <div class="row">
        <div class="col-12">
            <div class="page-title-box page-title-box-alt">
                <h4 class="page-title">Location's Shifts Management</h4>
                <div class="page-title-right">
                    <ol class="breadcrumb m-0">

                        <li class="breadcrumb-item"><a href="javascript: void(0);">Location</a></li>
                        <li class="breadcrumb-item active">Shift Management</li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
    <!-- end page title -->

    <div class="row">
        <div class="col-xl-12 col-md-12">
            <div class="card">
                <div class="card-header">
                    Create Shifts
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-xs-12">
                            <form [formGroup]="rosterShiftFormGroup" class="" (ngSubmit)="save()" autocomplete="off">
                                <div class="alert alert-danger" *ngIf="error_message">{{error_message}}</div>
                                <div class="alert alert-success" *ngIf="success_message">{{success_message}}</div>

                                <div class="row">
                                    <div class="col-md-3">
                                        <div class="mb-2">
                                            <label for="name" class="form-label">Name <span
                                                    style="color: red;">*</span></label>
                                            <input class="form-control" type="text" id="name" required
                                                placeholder="Enter shift's name" formControlName="name">
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="mb-2">
                                            <label for="description" class="form-label">Description <span
                                                    style="color: red;">*</span></label>
                                            <input class="form-control" type="text" id="description"
                                                placeholder="Enter shift's description" formControlName="description">
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="mb-2">
                                            <label for="margin_before_hrs" class="form-label">Pre-shift Margin (hrs)
                                                <span style="color: red;">*</span></label>
                                            <input class="form-control" type="number" step="1" id="margin_before_hrs"
                                                formControlName="margin_before_hrs">
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="mb-2">
                                            <label for="margin_after_hrs" class="form-label">Post-shift Margin (hrs)
                                                <span style="color: red;">*</span></label>
                                            <input class="form-control" type="number" step="1" id="margin_after_hrs"
                                                formControlName="margin_before_hrs">
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="mb-2">
                                            <label for="shift_start" class="form-label">Shift Start Time
                                                <span style="color: red;">*</span></label>
                                            <input class="form-control" type="time" id="shift_start"
                                                formControlName="shift_start">
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="mb-2">
                                            <label for="shift_end" class="form-label">Shift End Time
                                                <span style="color: red;">*</span></label>
                                            <input class="form-control" type="time" id="shift_end"
                                                formControlName="shift_end">
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="mb-2">
                                            <label for="allownace" class="form-label">Allowance
                                                <span style="color: red;">*</span></label>
                                            <input class="form-control" type="number" step="1" id="allownace"
                                                formControlName="allownace">
                                        </div>
                                    </div>
                                </div>

                                <hr />

                                <div class="row table-responsive" formGroupName="schedule">
                                    <div class="col-md-12">
                                        <div class="mb-2">
                                            <table class="table table-bordered table-hover">
                                                <thead>
                                                    <tr>
                                                        <th class="col-1"></th>
                                                        <th class="col-1">Week 1</th>
                                                        <th class="col-1">Week 2</th>
                                                        <th class="col-1">Week 3</th>
                                                        <th class="col-1">Week 4</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Monday</td>
                                                        <td>
                                                            <select class="form-control" formControlName="Monday_W1">
                                                                <option value="No Shift">No Shift</option>
                                                                <option value="All Day">All Day</option>
                                                                <option value="First Half">First Half</option>
                                                                <option value="Second Half">Second Half</option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <select class="form-control" formControlName="Monday_W2">
                                                                <option value="No Shift">No Shift</option>
                                                                <option value="All Day">All Day</option>
                                                                <option value="First Half">First Half</option>
                                                                <option value="Second Half">Second Half</option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <select class="form-control" formControlName="Monday_W3">
                                                                <option value="No Shift">No Shift</option>
                                                                <option value="All Day">All Day</option>
                                                                <option value="First Half">First Half</option>
                                                                <option value="Second Half">Second Half</option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <select class="form-control" formControlName="Monday_W4">
                                                                <option value="No Shift">No Shift</option>
                                                                <option value="All Day">All Day</option>
                                                                <option value="First Half">First Half</option>
                                                                <option value="Second Half">Second Half</option>
                                                            </select>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Tuesday</td>
                                                        <td>
                                                            <select class="form-control" formControlName="Tuesday_W1">
                                                                <option value="No Shift">No Shift</option>
                                                                <option value="All Day">All Day</option>
                                                                <option value="First Half">First Half</option>
                                                                <option value="Second Half">Second Half</option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <select class="form-control" formControlName="Tuesday_W2">
                                                                <option value="No Shift">No Shift</option>
                                                                <option value="All Day">All Day</option>
                                                                <option value="First Half">First Half</option>
                                                                <option value="Second Half">Second Half</option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <select class="form-control" formControlName="Tuesday_W3">
                                                                <option value="No Shift">No Shift</option>
                                                                <option value="All Day">All Day</option>
                                                                <option value="First Half">First Half</option>
                                                                <option value="Second Half">Second Half</option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <select class="form-control" formControlName="Tuesday_W4">
                                                                <option value="No Shift">No Shift</option>
                                                                <option value="All Day">All Day</option>
                                                                <option value="First Half">First Half</option>
                                                                <option value="Second Half">Second Half</option>
                                                            </select>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Wednesday</td>
                                                        <td>
                                                            <select class="form-control" formControlName="Wednesday_W1">
                                                                <option value="No Shift">No Shift</option>
                                                                <option value="All Day">All Day</option>
                                                                <option value="First Half">First Half</option>
                                                                <option value="Second Half">Second Half</option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <select class="form-control" formControlName="Wednesday_W2">
                                                                <option value="No Shift">No Shift</option>
                                                                <option value="All Day">All Day</option>
                                                                <option value="First Half">First Half</option>
                                                                <option value="Second Half">Second Half</option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <select class="form-control" formControlName="Wednesday_W3">
                                                                <option value="No Shift">No Shift</option>
                                                                <option value="All Day">All Day</option>
                                                                <option value="First Half">First Half</option>
                                                                <option value="Second Half">Second Half</option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <select class="form-control" formControlName="Wednesday_W4">
                                                                <option value="No Shift">No Shift</option>
                                                                <option value="All Day">All Day</option>
                                                                <option value="First Half">First Half</option>
                                                                <option value="Second Half">Second Half</option>
                                                            </select>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Thursday</td>
                                                        <td>
                                                            <select class="form-control" formControlName="Thursday_W1">
                                                                <option value="No Shift">No Shift</option>
                                                                <option value="All Day">All Day</option>
                                                                <option value="First Half">First Half</option>
                                                                <option value="Second Half">Second Half</option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <select class="form-control" formControlName="Thursday_W2">
                                                                <option value="No Shift">No Shift</option>
                                                                <option value="All Day">All Day</option>
                                                                <option value="First Half">First Half</option>
                                                                <option value="Second Half">Second Half</option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <select class="form-control" formControlName="Thursday_W3">
                                                                <option value="No Shift">No Shift</option>
                                                                <option value="All Day">All Day</option>
                                                                <option value="First Half">First Half</option>
                                                                <option value="Second Half">Second Half</option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <select class="form-control" formControlName="Thursday_W4">
                                                                <option value="No Shift">No Shift</option>
                                                                <option value="All Day">All Day</option>
                                                                <option value="First Half">First Half</option>
                                                                <option value="Second Half">Second Half</option>
                                                            </select>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Friday</td>
                                                        <td>
                                                            <select class="form-control" formControlName="Friday_W1">
                                                                <option value="No Shift">No Shift</option>
                                                                <option value="All Day">All Day</option>
                                                                <option value="First Half">First Half</option>
                                                                <option value="Second Half">Second Half</option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <select class="form-control" formControlName="Friday_W2">
                                                                <option value="No Shift">No Shift</option>
                                                                <option value="All Day">All Day</option>
                                                                <option value="First Half">First Half</option>
                                                                <option value="Second Half">Second Half</option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <select class="form-control" formControlName="Friday_W3">
                                                                <option value="No Shift">No Shift</option>
                                                                <option value="All Day">All Day</option>
                                                                <option value="First Half">First Half</option>
                                                                <option value="Second Half">Second Half</option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <select class="form-control" formControlName="Friday_W4">
                                                                <option value="No Shift">No Shift</option>
                                                                <option value="All Day">All Day</option>
                                                                <option value="First Half">First Half</option>
                                                                <option value="Second Half">Second Half</option>
                                                            </select>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Saturday</td>
                                                        <td>
                                                            <select class="form-control" formControlName="Saturday_W1">
                                                                <option value="No Shift">No Shift</option>
                                                                <option value="All Day">All Day</option>
                                                                <option value="First Half">First Half</option>
                                                                <option value="Second Half">Second Half</option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <select class="form-control" formControlName="Saturday_W2">
                                                                <option value="No Shift">No Shift</option>
                                                                <option value="All Day">All Day</option>
                                                                <option value="First Half">First Half</option>
                                                                <option value="Second Half">Second Half</option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <select class="form-control" formControlName="Saturday_W3">
                                                                <option value="No Shift">No Shift</option>
                                                                <option value="All Day">All Day</option>
                                                                <option value="First Half">First Half</option>
                                                                <option value="Second Half">Second Half</option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <select class="form-control" formControlName="Saturday_W4">
                                                                <option value="No Shift">No Shift</option>
                                                                <option value="All Day">All Day</option>
                                                                <option value="First Half">First Half</option>
                                                                <option value="Second Half">Second Half</option>
                                                            </select>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Sunday</td>
                                                        <td>
                                                            <select class="form-control" formControlName="Sunday_W1">
                                                                <option value="No Shift">No Shift</option>
                                                                <option value="All Day">All Day</option>
                                                                <option value="First Half">First Half</option>
                                                                <option value="Second Half">Second Half</option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <select class="form-control" formControlName="Sunday_W2">
                                                                <option value="No Shift">No Shift</option>
                                                                <option value="All Day">All Day</option>
                                                                <option value="First Half">First Half</option>
                                                                <option value="Second Half">Second Half</option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <select class="form-control" formControlName="Sunday_W3">
                                                                <option value="No Shift">No Shift</option>
                                                                <option value="All Day">All Day</option>
                                                                <option value="First Half">First Half</option>
                                                                <option value="Second Half">Second Half</option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <select class="form-control" formControlName="Sunday_W4">
                                                                <option value="No Shift">No Shift</option>
                                                                <option value="All Day">All Day</option>
                                                                <option value="First Half">First Half</option>
                                                                <option value="Second Half">Second Half</option>
                                                            </select>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-3">
                                    <div class="mb-2">
                                        <label for="is_active" class="form-label">Is Active</label><br />
                                        <input type="checkbox" id="is_active" formControlName="is_active">
                                    </div>
                                </div>

                                <div style=" margin-top: 1%;" class="col-md-12">
                                    <button class="btn btn-primary" type="submit" *ngIf="btn_show"
                                        [disabled]="!rosterShiftFormGroup.valid">Save
                                    </button>
                                    &nbsp;
                                    <button class="btn btn-warning" type="button" (click)="reset()">Reset
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-xl-12 col-md-12">
            <div class="card">
                <div class="card-header">
                    Shift List
                </div>
                <div class="card-body">
                    <div class="row table-responsive">
                        <table id="demo-foo-pagination"
                            class="table table-responsive table-striped table-hover table-bordered">
                            <thead>
                                <tr>
                                    <th>Sr. No.</th>
                                    <th>Name</th>
                                    <th>Description</th>
                                    <th>Allowance</th>
                                    <th>From</th>
                                    <th>To</th>
                                    <th>Active</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style="text-align: center;" *ngIf="!shift_list.length">
                                    <td colspan="100">No Records</td>
                                </tr>
                                <tr *ngFor="let shift of shift_list; let i = index">
                                    <td class="col-1">{{i + 1}}</td>
                                    <td>{{shift.name}}</td>
                                    <td>{{shift.description}}</td>
                                    <td>{{shift.allownace}}</td>
                                    <td>{{shift.shift_start_hours_hand}} : {{shift.shift_start_mins_hand}}</td>
                                    <td>{{shift.shift_end_hours_hand}} : {{shift.shift_end_mins_hand}}</td>
                                    <td class="col-1">
                                        <span class="badge bg-success rounded-pill" *ngIf="shift.is_active">Yes</span>
                                        <span class="badge bg-danger rounded-pill" *ngIf="!shift.is_active">No</span>
                                    </td>
                                    <td class="col-1"><a href="#" (click)="view(shift)"
                                            class="btn btn-xs btn-primary">View</a></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="btn-group mb-2 float-end">
                        <button type="button" (click)="getPrevPage()"
                            class="btn btn-secondary btn-bordered waves-effect" [disabled]="page_number == 0">
                            <i class="fas fa-arrow-left"></i> &nbsp; Prev
                        </button>
                        <button [disabled]="!(shift_list.length >= page_size)" type="button" (click)="getNextPage()"
                            class="btn btn-secondary btn-bordered waves-effect">
                            Next &nbsp; <i class="fas fa-arrow-right"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div><!-- end col -->
    </div>
    <!-- end row -->

</div> <!-- container -->
<!-- Dashboard init-->