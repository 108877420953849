import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseObject } from 'src/app/shared/model/response.model';
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class LocationService {

    constructor(private http: HttpClient) { }

    getLocations(company_id: string) {
        return this.http.get<ResponseObject>(environment.apiEndpoint + "/location/" + company_id);
    }

    createLocation(data: any) {
        return this.http.post<ResponseObject>(environment.apiEndpoint + "/location", data);
    }

    updateLocation(id: string, data: any) {
        return this.http.put<ResponseObject>(environment.apiEndpoint + "/location/" + id, data);
    }
}
