import { Component } from '@angular/core';
import { CompanyService } from 'src/app/admin/company/company.service';
import { LocationService } from 'src/app/admin/location/location.service';
import { UserService } from 'src/app/admin/user/user.service';
import { ResponseObject } from 'src/app/shared/model/response.model';
import { RosterManagementService } from './roster-user-management.service';
import { ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-user',
    templateUrl: './roster-user-management.controller.html',
    styleUrls: []
})
export class RosterUserManagementComponent {

    public error_message: string = "";
    public success_message: string = "";

    public location_list: any = [];
    public company_list: any = [];
    public shift_list: any = [];
    public page_size: number = 5;
    public page_number: number = 0;
    public btn_show: boolean = true;

    public rosterShiftFormGroup = new FormGroup({
        company_id: new FormControl(this.activatedroute.snapshot.queryParams["c"], [Validators.required]),
        location_id: new FormControl(this.activatedroute.snapshot.queryParams["l"], [Validators.required]),
        name: new FormControl("", [Validators.required]),
        description: new FormControl("", [Validators.required]),
        margin_before_hrs: new FormControl(0, [Validators.required]),
        margin_after_hrs: new FormControl(0, [Validators.required]),
        shift_start: new FormControl("", [Validators.required]),
        shift_end: new FormControl("", [Validators.required]),
        allownace: new FormControl(0, [Validators.required]),
        schedule: new FormGroup({
            Monday_W1: new FormControl("No Shift", [Validators.required]),
            Monday_W2: new FormControl("No Shift", [Validators.required]),
            Monday_W3: new FormControl("No Shift", [Validators.required]),
            Monday_W4: new FormControl("No Shift", [Validators.required]),
            Tuesday_W1: new FormControl("No Shift", [Validators.required]),
            Tuesday_W2: new FormControl("No Shift", [Validators.required]),
            Tuesday_W3: new FormControl("No Shift", [Validators.required]),
            Tuesday_W4: new FormControl("No Shift", [Validators.required]),
            Wednesday_W1: new FormControl("No Shift", [Validators.required]),
            Wednesday_W2: new FormControl("No Shift", [Validators.required]),
            Wednesday_W3: new FormControl("No Shift", [Validators.required]),
            Wednesday_W4: new FormControl("No Shift", [Validators.required]),
            Thursday_W1: new FormControl("No Shift", [Validators.required]),
            Thursday_W2: new FormControl("No Shift", [Validators.required]),
            Thursday_W3: new FormControl("No Shift", [Validators.required]),
            Thursday_W4: new FormControl("No Shift", [Validators.required]),
            Friday_W1: new FormControl("No Shift", [Validators.required]),
            Friday_W2: new FormControl("No Shift", [Validators.required]),
            Friday_W3: new FormControl("No Shift", [Validators.required]),
            Friday_W4: new FormControl("No Shift", [Validators.required]),
            Saturday_W1: new FormControl("No Shift", [Validators.required]),
            Saturday_W2: new FormControl("No Shift", [Validators.required]),
            Saturday_W3: new FormControl("No Shift", [Validators.required]),
            Saturday_W4: new FormControl("No Shift", [Validators.required]),
            Sunday_W1: new FormControl("No Shift", [Validators.required]),
            Sunday_W2: new FormControl("No Shift", [Validators.required]),
            Sunday_W3: new FormControl("No Shift", [Validators.required]),
            Sunday_W4: new FormControl("No Shift", [Validators.required])
        }),
        is_active: new FormControl(false, [Validators.required])
    });


    constructor(private roasterShiftService: RosterManagementService,
        private activatedroute: ActivatedRoute) { }

    ngOnInit() {
        this.getRosterShifts();
    }

    getRosterShifts() {
        this.roasterShiftService.getRosterShifts(this.activatedroute.snapshot.queryParams["l"],
            this.page_size, this.page_number)
            .subscribe((res: ResponseObject) => {
                if (res.statusCode === 200) {
                    console.log(res.data);
                    this.shift_list = res.data;
                }
            });
    }

    getNextPage() {
        this.page_number += 1;
        this.getRosterShifts()
    }

    getPrevPage() {
        this.page_number -= 1;
        this.getRosterShifts()
    }

    save() {
        if (!this.rosterShiftFormGroup.valid) return;

        let data = {
            ...this.rosterShiftFormGroup.value,
            shift_start_hours_hand: Number(this.rosterShiftFormGroup.value.shift_start?.split(":")[0]),
            shift_start_mins_hand: Number(this.rosterShiftFormGroup.value.shift_start?.split(":")[1]),
            shift_end_hours_hand: Number(this.rosterShiftFormGroup.value.shift_end?.split(":")[0]),
            shift_end_mins_hand: Number(this.rosterShiftFormGroup.value.shift_end?.split(":")[1])
        };

        this.roasterShiftService.createRosterShift(data)
            .subscribe((res: ResponseObject) => {
                if (res.statusCode === 201) {
                    this.success_message = "User Created Successfuly."
                    this.getRosterShifts();
                    this.rosterShiftFormGroup.reset();
                }
            });
    }

    view(shift: any) {
        this.btn_show = false;
        let data = {
            ...shift,
            shift_start: shift.shift_start_hours_hand + ":" + shift.shift_start_mins_hand,
            shift_end: shift.shift_end_hours_hand + ":" + shift.shift_end_mins_hand
        };

        this.rosterShiftFormGroup.patchValue(data);
    }

    reset() {
        this.rosterShiftFormGroup.reset();
        this.btn_show = true;
    }
}
