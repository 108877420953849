<!-- Start Content-->
<div class="container-fluid">

    <!-- start page title -->
    <div class="row">
        <div class="col-12">
            <div class="page-title-box page-title-box-alt">
                <h4 class="page-title">Prescription History</h4>
                <div class="page-title-right">
                    <ol class="breadcrumb m-0">

                        <li class="breadcrumb-item"><a href="javascript: void(0);">Doctor</a></li>
                        <li class="breadcrumb-item active">View Prescription List</li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
    <!-- end page title -->

    <div class="row">
        <div class="col-xl-12 col-md-12">
            <div class="card">
                <div class="card-header">
                    Prescription list
                </div>
                <div class="card-body">
                    <div class="row">
                        <table class="table table-responsive table-striped table-hover table-bordered">
                            <thead>
                                <tr>
                                    <th>Sr. No.</th>
                                    <th>Name</th>
                                    <th>Sex / Age</th>
                                    <th>In Time</th>
                                    <th>Out Time</th>
                                    <th>Diagnosis</th>
                                    <th>Physician</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style="text-align: center;" *ngIf="!prescription_list.length">
                                    <td colspan="100">No Records</td>
                                </tr>
                                <tr *ngFor="let p of prescription_list; let i = index">
                                    <td class="col-1">{{i + 1}}</td>
                                    <td>{{p.patient_id.name}}</td>
                                    <td>{{p.patient_id.gender}} / {{p.walkin_register_id.age}}</td>
                                    <td>{{p.walkin_register_id.in | date: 'short'}}</td>
                                    <td>
                                        {{p.walkin_register_id.out | date: 'short'}}
                                    </td>
                                    <td>
                                        {{p.icd_code?.meaning_L4}}
                                    </td>
                                    <td>
                                        {{p.prescriber_id?.name}}
                                    </td>
                                    <td class="col-1">
                                        <div class="dropdown show">
                                            <a class="btn btn-success" (click)="viewPrescription(p)" role="button">
                                                View
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="btn-group mb-2 float-end">
                        <button type="button" (click)="getPrevPage()"
                            class="btn btn-secondary btn-bordered waves-effect" [disabled]="page_number == 0">
                            <i class="fas fa-arrow-left"></i> &nbsp; Prev
                        </button>
                        <button [disabled]="!(prescription_list.length >= page_size)" type="button"
                            (click)="getNextPage()" class="btn btn-secondary btn-bordered waves-effect">
                            Next &nbsp; <i class="fas fa-arrow-right"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div><!-- end col -->
    </div>
</div> <!-- container -->
<!-- Dashboard init-->