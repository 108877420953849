import { Component } from '@angular/core';
import { UserAPIService } from './user.service';
import { ResponseObject } from '../shared/model/response.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { AuthService } from '../shared/auth.service';
import { LocationMasterService } from '../shared/location_master.service';
import { UserService } from '../admin/user/user.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dependent.component.html',
    styleUrls: []
})
export class DependentComponent {

    public dependent_list: any = [];
    public state_list: Array<string> = [];
    public city_list: Array<string> = [];

    public dependentFormGroup = new FormGroup({
        name: new FormControl("", [Validators.required]),
        phone: new FormControl("", [Validators.required]),
        email: new FormControl("", [Validators.required]),
        dob: new FormControl("", [Validators.required]),
        gender: new FormControl("", [Validators.required]),
        address: new FormGroup({
            line1: new FormControl("", [Validators.required]),
            line2: new FormControl(),
            landmark: new FormControl(),
            state: new FormControl("", [Validators.required]),
            city: new FormControl("", [Validators.required]),
            pincode: new FormControl("", [Validators.required])
        }),
        relation: new FormControl("", [Validators.required]),
        is_active: new FormControl(false),
    });
    public dependent_id: string = "";
    public op_type: string = "Create";

    constructor(private userService: UserAPIService,
        private locationMasterService: LocationMasterService) { }

    get() {
        this.userService.getDependent()
            .subscribe((res: ResponseObject) => {
                if (res.statusCode === 200) {
                    this.dependent_list = res.data;
                }
            });
    }

    getCities(state: any) {
        this.city_list = this.locationMasterService.getCities(state.target.value)
    }

    ngOnInit() {
        this.get();
        this.state_list = this.locationMasterService.getStates();
    }

    private create(data: any) {
        this.userService.createDependent(data)
            .subscribe((res: ResponseObject) => {
                if (res.statusCode === 201) {
                    this.get();
                }
                this.dependentFormGroup.reset();
            });
    }

    private update(data: any) {
        this.userService.updateDependent(this.dependent_id, data)
            .subscribe((res: ResponseObject) => {
                if (res.statusCode === 200) {
                    this.get();
                }
                this.dependentFormGroup.reset();
            });
    }

    save() {

        if (!this.dependentFormGroup.valid) return;

        let data = {
            ... this.dependentFormGroup.value,
        }

        if (this.op_type === "Create")
            this.create(data);
        else if (this.op_type === "Update") {
            if (!this.dependent_id) return;
            this.update(data)
        }
    }

    updateModal(d: any) {
        this.op_type = "Update";
        this.dependent_id = d._id;
        d.dob = d.dob?.split("T")[0];
        this.dependentFormGroup.patchValue(d);
    }

    unSetCompanyIdContext() {
        this.op_type = "Create";
        this.dependent_id = "";
        this.dependentFormGroup.reset();
    }
}

@Component({
    selector: 'app-dashboard',
    templateUrl: './profile.component.html',
    styleUrls: []
})
export class UserProfileComponent {

    public file: any;
    public s3BaseUrl: string = environment.s3URL;
    public line1: string = "";
    public line2: string = "";
    public landmark: string = "";
    public state: string = "";
    public city: string = "";
    public pincode: string = "";

    public user_profile: any = {};
    public state_list: Array<string> = [];
    public city_list: Array<string> = [];

    constructor(private userService: UserAPIService,
        private userServiceOld: UserService,
        private authService: AuthService,
        private locationMasterService: LocationMasterService) { }

    get() {
        this.userService.getProfile()
            .subscribe((res: ResponseObject) => {
                if (res.statusCode === 200) {
                    if (!res.data[0].address) res.data[0].address = {};
                    this.user_profile = res.data[0];
                    let userDetails = JSON.parse(this.authService.getLoggedInUserDetails() || '{}');
                    userDetails.profile_picture = res.data[0]?.profile_picture;
                    this.authService.setUserDetails(userDetails);
                }
            });
    }

    getCities(state: any) {
        this.city_list = this.locationMasterService.getCities(state.target.value)
    }

    ngOnInit() {
        this.get();
        this.state_list = this.locationMasterService.getStates();
    }

    newFileSelect(e: any) {
        if (e.target.value) {
            this.file = <File>e.target.files[0];
        }
    }

    update() {
        let userDetails = JSON.parse(this.authService.getLoggedInUserDetails() || '{}');
        this.userServiceOld.updateCallCenterUser(userDetails?._id, {
            ...this.user_profile,
            address: {
                line1: this.line1,
                line2: this.line2,
                landmark: this.landmark,
                state: this.state,
                city: this.city,
                pincode: this.pincode
            }
        })
            .subscribe((res: ResponseObject) => {
                this.get();
            });
    }

    uploadLogo() {
        let fd = new FormData();
        if (this.file) {
            fd.append('file', this.file, this.file.name);
            this.userService.uploadProfilePicture(fd)
                .subscribe((res: ResponseObject) => {
                    this.get();
                });
        }
    }
}

