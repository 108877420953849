import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from './user.service';
import { ResponseObject } from 'src/app/shared/model/response.model';
import { CompanyService } from '../company/company.service';
import { LocationService } from '../location/location.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DepartmentService } from 'src/app/client_admin/department/department.service';
import { AuthService } from 'src/app/shared/auth.service';
import { LocationMasterService } from 'src/app/shared/location_master.service';
import {RosterManagementService} from "../../roster/roster-management/roster-user-management.service";

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent {

  public error_message: string = "";
  public success_message: string = "";

  public user_list: any = [];
  public op_type: string = "Create";
  public user_id: string = "";
  public ctx_user: any;
  public location_list: any = [];
  public company_list: any = [];
  public state_list: any = [];
  public city_list: any = [];
  public ctx_location_company: string = "";
  public ctx_location_location: string = "";
  public page_size: number = 5;
  public page_number: number = 0;

  public userFormGroup = new FormGroup({
    _id: new FormControl(),
    email: new FormControl(""),
    password: new FormControl("", [Validators.required]),
    name: new FormControl("", [Validators.required]),
    dob: new FormControl("", [Validators.required]),
    doj: new FormControl(),
    dop: new FormControl(),
    department: new FormControl(""),
    phone: new FormControl("", [Validators.required]),
    gender: new FormControl("", [Validators.required]),
    blood_group: new FormControl(""),
    resource_type: new FormControl("", [Validators.required]),
    organization_identifier: new FormControl(""),
    is_active: new FormControl(false, [Validators.required]),
    doctor_details: new FormGroup({
      registeration_number: new FormControl(),
      specialization: new FormControl()
    }),
    address: new FormGroup({
      line1: new FormControl(),
      line2: new FormControl(),
      landmark: new FormControl(),
      state: new FormControl(),
      city: new FormControl(),
      pincode: new FormControl()
    }),
    user_type: new FormControl([], [Validators.required]),
    locations: new FormControl([]),
    last_login: new FormControl()
  });

  updateModal(user: any) {
    this.op_type = "Update";
    this.user_id = user._id;
    this.ctx_user = user;
    delete user.__v;
    if (!user.doctor_details) {
      user.doctor_details = {
        registeration_number: "",
        specialization: ""
      }
    }
    this.city_list = this.locationMasterService.getCities(user.address?.state);
    user.dob = user.dob.split("T")[0];
    user.doj = user.doj?.split("T")[0];
    user.dop = user.dop?.split("T")[0];
    this.userFormGroup.patchValue(user);
  }

  unSetUserIdContext() {
    this.op_type = "Create";
    this.user_id = "";
    this.error_message = "";
    this.success_message = "";
    this.ctx_user = undefined;
    this.userFormGroup.reset({
      gender: ""
    });
  }

  constructor(private userService: UserService,
    private companyService: CompanyService,
    private locationMasterService: LocationMasterService,
    private locationService: LocationService) { }

  private getCallCenterUsers(page_size: number, page_number: number) {
    this.userService.getCallCenterUsers(page_size, page_number)
      .subscribe((res: ResponseObject) => {
        this.error_message = "";
        if (res.statusCode === 200) {
          this.user_list = res.data;
        }
        else {
          this.error_message = res.message;
        }
      });
  }

  getCities(state: any) {
    this.city_list = this.locationMasterService.getCities(state.target.value);
  }

  getNextPage() {
    this.page_number += 1;
    this.getCallCenterUsers(this.page_size, this.page_number)
  }

  getPrevPage() {
    this.page_number -= 1;
    this.getCallCenterUsers(this.page_size, this.page_number)
  }

  ngOnInit() {
    this.state_list = this.locationMasterService.getStates();
    this.getCallCenterUsers(this.page_size, this.page_number);
    this.companyService.getCompanies()
      .subscribe((res: ResponseObject) => {
        this.error_message = "";
        if (res.statusCode === 200) {
          this.company_list = res.data;
        }
      });

  }

  getLocations() {

    this.locationService.getLocations(this.ctx_location_company.split("~")[0])
      .subscribe((res: ResponseObject) => {
        this.error_message = "";
        if (res.statusCode === 200) {
          this.location_list = res.data;
        }
      });
  }

  appendLocation(){
    if (!this.ctx_location_company || !this.ctx_location_location) return;
    this.ctx_user.locations.push({
      location_id: this.ctx_location_location.split("~")[0],
      location_alias: this.ctx_location_location.split("~")[1],
      company_id: this.ctx_location_company.split("~")[0],
      company_name: this.ctx_location_company.split("~")[1],
    })
  }

  private createCallCenterUser(data: any) {
    this.userService.createCallCenterUser(data)
      .subscribe((res: ResponseObject) => {
        this.error_message = "";
        if (res.statusCode === 201) {
          this.success_message = "User Created Successfuly."
          this.getCallCenterUsers(this.page_size, this.page_number);
          this.userFormGroup.reset();
        }
        else {
          this.error_message = res.message;
        }
      });
  }

  private updateCallCenterUser(id: string, data: any) {
    this.userService.updateCallCenterUser(id, data)
      .subscribe((res: ResponseObject) => {
        this.error_message = "";
        if (res.statusCode === 200) {
          this.success_message = "User Updated Successfuly."
          this.getCallCenterUsers(this.page_size, this.page_number);
        }
        else {
          this.error_message = res.message;
        }
      });
  }

  save() {
    this.error_message = "";
    this.success_message = "";

    if (!this.userFormGroup.valid) {
      this.error_message = "Invalid form inputs";
      return;
    }
    if (!this.userFormGroup.value.locations) this.userFormGroup.value.locations = [];
    if (!this.userFormGroup.value.is_active) this.userFormGroup.value.is_active = false;
    if (this.ctx_user && !this.ctx_user.locations) this.ctx_user.locations = [];

    let data = {
      ... this.userFormGroup.value
    }

    if (this.op_type === "Create")
      this.createCallCenterUser(data);
    else if (this.op_type === "Update") {
      this.updateCallCenterUser(this.user_id, { ...data, locations: this.ctx_user.locations })
    }

  }

  checkRoleExists(user_type: string) {
    let user_types: string[] | null = this.userFormGroup.controls.user_type.value;
    if (user_types) {
      return user_types.includes(user_type);
    }
    return false;
  }

  popLocation(index: Number) {
    this.ctx_user.locations.splice(index, 1)
  }

}

@Component({
  selector: 'app-user',
  templateUrl: './user-restricted.component.html',
  styleUrls: ['./user.component.css']
})
export class UserManagementRestrictedComponent {

  public error_message: string = "";
  public success_message: string = "";

  public user_list: any = [];
  public op_type: string = "Create";
  public user_id: string = "";
  public ctx_user: any;
  public location_list: any = [];
  public company_list: any = [];
  public roaster_list: any = [];
  public department_list: any = [];
  public state_list: any = [];
  public city_list: any = [];
  public ctx_location_company: string = "";
  public ctx_location_location: string = "";
  public is_Ops: boolean = false;
  public shift: string = "";

  public userFormGroup = new FormGroup({
    _id: new FormControl(),
    email: new FormControl(""),
    password: new FormControl("", [Validators.required]),
    name: new FormControl("", [Validators.required]),
    dob: new FormControl("", [Validators.required]),
    doj: new FormControl(""),
    dop: new FormControl(),
    phone: new FormControl("", [Validators.required]),
    gender: new FormControl("", [Validators.required]),
    department: new FormControl("", [Validators.required]),
    blood_group: new FormControl(""),
    resource_type: new FormControl("", [Validators.required]),
    organization_identifier: new FormControl(""),
    is_active: new FormControl(false, [Validators.required]),
    doctor_details: new FormGroup({
      registeration_number: new FormControl(),
      specialization: new FormControl()
    }),
    // address: new FormGroup({
    //   line1: new FormControl(),
    //   line2: new FormControl(),
    //   landmark: new FormControl(),
    //   state: new FormControl(),
    //   city: new FormControl(),
    //   pincode: new FormControl()
    // }),
    user_type: new FormControl([], [Validators.required]),
    last_login: new FormControl()
  });

  private getDepartments() {
    this.departmentService.get(this.activatedroute.snapshot.queryParams["c"])
      .subscribe((res: ResponseObject) => {
        this.error_message = "";
        if (res.statusCode === 200) {
          this.department_list = res.data;
        }
        else {
          this.error_message = res.message;
        }
      });
  }

  updateModal(user: any) {
    if ((user.user_type?.includes("Ops") ||
      user.user_type?.includes("Doctor") ||
      user.user_type?.includes("Paramedic") ||
      user.user_type?.includes("Admin") ||
      user.user_type?.includes("Pharmacy")) && !this.is_Ops) {
      this.error_message = "You do not have enough permissions to perform this operation. Please contact admin.";
      return;
    }
    this.op_type = "Update";
    this.user_id = user._id;
    this.ctx_user = user;
    delete user.__v;
    if (!user.doctor_details) {
      user.doctor_details = {
        registeration_number: "",
        specialization: ""
      }
    }
    this.city_list = this.locationMasterService.getCities(user.address?.state);
    user.dob = user.dob.split("T")[0];
    user.doj = user.doj?.split("T")[0];
    user.dop = user.dop?.split("T")[0];
    this.userFormGroup.patchValue(user);
  }

  unSetUserIdContext() {
    this.op_type = "Create";
    this.user_id = "";
    this.error_message = "";
    this.success_message = "";
    this.ctx_user = undefined;
    this.userFormGroup.reset({
      gender: ""
    });
  }

  constructor(private userService: UserService,
    private companyService: CompanyService,
    private authService: AuthService,
    private locationService: LocationService,
    private activatedroute: ActivatedRoute,
    private locationMasterService: LocationMasterService,
    private roasterManagementService: RosterManagementService,
    private departmentService: DepartmentService) { }

  getCities(state: any) {
    this.city_list = this.locationMasterService.getCities(state.target.value);
  }

  private getCallCenterUsers() {
    this.userService.getLocationUsersAll(this.activatedroute.snapshot.queryParams["l"])
      .subscribe((res: ResponseObject) => {
        this.error_message = "";
        if (res.statusCode === 200) {
          this.user_list = res.data;
        }
        else {
          this.error_message = res.message;
        }
      });
  }

  ngOnInit() {
    this.state_list = this.locationMasterService.getStates();
    this.getCallCenterUsers();

    this.companyService.getCompanies()
      .subscribe((res: ResponseObject) => {
        this.error_message = "";
        if (res.statusCode === 200) {
          this.company_list = res.data;
        }
      });

    this.getDepartments();

    let user = JSON.parse(this.authService.getLoggedInUserDetails() || '{}');
    this.is_Ops = user.user_type.includes('Ops');

    this.roasterManagementService.getRosterShifts(this.activatedroute.snapshot.queryParams["l"])
      .subscribe((res: ResponseObject) => {
        this.error_message = "";
        if (res.statusCode === 200) {
          this.roaster_list = res.data;
        }
      });
  }

  getLocations() {

    this.locationService.getLocations(this.ctx_location_company.split("~")[0])
      .subscribe((res: ResponseObject) => {
        this.error_message = "";
        if (res.statusCode === 200) {
          this.location_list = res.data;
        }
      });
  }

  appendLocation(){
    if (!this.ctx_location_company || !this.ctx_location_location) return;
    this.ctx_user.locations.push({
      location_id: this.ctx_location_location.split("~")[0],
      location_alias: this.ctx_location_location.split("~")[1],
      company_id: this.ctx_location_company.split("~")[0],
      company_name: this.ctx_location_company.split("~")[1],
    })
  }

  private createCallCenterUser(data: any) {
    this.userService.createCallCenterUser(data)
      .subscribe((res: ResponseObject) => {
        this.error_message = "";
        if (res.statusCode === 201) {
          this.success_message = "User Created Successfuly."
          this.getCallCenterUsers();
          this.userFormGroup.reset();
        }
        else {
          this.error_message = res.message;
        }
      }, err => {
        if (err.status === 400) this.error_message = err.error.message[0];
        else this.error_message = err.error.message;
      });
  }

  private updateCallCenterUser(id: string, data: any) {
    this.userService.updateCallCenterUser(id, data)
      .subscribe((res: ResponseObject) => {
        this.error_message = "";
        if (res.statusCode === 200) {
          this.success_message = "User Updated Successfuly."
          this.getCallCenterUsers();
        }
        else {
          this.error_message = res.message;
        }
      });
  }

  save() {
    this.error_message = "";
    this.success_message = "";

    if (!this.userFormGroup.valid) {
      this.error_message = "Invalid form inputs";
      return;
    }

    if (!this.userFormGroup.value.is_active) this.userFormGroup.value.is_active = false;
    if (this.ctx_user && !this.ctx_user.locations) this.ctx_user.locations = [];

    let data = {
      ... this.userFormGroup.value
    }

    if (this.op_type === "Create") {
      this.createCallCenterUser({
        ...data, locations: [
          {
            location_id: this.activatedroute.snapshot.queryParams["l"],
            company_id: this.activatedroute.snapshot.queryParams["c"]
          }
        ]
      });
    }
    else if (this.op_type === "Update") {
      this.updateCallCenterUser(this.user_id, { ...data, locations: this.ctx_user.locations })
    }

  }

  checkRoleExists(user_type: string) {
    let user_types: string[] | null = this.userFormGroup.controls.user_type.value;
    if (user_types) {
      return user_types.includes(user_type);
    }
    return false;
  }

  mapUserToShift(user_id: string) {

  }

}
