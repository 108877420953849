import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseObject } from 'src/app/shared/model/response.model';
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class ClientUserManagementService {

    constructor(private http: HttpClient) { }

    getWalkIns(location_id: string, date: string, doctor_id: string | null = null) {
        return this.http.get<ResponseObject>(environment.apiEndpoint + "/walk-in/" + location_id
            + "?date=" + date + "&doctor_id=" + doctor_id);
    }

    getWalkInsForDay(location_id: string, date: string) {
        return this.http.get<ResponseObject>(environment.apiEndpoint + "/walk-in/" + location_id
            + "?date=" + date);
    }

    createWalkIn(data: any) {
        return this.http.post<ResponseObject>(environment.apiEndpoint + "/walk-in", data);
    }

    updateWalkIn(id: string, data: any) {
        return this.http.put<ResponseObject>(environment.apiEndpoint + "/walk-in/" + id, data);
    }

    markOut(id: string) {
        return this.http.patch<ResponseObject>(environment.apiEndpoint + "/walk-in/mark-out/" + id, {});
    }
}
