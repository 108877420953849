<!-- Start Content-->
<div class="container-fluid">

    <!-- start page title -->
    <div class="row">
        <div class="col-12">
            <div class="page-title-box page-title-box-alt">
                <h4 class="page-title">Book Vaccination</h4>
                <div class="page-title-right">
                    <ol class="breadcrumb m-0">

                        <li class="breadcrumb-item"><a href="javascript: void(0);">Vaccination</a></li>
                        <li class="breadcrumb-item active">Booking</li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
    <!-- end page title -->

    <div class="row justify-content-center">
        <div class="col-xl-12">

            <!-- Pricing Title-->
            <div class="text-center pb-2">
                <h3 class="mb-2">Our <span class="text-primary">Vaccines</span></h3>
                <p class="text-muted w-50 m-auto">
                    Choose from the range of vaccines available.
                </p>
            </div>

            <!-- Plans -->
            <div class="row my-3">
                <div class="col-xl-3 col-md-3" *ngFor="let p of vaccine_list">
                    <div class="card card-pricing">
                        <div class="card-body text-center">
                            <p class="fw-bold text-uppercase">{{p.name}} - {{p.brand}}</p>
                            <small>Fulfilled at {{p.delivery_type}}</small>
                            <div class="d-grid">
                                <button class="btn btn-primary waves-effect waves-light mt-4" data-bs-toggle="modal"
                                    data-bs-target="#bookAppointmentModal" (click)="openBookingForm(p)">View Vaccine
                                    Details &amp;
                                    Book</button>
                            </div>
                        </div>
                    </div> <!-- end Pricing_card -->
                </div> <!-- end col -->
            </div>
            <!-- end row -->

        </div> <!-- end col-->
    </div>
    <!-- end row -->

</div> <!-- container -->
<!-- Dashboard init-->


<!-- Center modal content -->
<div class="modal fade" id="bookAppointmentModal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="myCenterModalLabel">View Vaccine Details &amp; Book Appointment</h4>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <h4 style="display: inline;">{{vaccine_selected?.name}}</h4> &nbsp;
                <small>{{vaccine_selected?.brand}}</small>
                &nbsp; | &nbsp;
                <small>Fulfilled at {{vaccine_selected?.delivery_type}}</small>
                <hr />
                <table class="table table-bordered" *ngIf="!is_client_admin">
                    <thead>
                        <th>Sr. No.</th>
                        <th>Name</th>
                        <th></th>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1.</td>
                            <td>Me</td>
                            <td><input type="checkbox" value="ME" (change)="onCheckBoxToggle($event)" /></td>
                        </tr>
                        <tr *ngFor="let d of dependent_list; let i = index;">
                            <td>{{i + 2}}.</td>
                            <td>{{d.name}}</td>
                            <td><input type="checkbox" value="{{d._id}}" (change)="onCheckBoxToggle($event)" /></td>
                        </tr>
                    </tbody>
                </table>
                <div class="row" *ngIf="!is_client_admin && delivery_type=='Lab'">
                    <div class="col-md-4 mb-2">
                        <label>State:</label>
                        <select class="form-control" (change)="getCities($event)">
                            <option value="">-- Choose One --</option>
                            <option value="{{s}}" *ngFor="let s of state_list">{{s}}</option>
                        </select>
                    </div>
                    <div class="col-md-4 mb-2">
                        <label>City:</label>
                        <select class="form-control" (change)="getLabs($event)">
                            <option value="">-- Choose One --</option>
                            <option value="{{c}}" *ngFor="let c of city_list">{{c}}</option>
                        </select>
                    </div>
                    <div class="col-md-4 mb-2">
                        <label>Delivery Type
                            <span style="color: red;">*</span></label>
                        <select class="form-control" [disabled]="vaccine_selected?.delivery_type != 'Lab and Home'"
                            [(ngModel)]="delivery_type">
                            <option value="">-- Choose One --</option>
                            <option value="Home">Home</option>
                            <option value="Lab">Lab</option>
                        </select>
                    </div>
                </div>
                <div class="row" *ngIf="!is_client_admin">
                    <div class="col-md-6 mb-2" *ngIf="delivery_type=='Lab'">
                        <label>Centres</label>
                        <select class="form-control" [(ngModel)]="vaccine_map_selected">
                            <option selected value="">-- Choose One --</option>
                            <option value="{{l._id}}" *ngFor="let l of lab_list">{{l.lab_id?.name || "-- Home Pickup--"}}</option>
                        </select>
                    </div>
                    <div class="col-md-6 mb-2">
                        <label>Date for Scheduling <span style="color: red;">* </span> </label>
                        <input type="date" class="form-control" [(ngModel)]="date_booking" min="{{date_picker_min}}" />
                        <input type="hidden" [(ngModel)]="vaccine_booking" />
                    </div>
                </div>
                <div class="col-md-12 mb-2 d-grid">
                    <form target="_blank" method="post" id="payuPaymentForm" action="https://secure.payu.in/_payment"
                        [formGroup]="paymentFormGroup">
                        <input type="hidden" formControlName="key" name="key" />
                        <input type="hidden" formControlName="txnid" name="txnid" />
                        <input type="hidden" formControlName="amount" name="amount" />
                        <input type="hidden" formControlName="productinfo" name="productinfo" />
                        <input type="hidden" formControlName="firstname" name="firstname" />
                        <input type="hidden" formControlName="email" name="email" />
                        <input type="hidden" formControlName="phone" name="phone" />
                        <input type="hidden" formControlName="surl" name="surl" />
                        <input type="hidden" formControlName="furl" name="furl" />
                        <input type="hidden" formControlName="udf1" name="udf1" />
                        <input type="hidden" formControlName="udf2" name="udf2" />
                        <input type="hidden" formControlName="hash" name="hash" />
                        <button type="submit" style="display: none;" id="btnPaymentSubmit"></button>
                    </form>
                    <button class="btn btn-primary" data-bs-dismiss="modal" aria-label="Close" type="button"
                        (click)="book()">
                        Pay
                        <span *ngIf="vaccine_selected?.billing_type == 'PriceCap'">&#8377; {{((((vaccine_selected?.price
                            || 0) * (booking_users.length || 0)) -
                            vaccine_selected?.cap_limit) < 0)? 0: ((((vaccine_selected?.price || 0) *
                                (booking_users.length || 0)) - vaccine_selected?.cap_limit))}} </span>
                                <span *ngIf="vaccine_selected?.billing_type == 'PersonCap'">&#8377; 0 </span>
                                &amp; Book
                    </button>
                </div>
            </div>
        </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
