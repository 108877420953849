import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseObject } from 'src/app/shared/model/response.model';
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class VaccinesService {

    constructor(private http: HttpClient) { }

    getVaccines() {
        return this.http.get<ResponseObject>(environment.apiEndpoint + "/vaccines");
    }

    createVaccine(data: any) {
        return this.http.post<ResponseObject>(environment.apiEndpoint + "/vaccines", data);
    }

    update(id: string, data: any) {
        return this.http.put<ResponseObject>(environment.apiEndpoint + "/vaccines/" + id, data);
    }

    map(data: any) {
        return this.http.patch<ResponseObject>(environment.apiEndpoint + "/vaccines/map-location", data);
    }

    getMap(id: any, is_active: boolean = true) {
        return this.http.get<ResponseObject>(environment.apiEndpoint + "/vaccines/map-location/" + id +
          "?is_active=" + is_active);
    }

    updateMap(id: any, data: any) {
      return this.http.patch<ResponseObject>(environment.apiEndpoint + "/vaccines/map-location/" + id, data);
    }

    book(details: any) {
        return this.http.post<ResponseObject>(environment.apiEndpoint + "/vaccine-booking", details);
    }

    getMyBooking() {
        return this.http.get<ResponseObject>(environment.apiEndpoint + "/vaccine-booking/my");
    }

    updateBooking(id: any, data: any) {
        return this.http.put<ResponseObject>(environment.apiEndpoint + "/vaccine-booking/" + id, data);
    }

    getLocationBooking(location_id: string) {
        return this.http.get<ResponseObject>(environment.apiEndpoint + "/vaccine-booking?location_id=" + location_id);
    }

    getLocationBookingByStatus(location_id: string,
        status: string,
        filter_delivery_type: string,
        page_size: number = 0, page_number: number = 0) {
        return this.http.get<ResponseObject>(environment.apiEndpoint + "/vaccine-booking?location_id=" + location_id
            + "&status=" + status
            + "&delivery_type=" + filter_delivery_type
            + "&page_size=" + page_size
            + "&page_number=" + page_number);
    }

    attemptPayment(data: any) {
        return this.http.post<ResponseObject>(environment.apiEndpoint + "/payment/attempt", data);
    }

    getPaymentsforPackage(identifier: any) {
        return this.http.get<ResponseObject>(environment.apiEndpoint + "/payment?purpose_identifer=" + identifier);
    }

    getLocationVaccines(location_id: string) {
        return this.http.get<ResponseObject>(environment.apiEndpoint + "/vaccines/location?location_ids=" + location_id);
    }

    uploadReport(booking_id: any, data: any) {
        return this.http.post<ResponseObject>(environment.apiEndpoint + "/vaccine-booking/upload/" + booking_id, data);
    }
}
