import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { AuthService } from "../shared/auth.service";
import { ResponseObject } from "../shared/model/response.model";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";

@Injectable()
export class LoginService {

    constructor(private http: HttpClient,
        private authService: AuthService,
        private route: Router
    ) { }

    public login(phone: string, password: string, type: string) {
        return this.http.post<ResponseObject>(environment.apiEndpoint + "/auth/login", { phone, password, type });
    }

    public sendOTP(phone: string) {
        return this.http.post<ResponseObject>(environment.apiEndpoint + "/auth/send-otp", { phone });
    }

    public loginOTP(phone: string) {
        return this.http.post<ResponseObject>(environment.apiEndpoint + "/auth/login-otp", { phone });
    }

    public verifyOTP(data: any) {
        return this.http.post<ResponseObject>(environment.apiEndpoint + "/auth/verify-otp", data);
    }

    public verifyDomain(email: string) {
        return this.http.post<ResponseObject>(environment.apiEndpoint + "/auth/verify-domain/" + email, {});
    }

    public registerEmployee(user: any) {
        return this.http.post<ResponseObject>(environment.apiEndpoint + "/auth/register-employee", user);
    }
}
