import { Component } from '@angular/core';
import { JSLoader } from 'src/app/shared/js-loader.function';

@Component({
    selector: 'app-dashboard',
    templateUrl: './payment_success.component.html',
    styleUrls: []
})
export class PaymentSuccessStaticComponent {
    ngOnInit() {
    }
}


@Component({
    selector: 'app-dashboard',
    templateUrl: './payment_fail.component.html',
    styleUrls: []
})
export class PaymentFailStaticComponent {
    ngOnInit() {
    }
}


@Component({
    selector: 'app-dashboard',
    templateUrl: './404.component.html',
    styleUrls: []
})
export class FourNotFourStaticComponent {
    ngOnInit() {
    }
}


