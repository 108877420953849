<!-- Start Content-->
<div class="container-fluid">

    <!-- start page title -->
    <div class="row">
        <div class="col-12">
            <div class="page-title-box page-title-box-alt">
                <h4 class="page-title">Vaccine Map</h4>
                <div class="page-title-right">
                    <ol class="breadcrumb m-0">

                        <li class="breadcrumb-item"><a href="javascript: void(0);">Vaccination</a></li>
                        <li class="breadcrumb-item active">Vaccine Mapping</li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
    <!-- end page title -->

    <div class="row">
        <div class="col-xl-12 col-md-12">
            <div class="card">
                <div class="card-header">
                    Vaccine Mapping
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-xs-12">
                            <form [formGroup]="vaccineMapFormGroup" class="" (ngSubmit)="save()">
                                <div class="row">
                                    <div class="col-md-3">
                                        <div class="mb-2">
                                            <label for="name" class="form-label">Vaccine <span
                                                    style="color: red;">*</span></label>
                                            <select class="form-control" formControlName="vaccine_id">
                                                <option value="">-- Choose One --</option>
                                                <option *ngFor="let p of vaccine_list" value="{{p._id}}">{{p.name}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="mb-2">
                                            <label for="description" class="form-label">Centre</label>
                                            <select class="form-control" formControlName="lab_id">
                                                <option value="">-- Choose One --</option>
                                                <option *ngFor="let l of lab_list" value="{{l._id}}">{{l.name}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="mb-2">
                                            <label for="description" class="form-label">Billing Type <span
                                                    style="color: red;">*</span></label>
                                            <select class="form-control" formControlName="billing_type">
                                                <option value="">-- Choose One --</option>
                                                <option value="PriceCap">Price Cap</option>
                                                <option value="PersonCap">Person Cap</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="mb-2">
                                            <label for="description" class="form-label">Cap Limit <span
                                                    style="color: red;">*</span></label>
                                            <input type="number" placeholder="Enter cap limit here" class="form-control"
                                                formControlName="cap_limit" />
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="mb-2">
                                            <label for="description" class="form-label">Price <span
                                                    style="color: red;">*</span></label>
                                            <input type="number" placeholder="Enter price here" class="form-control"
                                                formControlName="price" />
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="mb-2">
                                            <label for="is_active" class="form-label">Is Active</label>
                                            <br />
                                            <input type="checkbox" id="is_active" formControlName="is_active">
                                        </div>
                                    </div>
                                </div>

                                <div class="mb-2 text-left">
                                    <button *ngIf="op_type != 'Update'" class="btn btn-primary" type="submit"
                                        [disabled]="!vaccineMapFormGroup.valid">Save
                                    </button> &nbsp;
                                    <button class="btn btn-warning" type="button"
                                        (click)="unSetCompanyIdContext()">Reset
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-xl-12 col-md-12">
            <div class="card">
                <div class="card-header">
                    Vaccine Mapping List
                </div>
                <div class="card-body">
                    <div class="row table-responsive">
                        <table class="table table-striped table-hover table-bordered">
                            <thead>
                                <tr>
                                    <th>Sr. No.</th>
                                    <th>Vaccine</th>
                                    <th>Lab</th>
                                    <th>Limit Type</th>
                                    <th>Limit Size</th>
                                    <th>Price</th>
                                    <th>Active</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style="text-align: center;" *ngIf="!map_list.length">
                                    <td colspan="100">No Content</td>
                                </tr>
                                <tr *ngFor="let s of map_list; let i = index">
                                    <td class="col-1">{{i + 1}}</td>
                                    <td>{{s.vaccine_id.name}}</td>
                                    <td>{{s.lab_id?.name || "-- Home Pickup --"}}</td>
                                    <td>{{s.billing_type}}</td>
                                    <td>{{s.cap_limit}}</td>
                                    <td>{{s.price}}</td>
                                    <td>
                                        <span class="badge bg-success rounded-pill" *ngIf="s.is_active">Yes</span>
                                        <span class="badge bg-danger rounded-pill" *ngIf="!s.is_active">No</span>
                                    </td>
                                    <td>
                                      <div class="dropdown">
                                        <button class="btn btn-danger btn-sm dropdown-toggle" type="button"
                                                data-bs-toggle="dropdown" aria-expanded="false">
                                          Actions &nbsp; <i class="ri-arrow-down-fill"></i>
                                        </button>
                                        <ul class="dropdown-menu">

                                          <li><a class="dropdown-item"
                                                 (click)="updateModal(s)">View</a></li>
                                          <li><a class="dropdown-item" (click)="updateVaccineMap(s._id, !s.is_active)">
                                            Update</a></li>
                                        </ul>
                                      </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div><!-- end col -->
    </div>
    <!-- end row -->

</div> <!-- container -->
<!-- Dashboard init-->
