<!-- Start Content-->
<div class="container-fluid">

    <!-- start page title -->
    <div class="row">
        <div class="col-12">
            <div class="page-title-box page-title-box-alt">
                <h4 class="page-title">My Profile</h4>
                <div class="page-title-right">
                    <ol class="breadcrumb m-0">
                        <li class="breadcrumb-item"><a href="javascript: void(0);">User Management</a></li>
                        <li class="breadcrumb-item active">Profile</li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
    <!-- end page title -->

    <div class="row">
        <div class="col-lg-4 col-xl-4">
            <div class="card text-center">
                <div class="card-body">
                    <img src="{{s3BaseUrl}}/{{user_profile?.profile_picture}}"
                        class="rounded-circle avatar-xl img-thumbnail" alt="profile-image">

                    <h4 class="mt-3 mb-0">{{user_profile?.name}}</h4>
                    <p class="text-muted">{{ user_profile?.user_type?.join(", ") }}</p>

                    <div class="input-group mb-2">
                        <input type="file" class="form-control" placeholder="Company logo"
                            (input)="newFileSelect($event)" aria-describedby="basic-addon2">
                        <button class="btn btn-success" (click)="uploadLogo()" type="button">Update Profile
                            Picture</button>
                    </div>

                    <div class="text-start mt-3">
                        <div class="table-responsive">
                            <table class="table table-borderless table-sm">
                                <tbody>
                                    <tr>
                                        <th scope="row">Full Name :</th>
                                        <td class="text-muted">{{user_profile?.name}}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Mobile :</th>
                                        <td class="text-muted">{{user_profile?.phone}}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Email :</th>
                                        <td class="text-muted">{{user_profile?.email}}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Gender :</th>
                                        <td class="text-muted">{{user_profile?.gender}}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Date of Birth :</th>
                                        <td class="text-muted">{{user_profile?.dob?.split("T")[0]}}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Address :</th>
                                        <td class="text-muted">
                                            {{user_profile?.address?.line1}}, {{user_profile?.address?.line2}}
                                            <br />
                                            {{user_profile?.address?.landmark}}, {{user_profile?.address?.city}}
                                            <br />
                                            {{user_profile?.address?.state}} {{user_profile?.address?.pincode}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div> <!-- end card-box -->
        </div> <!-- end col-->

        <div class="col-lg-8 col-xl-8">
            <div class="card">
                <div class="card-header">Current Locations</div>
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table table-borderless table-nowrap">
                            <thead class="table-light">
                                <tr>
                                    <th>#</th>
                                    <th>Company Name</th>
                                    <th>Location</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let l of user_profile?.locations;  let i = index">
                                    <td>{{i + 1}}.</td>
                                    <td>{{l.company_name}}</td>
                                    <td>{{l.location_alias}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div> <!-- end card-->


            <div class="card">
                <div class="card-header">Update Address</div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="mb-2">
                                <label class="form-label" for="line1">Line 1 <span style="color: red;">*</span></label>
                                <input class="form-control" required type="text" id="line1" placeholder="Enter Line 1"
                                    [(ngModel)]="line1">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="mb-2">
                                <label class="form-label" for="line2">Line 2</label>
                                <input class="form-control" type="text" id="line2" placeholder="Enter Line 2"
                                    [(ngModel)]="line2">
                            </div>
                        </div>
                    </div>



                    <div class="form-group">
                        <div class="row">
                            <div class="col-md-3">
                                <div class="mb-2">
                                    <label class="form-label" for="locality">Locality <span
                                            style="color: red;">*</span></label>
                                    <input class="form-control" required type="text" id="locality"
                                        placeholder="Enter Locality" [(ngModel)]="landmark">
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="mb-2">
                                    <label class="form-label" for="state">State <span
                                            style="color: red;">*</span></label>
                                    <select class="form-control" id="state" (change)="getCities($event)"
                                        [(ngModel)]="state">
                                        <option selected value="">-- Select --</option>
                                        <option value="{{s}}" *ngFor="let s of state_list">{{s}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="mb-2">
                                    <label class="form-label" for="city">City <span style="color: red;">*</span></label>
                                    <select class="form-control" id="state" [(ngModel)]="city">
                                        <option selected value="">-- Select --</option>
                                        <option value="{{s}}" *ngFor="let s of city_list">{{s}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="mb-2">
                                    <label class="form-label" for="pincode">Pincode <span
                                            style="color: red;">*</span></label>
                                    <input class="form-control" type="text" id="pincode" placeholder="Enter Pincode"
                                        [(ngModel)]="pincode">
                                </div>
                            </div>
                        </div>


                        <div class="row">
                            <div class="col-md-12">
                                <button class="btn btn-primary" type="button" (click)="update()">Update
                                    Information</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div> <!-- end card-->
        </div> <!-- end col -->
    </div>
    <!-- end row-->

</div> <!-- container-fluid -->