
import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ResponseObject } from 'src/app/shared/model/response.model';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/admin/user/user.service';
import { AuthService } from 'src/app/shared/auth.service';
import { HandoverRegisterService } from './handover.service';

@Component({
    selector: 'app-user',
    templateUrl: './handover.component.html',
    styleUrls: ['./handover.component.css']
})
export class HandoverRegisterComponent {

    public error_message: string = "";
    public success_message: string = "";
    public handover_list: any = [];
    public paramedic_list: any = [];
    public medical_user_list: any = [];


    public handoverRegisterFormGroup = new FormGroup({
        given_to: new FormControl("", [Validators.required]),
        location_id: new FormControl(this.activatedroute.snapshot.queryParams["l"], [Validators.required]),
        company_id: new FormControl(this.activatedroute.snapshot.queryParams["c"], [Validators.required]),
        on_duty_nurse: new FormControl("", [Validators.required]),
        notes: new FormControl("", [Validators.required])
    });

    constructor(private handoverRegisterService: HandoverRegisterService,
        private userService: UserService,
        private activatedroute: ActivatedRoute) { }

    private getRegisterContent() {
        this.handoverRegisterService.get(this.activatedroute.snapshot.queryParams["l"])
            .subscribe((res: ResponseObject) => {
                this.error_message = "";
                if (res.statusCode === 200) {
                    this.handover_list = res.data;
                }
                else {
                    this.error_message = res.message;
                }
            }, err => {
                if (err.status === 400) this.error_message = err.error.message[0];
                else this.error_message = err.error.message;
            });
    }

    private getMedicalUsers() {
        this.userService.getDoctors(this.activatedroute.snapshot.queryParams["l"])
            .subscribe((res: ResponseObject) => {
                this.error_message = "";
                if (res.statusCode === 200) {
                    this.userService.getParamedics(this.activatedroute.snapshot.queryParams["l"])
                        .subscribe((res2: ResponseObject) => {
                            this.error_message = "";
                            if (res2.statusCode === 200) {
                                this.paramedic_list = res2.data;

                                let arr = this.paramedic_list.concat(res.data);

                                let mymap = new Map();
                                this.medical_user_list = arr.filter((el: any) => {
                                    const val = mymap.get(el.name);
                                    if (val) {
                                        if (el.id < val) {
                                            mymap.delete(el.name);
                                            mymap.set(el.name, el.id);
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    }
                                    mymap.set(el.name, el.id);
                                    return true;
                                });

                                console.log(this.medical_user_list);
                            }
                            else {
                                this.error_message = res.message;
                            }
                        }, err => {
                            if (err.status === 400) this.error_message = err.error.message[0];
                            else this.error_message = err.error.message;
                        });
                }
                else {
                    this.error_message = res.message;
                }
            }, err => {
                if (err.status === 400) this.error_message = err.error.message[0];
                else this.error_message = err.error.message;
            });

    }

    ngOnInit() {
        this.getRegisterContent();
        this.getMedicalUsers();
    }

    private createHandover(data: any) {
        this.handoverRegisterService.create(data)
            .subscribe((res: ResponseObject) => {
                this.error_message = "";
                if (res.statusCode === 201) {
                    this.getRegisterContent();
                    this.handoverRegisterFormGroup.reset();
                }
                else {
                    this.error_message = res.message;
                }
            }, err => {
                if (err.status === 400) this.error_message = err.error.message[0];
                else this.error_message = err.error.message;
            });
    }

    save() {
        this.error_message = "";
        this.success_message = "";

        if (!this.handoverRegisterFormGroup.valid) {
            this.error_message = "Invalid form inputs";
            return;
        }
        let data = {
            ... this.handoverRegisterFormGroup.value,
        }
        this.createHandover(data);
    }
}