
import { Component, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/admin/user/user.service';
import { ResponseObject } from 'src/app/shared/model/response.model';
import { OHCAppointmentService } from './ohc-appointment.service';
import { AuthService } from 'src/app/shared/auth.service';
import { DOCUMENT } from '@angular/common';


@Component({
    selector: 'app-user',
    templateUrl: './ohc-appointment.component.html',
    styleUrls: ['./ohc-appointment.component.css']
})
export class OHCAppointmentComponent {

    public doctor_list: any = [];
    public app_list: any = [];
    public op_type: string = "Create";
    public booking_date: string = "";

    public page_size: number = 3;
    public page_number: number = 0;

    constructor(private userService: UserService,
        private activatedroute: ActivatedRoute,
        private ohcAppointmentService: OHCAppointmentService,
        private authService: AuthService,
        @Inject(DOCUMENT) private document: Document) { }

    private get() {
        let user = this.authService.getLoggedInUserDetails();
        this.ohcAppointmentService.get(
            this.activatedroute.snapshot.queryParams["l"], JSON.parse(user || "{}")?._id,
            this.page_size, this.page_number
        )
            .subscribe((res: ResponseObject) => {
                if (res.statusCode === 200) {
                    this.app_list = res.data;
                }
            });
    }

    getNextPage() {
        this.page_number += 1;
        this.get()
    }

    getPrevPage() {
        this.page_number -= 1;
        this.get()
    }

    ngOnInit() {
        this.userService.getDoctors(this.activatedroute.snapshot.queryParams["l"])
            .subscribe((res: ResponseObject) => {
                if (res.statusCode === 200) {
                    this.doctor_list = res.data;
                }
            });
        this.get();
    }

    bookAppointment(doctor_id: string, index: number) {
        // @ts-ignore
        let slot = this.document?.getElementById("slot" + index)?.value;
        this.ohcAppointmentService.create({
            company_id: this.activatedroute.snapshot.queryParams["c"],
            location_id: this.activatedroute.snapshot.queryParams["l"],
            booked_with: doctor_id,
            slot: slot
        })
            .subscribe((res: ResponseObject) => {
                if (res.statusCode === 201) {
                    this.get();
                    this.booking_date = "";
                }
            });
    }

    buttonValid(i: number) {
        //@ts-ignore
        if (this.document?.getElementById("slot" + i)?.value) {
            //@ts-ignore
            this.document.getElementById("button" + i).disabled = false;
            this.booking_date = "1";
        }
        else {
            //@ts-ignore
            this.document.getElementById("button" + i).disabled = true;
            this.booking_date = "";
        }
    }

    cancelAppointment(appointment: string) {
        this.ohcAppointmentService.cancel(appointment)
            .subscribe((res: ResponseObject) => {
                if (res.statusCode === 200) {
                    this.get();
                    this.booking_date = "";
                }
            });
    }
}

