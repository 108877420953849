
import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ResponseObject } from 'src/app/shared/model/response.model';
import { ActivatedRoute } from '@angular/router';
import { AmbulanceO2RegisterService } from './ambulance-o2-register.service';

@Component({
    selector: 'app-user',
    templateUrl: './ambulance-o2-register.component.html',
    styleUrls: ['./ambulance-o2-register.component.css']
})
export class AmbulanceO2RegisterComponent {

    public error_message: string = "";
    public success_message: string = "";
    public training_list: any = [];
    public received_quantity: number = 0;


    public ambulanceO2RegisterFormGroup = new FormGroup({
        date: new FormControl("", [Validators.required]),
        reading: new FormControl("", [Validators.required]),
        location_id: new FormControl(this.activatedroute.snapshot.queryParams["l"], [Validators.required]),
        company_id: new FormControl(this.activatedroute.snapshot.queryParams["c"], [Validators.required]),
    });

    constructor(private ambulanceO2RegisterService: AmbulanceO2RegisterService,
        private activatedroute: ActivatedRoute) { }

    private getRegisterContent() {
        this.ambulanceO2RegisterService.get(this.activatedroute.snapshot.queryParams["l"])
            .subscribe((res: ResponseObject) => {
                this.error_message = "";
                if (res.statusCode === 200) {
                    this.training_list = res.data;
                }
                else {
                    this.error_message = res.message;
                }
            }, err => {
                if (err.status === 400) this.error_message = err.error.message[0];
                else this.error_message = err.error.message;
            });
    }

    ngOnInit() {
        this.getRegisterContent();
    }

    private createdepartment(data: any) {
        this.ambulanceO2RegisterService.create(data)
            .subscribe((res: ResponseObject) => {
                this.error_message = "";
                if (res.statusCode === 201) {
                    this.getRegisterContent();
                }
                else {
                    this.error_message = res.message;
                }
                this.ambulanceO2RegisterFormGroup.reset();
            });
    }

    save() {
        this.error_message = "";
        this.success_message = "";

        if (!this.ambulanceO2RegisterFormGroup.valid) {
            this.error_message = "Invalid form inputs";
            return;
        }
        let data = {
            ... this.ambulanceO2RegisterFormGroup.value,
        }
        this.createdepartment(data);
    }
}