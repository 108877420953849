import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseObject } from 'src/app/shared/model/response.model';
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class LaundryRegisterService {

    constructor(private http: HttpClient) { }

    get(location_id: string) {
        return this.http.get<ResponseObject>(environment.apiEndpoint + "/laundry-register/" + location_id);
    }

    create(data: any) {
        return this.http.post<ResponseObject>(environment.apiEndpoint + "/laundry-register", data);
    }

    markReceived(id: string, quantity: number) {
        return this.http.patch<ResponseObject>(environment.apiEndpoint + "/laundry-register/mark-received/" + id + "?quantity=" + quantity, {});
    }
}