<!-- Start Content-->
<div class="container-fluid">

    <!-- start page title -->
    <div class="row">
        <div class="col-12">
            <div class="page-title-box page-title-box-alt">
                <h4 class="page-title">Pharmacy Management</h4>
                <div class="page-title-right">
                    <ol class="breadcrumb m-0">

                        <li class="breadcrumb-item"><a href="javascript: void(0);">Clinic</a></li>
                        <li class="breadcrumb-item active">Pharmacy</li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
    <!-- end page title -->

    <div class="row">
        <div class="col-xl-12 col-md-12">
            <div class="card">
                <div class="card-header">
                    Current Queue
                </div>
                <div class="card-body">
                    <div class="row">
                        <table class="table table-responsive table-striped table-hover table-bordered">
                            <thead>
                                <tr>
                                    <th>Sr. No.</th>
                                    <th>Name</th>
                                    <th>Sex / Age</th>
                                    <th>In Time</th>
                                    <th>Out Time</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style="text-align: center;" *ngIf="!prescription_list.length">
                                    <td colspan="100">No Records</td>
                                </tr>
                                <tr *ngFor="let p of prescription_list; let i = index">
                                    <td class="col-1">{{i + 1}}</td>
                                    <td>{{p.patient_id.name}}</td>
                                    <td>{{p.patient_id.gender}} / {{p.walkin_register_id.age}}</td>
                                    <td>{{p.walkin_register_id.in | date: 'short'}}</td>
                                    <td>
                                        {{p.walkin_register_id.out | date: 'short'}}
                                    </td>
                                    <td class="col-1">
                                        <div class="dropdown show">
                                            <a class="btn btn-success" (click)="viewPrescription(p._id)" role="button">
                                                View
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div><!-- end col -->
    </div>

    <div class="row">
        <div class="col-xl-12 col-md-12">
            <div class="card">
                <div class="card-header">
                    <h5>Find & Manage Medicine</h5>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-xs-12">
                            <form [formGroup]="pharmacyFormGroup" class="" (ngSubmit)="save()">
                                <div class="row">
                                    <div class="col-md-3">
                                        <label for="name" class="form-label">Name <span
                                                style="color: red;">*</span></label>
                                        <input [readOnly]="op_type == 'Update'" type="text" class="form-control"
                                            type="text" id="name" placeholder="Enter medicine's name"
                                            formControlName="name" aria-label="Medicine's name"
                                            aria-describedby="basic-addon2">
                                        <input class="form-control" type="hidden" id="name"
                                            placeholder="Enter Medicine's name" formControlName="medicine_id"
                                            aria-label="Medicine's name" aria-describedby="basic-addon2">
                                    </div>
                                    <div class="col-md-3">
                                        <div class="mb-2">
                                            <label for="potency" class="form-label">Potency <span
                                                    style="color: red;">*</span></label>
                                            <input [readOnly]="op_type == 'Update'" class="form-control" type="text"
                                                id="potency" required placeholder="Enter medicine's potency"
                                                formControlName="potency">
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="mb-2">
                                            <label for="brand" class="form-label">Brand <span
                                                    style="color: red;">*</span></label>
                                            <input [readOnly]="op_type == 'Update'" class="form-control" type="text"
                                                id="brand" required placeholder="Enter medicine's brand"
                                                formControlName="brand">
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="mb-2">
                                            <label for="stock" class="form-label">Stock <span
                                                    style="color: red;">*</span></label>
                                            <input class="form-control" type="number" id="stock"
                                                placeholder="Enter stock value" formControlName="stock">
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="mb-2">
                                            <label for="type" class="form-label">Type <span
                                                    style="color: red;">*</span></label>
                                            <select [disabled]="op_type == 'Update'" class="form-control" id="type"
                                                formControlName="type">
                                                <option value="">-- Select --</option>
                                                <option value="Tablet">Tablet</option>
                                                <option value="Capsule">Capsule</option>
                                                <option value="Syrup">Syrup</option>
                                                <option value="Injection">Injection</option>
                                                <option value="Ointment">Ointment</option>
                                                <option value="Packet/Bottles/Box">Packet/Bottles/Box</option>
                                                <option value="Inhaler/Nebulizer">Inhaler/Nebulizer</option>
                                                <option value="Dressing">Dressing</option>
                                                <option value="Drops">Drops</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="mb-2">
                                            <label for="batch_number" class="form-label">Batch Number <span
                                                    style="color: red;">*</span></label>
                                            <input class="form-control" type="text" id="batch_number"
                                                placeholder="Enter batch number" formControlName="batch_number">
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="mb-2">
                                            <label for="expirey" class="form-label">Expiry Date <span
                                                    style="color: red;">*</span></label>
                                            <input class="form-control" type="date" min="{{date_picker_min}}"
                                                id="expirey" formControlName="expirey">
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="mb-2">
                                            <label for="manufacturing" class="form-label">Manufacturing Date <span
                                                    style="color: red;">*</span></label>
                                            <input max="{{date_picker_min}}" class="form-control" type="date"
                                                id="manufacturing" formControlName="manufacturing">
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="mb-2">
                                            <label for="mrp" class="form-label">MRP <span
                                                    style="color: red;">*</span></label>
                                            <input class="form-control" type="number" step="0.1" id="mrp"
                                                formControlName="mrp">
                                        </div>
                                    </div>
                                </div>

                                <div class="mb-2 text-left">
                                    <button class="btn btn-primary" type="submit"
                                        [disabled]="!pharmacyFormGroup.valid">Save
                                    </button> &nbsp;
                                    <button class="btn btn-warning" type="button" (click)="unSetMedicineContext()">Clear
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-xl-12 col-md-12">
            <div class="card">
                <div class="card-body">
                    <div class="row table-responsive">
                        <table class="table table-striped table-hover table-bordered">
                            <thead>
                                <tr>
                                    <th>Sr. No.</th>
                                    <th>Name</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style="text-align: center;" *ngIf="!medicine_list.length">
                                    <td colspan="100">No Records</td>
                                </tr>
                                <tr *ngFor="let medicine of medicine_list; let i = index">
                                    <td class="col-1">{{i + 1}}</td>
                                    <td>{{medicine.batches[0].brand.toUpperCase()}} {{medicine.batches[0].type}}
                                        {{medicine._id.toUpperCase()}} -
                                        {{medicine.batches[0].potency}}</td>
                                    <td>
                                        <table class="table table-sm mb-0">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Batch</th>
                                                    <th scope="col">Expiry Date</th>
                                                    <th scope="col" class="col-1">Stock</th>
                                                    <td scope="col" class="col-1"></td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let b of medicine.batches;">
                                                    <th scope="row">{{b.batch_number.toUpperCase()}}</th>
                                                    <td>{{b.expirey | date: 'mediumDate'}}</td>
                                                    <td>{{b.stock}}</td>
                                                    <td>
                                                        <button class="btn btn-xs btn-danger" type="button"
                                                            (click)="selectMedicine(b)">Select</button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div><!-- end col -->
    </div>
    <!-- end row -->

</div> <!-- container -->
<!-- Dashboard init-->
