<!-- Start Content-->
<div class="container-fluid">

    <!-- start page title -->
    <div class="row">
        <div class="col-12">
            <div class="page-title-box page-title-box-alt">
                <h4 class="page-title">Location's User Management</h4>
                <div class="page-title-right">
                    <ol class="breadcrumb m-0">

                        <li class="breadcrumb-item"><a href="javascript: void(0);">Location</a></li>
                        <li class="breadcrumb-item active">User Management</li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
    <!-- end page title -->

    <div class="row">
        <div class="col-xl-12 col-md-12">
            <div class="card">
                <div class="card-header">
                    Manage Users
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-xs-12">
                            <form [formGroup]="userFormGroup" class="" (ngSubmit)="save()" autocomplete="off">
                                <div class="alert alert-danger" *ngIf="error_message">{{error_message}}</div>
                                <div class="alert alert-success" *ngIf="success_message">{{success_message}}</div>

                                <div class="row">
                                    <div class="col-md-3">
                                        <div class="mb-2">
                                            <label for="name" class="form-label">Name <span
                                                    style="color: red;">*</span></label>
                                            <input class="form-control" type="text" id="name" required
                                                placeholder="Enter user's full name" formControlName="name">
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="mb-2">
                                            <label for="email" class="form-label">Email</label>
                                            <input class="form-control" [readOnly]="op_type == 'Update'" type="email"
                                                id="email" placeholder="Enter user's email" formControlName="email">
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="mb-2">
                                            <label for="blood_group" class="form-label">Blood Group </label>
                                            <select class="form-control" type="blood_group" id="blood_group"
                                                formControlName="blood_group">
                                                <option value="">-- Choose One --</option>
                                                <option value="A RhD positive (A +)">A RhD positive (A +)</option>
                                                <option value="A RhD negative(A -)">A RhD negative(A -)</option>
                                                <option value="B RhD positive(B +)">B RhD positive(B +)</option>
                                                <option value="B RhD negative(B -)">B RhD negative(B -)</option>
                                                <option value="O RhD positive(O +)">O RhD positive(O +)</option>
                                                <option value="O RhD negative(O -)">O RhD negative(O -)</option>
                                                <option value="AB RhD positive(AB +)">AB RhD positive(AB +)</option>
                                                <option value="AB RhD negative(AB -)">AB RhD negative(AB -)</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="mb-2">
                                            <label for="resource_type" class="form-label">User Type <span
                                                    style="color: red;">*</span></label>
                                            <select class="form-control" [disabled]="op_type == 'Update'"
                                                type="resource_type" id="resource_type" formControlName="resource_type">
                                                <option value="">-- Choose One --</option>
                                                <option value="Contract">Contract</option>
                                                <option value="Permanent">Permanent</option>
                                                <option value="Student/Intern">Student/Intern</option>
                                                <option value="Apprentice">Apprentice</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="mb-2">
                                            <label for="organization_identifier" class="form-label">Official ID
                                                <span style="color: red;">*</span></label>
                                            <input class="form-control" [readOnly]="op_type == 'Update'" type="text"
                                                id="organization_identifier"
                                                placeholder="Enter user's organization's identifier"
                                                formControlName="organization_identifier">
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="mb-2">
                                            <label for="phone" class="form-label">Phone <span
                                                    style="color: red;">*</span></label>
                                            <input class="form-control" [readOnly]="op_type == 'Update'" type="text"
                                                id="phone" placeholder="Enter user's phone" formControlName="phone">
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="mb-2">
                                            <label for="password" class="form-label">Password <span
                                                    style="color: red;">*</span></label>
                                            <div class="input-group input-group-merge">
                                                <input type="password" id="password" required class="form-control"
                                                    placeholder="Enter your password" formControlName="password">
                                                <div class="input-group-text" data-password="false">
                                                    <span class="password-eye"></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="mb-2">
                                            <label for="dob" class="form-label">DoB <span
                                                    style="color: red;">*</span></label>
                                            <input [readOnly]="op_type == 'Update'" class="form-control" type="date"
                                                id="dob" required placeholder="" formControlName="dob">
                                        </div>
                                    </div>
                                </div>


                                <div class="row">
                                    <div class="col-md-3">
                                      <div class="mb-2">
                                        <label for="shift" class="form-label">Shift</label>
                                        <select class="form-control select" id="shift" required
                                                [(ngModel)]="shift"
                                                [ngModelOptions]="{standalone: true}">
                                          <option value="">-- Choose One --</option>
                                          <option *ngFor="let r of roaster_list;" value="{{r._id}}">{{r.name}}</option>
                                        </select>
                                      </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="mb-2">
                                            <label for="user_type" class="form-label">User Role <span
                                                    style="color: red;">*</span></label>
                                            <select class="form-control select" id="user_type" multiple required
                                                formControlName="user_type">
                                                <option value="Patient">Patient</option>
                                                <option value="Client Admin">Client Admin</option>
                                                <option *ngIf="is_Ops" value="Doctor">Doctor</option>
                                                <option *ngIf="is_Ops" value="Paramedic">Paramedic</option>
                                                <option *ngIf="is_Ops" value="Pharmacy">Pharmacy</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="mb-2">
                                            <label for="gender" class="form-label">Gender <span
                                                    style="color: red;">*</span></label>
                                            <select class="form-control" id="gender" required formControlName="gender">
                                                <option value="">-- Select --</option>
                                                <option value="Male">Male</option>
                                                <option value="Female">Female</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="mb-2">
                                            <label for="department" class="form-label">Department <span
                                                    style="color: red;">*</span></label>
                                            <select formControlName="department" id="department" class="form-control">
                                                <option value="">-- Select One --</option>
                                                <option value="{{d._id}}" *ngFor="let d of department_list;">{{d.name}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="mb-2">
                                            <label for="doj" class="form-label">Date of Joining
                                                </label>
                                            <input type="date" formControlName="doj" id="doj" class="form-control" />
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="mb-2">
                                            <label for="dop" class="form-label">Date of Partition</label>
                                            <input type="date" formControlName="dop" id="dop" class="form-control" />
                                        </div>
                                    </div>
                                </div>

                                <div formGroupName="doctor_details" [hidden]="!checkRoleExists('Doctor')">
                                    <div class="row">
                                        <div class="col-md-3">
                                            <div class="mb-2" id="formGridCheckboxVaccine">
                                                <label for="specialization" class="form-label">Specialization <span
                                                        style="color: red;">*</span></label>
                                                <select class="form-control" id="specialization" formControlName="specialization">
                                                    <option selected value="">-- Select One --</option>
                                                    <option value="Internal Medicine">Internal Medicine</option>
                                                    <option value="Family / General Medicine">Family / General Medicine
                                                    </option>
                                                    <option value="Cardiology">Cardiology</option>
                                                    <option value="Nuerology">Nuerology</option>
                                                    <option value="Nephrology">Nephrology</option>
                                                    <option value="Orthopedics">Orthopedics</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <div class="mb-2" id="formGridCheckboxHealthCheck">
                                                <label for="registeration_number" class="form-label">Registration Number <span
                                                        style="color: red;">*</span></label>
                                                <input class="form-control" type="text" id="registeration_number"
                                                    placeholder="Enter doctor registeration number"
                                                    formControlName="registeration_number">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <hr />
<!--                                <div class="form-group" formGroupName="address">-->
<!--                                    <div class="row">-->
<!--                                        <div class="col-md-6">-->
<!--                                            <div class="mb-2">-->
<!--                                                <label class="form-label" for="line1">Line 1</label>-->
<!--                                                <input class="form-control" type="text" id="line1"-->
<!--                                                    placeholder="Enter Line 1" formControlName="line1">-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                        <div class="col-md-6">-->
<!--                                            <div class="mb-2">-->
<!--                                                <label class="form-label" for="line2">Line 2</label>-->
<!--                                                <input class="form-control" type="text" id="line2"-->
<!--                                                    placeholder="Enter Line 2" formControlName="line2">-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                        <div class="col-md-3">-->
<!--                                            <div class="mb-2">-->
<!--                                                <label class="form-label" for="locality">Locality</label>-->
<!--                                                <input class="form-control" required type="text" id="locality"-->
<!--                                                    placeholder="Enter Locality" formControlName="landmark">-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                        <div class="col-md-3">-->
<!--                                            <div class="mb-2">-->
<!--                                                <label class="form-label" for="state">State</label>-->
<!--                                                <select class="form-control" id="state" (change)="getCities($event)"-->
<!--                                                    formControlName="state">-->
<!--                                                    <option selected value="">&#45;&#45; Select &#45;&#45;</option>-->
<!--                                                    <option value="{{s}}" *ngFor="let s of state_list">{{s}}-->
<!--                                                    </option>-->
<!--                                                </select>-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                        <div class="col-md-3">-->
<!--                                            <div class="mb-2">-->
<!--                                                <label class="form-label" for="city">City</label>-->
<!--                                                <select class="form-control" id="city" formControlName="city">-->
<!--                                                    <option selected value="">&#45;&#45; Select &#45;&#45;</option>-->
<!--                                                    <option value="{{s}}" *ngFor="let s of city_list">{{s}}-->
<!--                                                    </option>-->
<!--                                                </select>-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                        <div class="col-md-3">-->
<!--                                            <div class="mb-2">-->
<!--                                                <label class="form-label" for="pincode">Pincode</label>-->
<!--                                                <input class="form-control" type="text" id="pincode"-->
<!--                                                    placeholder="Enter Pincode" formControlName="pincode">-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                    </div>-->

<!--                                </div>-->

                                <div class="mb-2">
                                    <div class="form-check">
                                        <input formControlName="is_active" type="checkbox" id="formGridCheckboxVaccineIsActive"
                                            class="form-check-input"><label title="" for="formGridCheckboxVaccineIsActive"
                                            class="form-check-label">Active?</label>
                                    </div>
                                </div>

                                <div style="margin-top: 1%;" class="col-md-12">
                                    <button class="btn btn-primary" [disabled]="!userFormGroup.valid" type="submit">Save
                                    </button> &nbsp;
                                    <button class="btn btn-warning" type="button" (click)="unSetUserIdContext()">
                                        Reset
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-xl-12 col-md-12">
            <div class="card">
                <div class="card-header">
                    User List
                </div>
                <div class="card-body">
                    <div class="row table-responsive">
                        <table id="demo-foo-pagination"
                            class="table table-responsive table-striped table-hover table-bordered">
                            <thead>
                                <tr>
                                    <th>Sr. No.</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Roles</th>
                                    <th>User Type</th>
                                    <th>DoJ</th>
                                    <th>Active</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style="text-align: center;" *ngIf="!user_list.length">
                                    <td colspan="100">No Records</td>
                                </tr>
                                <tr *ngFor="let user of user_list; let i = index">
                                    <td class="col-1">{{i + 1}}</td>
                                    <td>{{user.name}}</td>
                                    <td>{{user.email}}</td>
                                    <td>
                                        <span *ngFor="let type of user.user_type;" class="badge bg-primary rounded-pill"
                                            style="margin-right: 2%;">{{type}}</span>
                                    </td>
                                    <td>{{user.resource_type}}</td>
                                    <td>{{user.doj | date: "dd/MM/yyyy hh:mm:ss"}}</td>
                                    <td class="col-1">
                                        <span class="badge bg-success rounded-pill" *ngIf="user.is_active">Yes</span>
                                        <span class="badge bg-danger rounded-pill" *ngIf="!user.is_active">No</span>
                                    </td>
                                    <td class="col-1">
                                        <a href="#" class="btn btn-xs btn-danger" (click)="updateModal(user)">Update</a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div><!-- end col -->
    </div>
    <!-- end row -->

</div> <!-- container -->
<!-- Dashboard init-->
