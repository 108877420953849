<div class="auth-fluid" style="background: url('assets/images/main_bg.gif');">
    <!-- Auth fluid right content -->
    <div class="auth-fluid-right" style="background-color: rgba(0,0,0,0);">
        <div class="auth-user-testimonial">
            <h3 class="mb-3 text-black">{{title}}</h3>
<!--            <p class="lead fw-normal"><i class="mdi mdi-format-quote-open"></i> A one stop solution to Health-->
<!--                Management that extends beyond traditional wellness programs cultivates healthy-->
<!--                habits among employees while maximizing human resource investments, increasing productivity, and-->
<!--                achieving-->
<!--                organizational health goals to utilize maximum return on investment.-->
<!--                <i class="mdi mdi-format-quote-close"></i>-->
<!--            </p>-->
            <!-- <h5 class="text-white">
                - Admin User
            </h5> -->
        </div> <!-- end auth-user-testimonial-->
    </div>
    <!-- end Auth fluid right content -->

    <!--Auth fluid left content -->
    <div class="auth-fluid-form-box">
        <div class="align-items-center d-flex h-100">
            <div class="card-body">

                <!-- Logo -->
                <div class="auth-brand text-center text-lg-start">
                    <div class="auth-logo">
                        <a href="/" class="logo logo-dark text-center">
                            <span class="logo-lg">
                                <img *ngIf="is_parent_site" src="assets/images/avyukt-healthcare-logo11.png" alt=""
                                    height="50">
                                <img *ngIf="!is_parent_site" src="{{logo_url}}" alt="" height="50">
                            </span>
                        </a>

                        <a href="/" class="logo logo-light text-center">
                            <span class="logo-lg">
                                <img *ngIf="is_parent_site" src="assets/images/avyukt-healthcare-logo11.png" alt=""
                                    height="22">
                                <img *ngIf="!is_parent_site" src="{{logo_url}}" alt="" height="22">
                            </span>
                        </a>
                    </div>
                </div>

                <!-- title-->
                <h4 *ngIf="!is_otp_mode && !is_registeration_mode" class="mt-0">Sign In</h4>
                <p *ngIf="!is_otp_mode && !is_registeration_mode" class="text-muted mb-4">Enter your phone number and
                    password for access</p>

                <!-- form -->
                <div *ngIf="is_otp_mode">
                    <h4 class="mt-0">Forgot Password</h4>
                    <p class="text-muted mb-4">Email and OTP verification for password reset</p>
                    <div class="alert alert-info" *ngIf="success_message">{{success_message}}</div>
                    <div class="alert alert-warning" *ngIf="error_message">{{error_message}}</div>
                    <div class="mb-2">
                        <label for="emailaddress" class="form-label">Registered Phone Number</label>
                        <input [readOnly]="is_otp_mode_entended" class="form-control" type="email" id="emailaddress"
                            required="" placeholder="Your registered phone number here ..." [(ngModel)]="email">
                    </div>
                    <div *ngIf="is_otp_mode_entended" class="mb-2">
                        <label for="otp" class="form-label">One Time Password</label>
                        <input class="form-control" type="text" id="otp" required=""
                            placeholder="Your generated one time password ..." [(ngModel)]="otp">
                    </div>
                    <div *ngIf="is_otp_mode_entended" class="mb-2">
                        <label for="login_password" class="form-label">Password</label>
                        <input class="form-control" type="password" id="login_password" required="" placeholder="*******"
                            [(ngModel)]="password">
                    </div>
                    <div class="d-grid text-center">
                        <button class="btn btn-primary" type="button" (click)="processOTP()" [disabled]="!email">
                            <span *ngIf="is_otp_mode && !is_otp_mode_entended">Send OTP</span>
                            <span *ngIf="is_otp_mode_entended">Update Password</span>
                        </button>
                    </div>
                </div>


                <form *ngIf="!is_otp_mode && !is_registeration_mode" [formGroup]="loginFormGroup" (ngSubmit)="login()">
                    <div class="alert alert-danger" *ngIf="error_message">{{error_message}}</div>
                    <div class="mb-2">
                        <label for="phone_login" class="form-label">Phone Number</label>
                        <input class="form-control" type="email" id="phone_login" required=""
                            placeholder="Enter your phone number" formControlName="email">
                    </div>
                    <div class="mb-2">
                        <label for="password" class="form-label">{{type}}</label>
                        <div class="input-group input-group-merge">
                            <input type="password" id="password" required="" class="form-control"
                                placeholder="Enter your {{type}}" formControlName="password">
                            <div class="input-group-text" data-password="false">
                                <span class="password-eye"></span>
                            </div>
                        </div>
                        <a (click)="start_pwd_recover()" href="#" class="text-muted float-end"><small>Forgot your
                                password?</small></a>
                        <a (click)="flip_login_type(type)" href="#" class="text-muted float-start"><small>Login with
                                {{reverse_type}}</small></a>
                    </div>
                    <hr />
                    <div class="row mb-2">
                        <div class="d-grid text-center">
                            <button class="btn btn-primary" type="submit" [disabled]="!loginFormGroup.valid">Log In
                            </button>
                        </div>
                    </div>
                    <div class="d-grid text-center mb-2">
                        <button class="btn btn-secondary" (click)="activateRegisterMode()" type="button">Register Now
                        </button>
                    </div>
                </form>
                <!-- end form-->

                <div *ngIf="is_registeration_mode">
                    <div class="alert alert-danger" *ngIf="error_message">{{error_message}}</div>
                    <div class="alert alert-success" *ngIf="success_message">{{success_message}}</div>
                    <h4 class="mt-0">Employee Registration</h4>
                    <p class="text-muted mb-4">Register with your corporate account details</p>
                    <form [formGroup]="registerationFormGroup" (ngSubmit)="register()">
                        <div class="row">
                            <div class="col-md-6 mb-2">
                                <label>Name</label>
                                <input class="form-control" type="text" placeholder="Enter your name here"
                                    formControlName="name" />
                            </div>
                            <div class="col-md-6 mb-2">
                                <label>DoB</label>
                                <input class="form-control" type="date" placeholder="Enter your dob here"
                                    formControlName="dob" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 mb-2">
                                <label>Official Email</label>
                                <div class="input-group mb-2">
                                    <input class="form-control" type="email" placeholder="Enter your company email here"
                                        formControlName="email" />
                                    <button class="btn btn-success" (click)="verifyDomain()"
                                        type="button">Verify</button>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 mb-2">
                                <label>Gender</label>
                                <select class="form-control" formControlName="gender">
                                    <option value="">-- Choose One --</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                </select>
                            </div>
                            <div class="col-md-6 mb-2">
                                <label>Phone</label>
                                <div class="input-group mb-2">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">+91</span>
                                    </div>
                                    <input type="text" class="form-control" type="text" id="phone"
                                        placeholder="Enter your phone number" formControlName="phone"
                                        aria-label="User's phone" aria-describedby="basic-addon2">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 mb-2">
                                <label>User Type</label>
                                <select class="form-control" formControlName="resource_type">
                                    <option value="">-- Choose One --</option>
                                    <option value="Contract">Contract</option>
                                    <option value="Permanent">Permanent</option>
                                    <option value="Student/Intern">Student/Intern</option>
                                </select>
                            </div>
                            <div class="col-md-6 mb-2">
                                <label>Official ID</label>
                                <input class="form-control" type="text"
                                    placeholder="Enter your unique organization identifier"
                                    formControlName="organization_identifier" />
                            </div>
                        </div>
                        <div class="row" *ngIf="is_domain_verified">
                            <div class="col-md-6 mb-2">
                                <label>Department</label>
                                <select class="form-control" formControlName="department">
                                    <option selected value="">-- Choose One --</option>
                                    <option value="{{d._id}}" *ngFor="let d of department_list;">{{d.name}}</option>
                                </select>
                            </div>
                            <div class="col-md-6 mb-2">
                                <label>Location</label>
                                <select class="form-control" [ngModelOptions]="{standalone: true}"
                                    [(ngModel)]="location_id">
                                    <option selected value="">-- Choose One --</option>
                                    <option value="{{l._id}}" *ngFor="let l of location_list;">{{l.alias}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 mb-2">
                                <label>Blood Group</label>
                                <select class="form-control" formControlName="blood_group">
                                    <option value="">-- Choose One --</option>
                                    <option value="A RhD positive (A +)">A RhD positive (A +)</option>
                                    <option value="A RhD negative(A -)">A RhD negative(A -)</option>
                                    <option value="B RhD positive(B +)">B RhD positive(B +)</option>
                                    <option value="B RhD negative(B -)">B RhD negative(B -)</option>
                                    <option value="O RhD positive(O +)">O RhD positive(O +)</option>
                                    <option value="O RhD negative(O -)">O RhD negative(O -)</option>
                                    <option value="AB RhD positive(AB +)">AB RhD positive(AB +)</option>
                                    <option value="AB RhD negative(AB -)">AB RhD negative(AB -)</option>
                                </select>
                            </div>
                        </div>

                        <button class="btn btn-primary" type="submit">Submit
                        </button>
                    </form>
                </div>

            </div> <!-- end .card-body -->
        </div> <!-- end .align-items-center.d-flex.h-100-->
    </div>
    <!-- end auth-fluid-form-box-->
</div>
<!-- end auth-fluid-->
