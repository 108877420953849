import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseObject } from 'src/app/shared/model/response.model';
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class DepartmentService {

    constructor(private http: HttpClient) { }

    get(company_id: string) {
        return this.http.get<ResponseObject>(environment.apiEndpoint + "/department/" + company_id);
    }

    create(data: any) {
        return this.http.post<ResponseObject>(environment.apiEndpoint + "/department", data);
    }

    update(id: string, data: any) {
        return this.http.put<ResponseObject>(environment.apiEndpoint + "/department/" + id, data);
    }
}