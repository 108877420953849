<!-- Start Content-->
<div class="container-fluid">

    <!-- start page title -->
    <div class="row">
        <div class="col-12">
            <div class="page-title-box page-title-box-alt">
                <h4 class="page-title">Laundry Register</h4>
                <div class="page-title-right">
                    <ol class="breadcrumb m-0">

                        <li class="breadcrumb-item"><a href="javascript: void(0);">Clinic</a></li>
                        <li class="breadcrumb-item active">Laundry Register</li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
    <!-- end page title -->

    <div class="row">
        <div class="col-xl-12 col-md-12">
            <div class="card">
                <div class="card-header">
                    <h5>Laundry Register</h5>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-xs-12">
                            <form [formGroup]="laundryRegisterFormGroup" class="" (ngSubmit)="save()">
                                <div class="alert alert-danger" *ngIf="error_message">{{error_message}}</div>
                                <div class="alert alert-success" *ngIf="success_message">{{success_message}}</div>

                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="mb-2">
                                            <label for="item" class="form-label">Item Name <span
                                                    style="color: red;">*</span></label>
                                            <input class="form-control" type="text" id="item"
                                                placeholder="Enter item name" formControlName="item">
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="mb-2">
                                            <label for="quantity_sent" class="form-label">Quantity Sent <span
                                                    style="color: red;">*</span></label>
                                            <input class="form-control" type="number" id="quantity_sent"
                                                placeholder="Enter quantity sent" formControlName="quantity_sent">
                                        </div>
                                    </div>
                                </div>

                                <div class="mb-2 text-left">
                                    <button class="btn btn-primary" type="submit"
                                        [disabled]="!laundryRegisterFormGroup.valid">Save
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-xl-12 col-md-12">
            <div class="card">
                <div class="card-body">
                    <div class="row table-responsive">
                        <table class="table table-striped table-hover table-bordered">
                            <thead>
                                <tr>
                                    <th>Sr. No.</th>
                                    <th>Item name</th>
                                    <th>Quantity Sent</th>
                                    <th>Sent By</th>
                                    <th>Date Sent</th>
                                    <th>Quantity Recieved</th>
                                    <th>Recieved By</th>
                                    <th>Date Recieved</th>
                                    <th> Mark Received</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style="text-align: center;" *ngIf="!laundry_list.length">
                                    <td colspan="100">No Content</td>
                                </tr>
                                <tr *ngFor="let s of laundry_list; let i = index">
                                    <td class="col-1">{{i + 1}}</td>
                                    <td>{{s.item}}</td>
                                    <td>{{s.quantity_sent}}</td>
                                    <td>{{s.sent_by.name}}</td>
                                    <td>{{s.date_sent | date: 'short'}}</td>
                                    <td>{{s.quantity_received}}</td>
                                    <td>{{s.received_by?.name}}</td>
                                    <td>{{s.date_received | date: 'short'}}</td>
                                    <td>
                                        <div class="input-group input-group-sm mb-2"
                                            *ngIf="s.quantity_received != s.quantity_sent">
                                            <input type="number" class="form-control form-control-sm" step="1" id="name"
                                                placeholder="Quantity Received" [(ngModel)]="s.received_quantity">
                                            <div class="input-group-append">
                                                <button class="btn btn-sm btn-success"
                                                    (click)="markRecieved(s._id, s.received_quantity)"
                                                    type="button">Recieved</button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div><!-- end col -->
    </div>
    <!-- end row -->

</div> <!-- container -->
<!-- Dashboard init-->