<!-- Start Content-->
<div class="container-fluid">

    <!-- start page title -->
    <div class="row">
        <div class="col-12">
            <div class="page-title-box page-title-box-alt">
                <h4 class="page-title">Register Walk In</h4>
                <div class="page-title-right">
                    <ol class="breadcrumb m-0">

                        <li class="breadcrumb-item"><a href="javascript: void(0);">Clinic</a></li>
                        <li class="breadcrumb-item active">Walk-Ins</li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
    <!-- end page title -->

    <div class="row">
        <div class="col-xl-12 col-md-12">
            <div class="card">
                <div class="card-header">
                    <h5>Register new walk-in</h5>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-xs-12">
                            <form [formGroup]="walkInFormGroup" class="" (ngSubmit)="save()">
                                <div class="alert alert-danger" *ngIf="error_message">{{error_message}}</div>
                                <div class="alert alert-success" *ngIf="success_message">{{success_message}}</div>

                                <h5>Demographics</h5>
                                <div class="row">
                                    <div class="col-md-3">
                                        <label for="phone" class="form-label">Phone <span
                                                style="color: red;">*</span></label>
                                        <div class="input-group mb-2">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">+91</span>
                                            </div>
                                            <input type="text" class="form-control" [readOnly]="op_type == 'Update'"
                                                type="text" id="phone" placeholder="Enter user's phone"
                                                formControlName="phone" aria-label="User's phone"
                                                aria-describedby="basic-addon2">
                                            <input type="text" class="form-control" type="hidden" id="phone"
                                                placeholder="Enter user's phone" formControlName="patient_id"
                                                aria-label="User's phone" aria-describedby="basic-addon2">
                                            <div class="input-group-append">
                                                <button class="btn btn-success"
                                                    (click)="searchUser(walkInFormGroup.controls.phone.value || '0')"
                                                    type="button">Search</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="mb-2">
                                            <label for="name" class="form-label">Name <span
                                                    style="color: red;">*</span></label>
                                            <input readonly class="form-control" type="text" id="name" required
                                                placeholder="Enter user's full name" formControlName="name">
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="mb-2">
                                            <label for="email" class="form-label">Email <span
                                                    style="color: red;">*</span></label>
                                            <input readonly class="form-control" type="email" id="email"
                                                placeholder="Enter user's email" formControlName="email">
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="mb-2">
                                            <label for="gender" class="form-label">Gender <span
                                                    style="color: red;">*</span></label>
                                            <select readonly class="form-control" id="gender" required
                                                formControlName="gender">
                                                <option value="">-- Select --</option>
                                                <option value="Male">Male</option>
                                                <option value="Female">Female</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <h5>Vitals</h5>

                                <div class="row">
                                    <div class="col-md-2">
                                        <div class="mb-2">
                                            <label for="age" class="form-label">Age <span
                                                    style="color: red;">*</span></label>
                                            <input readonly class="form-control" type="text" id="age"
                                                placeholder="Enter respiratory Rate" formControlName="age">
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <label for="bp" class="form-label">Blood Pressure </label>
                                        <div class="mb-2">
                                            <input class="form-control" type="text" id="bp" placeholder="Blood Pressure"
                                                formControlName="bp">
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="mb-2">
                                            <label for="spo2" class="form-label">SpO2 </label>
                                            <div class="input-group">
                                                <input class="form-control" type="text" id="spo2"
                                                    placeholder="Enter SpO2" formControlName="spo2">
                                                <div class="input-group-append">
                                                    <span class="input-group-text">%</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="mb-2">
                                            <label for="temperature" class="form-label">Temperature</label>
                                            <div class="input-group">
                                                <input class="form-control" type="text" id="temperature"
                                                    placeholder="Enter temperature" formControlName="temperature">
                                                <div class="input-group-append">
                                                    <span class="input-group-text">&deg;C</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="mb-2">
                                            <label for="hr" class="form-label">Heart Rate </label>
                                            <div class="input-group">
                                                <input class="form-control" type="text" id="hr"
                                                    placeholder="Enter Heart Rate" formControlName="hr">
                                                <div class="input-group-append">
                                                    <span class="input-group-text">per min</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="mb-2">
                                            <label for="rr" class="form-label">Respiratory Rate </label>
                                            <div class="input-group">
                                                <input class="form-control" type="text" id="rr"
                                                    placeholder="Enter respiratory Rate" formControlName="rr">
                                                <div class="input-group-append">
                                                    <span class="input-group-text">per min</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <h5>Details</h5>

                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="mb-2">
                                            <label for="description" class="form-label">Description <span
                                                    style="color: red;">*</span></label>
                                            <textarea class="form-control" type="text" id="description"
                                                placeholder="Enter complaints, remarks etc.."
                                                formControlName="description">
                                                    </textarea>
                                        </div>
                                    </div>
                                </div>

                                <div class="mb-2 text-left">
                                    <button class="btn btn-primary" type="submit"
                                        [disabled]="!walkInFormGroup.valid">Save
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-xl-12 col-md-12">
            <div class="card">
                <div class="card-body">
                    <div class="row table-responsive">
                        <table class="table table-striped table-hover table-bordered">
                            <thead>
                                <tr>
                                    <th>Sr. No.</th>
                                    <th>Name</th>
                                    <th>Sex / Age</th>
                                    <th>UID</th>
                                    <th>Doctor</th>
                                    <th>̦Paramedic</th>
                                    <th>In Time</th>
                                    <th>Out Time</th>
                                    <th>Pharmacy</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style="text-align: center;" *ngIf="!walkin_list.length">
                                    <td colspan="100">No Records</td>
                                </tr>
                                <tr *ngFor="let walkin of walkin_list; let i = index">
                                    <td class="col-1">{{i + 1}}</td>
                                    <td>{{walkin.patient_id.name}}</td>
                                    <td>{{walkin.patient_id.gender}} / {{walkin.age}}</td>
                                    <td>
                                        {{walkin.patient_id.phone}}
                                    </td>
                                    <td>
                                        {{walkin.doctor_id?.name}}
                                    </td>
                                    <td>
                                        {{walkin.paramedic_id.name}}
                                    </td>
                                    <td>
                                        {{walkin.in | date: 'short'}}
                                    </td>
                                    <td>
                                        {{walkin.out | date: 'short'}}
                                    </td>
                                    <td class="col-1">
                                        <span class="badge bg-success rounded-pill"
                                            *ngIf="walkin.is_dispensed">Dispensed</span>
                                        <span class="badge bg-warning rounded-pill" *ngIf="!walkin.is_dispensed">Not
                                            Dispensed</span>
                                    </td>
                                    <td class="col-1">
                                        <div class="dropdown">
                                            <button class="btn btn-danger btn-sm dropdown-toggle" type="button"
                                                data-bs-toggle="dropdown" aria-expanded="false">
                                                Actions &nbsp; <i class="ri-arrow-down-fill"></i>
                                            </button>
                                            <ul class="dropdown-menu">

                                                <li><a class="dropdown-item"
                                                        (click)="viewPrescription(walkin.prescription_id)">View
                                                        Prescription</a></li>
                                                <li><a class="dropdown-item" (click)="markOut(walkin._id)">Complete
                                                        Visit</a></li>
                                            </ul>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div><!-- end col -->
    </div>
    <!-- end row -->

</div> <!-- container -->
<!-- Dashboard init-->