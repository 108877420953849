import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ClientUserManagementService } from './client-user-management.service';
import { ResponseObject } from 'src/app/shared/model/response.model';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/admin/user/user.service';
import { AuthService } from 'src/app/shared/auth.service';

@Component({
  selector: 'app-user',
  templateUrl: './client-user-management.component.html',
  styleUrls: ['./client-user-management.component.css']
})
export class ClientUserManegementComponent {

  public error_message: string = "";
  public success_message: string = "";

  public walkin_list: any = [];
  public op_type: string = "Create";
  public company_id: string = "";
  public user: any = {};

  public walkInFormGroup = new FormGroup({
    phone: new FormControl("", [Validators.required]),
    name: new FormControl("", [Validators.required]),
    age: new FormControl("", [Validators.required]),
    patient_id: new FormControl("", [Validators.required]),
    gender: new FormControl("", [Validators.required]),
    email: new FormControl("", [Validators.required]),
    description: new FormControl("", [Validators.required]),
    bp: new FormControl(""),
    spo2: new FormControl(""),
    temperature: new FormControl(""),
    pulse: new FormControl(""),
    glucose: new FormControl(""),
    hr: new FormControl(""),
    rr: new FormControl("")
  });

  updateModal(company: any) {
    this.op_type = "Update";
    this.company_id = company._id;
    delete company._id;
    this.walkInFormGroup.setValue(company);
  }

  unSetCompanyIdContext() {
    this.op_type = "Create";
    this.company_id = "";
    this.error_message = "";
    this.success_message = "";
    this.walkInFormGroup.reset({ gender: "" });
  }

  constructor(private clientUserManagementService: ClientUserManagementService,
    private router: Router,
    private activatedroute: ActivatedRoute,
    private userService: UserService,
    private authService: AuthService) { }

  private getWalkIns() {
    this.clientUserManagementService.getWalkInsForDay(this.activatedroute.snapshot.queryParams["l"],
    (new Date().toISOString()).split('T')[0].toString()
    )
      .subscribe((res: ResponseObject) => {
        this.error_message = "";
        if (res.statusCode === 200) {
          this.walkin_list = res.data;
        }
        else {
          this.error_message = res.message;
        }
      }, err => {
        if (err.status === 400) this.error_message = err.error.message[0];
        else this.error_message = err.error.message;
      });
  }

  ngOnInit() {
    this.getWalkIns();
  }

  private createWalkIn(data: any) {
    this.clientUserManagementService.createWalkIn({
      ...data,
      company_id: this.activatedroute.snapshot.queryParams["c"],
      location_id: this.activatedroute.snapshot.queryParams["l"],
      in: new Date().toISOString(),
      paramedic_id: JSON.parse(this.authService.getLoggedInUserDetails() || '{}')._id,
      is_dispensed: false
    })
      .subscribe((res: ResponseObject) => {
        this.error_message = "";
        if (res.statusCode === 201) {
          this.success_message = "WalkIn Created Successfuly."
          this.getWalkIns();
        }
        else {
          this.error_message = res.message;
        }
      }, err => {
        if (err.status === 400) this.error_message = err.error.message[0];
        else this.error_message = err.error.message;
      });
  }

  private updateWalkIn(id: string, data: any) {
    this.clientUserManagementService.updateWalkIn(id, data)
      .subscribe((res: ResponseObject) => {
        this.error_message = "";
        if (res.statusCode === 200) {
          this.success_message = "WalkIn Updated Successfuly."
          this.getWalkIns();
        }
        else {
          this.error_message = res.message;
        }
      }, err => {
        if (err.status === 400) this.error_message = err.error.message[0];
        else this.error_message = err.error.message;
      });
  }

  save() {
    this.error_message = "";
    this.success_message = "";

    if (!this.walkInFormGroup.valid) {
      this.error_message = "Invalid form inputs";
      return;
    }
    console.log(this.walkInFormGroup.value);
    let data = {
      ... this.walkInFormGroup.value,
      is_active: true
    }

    if (this.op_type === "Create")
      this.createWalkIn(data);
    else if (this.op_type === "Update")
      this.updateWalkIn(this.company_id, data)

  }

  searchUser(phone: string) {
    this.userService.getUsersByPhone(phone).subscribe((res: ResponseObject) => {
      this.error_message = "";
      if (res.statusCode === 200) {
        this.user = res.data[0];
        if (!this.user) this.error_message = "User Not Found!";
        else {
          this.walkInFormGroup.setValue({
            phone: this.user.phone,
            name: this.user.name,
            email: this.user.email,
            gender: this.user.gender,
            patient_id: this.user._id,
            description: "",
            bp: "",
            spo2: "",
            temperature: "",
            pulse: "",
            glucose: "",
            hr: "",
            rr: "",
            age: this.calculateAge(this.user.dob).toString()
          })
        }
      }
      else {
        this.error_message = res.message;
      }
    }, err => {
      if (err.status === 400) this.error_message = err.error.message[0];
      else this.error_message = err.error.message;
    });
  }

  private calculateAge(user_dob: string) {
    let dob = new Date(user_dob);  
    //calculate month difference from current date in time  
    var month_diff = Date.now() - dob.getTime();  
      
    //convert the calculated difference in date format  
    var age_dt = new Date(month_diff);   
      
    //extract year from date      
    var year = age_dt.getUTCFullYear();  
      
    //now calculate the age of the user  
    return Math.abs(year - 1970);  
  }

  viewPrescription(p: any) {
    this.router.navigate(['/detail-prescription'], { queryParams: { prescription: p }});
  }

  markOut(walkin_id: string) {
    this.clientUserManagementService.markOut(walkin_id)
    .subscribe((res: ResponseObject) => {
      this.error_message = "";
      if (res.statusCode === 200) {
        this.getWalkIns();
      }
      else {
        this.error_message = res.message;
      }
    }, err => {
      if (err.status === 400) this.error_message = err.error.message[0];
      else this.error_message = err.error.message;
    });
  }

}
