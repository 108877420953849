<!-- Start Content-->
<div class="container-fluid">

    <!-- start page title -->
    <div class="row">
        <div class="col-12">
            <div class="page-title-box page-title-box-alt">
                <h4 class="page-title">Clinic Appointments</h4>
                <div class="page-title-right">
                    <ol class="breadcrumb m-0">

                        <li class="breadcrumb-item"><a href="javascript: void(0);">Clinic</a></li>
                        <li class="breadcrumb-item active">Appointments</li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
    <!-- end page title -->


    <div class="row">
        <div class="col-xl-12 col-md-12">
            <div class="card">
                <div class="card-header">History</div>
                <div class="card-body">
                    <div class="row table-responsive">
                        <table class="table table-striped table-hover table-bordered">
                            <thead>
                                <tr>
                                    <th class="col-1">Sr. No.</th>
                                    <th>Doctor</th>
                                    <th>Specialization</th>
                                    <th>Slot</th>
                                    <th>Status</th>
                                    <th class="col-1"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style="text-align: center;" *ngIf="!app_list.length">
                                    <td colspan="100">No upcoming appointments</td>
                                </tr>
                                <tr *ngFor="let s of app_list; let i = index">
                                    <td class="col-1">{{i + 1}}</td>
                                    <td>Dr. {{s.booked_with.name}}</td>
                                    <td>{{s.booked_with.doctor_details.specialization}}</td>
                                    <td>{{s.slot | date: 'short'}}</td>
                                    <td>
                                        <span> {{s.status}} </span>
                                    </td>
                                    <td>
                                        <button [disabled]="s.status == 'Cancelled'" type="button"
                                            (click)="cancelAppointment(s._id)" class="btn btn-xs btn-danger">
                                            Cancel</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="btn-group mb-2 float-end">
                        <button type="button" (click)="getPrevPage()"
                            class="btn btn-secondary btn-bordered waves-effect" [disabled]="page_number == 0">
                            <i class="fas fa-arrow-left"></i> &nbsp; Prev
                        </button>
                        <button [disabled]="!(app_list.length >= page_size)" type="button" (click)="getNextPage()"
                            class="btn btn-secondary btn-bordered waves-effect">
                            Next &nbsp; <i class="fas fa-arrow-right"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div><!-- end col -->
    </div>

    <div class="row">
        <div class="col-xl-12 col-md-12">
            <div class="card">
                <div class="card-header">Book Appointment</div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-xl-3 col-sm-6" *ngFor="let d of doctor_list; let i = index;">
                            <div class="text-center card border border-secondary">
                                <div class="card-body">

                                    <div class="avatar-xl mx-auto mt-1">
                                        <div class="avatar-title bg-light rounded-circle">
                                            <i class="mdi mdi-account h1 m-0 text-body"></i>
                                        </div>
                                    </div>

                                    <h4 class="mt-3 mb-1"><a href="#" class="text-dark">Dr. {{d.name}}</a></h4>
                                    <p class="text-muted">{{d.doctor_details.specialization}}</p>


                                    <div class="mb-2">
                                        <input type="text" class="form-control" type="datetime-local" id="slot{{i}}"
                                            (change)="buttonValid(i)" aria-label="Medicine's name"
                                            aria-describedby="basic-addon2">
                                    </div>
                                    <div class="row">
                                        <button type="button" (click)="bookAppointment(d._id, i)" id="button{{i}}"
                                            [disabled]="buttonValid(i)"
                                            class="waves-effect waves-light btn btn-outline-purple">Book
                                            Now</button>
                                    </div>
                                </div>
                            </div> <!-- end card -->
                        </div> <!-- end col -->
                    </div>
                </div>
            </div>
        </div><!-- end col -->

    </div>
    <!-- end row -->

</div> <!-- container-fluid -->