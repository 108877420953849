import { Injectable } from "@angular/core";
import { Observable, tap } from "rxjs";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from "@angular/common/http";
import { AuthService } from "../auth.service";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private route: Router, private toastr: ToastrService) { }

  intercept(req: HttpRequest<any>,
    next: HttpHandler): Observable<HttpEvent<any>> {

    const idToken = localStorage.getItem("token");

    if (idToken) {
      const cloned = req.clone({
        headers: req.headers.set("Authorization",
          "Bearer " + idToken)
      });
      return next.handle(cloned).pipe(
        tap({
          next: (event) => {
            if (event instanceof HttpResponse) {
              if (Math.floor(event.status / 100) == 2 && req.method != "GET") {
                this.toastr.success(event.body.message, 'Task Executed!', {
                  progressAnimation: 'decreasing',
                  disableTimeOut: 'extendedTimeOut',
                  positionClass: 'toast-bottom-right',
                  tapToDismiss: true,
                  progressBar: true
                });
              }
              if (((event.status / 100) == 4 && event.status != 400)) {
                let authService = new AuthService();
                authService.clearStorage();
                this.route.navigate(["/"]);
              }
              if (event.status == 401 || event.status == 403) {
                let authService = new AuthService();
                authService.clearStorage();
                this.route.navigate(["/"]);
              }
            }
            return event;
          },
          error: (error) => {
            if (((error.status / 100) == 4 && (error.status % 100) != 0)) {
              let authService = new AuthService();
              authService.clearStorage();
              this.route.navigate(["/"]);
            }
            if (error.status == 401 || error.status == 403) {
              let authService = new AuthService();
              authService.clearStorage();
              this.route.navigate(["/"]);
            }
            if (error.status === 500) {
              this.toastr.error(error.error.message, 'Oh, Snap!', {
                progressAnimation: 'decreasing',
                disableTimeOut: 'extendedTimeOut',
                positionClass: 'toast-bottom-right',
                progressBar: true,
                timeOut: 10000
              });
            }

            if (error.status === 400) {
              for (let i = 0; i < error.error.message.length; i++) {
                this.toastr.error(error.error.message[i], 'Hmm. Something is missing ...', {
                  progressAnimation: 'decreasing',
                  disableTimeOut: 'extendedTimeOut',
                  positionClass: 'toast-bottom-right',
                  progressBar: true,
                  timeOut: 10000
                });
              }
            }
          }
        }));
    }
    else {
      return next.handle(req);
    }
  }
}