<!-- Start Content-->
<div class="container-fluid">

    <!-- start page title -->
    <div class="row">
        <div class="col-12">
            <div class="page-title-box page-title-box-alt">
                <h4 class="page-title">Location Management</h4>
                <div class="page-title-right">
                    <ol class="breadcrumb m-0">

                        <li class="breadcrumb-item"><a href="javascript: void(0);">Cutomer</a></li>
                        <li class="breadcrumb-item active">Location Management</li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
    <!-- end page title -->

    <div class="row">
        <div class="col-xl-12 col-md-12">
            <div class="card">
                <div class="card-header">
                    Manage Locations
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-xs-12">
                            <form [formGroup]="locationFormGroup" class="" (ngSubmit)="save()">
                                <div class="alert alert-danger" *ngIf="error_message">{{error_message}}</div>
                                <div class="alert alert-success" *ngIf="success_message">{{success_message}}</div>
                                <div class="form-group">
                                    <h5 class="card-title">Location
                                        Details</h5>
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="mb-2">
                                                <label class="form-label" for="alias">Alias <span
                                                        style="color: red;">*</span></label>
                                                <input class="form-control" type="text" id="alias" required
                                                    placeholder="Enter company's full alias" formControlName="alias">
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="mb-2">
                                                <label class="form-label" for="formCompanyID">Company <span
                                                        style="color: red;">*</span></label>
                                                <select class="form-control" [disabled]="op_type == 'Update'"
                                                    id="formCompanyID" formControlName="company_id">
                                                    <option selected value="">--Select--</option>
                                                    <option *ngFor="let company of company_list;"
                                                        value="{{company._id}}">
                                                        {{company.name}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="mb-2">
                                                <label class="form-label" for="master_ops_resource">Master Ops Resource
                                                    <span style="color: red;">*</span></label>
                                                <select class="form-control" id="master_ops_resource"
                                                    formControlName="master_ops_resource">
                                                    <option selected value="">--Select--</option>
                                                    <option *ngFor="let user of user_list;" value="{{user._id}}">
                                                        {{user.name}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <h5 class="card-title" style="margin-top: var(--bs-card-title-spacer-y);">Services</h5>

                                <div class="row">
                                    <div class="col-md-3">
                                        <label class="form-label" for="services" class="form-label">Services <span
                                                style="color: red;">*</span></label>
                                        <select class="form-control" size="6" id="services" formControlName="services"
                                            multiple>
                                            <option value="OHC">OHC</option>
                                            <option value="AHC">AHC</option>
                                            <option value="Vaccine">Vaccine</option>
                                            <option value="Ambulance">Ambulance</option>
                                            <option value="Pharmacy">Pharmacy</option>
                                            <option value="Others">Others</option>
                                        </select>
                                    </div>

                                    <div class="col-md-3">
                                        <label class="form-label" for="registers" class="form-label">Registers <span
                                                style="color: red;">*</span></label>
                                        <select class="form-control" size="6" id="registers" formControlName="registers"
                                            multiple>
                                            <option value="Ambulance-O2">Ambulance-O2</option>
                                            <option value="Sterilization">Sterilization</option>
                                            <option value="Rest">Rest</option>
                                            <option value="WalkIn">WalkIn</option>
                                            <option value="Appointments">Appointments</option>
                                            <option value="Laundry">Laundry</option>
                                            <option value="Handover">Handover</option>
                                            <option value="BMW">BMW</option>
                                            <option value="Training">Training</option>
                                            <option value="RegulatoryData">Regulatory Data</option>
                                        </select>
                                    </div>
                                </div>

                                <h5 class="card-title" style="margin-top: var(--bs-card-title-spacer-y);">Location
                                    Address</h5>

                                <div formGroupName="location">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="mb-2">
                                                <label class="form-label" for="line1">Line 1 <span
                                                        style="color: red;">*</span></label>
                                                <input class="form-control" required type="text" id="line1"
                                                    placeholder="Enter Line 1" formControlName="line1">
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="mb-2">
                                                <label class="form-label" for="line2">Line 2</label>
                                                <input class="form-control" type="text" id="line2"
                                                    placeholder="Enter Line 2" formControlName="line2">
                                            </div>
                                        </div>
                                    </div>



                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-md-3">
                                                <div class="mb-2">
                                                    <label class="form-label" for="landmark">Landmark</label>
                                                    <input class="form-control" required type="text" id="landmark"
                                                        placeholder="Enter Landmark" formControlName="landmark">
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="mb-2">
                                                    <label class="form-label" for="city">City <span
                                                            style="color: red;">*</span></label>
                                                    <input class="form-control" required type="text" id="city"
                                                        placeholder="Enter City" formControlName="city">
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="mb-2">
                                                    <label class="form-label" for="state">State <span
                                                            style="color: red;">*</span></label>
                                                    <select class="form-control" id="state" formControlName="state">
                                                        <option value="">-- Select --</option>
                                                        <option value="Andhra Pradesh">Andhra Pradesh</option>
                                                        <option value="Andaman and Nicobar Islands">Andaman and Nicobar
                                                            Islands
                                                        </option>
                                                        <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                                                        <option value="Assam">Assam</option>
                                                        <option value="Bihar">Bihar</option>
                                                        <option value="Chandigarh">Chandigarh</option>
                                                        <option value="Chhattisgarh">Chhattisgarh</option>
                                                        <option value="Dadar and Nagar Haveli">Dadar and Nagar Haveli
                                                        </option>
                                                        <option value="Daman and Diu">Daman and Diu</option>
                                                        <option value="Delhi">Delhi</option>
                                                        <option value="Lakshadweep">Lakshadweep</option>
                                                        <option value="Puducherry">Puducherry</option>
                                                        <option value="Goa">Goa</option>
                                                        <option value="Gujarat">Gujarat</option>
                                                        <option value="Haryana">Haryana</option>
                                                        <option value="Himachal Pradesh">Himachal Pradesh</option>
                                                        <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                                                        <option value="Jharkhand">Jharkhand</option>
                                                        <option value="Karnataka">Karnataka</option>
                                                        <option value="Kerala">Kerala</option>
                                                        <option value="Madhya Pradesh">Madhya Pradesh</option>
                                                        <option value="Maharashtra">Maharashtra</option>
                                                        <option value="Manipur">Manipur</option>
                                                        <option value="Meghalaya">Meghalaya</option>
                                                        <option value="Mizoram">Mizoram</option>
                                                        <option value="Nagaland">Nagaland</option>
                                                        <option value="Odisha">Odisha</option>
                                                        <option value="Punjab">Punjab</option>
                                                        <option value="Rajasthan">Rajasthan</option>
                                                        <option value="Sikkim">Sikkim</option>
                                                        <option value="Tamil Nadu">Tamil Nadu</option>
                                                        <option value="Telangana">Telangana</option>
                                                        <option value="Tripura">Tripura</option>
                                                        <option value="Uttar Pradesh">Uttar Pradesh</option>
                                                        <option value="Uttarakhand">Uttarakhand</option>
                                                        <option value="West Bengal">West Bengal</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="mb-2">
                                                    <label class="form-label" for="pincode">Pincode <span
                                                            style="color: red;">*</span></label>
                                                    <input class="form-control" type="text" id="pincode"
                                                        placeholder="Enter Pincode" formControlName="pincode">
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div class="mb-2" id="formGridCheckboxVaccine">
                                    <div class="form-check">
                                        <input formControlName="is_active" type="checkbox" id="formGridCheckboxVaccine"
                                            class="form-check-input"><label title="" for="formGridCheckboxVaccine"
                                            class="form-check-label" value="admin">Active?</label>
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <button class="btn btn-primary" type="submit"
                                        [disabled]="!locationFormGroup.valid">Save
                                    </button> &nbsp;
                                    <button class="btn btn-warning" type="button"
                                        (click)="unSetLocationIdContext()">Reset
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-xl-12 col-md-12">
            <div class="card">
                <div class="card-header">
                    Location list
                </div>
                <div class="card-body">
                    <div class="table-responsive">
                        <div class="col-md-3">
                            <div class="mb-2">
                                <label class="form-label" for="companySelector">Company</label>
                                <select id="companySelector" class="form-control" [(ngModel)]="company_id"
                                    (ngModelChange)="onChangeCompany($event)">
                                    <option selected value="">--Select--</option>
                                    <option *ngFor="let company of company_list;" value="{{company._id}}">
                                        {{company.name}}</option>
                                </select>
                            </div>
                        </div>
                        <table class="table table-responsive table-striped table-hover table-bordered">
                            <thead>
                                <tr>
                                    <th>Sr. No.</th>
                                    <th>Alias</th>
                                    <th>Features</th>
                                    <th>Ops Resource</th>
                                    <th>Active</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style="text-align: center;" *ngIf="!location_list.length">
                                    <td colspan="100">No Records</td>
                                </tr>
                                <tr *ngFor="let location of location_list; let i = index">
                                    <td class="col-1">{{i + 1}}</td>
                                    <td>{{location.alias}}</td>
                                    <td>
                                        <span *ngFor="let service of location.services;"
                                            class="badge bg-primary rounded-pill"
                                            style="margin-right: 2%;">{{service}}</span>
                                    </td>
                                    <td>
                                        {{location.master_ops_resource?.name}}
                                    </td>
                                    <td class="col-1">
                                        <span class="badge bg-success rounded-pill"
                                            *ngIf="location.is_active">Yes</span>
                                        <span class="badge bg-danger rounded-pill" *ngIf="!location.is_active">No</span>
                                    </td>
                                    <td class="col-1">
                                        <a href="#" class="btn btn-xs btn-danger"
                                            (click)="updateModal(location)">Update</a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div><!-- end col -->
    </div>
    <!-- end row -->

</div> <!-- container -->
<!-- Dashboard init-->