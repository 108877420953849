
import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ResponseObject } from 'src/app/shared/model/response.model';
import { ActivatedRoute } from '@angular/router';
import { VaccinesService } from '../vaccines/vaccines.service';
import { LabsService } from 'src/app/ahc/labs/labs.service';

@Component({
    selector: 'app-user',
    templateUrl: './vaccine-location-map.component.html',
    styleUrls: ['./vaccine-location-map.component.css']
})
export class VaccineLocationMapComponent {

    public error_message: string = "";
    public success_message: string = "";

    public map_list: any = [];
    public lab_list: any = [];
    public vaccine_list: any = [];
    public op_type: string = "Create";
    public map_id: string = "";
    public user: any = {};

    public vaccineMapFormGroup = new FormGroup({
        company_id: new FormControl(this.activatedroute.snapshot.queryParams["c"]),
        location_id: new FormControl(this.activatedroute.snapshot.queryParams["l"]),
        vaccine_id: new FormControl("", [Validators.required]),
        lab_id: new FormControl(""),
        billing_type: new FormControl("", [Validators.required]),
        price: new FormControl(0, [Validators.required]),
        cap_limit: new FormControl(0, [Validators.required]),
        is_active: new FormControl(true)
    });

    updateModal(mapping: any) {
        this.op_type = "Update";
        this.map_id = mapping._id
        delete mapping._id;
        delete mapping.__v;
        delete mapping.createdAt;
        delete mapping.updatedAt;
        this.vaccineMapFormGroup.patchValue({
            company_id: this.activatedroute.snapshot.queryParams["c"],
            location_id: this.activatedroute.snapshot.queryParams["l"],
            vaccine_id: mapping.package_id._id,
            lab_id: mapping.lab_id._id,
            billing_type: mapping.billing_type,
            cap_limit: mapping.cap_limit,
            is_active: mapping.is_active
        });
    }

    unSetCompanyIdContext() {
        this.op_type = "Create";
        this.error_message = "";
        this.success_message = "";
        this.map_id = "";
        this.vaccineMapFormGroup.reset();
    }

    constructor(private vaccineService: VaccinesService,
        private labTestService: LabsService,
        private activatedroute: ActivatedRoute) { }

    private get() {
        this.vaccineService.getMap(this.activatedroute.snapshot.queryParams["l"])
            .subscribe((res: ResponseObject) => {
                this.error_message = "";
                if (res.statusCode === 200) {
                    this.map_list = res.data;
                }
                else {
                    this.error_message = res.message;
                }
            });
    }

    ngOnInit() {
        this.get();

        this.vaccineService.getVaccines()
            .subscribe((res: ResponseObject) => {
                this.error_message = "";
                if (res.statusCode === 200) {
                    this.vaccine_list = res.data;
                }
                else {
                    this.error_message = res.message;
                }
            });

        this.labTestService.getCenters()
            .subscribe((res: ResponseObject) => {
                this.error_message = "";
                if (res.statusCode === 200) {
                    this.lab_list = res.data;
                }
                else {
                    this.error_message = res.message;
                }
            });
    }

    private create(data: any) {
        this.vaccineService.map(data)
            .subscribe((res: ResponseObject) => {
                this.error_message = "";
                if (res.statusCode === 200) {
                    this.get();
                }
                else {
                    this.error_message = res.message;
                }
            });
    }

    public updateVaccineMap(id: string, is_active : any) {
        this.vaccineService.updateMap(id, { is_active })
          .subscribe((res: ResponseObject) => {
            this.error_message = "";
            if (res.statusCode === 200) {
              this.get();
            }
            else {
              this.error_message = res.message;
            }
          });
    }

    save() {
        this.error_message = "";
        this.success_message = "";

        if (!this.vaccineMapFormGroup.valid) {
            this.error_message = "Invalid form inputs";
            return;
        }
        let data = {
            ... this.vaccineMapFormGroup.value,
        }

        if (this.op_type === "Create")
            this.create(data);

    }
}

