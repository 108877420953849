<!-- Start Content-->
<div class="container-fluid">

    <!-- start page title -->
    <div class="row">
        <div class="col-12">
            <div class="page-title-box page-title-box-alt">
                <h4 class="page-title">Out Patient</h4>
                <div class="page-title-right">
                    <ol class="breadcrumb m-0">

                        <li class="breadcrumb-item"><a href="javascript: void(0);">Clinic</a></li>
                        <li class="breadcrumb-item active">OPD Log</li>
                    </ol>
                </div>
            </div>
        </div>
    </div>

    <!-- end page title -->
    <div class="row">
        <div class="col-xl-12 col-md-12">
            <div class="card">
                <div class="card-body">
                    <div class="row table-responsive">
                        <table class="table table-striped table-hover table-bordered">
                            <thead>
                                <tr>
                                    <th>Sr. No.</th>
                                    <th>Name</th>
                                    <th>Phone</th>
                                    <th>Email</th>
                                    <th>Employee Type</th>
                                    <th>Sex / Age</th>
                                    <th>UID</th>
                                    <th>Department</th>
                                    <th>Doctor</th>
                                    <th>̦Paramedic</th>
                                    <th>Blood Pressure</th>
                                    <th>SpO2</th>
                                    <th>Temperature</th>
                                    <th>Heart Rate</th>
                                    <th>Respiratory Rate</th>
                                    <th>Glucose</th>
                                    <th>Height</th>
                                    <th>Weight</th>
                                    <th>BMI</th>
                                    <th>Complaint Type</th>
                                    <th>Severity</th>
                                    <th>Nature of Accident</th>
                                    <th>Body Part Injured</th>
                                    <th>Machine Involved</th>
                                    <th>History</th>
                                    <th>Diagnosis</th>
                                    <th>Allergy</th>
                                    <th>Investigation</th>
                                    <th>Advice</th>
                                    <th>In Time</th>
                                    <th>Out Time</th>
                                    <!-- <th>Follow-Up Due</th> -->
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style="text-align: center;" *ngIf="!walkin_list.length">
                                    <td colspan="100">No Records</td>
                                </tr>
                                <tr *ngFor="let walkin of walkin_list; let i = index">
                                    <td class="col-1">{{i + 1}}</td>
                                    <td>{{walkin.patient_id.name}}</td>
                                    <td>{{walkin.patient_id.phone}}</td>
                                    <td>{{walkin.patient_id.email}}</td>
                                    <td>{{walkin.patient_id.resource_type}}</td>
                                    <td>{{walkin.patient_id.gender}} / {{walkin.age}}</td>
                                    <td>
                                        {{walkin.patient_id.organization_identifier}}
                                    </td>
                                    <td>{{walkin.department_name}}</td>
                                    <td>
                                        {{walkin.doctor_id?.name}}
                                    </td>
                                    <td>
                                        {{walkin.paramedic_id.name}}
                                    </td>
                                  <td>{{walkin.bp}}</td>
                                  <td>{{walkin.spo2}}</td>
                                  <td>{{walkin.temperature}}</td>
                                  <td>{{walkin.hr}}</td>
                                  <td>{{walkin.rr}}</td>
                                  <td>{{walkin.glucose}}</td>
                                  <td>{{walkin.height}}</td>
                                  <td>{{walkin.weight}}</td>
                                  <td>{{walkin.bmi}}</td>
                                  <td>{{walkin.complaint_type}}</td>
                                  <td>{{walkin.severity_grade}}</td>
                                  <td>{{walkin.nature_of_accident}}</td>
                                  <td>{{walkin.body_part_injured}}</td>
                                  <td>{{walkin.machine_involved}}</td>
                                  <td>{{walkin.prescription_id?.history}}</td>
                                  <td>{{walkin.prescription_id?.diagnosis}}</td>
                                  <td>{{walkin.prescription_id?.allergy}}</td>
                                  <td>{{walkin.prescription_id?.investigations}}</td>
                                  <td>{{walkin.prescription_id?.advice}}</td>
                                    <td>
                                        {{walkin.in | date: 'short'}}
                                    </td>
                                    <td>
                                        {{walkin.out | date: 'short'}}
                                    </td>
                                    <!-- <td class="col-1">
                                        <span class="badge bg-danger rounded-pill"
                                            *ngIf="isFollowUpDue(walkin.prescription_id?.followup_on)">Yes</span>
                                        <span class="badge bg-info rounded-pill"
                                            *ngIf="!isFollowUpDue(walkin.prescription_id?.followup_on)">No</span>
                                    </td> -->
                                    <td class="col-1">
                                        <div class="dropdown">
                                            <button class="btn btn-danger btn-sm dropdown-toggle" type="button"
                                                data-bs-toggle="dropdown" aria-expanded="false">
                                                Actions &nbsp; <i class="ri-arrow-down-fill"></i>
                                            </button>
                                            <ul class="dropdown-menu">

                                                <li><a class="dropdown-item" *ngIf="walkin.prescription_id"
                                                        (click)="viewPrescription(walkin.prescription_id?._id)">View
                                                        Prescription</a></li>
                                                <li><a class="dropdown-item" (click)="markOut(walkin._id)">Complete
                                                        Visit</a></li>
                                            </ul>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="btn-group mb-2 float-end">
                        <button type="button" (click)="getPrevPage()"
                            class="btn btn-secondary btn-bordered waves-effect" [disabled]="page_number == 0">
                            <i class="fas fa-arrow-left"></i> &nbsp; Prev
                        </button>
                        <button [disabled]="!(walkin_list.length >= page_size)" type="button" (click)="getNextPage()"
                            class="btn btn-secondary btn-bordered waves-effect">
                            Next &nbsp; <i class="fas fa-arrow-right"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div><!-- end col -->
    </div>
    <!-- end row -->

</div> <!-- container -->
<!-- Dashboard init-->
