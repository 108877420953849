<!-- Start Content-->
<div class="container-fluid">

    <!-- start page title -->
    <div class="row">
        <div class="col-12">
            <div class="page-title-box page-title-box-alt">
                <h4 class="page-title">Rest Register</h4>
                <div class="page-title-right">
                    <ol class="breadcrumb m-0">

                        <li class="breadcrumb-item"><a href="javascript: void(0);">Clinic</a></li>
                        <li class="breadcrumb-item active">Rest Register</li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
    <!-- end page title -->

    <div class="row">
        <div class="col-xl-12 col-md-12">
            <div class="card">
                <div class="card-body">
                    <div class="row table-responsive">
                        <table class="table table-striped table-hover table-bordered">
                            <thead>
                                <tr>
                                    <th>Sr. No.</th>
                                    <th>Patient</th>
                                    <th>Duration (In minutes.)</th>
                                    <th>From</th>
                                    <th>To</th>
                                    <th>Prescribed By</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style="text-align: center;" *ngIf="!rest_list.length">
                                    <td colspan="100">No Content</td>
                                </tr>
                                <tr *ngFor="let s of rest_list; let i = index">
                                    <td class="col-1">{{i + 1}}</td>
                                    <td>{{s.prescribed_to.name}}</td>
                                    <td>{{s.duration_mins}}</td>
                                    <td>{{s.date_received| date: 'short'}}</td>
                                    <td>{{s.date_sent | date: 'short'}}</td>
                                    <td>{{s.prescribed_by.name}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div><!-- end col -->
    </div>
    <!-- end row -->

</div> <!-- container -->
<!-- Dashboard init-->