<!-- Start Content-->
<div class="container-fluid">

    <!-- start page title -->
    <div class="row">
        <div class="col-12">
            <div class="page-title-box page-title-box-alt">
                <h4 class="page-title">Health Package</h4>
                <div class="page-title-right">
                    <ol class="breadcrumb m-0">

                        <li class="breadcrumb-item"><a href="javascript: void(0);">Health Checkup</a></li>
                        <li class="breadcrumb-item active">Packages</li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
    <!-- end page title -->

    <div class="row">
        <div class="col-xl-12 col-md-12">
            <div class="card">
                <div class="card-header">
                    Health Packages
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-xs-12">
                            <form [formGroup]="packageFormGroup" class="" (ngSubmit)="save()">
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="mb-2">
                                            <label for="name" class="form-label">Name <span
                                                    style="color: red;">*</span></label>
                                            <input class="form-control" type="text" id="name"
                                                placeholder="Enter lab name" formControlName="name">
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="mb-2">
                                            <label for="description" class="form-label">Description <span
                                                    style="color: red;">*</span></label>
                                            <input class="form-control" type="text" id="description"
                                                placeholder="Enter notes / description" formControlName="description">
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="mb-2">
                                            <label for="description" class="form-label">Delivery Type
                                                <span style="color: red;">*</span></label>
                                            <select class="form-control" formControlName="delivery_type">
                                                <option value="">-- Choose One --</option>
                                                <option value="Home">Home</option>
                                                <option value="Lab">Lab</option>
                                                <option value="Lab and Home">Lab and Home</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="mb-2">
                                            <label for="escalation_email" class="form-label">Tests Included <span
                                                    style="color: red;">*</span></label>
                                            <select multiple="multiple" class="form-control multi-select"
                                                id="my_multi_select2" name="my_multi_select2[]"
                                                formControlName="test_id" data-plugin="multiselect"
                                                data-selectable-optgroup="true">
                                                <optgroup *ngFor="let c of tests_list"
                                                    label="{{c._id.category}} - {{c._id.sub_category}}">
                                                    <option *ngFor="let t of c.tests" value="{{t._id}}">{{t.name}}
                                                    </option>
                                                </optgroup>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="mb-2">
                                            <label for="is_active" class="form-label">Is Active</label>
                                            <br />
                                            <input type="checkbox" id="is_active" formControlName="is_active">
                                        </div>
                                    </div>
                                </div>

                                <div class="mb-2 text-left">
                                    <button *ngIf="op_type != 'Update'" class="btn btn-primary" type="submit"
                                        [disabled]="!packageFormGroup.valid">Save
                                    </button> &nbsp;
                                    <button class="btn btn-warning" type="button"
                                        (click)="unSetCompanyIdContext()">Reset
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-xl-12 col-md-12">
            <div class="card">
                <div class="card-header">
                    AHC package list
                </div>
                <div class="card-body">
                    <div class="row table-responsive">
                        <table class="table table-striped table-hover table-bordered">
                            <thead>
                                <tr>
                                    <th>Sr. No.</th>
                                    <th>Name</th>
                                    <th>Description</th>
                                    <th>Active</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style="text-align: center;" *ngIf="!package_list.length">
                                    <td colspan="100">No Content</td>
                                </tr>
                                <tr *ngFor="let s of package_list; let i = index">
                                    <td class="col-1">{{i + 1}}</td>
                                    <td>{{s.name}}</td>
                                    <td>{{s.description}}</td>
                                    <td>
                                        <span class="badge bg-success rounded-pill" *ngIf="s.is_active">Yes</span>
                                        <span class="badge bg-danger rounded-pill" *ngIf="!s.is_active">No</span>
                                    </td>
                                    <td>
                                        <button class="btn btn-info btn-sm" type="button"
                                            (click)="updateModal(s)">View</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div><!-- end col -->
    </div>
    <!-- end row -->

</div> <!-- container -->
<!-- Dashboard init-->