<div class="mt-5 mb-5">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-10">

                <div class="text-center">
                    <img src="assets/images/animat-diamond-color.gif" alt="" height="160">
                    <h3 class="mt-1">Your payment attempt has failed.</h3>
                    <p class="text-muted">If money was deducted from your account, please reach out to us at <a
                            href="mailto:#" class="text-muted fw-medium">info@avyukthealthcare.com</a></p>

                    <div class="row mt-5">
                        <div class="col-md-6">
                            <div class="text-center mt-3 px-1">
                                <div class="avatar-md rounded-circle bg-soft-primary mx-auto">
                                    <i class="fe-target font-22 avatar-title text-primary"></i>
                                </div>
                                <h5 class="font-16 mt-3">What happens next?</h5>
                                <p class="text-muted">Your booking is not yet confirmed as your payment was
                                    unsuccessful. You can re-attempt your payment from the bookings page.</p>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="text-center mt-3 px-1">
                                <div class="avatar-md rounded-circle bg-soft-primary mx-auto">
                                    <i class="fe-help-circle font-22 avatar-title text-primary"></i>
                                </div>
                                <h5 class="font-16 mt-3">Do you need support?</h5>
                                <p class="text-muted">If you have any questions, do not hesitate to reach out to us on
                                    <a href="mailto:#" class="text-muted fw-medium">info@avyukthealthcare.com</a>
                                </p>
                            </div>
                        </div> <!-- end col-->
                    </div> <!-- end row-->
                </div> <!-- end /.text-center-->

            </div> <!-- end col -->
        </div>
        <!-- end row -->
    </div>
    <!-- end container -->
</div>