
import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ResponseObject } from 'src/app/shared/model/response.model';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/admin/user/user.service';
import { AuthService } from 'src/app/shared/auth.service';
import { RestRegisterService } from './rest-register.service';


@Component({
    selector: 'app-user',
    templateUrl: './rest-register.component.html',
    styleUrls: ['./rest-register.component.css']
})
export class RestRegisterComponent {

    public error_message: string = "";
    public success_message: string = "";
    public rest_list: any = [];

    constructor(private restRegisterService: RestRegisterService,
        private activatedroute: ActivatedRoute) { }

    private getRegisterContent() {
        this.restRegisterService.get(this.activatedroute.snapshot.queryParams["l"])
            .subscribe((res: ResponseObject) => {
                this.error_message = "";
                if (res.statusCode === 200) {
                    this.rest_list = res.data;
                }
                else {
                    this.error_message = res.message;
                }
            }, err => {
                if (err.status === 400) this.error_message = err.error.message[0];
                else this.error_message = err.error.message;
            });
    }

    ngOnInit() {
        this.getRegisterContent();
    }
}

