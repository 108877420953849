import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseObject } from 'src/app/shared/model/response.model';
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class PrescriptionService {

    constructor(private http: HttpClient) { }

    getPrescriptionsByDoctor(location_id: string, doctor_id: string,
        page_size: number = 0, page_number: number = 0) {
        return this.http.get<ResponseObject>(environment.apiEndpoint + "/prescription?prescriber_id=" + doctor_id +
            "&location_id=" + location_id
            + "&page_size=" + page_size
            + "&page_number=" + page_number);
    }

    getPrescriptionsByID(id: string,
        page_size: number = 0, page_number: number = 0) {
        return this.http.get<ResponseObject>(environment.apiEndpoint + "/prescription?_id=" + id
            + "&page_size=" + page_size
            + "&page_number=" + page_number);
    }

    getPrescriptionsByPatient(location_id: string, id: string,
        page_size: number = 0, page_number: number = 0) {
        return this.http.get<ResponseObject>(environment.apiEndpoint + "/prescription?patient_id=" + id +
            "&location_id=" + location_id
            + "&page_size=" + page_size
            + "&page_number=" + page_number);
    }

    getPrescriptionsOfMe(id: string,
        page_size: number = 0, page_number: number = 0) {
        return this.http.get<ResponseObject>(environment.apiEndpoint + "/prescription?patient_id=" + id
            + "&page_size=" + page_size
            + "&page_number=" + page_number);
    }

    createPrescriptions(data: any) {
        return this.http.post<ResponseObject>(environment.apiEndpoint + "/prescription", data);
    }

    updateWalkIn(id: string, data: any) {
        return this.http.put<ResponseObject>(environment.apiEndpoint + "/prescription/" + id, data);
    }

    getPrescriptionsUndispensed(location_id: string,
        page_size: number = 0, page_number: number = 0) {
        return this.http.get<ResponseObject>(environment.apiEndpoint + "/prescription?is_dispensed=false" +
            "&location_id=" + location_id
            + "&page_size=" + page_size
            + "&page_number=" + page_number);
    }

    getIcd10Cm(name: string) {
        return this.http.get<ResponseObject>(environment.apiEndpoint + "/prescription/icd10-cm?name=" + name);
    }

    dispence(prescription_id: string, updates: any) {
        return this.http.patch<ResponseObject>(environment.apiEndpoint + "/prescription/dispence/" + prescription_id, updates);
    }

    getRegulatoryData(location_id: string) {
        return this.http.get<ResponseObject>(environment.apiEndpoint + "/prescription/regulatory-data/" + location_id);
    }

    getLocationTopDiseases(location_ids: string) {
        return this.http.get<ResponseObject>(environment.apiEndpoint + "/prescription/top-10-disease?location_ids=" + location_ids);
    }
}
