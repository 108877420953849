
import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ResponseObject } from 'src/app/shared/model/response.model';
import { ActivatedRoute, Router } from '@angular/router';
import { HealtchCheckPackageService } from '../packages/health-check-packages.service';
import { LabsService } from '../labs/labs.service';

@Component({
    selector: 'app-user',
    templateUrl: './health-package-location-map.component.html',
    styleUrls: ['./health-package-location-map.component.css']
})
export class HCPackageLocationMapComponent {

    public error_message: string = "";
    public success_message: string = "";

    public map_list: any = [];
    public lab_list: any = [];
    public package_list: any = [];
    public op_type: string = "Create";
    public map_id: string = "";
    public user: any = {};

    public hcPackageMapFormGroup = new FormGroup({
        company_id: new FormControl(this.activatedroute.snapshot.queryParams["c"]),
        location_id: new FormControl(this.activatedroute.snapshot.queryParams["l"]),
        package_id: new FormControl("", [Validators.required]),
        lab_id: new FormControl("", [Validators.required]),
        billing_type: new FormControl("", [Validators.required]),
        price: new FormControl(0, [Validators.required]),
        cap_limit: new FormControl(0, [Validators.required]),
        is_active: new FormControl(true)
    });

    updateModal(mapping: any) {
        this.op_type = "Update";
        this.map_id = mapping._id
        delete mapping._id;
        delete mapping.__v;
        delete mapping.createdAt;
        delete mapping.updatedAt;
        this.hcPackageMapFormGroup.patchValue({
            company_id: this.activatedroute.snapshot.queryParams["c"],
            location_id: this.activatedroute.snapshot.queryParams["l"],
            package_id: mapping.package_id._id,
            lab_id: mapping.lab_id._id,
            billing_type: mapping.billing_type,
            cap_limit: mapping.cap_limit,
            is_active: mapping.is_active
        });
    }

    unSetCompanyIdContext() {
        this.op_type = "Create";
        this.error_message = "";
        this.success_message = "";
        this.map_id = "";
        this.hcPackageMapFormGroup.reset();
    }

    constructor(private healthCheckPackageService: HealtchCheckPackageService,
        private labTestService: LabsService,
        private activatedroute: ActivatedRoute) { }

    private get() {
        this.healthCheckPackageService.getMap(this.activatedroute.snapshot.queryParams["l"], false)
            .subscribe((res: ResponseObject) => {
                this.error_message = "";
                if (res.statusCode === 200) {
                    this.map_list = res.data;
                }
                else {
                    this.error_message = res.message;
                }
            });
    }

    ngOnInit() {
        this.get();

        this.healthCheckPackageService.getPackages()
            .subscribe((res: ResponseObject) => {
                this.error_message = "";
                if (res.statusCode === 200) {
                    this.package_list = res.data;
                }
                else {
                    this.error_message = res.message;
                }
            });

        this.labTestService.getCenters()
            .subscribe((res: ResponseObject) => {
                this.error_message = "";
                if (res.statusCode === 200) {
                    this.lab_list = res.data;
                }
                else {
                    this.error_message = res.message;
                }
            });
    }

    private create(data: any) {
        this.healthCheckPackageService.map(data)
            .subscribe((res: ResponseObject) => {
                this.error_message = "";
                if (res.statusCode === 200) {
                    this.get();
                }
                else {
                    this.error_message = res.message;
                }
            });
    }

    public updatePackageMap(id: string, is_active : any) {
        this.healthCheckPackageService.updateMap(id, { is_active })
            .subscribe((res: ResponseObject) => {
                this.error_message = "";
                if (res.statusCode === 200) {
                    this.get();
                }
                else {
                    this.error_message = res.message;
                }
            });
    }

    save() {
        this.error_message = "";
        this.success_message = "";

        if (!this.hcPackageMapFormGroup.valid) {
            this.error_message = "Invalid form inputs";
            return;
        }
        let data = {
            ... this.hcPackageMapFormGroup.value,
        }

        if (this.op_type === "Create")
            this.create(data);
    }

    update() {

    }
}

