import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseObject } from 'src/app/shared/model/response.model';
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class LabsService {

    constructor(private http: HttpClient) { }

    getCenters() {
        return this.http.get<ResponseObject>(environment.apiEndpoint + "/lab-center");
    }

    createCenters(data: any) {
        return this.http.post<ResponseObject>(environment.apiEndpoint + "/lab-center", data);
    }

    getTests() {
        return this.http.get<ResponseObject>(environment.apiEndpoint + "/lab-center/tests");
    }

    getTestsAll() {
        return this.http.get<ResponseObject>(environment.apiEndpoint + "/lab-center/tests-all");
    }

    createTests(data: any) {
        return this.http.post<ResponseObject>(environment.apiEndpoint + "/lab-center/tests", data);
    }

    updateTests(data: any, id: any) {
        return this.http.post<ResponseObject>(environment.apiEndpoint + "/lab-center/tests/" + id, data);
    }

    getMultipleTests(multiple: string) {
        return this.http.get<ResponseObject>(environment.apiEndpoint + "/lab-center/tests?multiple=" + multiple);
    }

    update(id: string, data: any) {
        return this.http.put<ResponseObject>(environment.apiEndpoint + "/lab-center/" + id, data);
    }
}