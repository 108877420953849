import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PharmacyService } from './pharmacy.service';
import { ResponseObject } from 'src/app/shared/model/response.model';
import { ActivatedRoute, Router } from '@angular/router';
import { PrescriptionService } from '../doctor/create-prescription/prescription.service';

@Component({
    selector: 'app-user',
    templateUrl: './pharmacy.component.html',
    styleUrls: ['./pharmacy.component.css']
})
export class PharmacyComponent {

    public error_message: string = "";
    public success_message: string = "";

    public medicine_list: any = [];
    public prescription_list: any = [];
    public op_type: string = "Create";
    public company_id: string = "";
    public medicine: any = {};
    public quantity: Number = 0;
    public date_picker_min: string = "";

    public pharmacyFormGroup = new FormGroup({
        name: new FormControl("", [Validators.required]),
        medicine_id: new FormControl(""),
        brand: new FormControl("", [Validators.required]),
        stock: new FormControl("", [Validators.required]),
        type: new FormControl("", [Validators.required]),
        batch_number: new FormControl("", [Validators.required]),
        expirey: new FormControl("", [Validators.required]),
        manufacturing: new FormControl("", [Validators.required]),
        potency: new FormControl("", [Validators.required]),
        mrp: new FormControl("", [Validators.required])
    });

    unSetMedicineContext() {
        this.op_type = "Create";
        this.company_id = "";
        this.error_message = "";
        this.success_message = "";
        this.pharmacyFormGroup.reset({ type: "" });
    }

    private getUndispensedPrescription() {
        this.prescriptionService.getPrescriptionsUndispensed(this.activatedroute.snapshot.queryParams["l"])
            .subscribe((res: ResponseObject) => {
                this.error_message = "";
                if (res.statusCode === 200) {
                    this.prescription_list = res.data;
                }
                else {
                    this.error_message = res.message;
                }
            });
    }

    constructor(private pharmacyService: PharmacyService,
        private router: Router,
        private activatedroute: ActivatedRoute,
        private prescriptionService: PrescriptionService) { }

    private getAllMedicine() {
        this.pharmacyService.getMedicineStockList(this.activatedroute.snapshot.queryParams["l"])
            .subscribe((res: ResponseObject) => {
                this.error_message = "";
                if (res.statusCode === 200) {
                    this.medicine_list = res.data;
                }
                else {
                    this.error_message = res.message;
                }
            });
    }

    ngOnInit() {
        let cur_date = new Date();
        // cur_date.setDate(cur_date.getDate() + 4);
        this.date_picker_min = cur_date.toISOString().split("T")[0];

        this.getAllMedicine();
        this.getUndispensedPrescription();
    }

    private createMedicine(data: any) {
        this.pharmacyService.createMedicine({
            ...data,
            company_id: this.activatedroute.snapshot.queryParams["c"],
            location_id: this.activatedroute.snapshot.queryParams["l"],
        })
            .subscribe((res: ResponseObject) => {
                this.error_message = "";
                if (res.statusCode === 201) {
                    this.success_message = "Medicine Created Successfuly."
                    this.getAllMedicine();

                }
                else {
                    this.error_message = res.message;
                }
            });
    }

    private updateMedicine(data: any) {
        this.pharmacyService.updateMedicine(data)
            .subscribe((res: ResponseObject) => {
                this.error_message = "";
                if (res.statusCode === 200) {
                    this.success_message = "Medicine Updated Successfuly."
                    this.getAllMedicine();
                }
                else {
                    this.error_message = res.message;
                }
            });
    }

    save() {
        this.error_message = "";
        this.success_message = "";

        if (!this.pharmacyFormGroup.valid) {
            this.error_message = "Invalid inputs";
            return;
        }

        let data = {
            ... this.pharmacyFormGroup.value,
            is_active: true
        }

        if (this.op_type === "Create")
            this.createMedicine(data);
        else if (this.op_type === "Update")
            this.updateMedicine(data)

    }

    viewPrescription(p: any) {
        this.router.navigate(['/detail-prescription'], {
            queryParams: {
                prescription: p,
                l: this.activatedroute.snapshot.queryParams["l"]
            }
        });
    }

    addStock(medicine_id: string) {
        if (!medicine_id) {
            this.error_message = "Search and select a medicine to add stock.";
            return;
        }

        this.pharmacyService.addStock(medicine_id, this.quantity)
            .subscribe((res: ResponseObject) => {
                this.error_message = "";
                if (res.statusCode === 200) {
                    //@ts-ignore
                    this.pharmacyFormGroup.controls.stock.setValue((this.pharmacyFormGroup.controls.stock.value) + this.quantity);
                    this.getAllMedicine();
                }
                else {
                    this.error_message = res.message;
                }
            }, err => {
                if (err.status === 400) this.error_message = err.error.message[0];
                else this.error_message = err.error.message;
            });
    }

    selectMedicine(medicine: any) {
        this.op_type = "Update";
        this.pharmacyFormGroup.patchValue({
            medicine_id: medicine._id,
            name: medicine.name,
            type: medicine.type,
            stock: medicine.stock,
            brand: medicine.brand,
            batch_number: medicine.batch_number,
            expirey: medicine.expirey.split("T")[0],
            manufacturing: medicine.manufacturing.split("T")[0],
            potency: medicine.potency,
            mrp: medicine.mrp
        });
    }

}

@Component({
    selector: 'app-user',
    templateUrl: './pharmacy-ledger.component.html',
    styleUrls: ['./pharmacy.component.css']
})
export class PharmacyLedgerComponent {

    public error_message: string = "";

    public ledger_list: any = [];
    public medicine_list: any = [];
    public from_date: string = "";
    public to_date: string = "";

    public ledgerFormGroup = new FormGroup({
        // from_date: new FormControl("", [Validators.required]),
        // to_date: new FormControl("", [Validators.required]),
        medicine_id: new FormControl(""),
    });

    constructor(private pharmacyService: PharmacyService,
        private router: Router,
        private activatedroute: ActivatedRoute) { }

    ngOnInit() {
        this.pharmacyService.getMedicineStockList(this.activatedroute.snapshot.queryParams["l"]).subscribe(
            (res: ResponseObject) => {
                this.error_message = "";
                if (res.statusCode === 200) {
                    this.medicine_list = res.data;
                }
                else {
                    this.error_message = res.message;
                }
            });
    }

    searchMedicine() {
        this.pharmacyService.getAllMedicineLedger(this.activatedroute.snapshot.queryParams["l"],
            "",
            "",
            this.ledgerFormGroup.value.medicine_id || "")
            .subscribe((res: ResponseObject) => {
                this.error_message = "";
                if (res.statusCode === 200) {
                    this.ledger_list = res.data;
                }
                else {
                    this.error_message = res.message;
                }
            }, err => {
                if (err.status === 400) this.error_message = err.error.message[0];
                else this.error_message = err.error.message;
            });
    }

    viewPrescription(p: any) {
        this.router.navigate(['/detail-prescription'], { queryParams: { prescription: p } });
    }

}



@Component({
  selector: 'app-user',
  templateUrl: './pharmacy-report.component.html',
  styleUrls: ['./pharmacy.component.css']
})
export class PharmacyLedgerMonthlyReportComponent {

  public error_message: string = "";

  public ledger_list: any = [];
  public medicine_list: any = [];
  public month: number = new Date().getMonth();
  public to_date: string = "";

  public ledgerFormGroup = new FormGroup({
    // from_date: new FormControl("", [Validators.required]),
    // to_date: new FormControl("", [Validators.required]),
    medicine_id: new FormControl(""),
  });

  constructor(private pharmacyService: PharmacyService,
              private router: Router,
              private activatedroute: ActivatedRoute) { }

  private getData() {
    this.pharmacyService.getMedicineStockListMonthWise(this.activatedroute.snapshot.queryParams["l"], this.month).subscribe(
      (res: ResponseObject) => {
        this.error_message = "";
        if (res.statusCode === 200) {
          this.medicine_list = res.data;
          for (let i: number = 0; i < this.medicine_list.length; i++) {
            let temp_arr = [];
            let sum: number = 0;
            for (let j = 1; j <= 31; j++) {
              let is_day_found = false;
              for (let k: number = 0; k <  this.medicine_list[i].days.length; k++) {
                if (this.medicine_list[i].days[k].day == j) {
                  sum += this.medicine_list[i].days[k].net_dispense;
                  is_day_found = true;
                  break;
                }
              }
              if (!is_day_found) temp_arr.push({ day: j, net_dispense: 0 });
              if (j == 31) {
                temp_arr.push({ day: 32, net_dispense: sum });
              }
            }
            this.medicine_list[i].days = this.medicine_list[i].days.concat(temp_arr);
            this.medicine_list[i].days = this.sort_by_key(this.medicine_list[i].days, "day")
          }
        }
        else {
          this.error_message = res.message;
        }
      });
  }

  ngOnInit() {
    this.getData();
  }

  sort_by_key(array: any, key: any): any {
    return array.sort((a: any, b: any) =>
    {
      var x = a[key]; var y = b[key];
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
  }

  setNewMonth(newMonth: number) {
    this.getData();
  }
}
