
import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ResponseObject } from 'src/app/shared/model/response.model';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/admin/user/user.service';
import { AuthService } from 'src/app/shared/auth.service';
import { LabsService } from './labs.service';
import { LocationMasterService } from 'src/app/shared/location_master.service';

@Component({
    selector: 'app-user',
    templateUrl: './labs.component.html',
    styleUrls: ['./labs.component.css']
})
export class LabsComponent {

    public error_message: string = "";
    public success_message: string = "";
    public labs_list: any = [];
    public tests_list: any = [];
    public op_type: string = "Create";
    public lab_id: string = "";
    public state_list: Array<string> = [];
    public city_list: Array<string> = [];


    public labsFormGroup = new FormGroup({
        name: new FormControl("", [Validators.required]),
        description: new FormControl("", [Validators.required]),
        is_active: new FormControl(false),
        corporate_email: new FormControl("", [Validators.required]),
        address: new FormGroup({
            line1: new FormControl("", [Validators.required]),
            line2: new FormControl(),
            landmark: new FormControl(),
            state: new FormControl("", [Validators.required]),
            city: new FormControl("", [Validators.required]),
            pincode: new FormControl("", [Validators.required])
        }),
        escalation_email: new FormControl("", [Validators.required])
    });

    updateModal(lab: any) {
        this.op_type = "Update";
        this.lab_id = lab._id;
        this.labsFormGroup.patchValue(lab);
    }

    unSetCompanyIdContext() {
        this.op_type = "Create";
        this.error_message = "";
        this.success_message = "";
        this.lab_id = "";
        this.labsFormGroup.reset();
    }

    constructor(private labsService: LabsService,
        private locationMasterService: LocationMasterService) { }

    private getRegisterContent() {
        this.labsService.getCenters()
            .subscribe((res: ResponseObject) => {
                this.error_message = "";
                if (res.statusCode === 200) {
                    this.labs_list = res.data;
                }
                else {
                    this.error_message = res.message;
                }
            }, err => {
                if (err.status === 400) this.error_message = err.error.message[0];
                else this.error_message = err.error.message;
            });
    }

    ngOnInit() {
        this.getRegisterContent();
        this.state_list = this.locationMasterService.getStates();

        this.labsService.getTests()
            .subscribe((res: ResponseObject) => {
                this.error_message = "";
                if (res.statusCode === 200) {
                    this.tests_list = res.data;
                }
                else {
                    this.error_message = res.message;
                }
            }, err => {
                if (err.status === 400) this.error_message = err.error.message[0];
                else this.error_message = err.error.message;
            });
    }

    private createLab(data: any) {
        this.labsService.createCenters(data)
            .subscribe((res: ResponseObject) => {
                this.error_message = "";
                if (res.statusCode === 201) {
                    this.getRegisterContent();
                }
                else {
                    this.error_message = res.message;
                }
                this.labsFormGroup.reset();
            }, err => {
                if (err.status === 400) this.error_message = err.error.message[0];
                else this.error_message = err.error.message;
            });
    }

    private update(data: any) {
        this.labsService.update(this.lab_id, data)
            .subscribe((res: ResponseObject) => {
                this.error_message = "";
                if (res.statusCode === 200) {
                    this.getRegisterContent();
                }
                else {
                    this.error_message = res.message;
                }
                this.labsFormGroup.reset();
            }, err => {
                if (err.status === 400) this.error_message = err.error.message[0];
                else this.error_message = err.error.message;
            });
    }


    getCities(state: any) {
        this.city_list = this.locationMasterService.getCities(state.target.value)
    }

    save() {
        this.error_message = "";
        this.success_message = "";

        if (!this.labsFormGroup.valid) {
            this.error_message = "Invalid form inputs";
            return;
        }
        let data = {
            ... this.labsFormGroup.value,
        }

        if (this.op_type === "Create")
            this.createLab(data);
        else if (this.op_type === "Update") {
            if (!this.lab_id) {
                this.error_message = "Please select an appropriate lab center";
                return;
            }
            this.update(data)
        }
    }
}


@Component({
    selector: 'app-user',
    templateUrl: './labs-test.component.html',
    styleUrls: ['./labs.component.css']
})
export class LabsTestsComponent {

    public error_message: string = "";
    public success_message: string = "";
    public labs_list: any = [];
    public tests_list: any = [];
    public op_type: string = "Create";
    public lab_id: string = "";


    public labsFormGroup = new FormGroup({
        name: new FormControl("", [Validators.required]),
        category: new FormControl("", [Validators.required]),
        sub_category: new FormControl("", [Validators.required])
    });

    updateModal(lab: any) {
        this.op_type = "Update";
        this.lab_id = lab._id
        this.labsFormGroup.patchValue(lab);
    }

    unSetCompanyIdContext() {
        this.op_type = "Create";
        this.error_message = "";
        this.success_message = "";
        this.lab_id = "";
        this.labsFormGroup.reset();
    }

    constructor(private labsService: LabsService) { }

    private getRegisterContent() {
        this.labsService.getTestsAll()
            .subscribe((res: ResponseObject) => {
                this.error_message = "";
                if (res.statusCode === 200) {
                    this.labs_list = res.data;
                }
                else {
                    this.error_message = res.message;
                }
            });
    }

    ngOnInit() {
        this.getRegisterContent();
    }

    private createLab(data: any) {
        this.labsService.createTests(data)
            .subscribe((res: ResponseObject) => {
                this.error_message = "";
                if (res.statusCode === 201) {
                    this.getRegisterContent();
                }
                else {
                    this.error_message = res.message;
                }
                this.labsFormGroup.reset();
            });
    }

    private update(data: any) {
        this.labsService.updateTests(this.lab_id, data)
            .subscribe((res: ResponseObject) => {
                this.error_message = "";
                if (res.statusCode === 200) {
                    this.getRegisterContent();
                }
                else {
                    this.error_message = res.message;
                }
                this.labsFormGroup.reset();
            });
    }

    save() {
        this.error_message = "";
        this.success_message = "";

        if (!this.labsFormGroup.valid) {
            this.error_message = "Invalid form inputs";
            return;
        }
        let data = {
            ... this.labsFormGroup.value,
        }

        if (this.op_type === "Create")
            this.createLab(data);
        else if (this.op_type === "Update") {
            if (!this.lab_id) {
                this.error_message = "Please select an appropriate lab center";
                return;
            }
            this.update(data)
        }
    }
}

