
import { Component } from '@angular/core';
import { ResponseObject } from 'src/app/shared/model/response.model';
import { ActivatedRoute, Router } from '@angular/router';
import { PrescriptionService } from 'src/app/doctor/create-prescription/prescription.service';

@Component({
    selector: 'app-user',
    templateUrl: './regulatory-data-submission-register.component.html',
    styleUrls: ['./regulatory-data-submission-register.component.css']
})
export class RegulatoryDataSubmissionRegisterComponent {

    public error_message: string = "";
    public success_message: string = "";
    public regulatory_data_list: any = [];


    constructor(private prescriptionService: PrescriptionService,
        private activatedroute: ActivatedRoute) { }

    private get() {
        this.prescriptionService.getRegulatoryData(this.activatedroute.snapshot.queryParams["l"])
            .subscribe((res: ResponseObject) => {
                if (res.statusCode === 200) {
                    this.regulatory_data_list = res.data;
                }
            });
    }


    ngOnInit() {
        this.get();
    }

    downloadExcel() {
        let export_data = [];
        for (let i = 0; i < this.regulatory_data_list.length; i++) {
            export_data.push({
                PatientName: this.regulatory_data_list[i].patient_id.name,
                Gender: this.regulatory_data_list[i].patient_id.gender,
                Age: this.regulatory_data_list[i].walkin_register_id.age,
                ID: this.regulatory_data_list[i].patient_id.organization_identifier,
                Phone: this.regulatory_data_list[i].patient_id.phone,
                Date: this.regulatory_data_list[i].createdAt,
                Diagnosis: this.regulatory_data_list[i].icd_code?.meaning_L4
            })
        }
        const replacer = (key: string, value: string) => (value === null ? '' : value); // specify how you want to handle null values here
        const header = Object.keys(export_data[0]);
        const csv = export_data.map((row: any) =>
            header
                .map((fieldName) => JSON.stringify(row[fieldName], replacer))
                .join(',')
        );
        csv.unshift(header.join(','));
        const csvArray = csv.join('\r\n');

        const a = document.createElement('a');
        const blob = new Blob([csvArray], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);

        a.href = url;
        a.download = 'regulatory_data.csv';
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
    }
}