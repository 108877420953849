<!-- Start Content-->
<div class="container-fluid">

    <!-- start page title -->
    <div class="row">
        <div class="col-12">
            <div class="page-title-box page-title-box-alt">
                <h4 class="page-title">Out Patient</h4>
                <div class="page-title-right">
                    <ol class="breadcrumb m-0">

                        <li class="breadcrumb-item"><a href="javascript: void(0);">Clinic</a></li>
                        <li class="breadcrumb-item active">OPD</li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
    <!-- end page title -->

    <div class="row">
        <div class="col-xl-12 col-md-12">
            <div class="card">
                <div class="card-header">
                    Register new walk-in
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-xs-12">
                            <form [formGroup]="walkInFormGroup" class="" (ngSubmit)="save()">
                                <div class="alert alert-success" *ngIf="success_message">{{success_message}}</div>

                                <h5>Demographics</h5>
                                <div class="row">
                                    <div class="col-md-3">
                                        <label for="phone" class="form-label"
                                            title="Enter the phone number without (+91) or the organizationa's unique identifier"
                                            data-plugin="tippy" data-tippy-placement="top">Phone / UID
                                            <span style="color: red;">*</span> &nbsp;
                                            <i class="fas fa-question-circle"></i>
                                        </label>
                                        <div class="input-group mb-2">
                                            <input type="text" class="form-control" [readOnly]="op_type == 'Update'"
                                                id="phone" placeholder="Enter user's phone / uid"
                                                formControlName="phone" aria-label="User's phone"
                                                aria-describedby="basic-addon2">
                                            <input class="form-control" type="hidden" id="phone"
                                                placeholder="Enter user's phone" formControlName="patient_id"
                                                aria-label="User's phone" aria-describedby="basic-addon2">
                                            <div class="input-group-append">
                                                <button class="btn btn-success"
                                                    (click)="searchUser(walkInFormGroup.controls.phone.value || '0')"
                                                    type="button">Search</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="mb-2">
                                            <label for="name" class="form-label">Name <span
                                                    style="color: red;">*</span></label>
                                            <input readonly class="form-control" type="text" id="name" required
                                                placeholder="Enter user's full name" formControlName="name">
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="mb-2">
                                            <label for="email" class="form-label">Email</label>
                                            <input readonly class="form-control" type="email" id="email"
                                                placeholder="Enter user's email" formControlName="email">
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="mb-2">
                                            <label for="gender" class="form-label">Gender <span
                                                    style="color: red;">*</span></label>
                                            <select readonly class="form-control" id="gender" required
                                                formControlName="gender">
                                                <option value="">-- Select --</option>
                                                <option value="Male">Male</option>
                                                <option value="Female">Female</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <h5>Vitals</h5>

                                <div class="row">
                                    <div class="col-md-2">
                                        <div class="mb-2">
                                            <label for="age" class="form-label">Age <span
                                                    style="color: red;">*</span></label>
                                            <input readonly class="form-control" type="text" id="age"
                                                placeholder="Pre-calculated age" formControlName="age">
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <label for="bp" class="form-label">Blood Pressure </label>
                                        <div class="mb-2">
                                            <input class="form-control" type="text" id="bp" placeholder="Blood Pressure"
                                                formControlName="bp">
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="mb-2">
                                            <label for="spo2" class="form-label">SpO2 </label>
                                            <div class="input-group">
                                                <input class="form-control" type="text" id="spo2"
                                                    placeholder="Enter SpO2" formControlName="spo2">
                                                <div class="input-group-append">
                                                    <span class="input-group-text">%</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="mb-2">
                                            <label for="temperature" class="form-label">Temperature</label>
                                            <div class="input-group">
                                                <input class="form-control" type="text" id="temperature"
                                                    placeholder="Enter temperature" formControlName="temperature">
                                                <div class="input-group-append">
                                                    <span class="input-group-text">&deg;F</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="mb-2">
                                            <label for="hr" class="form-label">Heart Rate </label>
                                            <div class="input-group">
                                                <input class="form-control" type="text" id="hr"
                                                    placeholder="Enter Heart Rate" formControlName="hr">
                                                <div class="input-group-append">
                                                    <span class="input-group-text">per min</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="mb-2">
                                            <label for="rr" class="form-label">Respiratory Rate </label>
                                            <div class="input-group">
                                                <input class="form-control" type="text" id="rr"
                                                    placeholder="Enter respiratory Rate" formControlName="rr">
                                                <div class="input-group-append">
                                                    <span class="input-group-text">per min</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-2">
                                        <div class="mb-2">
                                            <label for="rr" class="form-label">Glucose </label>
                                            <div class="input-group">
                                                <input class="form-control" type="text" id="glucose"
                                                    placeholder="Enter blood glucose levels" formControlName="glucose">
                                                <div class="input-group-append">
                                                    <span class="input-group-text">mg/dL</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-2">
                                        <div class="mb-2">
                                            <label for="rr" class="form-label">Height </label>
                                            <div class="input-group">
                                                <input class="form-control" type="text" id="glucose"
                                                    placeholder="Enter height in centimeter" formControlName="height">
                                                <div class="input-group-append">
                                                    <span class="input-group-text">cm</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-2">
                                        <div class="mb-2">
                                            <label for="rr" class="form-label">Weight </label>
                                            <div class="input-group">
                                                <input class="form-control" type="text" id="glucose"
                                                    placeholder="Enter wight in kilograms" formControlName="weight">
                                                <div class="input-group-append">
                                                    <span class="input-group-text">Kg</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="mb-2">
                                            <label for="rr" class="form-label">BMI </label>
                                            <div class="input-group">
                                                <input class="form-control" type="text" id="glucose"
                                                    placeholder="Calculated BMI Value" formControlName="bmi">
                                                <div class="input-group-append">
                                                    <span class="input-group-text">Kg/m<sup>2</sup></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="mb-2">
                                            <label>Pregnant? </label> <br />
                                            <input type="checkbox" formControlName="is_pregnant" />
                                        </div>
                                    </div>
                                </div>

                                <h5>Emergency Details</h5>

                                <div class="row">
                                    <div class="col-md-3">
                                        <div class="mb-2">
                                            <label>Emergency? </label> <br />
                                            <input type="checkbox" formControlName="is_emergency" />
                                        </div>
                                    </div>
                                    <div class="col-md-3" *ngIf="walkInFormGroup.controls.is_emergency.value">
                                        <div class="mb-2">
                                            <label>Complaint Type <span style="color: red;">*</span></label>
                                            <select class="form-control" formControlName="complaint_type">
                                                <option value="">-- Select One --</option>
                                                <option value="Injury">Injury</option>
                                                <option value="Health Complaint">Health Complaint</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div class="row" *ngIf="walkInFormGroup.controls.is_emergency.value">
                                    <div class="col-md-3">
                                        <div class="mb-2">
                                            <label>Severity <span style="color: red;">*</span></label>
                                            <select class="form-control" formControlName="severity_grade">
                                                <option value="">-- Select One --</option>
                                                <option value="1">Grade 1 (Amputation or fatal accidents, report to
                                                    Factory
                                                    Inspector within 48 hours.)</option>
                                                <option value="2">Grade 2 (Injuries needing over 48 hours rest or
                                                    hospital
                                                    treatment, temporary disablement.)</option>
                                                <option value="3">Grade 3 (First aid at OHC, rest period under 48
                                                    hours.)
                                                </option>
                                                <option value="4">Grade IV: Minor injuries, needing first aid, not
                                                    reported.
                                                </option>
                                                <option value="5">Grade V: Worker complaints, not involving accidents,
                                                    non-reportable.
                                                </option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-md-3"
                                        *ngIf="walkInFormGroup.controls.complaint_type.value == 'Injury'">
                                        <div class="mb-2">
                                            <label>Nature of Accident <span style="color: red;">*</span></label>
                                            <select class="form-control" formControlName="nature_of_accident">
                                                <option value="">-- Select One --</option>
                                                <option value="Fractures">Fractures</option>
                                                <option value="Dislocations">Dislocations</option>
                                                <option value="Sprains and strains">Sprains and strains</option>
                                                <option value="Multiple Locations">Concussions & other
                                                    internal injury</option>
                                                <option value="Amputations">Amputations</option>
                                                <option value="Other wounds">Other wounds</option>
                                                <option value="Superficial injuries">Superficial injuries</option>
                                                <option value="Contusions & crushings">Contusions & crushings</option>
                                                <option value="Burns">Burns</option>
                                                <option value="Acute poisoning">Acute poisoning</option>
                                                <option value="Asphyxia">Asphyxia</option>
                                                <option value="Effects of electrical currents">Effects of electrical
                                                    currents</option>
                                                <option value="Multiple injuries of different nature">Multiple injuries
                                                    of different nature</option>
                                                <option value="Others and unspecified injuries">Others and unspecified
                                                    injuries</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-3"
                                        *ngIf="walkInFormGroup.controls.complaint_type.value == 'Injury'">
                                        <div class="mb-2">
                                            <label>Body Part Injured <span style="color: red;">*</span></label>
                                            <select class="form-control" formControlName="body_part_injured">
                                                <option value="">-- Select One --</option>
                                                <option value="Head/Facial">Head/Facial</option>
                                                <option value="Eye">Eye</option>
                                                <option value="Upper Limb">Upper Limb</option>
                                                <option value="Multiple Locations">Multiple Locations</option>
                                                <option value="Lower Limb">Lower Limb</option>
                                                <option value="Foot">Foot</option>
                                                <option value="Trunk">Trunk</option>
                                                <option value="Neck">Neck</option>
                                                <option value="Others">Others</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-md-3"
                                        *ngIf="walkInFormGroup.controls.complaint_type.value == 'Injury'">
                                        <div class="mb-2">
                                            <label for="machine_involved">Machine / Object Involved <span
                                                    style="color: red;">*</span>
                                            </label>
                                            <input class="form-control" type="text" id="machine_involved"
                                                placeholder="Machine / Object Involved"
                                                formControlName="machine_involved">
                                        </div>
                                    </div>
                                </div>

                                <h5>Details</h5>

                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="mb-2">
                                            <label for="description" class="form-label">Description <span
                                                    style="color: red;">*</span></label>
                                            <textarea class="form-control" type="text" id="description"
                                                placeholder="Enter complaints, remarks etc.."
                                                formControlName="description">
                                                    </textarea>
                                        </div>
                                    </div>
                                </div>

                                <div class="mb-2 text-left">
                                    <button class="btn btn-primary" type="submit"
                                        [disabled]="!walkInFormGroup.valid">Save
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-xl-12 col-md-12">
            <div class="card">
                <div class="card-header">
                    Today's Walk-Ins
                </div>
                <div class="card-body">
                    <div class="row table-responsive">
                        <table class="table table-striped table-hover table-bordered">
                            <thead>
                                <tr>
                                    <th>Sr. No.</th>
                                    <th>Name</th>
                                    <th>Sex / Age</th>
                                    <th>UID</th>
                                    <th>Doctor</th>
                                    <th>̦Paramedic</th>
                                    <th>In Time</th>
                                    <th>Out Time</th>
                                    <th>Pharmacy</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style="text-align: center;" *ngIf="!walkin_list.length">
                                    <td colspan="100">No Records</td>
                                </tr>
                                <tr *ngFor="let walkin of walkin_list; let i = index">
                                    <td class="col-1">{{i + 1}}</td>
                                    <td>{{walkin.patient_id.name}}</td>
                                    <td>{{walkin.patient_id.gender}} / {{walkin.age}}</td>
                                    <td>
                                        {{walkin.patient_id.phone}}
                                    </td>
                                    <td>
                                        {{walkin.doctor_id?.name}}
                                    </td>
                                    <td>
                                        {{walkin.paramedic_id.name}}
                                    </td>
                                    <td>
                                        {{walkin.in | date: 'short'}}
                                    </td>
                                    <td>
                                        {{walkin.out | date: 'short'}}
                                    </td>
                                    <td class="col-1">
                                        <span class="badge bg-success rounded-pill"
                                            *ngIf="walkin.is_dispensed">Dispensed</span>
                                        <span class="badge bg-warning rounded-pill" *ngIf="!walkin.is_dispensed">Not
                                            Dispensed</span>
                                    </td>
                                    <td class="col-1">
                                        <div class="dropdown">
                                            <button class="btn btn-danger btn-sm dropdown-toggle" type="button"
                                                data-bs-toggle="dropdown" aria-expanded="false">
                                                Actions &nbsp; <i class="ri-arrow-down-fill"></i>
                                            </button>
                                            <ul class="dropdown-menu">

                                                <li><a class="dropdown-item" *ngIf="walkin.prescription_id"
                                                        (click)="viewPrescription(walkin.prescription_id)">View
                                                        Prescription</a></li>
                                                <li><a class="dropdown-item" (click)="markOut(walkin._id)">Complete
                                                        Visit</a></li>
                                            </ul>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div><!-- end col -->
    </div>
    <!-- end row -->

    <div class="row">
        <div class="col-xl-12 col-md-12">
            <div class="card">
                <div class="card-header">
                    Today's Appointments
                </div>
                <div class="card-body">
                    <div class="row table-responsive">
                        <table class="table table-striped table-hover table-bordered">
                            <thead>
                                <tr>
                                    <th class="col-1">Sr. No.</th>
                                    <th>Name</th>
                                    <th>Department</th>
                                    <th>ID</th>
                                    <th>Doctor's Name</th>
                                    <th>Specialization</th>
                                    <th class="col-2"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style="text-align: center;" *ngIf="!today_appointment_list.length">
                                    <td colspan="100">No Appointments</td>
                                </tr>
                                <tr *ngFor="let s of today_appointment_list; let i = index">
                                    <td class="col-1">{{i + 1}}</td>
                                    <td>{{s.booked_by.name}}</td>
                                    <td>{{s.booked_by.department_name}}</td>
                                    <td>{{s.booked_by.organization_identifier}}</td>
                                    <td>{{s.booked_with.name}}</td>
                                    <td>{{s.booked_with.doctor_details.specialization}}</td>
                                    <td class="d-grid"><a href="#" (click)="checkInAppointment(s)"
                                            class="btn btn-block btn-xs btn-info">Check-In</a></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div><!-- end col -->
    </div>
    <!-- end row -->

</div> <!-- container -->
<!-- Dashboard init-->
