import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PrescriptionService } from './prescription.service';
import { ResponseObject } from 'src/app/shared/model/response.model';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/shared/auth.service';
import { WalkInService } from 'src/app/paramedic/walk-in/walk-in.service';
import { JSLoader } from 'src/app/shared/js-loader.function';
import { PharmacyService } from 'src/app/pharmacy/pharmacy.service';

@Component({
  selector: 'app-user',
  templateUrl: './create-prescription.component.html',
  styleUrls: ['./create-prescription.component.css']
})
export class CreatePrescriptionComponent {

  public error_message: string = "";
  public success_message: string = "";

  public walkin_list: any = [];
  public medicine_list: any = [];
  public med_in_pharma_array: any = [];
  public med_addon_medicine_array: any = [];
  public icd_array: any = [];
  public op_type: string = "Create";
  public company_id: string = "";
  public user: any = {};
  public ctx_walkin: any = {};
  public recommend_rest: string = "No";

  public medicine_id: string = "";
  public frequency: string = "";
  public instructions: string = "";
  public duration: string = "";
  public frequency_addon: string = "";
  public instructions_addon: string = "";
  public duration_addon: string = "";
  public name: string = "";
  public icd_showstring: string = "";
  public icd_searchable: boolean = false;

  public bp: string = "";
  public spo2: string = "";
  public temperature: string = "";
  public hr: string = "";
  public rr: string = "";

  public prescriptionFormGroup = new FormGroup({
    walkin_register_id: new FormControl("", [Validators.required]),
    patient_id: new FormControl("", [Validators.required]),
    prescriber_id: new FormControl("", [Validators.required]),
    history: new FormControl(""),
    complaint_off: new FormControl("", [Validators.required]),
    prognosis: new FormControl(""),
    advice: new FormControl(""),
    diagnosis: new FormControl("", [Validators.required]),
    recommend_rest: new FormControl(""),
    icd_code: new FormControl(""),
    followup_on: new FormControl(""),
    is_referred_outside: new FormControl(false),
    referred_outside_to: new FormControl(""),
    allergy: new FormControl(""),
    investigations: new FormControl("")
  });

  toggleICDSearchableButton(e: string) {
    if (e.length >= 3) this.icd_searchable = true;
    else this.icd_searchable = false;
  }

  addInPharmaMedicine() {
    if (!this.medicine_id || !this.frequency || !this.duration) {
      this.error_message = "Add all madatory details for medication";
      return;
    }

    this.med_in_pharma_array.push({
      medicine_id: this.medicine_id,
      frequency: this.frequency,
      instructions: this.instructions,
      duration: this.duration
    })
  }

  popInPharmaMedicine(med: any) {
    this.med_in_pharma_array.splice(this.med_in_pharma_array.indexOf(med), 1); // will return ['B'] and t is now equal to ['A', 'C', 'B']
  }

  addAddonMedicine() {
    if (!this.name || !this.frequency || !this.duration) {
      this.error_message = "Add all madatory details for medication";
      return;
    }

    this.med_addon_medicine_array.push({
      name: this.name,
      frequency: this.frequency_addon,
      instructions: this.instructions_addon,
      duration: this.duration_addon
    })
  }

  popAddonMedicine(med: any) {
    this.med_addon_medicine_array.splice(this.med_addon_medicine_array.indexOf(med), 1); // will return ['B'] and t is now equal to ['A', 'C', 'B']
  }

  updateModal(company: any) {
    this.op_type = "Update";
    this.company_id = company._id;
    delete company._id;
    this.prescriptionFormGroup.setValue(company);
  }

  unSetCompanyIdContext() {
    this.op_type = "Create";
    this.company_id = "";
    this.error_message = "";
    this.success_message = "";
    this.prescriptionFormGroup.reset();
  }

  constructor(private prescriptionService: PrescriptionService,
    private walkInService: WalkInService,
    private router: Router,
    private activatedroute: ActivatedRoute,
    private pharmacyService: PharmacyService,
    private authService: AuthService) { }

  private getAllMedicine() {
    this.pharmacyService.getMedicineStockList(this.activatedroute.snapshot.queryParams["l"])
      .subscribe((res: ResponseObject) => {
        this.error_message = "";
        if (res.statusCode === 200) {
          this.medicine_list = res.data;
        }
        else {
          this.error_message = res.message;
        }
      }, err => {
        if (err.status === 400) this.error_message = err.error.message[0];
        else this.error_message = err.error.message;
      });
  }

  private getWalkIns() {
    this.walkInService.getWalkIns(this.activatedroute.snapshot.queryParams["l"], (new Date().toISOString().split('T')[0]))
      .subscribe((res: ResponseObject) => {
        this.error_message = "";
        if (res.statusCode === 200) {
          this.walkin_list = res.data;
        }
        else {
          this.error_message = res.message;
        }
      });
  }

  ngOnInit() {
    this.getWalkIns();
    this.getAllMedicine();
    this.user = JSON.parse(this.authService.getLoggedInUserDetails() || '{}');
    JSLoader.load('assets/libs/jquery.quicksearch/jquery.quicksearch.min.js');
    JSLoader.load('assets/libs/select2/js/select2.min.js');
    JSLoader.load('assets/js/pages/form-advanced.init.js');
  }

  updateVitals() {
    this.walkInService.updateVitals(this.ctx_walkin._id, {
      bp: this.bp,
      spo2: this.spo2,
      temperature: this.temperature,
      hr: this.hr,
      rr: this.rr
    })
      .subscribe((res: ResponseObject) => {
        if (res.statusCode === 200) {
        }
      });
  }

  searchICD(e: any) {
    this.prescriptionService.getIcd10Cm(e).subscribe((res: ResponseObject) => {
      if (res.statusCode === 200) {
        this.icd_array = res.data;
      }
      else {
        this.error_message = res.message;
      }
    });
  }

  setICDChoice(val: string) {
    this.icd_showstring = val.split("~~")[1];
    this.prescriptionFormGroup.controls.icd_code.setValue(val.split("~~")[0]);
  }

  private createPrescription(data: any) {
    for (let i = 0; i < this.med_in_pharma_array.length; i++) {
      this.med_in_pharma_array[i].medicine_id = this.med_in_pharma_array[i].medicine_id.split("~~")[0];
    }
    if (!data.recommend_rest) data.recommend_rest = 0;
    this.prescriptionService.createPrescriptions({
      ...data,
      company_id: this.activatedroute.snapshot.queryParams["c"],
      location_id: this.activatedroute.snapshot.queryParams["l"],
      in: new Date().toISOString(),
      date: new Date().toISOString().split('T')[0],
      is_dispensed: false,
      medicine: this.med_in_pharma_array,
      addon_medicine: this.med_addon_medicine_array
    })
      .subscribe((res: ResponseObject) => {
        this.error_message = "";
        if (res.statusCode === 201) {
          this.success_message = "Prescription Created Successfuly."
          this.prescriptionFormGroup.reset();
          this.icd_array = [];
          this.icd_showstring = "";
          this.med_in_pharma_array = [];
          this.getWalkIns();
        }
        else {
          this.error_message = res.message;
        }
      });
  }

  private updateWalkIn(id: string, data: any) {
    this.prescriptionService.updateWalkIn(id, data)
      .subscribe((res: ResponseObject) => {
        this.error_message = "";
        if (res.statusCode === 200) {
          this.success_message = "WalkIn Updated Successfuly."
          this.getWalkIns();
        }
        else {
          this.error_message = res.message;
        }
      });
  }

  save() {
    this.error_message = "";
    this.success_message = "";

    if (!this.prescriptionFormGroup.valid) {
      this.error_message = "Invalid form inputs";
      return;
    }

    let data = {
      ... this.prescriptionFormGroup.value,
      is_active: true
    }

    if (this.op_type === "Create")
      this.createPrescription(data);
    else if (this.op_type === "Update")
      this.updateWalkIn(this.company_id, data)

  }

  startPrescribe(walkin_selected: any) {
    this.ctx_walkin = walkin_selected;
    this.prescriptionFormGroup.patchValue({
      walkin_register_id: walkin_selected._id,
      patient_id: walkin_selected.patient_id._id,
      prescriber_id: this.user._id,
      history: "",
      complaint_off: "",
      prognosis: "",
      advice: "",
      icd_code: "",
      recommend_rest: "",
      followup_on: "",
      is_referred_outside: false,
      referred_outside_to: "",
    });
    this.med_in_pharma_array = [];
    this.med_addon_medicine_array = [];
  }

  viewPastPrescriptions(patient_id: string) {
    this.router.navigate(['/list-prescription'], { queryParams: { uid: patient_id } });
  }
}
