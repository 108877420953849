<!-- Start Content-->
<div class="container-fluid">

    <!-- start page title -->
    <div class="row">
        <div class="col-12">
            <div class="page-title-box page-title-box-alt">
                <h4 class="page-title">Company Management</h4>
                <div class="page-title-right">
                    <ol class="breadcrumb m-0">

                        <li class="breadcrumb-item"><a href="javascript: void(0);">Customer</a></li>
                        <li class="breadcrumb-item active">Company Management</li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
    <!-- end page title -->


    <div class="row">
        <div class="col-xl-12 col-md-12">
            <div class="card">
                <div class="card-header">
                    Manage Company
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-xs-12">
                            <form [formGroup]="companyFormGroup" class="" (ngSubmit)="save()">
                                <div class="alert alert-danger" *ngIf="error_message">{{error_message}}</div>
                                <div class="alert alert-success" *ngIf="success_message">{{success_message}}</div>

                                <h5 class="card-title">Customer Basics</h5>

                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="mb-2">
                                                <label class="form-label" for="name">Name <span
                                                        style="color: red;">*</span></label>
                                                <input class="form-control" type="text" id="name" required
                                                    placeholder="Enter company's full name" formControlName="name">
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="mb-2">
                                                <label class="form-label" for="gst">GST <span
                                                        style="color: red;">*</span></label>
                                                <input class="form-control" [readOnly]="op_type == 'Update'" type="text"
                                                    id="gst" placeholder="Enter gst" formControlName="gst">
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="mb-2">
                                                <label class="form-label" for="legal_name">Legal Name <span
                                                        style="color: red;">*</span></label>
                                                <input class="form-control" type="text" id="legal_name"
                                                    placeholder="Enter legal name" formControlName="legal_name">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="mb-2">
                                            <label class="form-label" for="domain">Domain <span
                                                    style="color: red;">*</span></label>
                                            <input class="form-control" [readOnly]="op_type == 'Update'" type="text"
                                                id="domain" placeholder="Enter domain without http(s) or www"
                                                formControlName="domain">
                                        </div>
                                    </div>
                                </div>

                                <h5 class="card-title" style="margin-top: var(--bs-card-title-spacer-y);">Customer
                                    Single Point of Contact</h5>

                                <div formGroupName="spoc_details">
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="mb-2">
                                                    <label class="form-label" for="name">Client SPoC Name <span
                                                            style="color: red;">*</span></label>
                                                    <input class="form-control" [readOnly]="op_type == 'Update'"
                                                        type="text" id="name" placeholder="Enter name"
                                                        formControlName="name">
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="mb-2">
                                                    <label class="form-label" for="phone">Client SPoC Phone <span
                                                            style="color: red;">*</span></label>
                                                    <input class="form-control" [readOnly]="op_type == 'Update'"
                                                        type="text" id="phone" placeholder="Enter phone"
                                                        formControlName="phone">
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="mb-2">
                                                    <label class="form-label" for="email">Client SPoC Email <span
                                                            style="color: red;">*</span></label>
                                                    <input class="form-control" [readOnly]="op_type == 'Update'"
                                                        type="text" id="email" placeholder="Enter email"
                                                        formControlName="email">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <h5 class="card-title" style="margin-top: var(--bs-card-title-spacer-y);">Billing
                                    Details</h5>

                                <div formGroupName="billing_address">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="mb-2">
                                                <label class="form-label" for="line1">Line 1 <span
                                                        style="color: red;">*</span></label>
                                                <input class="form-control" required type="text" id="line1"
                                                    placeholder="Enter Line 1" formControlName="line1">
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="mb-2">
                                                <label class="form-label" for="line2">Line 2</label>
                                                <input class="form-control" type="text" id="line2"
                                                    placeholder="Enter Line 2" formControlName="line2">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="mb-2">
                                                    <label class="form-label" for="city">City <span
                                                            style="color: red;">*</span></label>
                                                    <input class="form-control" required type="text" id="city"
                                                        placeholder="Enter City" formControlName="city">
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="mb-2">
                                                    <label class="form-label" for="state">State <span
                                                            style="color: red;">*</span></label>
                                                    <select class="form-control" id="state" formControlName="state">
                                                        <option value="">-- Select --</option>
                                                        <option value="Andhra Pradesh">Andhra Pradesh</option>
                                                        <option value="Andaman and Nicobar Islands">Andaman and Nicobar
                                                            Islands
                                                        </option>
                                                        <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                                                        <option value="Assam">Assam</option>
                                                        <option value="Bihar">Bihar</option>
                                                        <option value="Chandigarh">Chandigarh</option>
                                                        <option value="Chhattisgarh">Chhattisgarh</option>
                                                        <option value="Dadar and Nagar Haveli">Dadar and Nagar Haveli
                                                        </option>
                                                        <option value="Daman and Diu">Daman and Diu</option>
                                                        <option value="Delhi">Delhi</option>
                                                        <option value="Lakshadweep">Lakshadweep</option>
                                                        <option value="Puducherry">Puducherry</option>
                                                        <option value="Goa">Goa</option>
                                                        <option value="Gujarat">Gujarat</option>
                                                        <option value="Haryana">Haryana</option>
                                                        <option value="Himachal Pradesh">Himachal Pradesh</option>
                                                        <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                                                        <option value="Jharkhand">Jharkhand</option>
                                                        <option value="Karnataka">Karnataka</option>
                                                        <option value="Kerala">Kerala</option>
                                                        <option value="Madhya Pradesh">Madhya Pradesh</option>
                                                        <option value="Maharashtra">Maharashtra</option>
                                                        <option value="Manipur">Manipur</option>
                                                        <option value="Meghalaya">Meghalaya</option>
                                                        <option value="Mizoram">Mizoram</option>
                                                        <option value="Nagaland">Nagaland</option>
                                                        <option value="Odisha">Odisha</option>
                                                        <option value="Punjab">Punjab</option>
                                                        <option value="Rajasthan">Rajasthan</option>
                                                        <option value="Sikkim">Sikkim</option>
                                                        <option value="Tamil Nadu">Tamil Nadu</option>
                                                        <option value="Telangana">Telangana</option>
                                                        <option value="Tripura">Tripura</option>
                                                        <option value="Uttar Pradesh">Uttar Pradesh</option>
                                                        <option value="Uttarakhand">Uttarakhand</option>
                                                        <option value="West Bengal">West Bengal</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="mb-2">
                                                    <label class="form-label" for="pincode">Pincode <span
                                                            style="color: red;">*</span></label>
                                                    <input class="form-control" type="text" id="pincode"
                                                        placeholder="Enter Pincode" formControlName="pincode">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-8">
                                        <label class="form-label">Logo</label>
                                        <div class="input-group mb-2">
                                            <input type="file" class="form-control" placeholder="Company logo"
                                                (input)="newFileSelect($event)" aria-describedby="basic-addon2">
                                            <button class="btn btn-success" (click)="uploadLogo()" type="button"
                                                [disabled]="!company_id">Uplaod
                                                (After Company Creation)</button>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="mb-2">
                                            <label class="form-label" for="domain">Sub Domain</label>
                                            <input class="form-control" type="text" id="domain"
                                                placeholder="Enter subdomain for whitelabeling"
                                                formControlName="whitelable_domain">
                                        </div>
                                    </div>
                                </div>


                                <div class="mb-2" id="formGridCheckboxVaccine">
                                    <div class="form-check">
                                        <input formControlName="is_active" type="checkbox" id="formGridCheckboxVaccine"
                                            class="form-check-input"><label title="" for="formGridCheckboxVaccine"
                                            class="form-check-label" value="admin">Active</label>
                                    </div>
                                </div>

                                <div class="mb-2" id="formGridCheckboxVaccine">
                                    <div class="form-check">
                                        <input formControlName="is_whitelisted" type="checkbox"
                                            id="formGridCheckboxVaccine" class="form-check-input"><label title=""
                                            for="formGridCheckboxVaccine" class="form-check-label"
                                            value="admin">Whitelabelled</label>
                                    </div>
                                </div>


                                <div class="col-md-12">
                                    <button class="btn btn-primary" type="submit"
                                        [disabled]="!companyFormGroup.valid">Save
                                    </button> &nbsp;
                                    <button class="btn btn-warning" (click)="unSetCompanyIdContext()"
                                        type="button">Reset
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="row">
        <div class="col-xl-12 col-md-12">
            <div class="card">
                <div class="card-header">
                    Company list
                </div>
                <div class="card-body">
                    <div class="row table-responsive">
                        <table class="table table-responsive table-striped table-hover table-bordered">
                            <thead>
                                <tr>
                                    <th>Sr. No.</th>
                                    <th>Name</th>
                                    <th>GST</th>
                                    <th>Client SPoC Name</th>
                                    <th>Client SPoC Phone</th>
                                    <th>Logo</th>
                                    <th>Active</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style="text-align: center;" *ngIf="!company_list.length">
                                    <td colspan="100">No Records</td>
                                </tr>
                                <tr *ngFor="let company of company_list; let i = index">
                                    <td class="col-1">{{i + 1}}</td>
                                    <td>{{company.name}}</td>
                                    <td>{{company.gst}}</td>
                                    <td>
                                        {{company.spoc_details.name}}
                                    </td>
                                    <td>
                                        {{company.spoc_details.phone}}
                                    </td>
                                    <td>
                                        <img src="{{s3BaseUrl}}/{{company.logo?.url}}" height="100" />
                                    </td>
                                    <td class="col-1">
                                        <span class="badge bg-success rounded-pill" *ngIf="company.is_active">Yes</span>
                                        <span class="badge bg-danger rounded-pill" *ngIf="!company.is_active">No</span>
                                    </td>
                                    <td class="col-1">
                                        <a href="#" class="btn btn-xs btn-danger"
                                            (click)="updateModal(company)">Update</a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div><!-- end col -->
    </div>
    <!-- end row -->

</div> <!-- container -->