import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseObject } from 'src/app/shared/model/response.model';
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class CompanyService {

    constructor(private http: HttpClient) { }

    getCompanies() {
        return this.http.get<ResponseObject>(environment.apiEndpoint + "/company");
    }

    createCompanies(data: any) {
        return this.http.post<ResponseObject>(environment.apiEndpoint + "/company", data);
    }

    updateCompanies(id: string, data: any) {
        return this.http.put<ResponseObject>(environment.apiEndpoint + "/company/" + id, data);
    }

    uploadLogo(company_id: string, fd: any) {
        return this.http.post<ResponseObject>(environment.apiEndpoint + "/company/upload-logo/" + company_id, fd);
    }

    getBranding(company_id: string) {
        return this.http.get<ResponseObject>(environment.apiEndpoint + "/auth/company-branding?company_id=" + company_id);
    }
}
