import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent, SessionExpireComponent } from './login/login.component';
import { AuthGuard } from './shared/gaurds/auth.gaurd';
import { AdminDashboardComponent } from './admin/dashboard/admin-dashboard.component';
import { UserComponent, UserManagementRestrictedComponent } from './admin/user/user.component';
import { CompanyComponent } from './admin/company/company.component';
import { LocationComponent } from './admin/location/location.component';
import { WalkInComponent, WalkInLogComponent } from './paramedic/walk-in/walk-in.component';
import { CreatePrescriptionComponent } from './doctor/create-prescription/create-prescription.component';
import { ViewDetailPrescriptionComponent, ViewPrescriptionComponent } from './doctor/view-prescriptions/view-prescription.component';
import { PharmacyComponent, PharmacyLedgerComponent, PharmacyLedgerMonthlyReportComponent } from './pharmacy/pharmacy.component';
import { DepartmentComponent } from './client_admin/department/department.component';
import { SterilizationRegisterComponent } from './paramedic/sterilization-register/sterilization-register.component';
import { LaundryRegisterComponent } from './paramedic/laundry-register/laundry-register.component';
import { HandoverRegisterComponent } from './paramedic/handover/handover.component';
import { BMWRegisterComponent } from './paramedic/bmw-register/bmw-register.component';
import { RestRegisterComponent } from './paramedic/rest-register/rest-register.component';
import { LabsComponent, LabsTestsComponent } from './ahc/labs/labs.component';
import { OHCAppointmentComponent } from './patient/ohc-appointment/ohc-appointment.component';
import { HealtchCheckPackageComponent, ListMyHCBookingsComponent, ListOpsBookingsComponent, PatientHealtchCheckPackageComponent } from './ahc/packages/health-check-packages.component';
import { HCPackageLocationMapComponent } from './ahc/package-lab-map/health-package-location-map.component';
import { FourNotFourStaticComponent, PaymentFailStaticComponent, PaymentSuccessStaticComponent } from './static_pages/static_pages.component';
import { TrainingRegisterComponent } from './paramedic/training-register/training-register.component';
import { AmbulanceO2RegisterComponent } from './paramedic/ambulance-o2-register/ambulance-o2-register.component';
import { DependentComponent, UserProfileComponent } from './user/dependent.component';
import { ListMyVaccineBookingsComponent, ListOpsVaccinationBookingsComponent, PatientVaccineComponent, VaccineComponent } from './vaccine/vaccines/vaccination.component';
import { VaccineLocationMapComponent } from './vaccine/vaccine-lab-map/vaccine-location-map.component';
import { RegulatoryDataSubmissionRegisterComponent } from './paramedic/regulatory-data-submission-register/regulatory-data-submission-register.component';
import { RosterUserManagementComponent } from './roster/roster-management/roster-user-management.controller';

const routes: Routes = [
  {
    path: "",
    component: LoginComponent
  },
  {
    path: "session-expired",
    component: SessionExpireComponent,
  },
  {
    path: "profile",
    component: UserProfileComponent,
  },
  {
    path: "dashboard",
    component: AdminDashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "user",
    component: UserComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "company-management",
    component: CompanyComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "company-location-management",
    component: LocationComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "walk-in",
    component: WalkInComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "walk-in-log",
    component: WalkInLogComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "create-prescription",
    component: CreatePrescriptionComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "list-prescription",
    component: ViewPrescriptionComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "detail-prescription",
    component: ViewDetailPrescriptionComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "pharmacy",
    component: PharmacyComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "pharmacy-ledger",
    component: PharmacyLedgerComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'pharmacy-monthly-report',
    component: PharmacyLedgerMonthlyReportComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "user-management",
    component: UserManagementRestrictedComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "department-management",
    component: DepartmentComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "sterilization-register",
    component: SterilizationRegisterComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "laundry-register",
    component: LaundryRegisterComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "regulatory-data-register",
    component: RegulatoryDataSubmissionRegisterComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "handover-register",
    component: HandoverRegisterComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "bmw-register",
    component: BMWRegisterComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "rest-register",
    component: RestRegisterComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "training-register",
    component: TrainingRegisterComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "ambulance-o2-register",
    component: AmbulanceO2RegisterComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "lab-centers",
    component: LabsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "ohc-appointment",
    component: OHCAppointmentComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "health-check-packages",
    component: HealtchCheckPackageComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "package-location-map",
    component: HCPackageLocationMapComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "book-health-checkup",
    component: PatientHealtchCheckPackageComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "my-health-check-booking",
    component: ListMyHCBookingsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "ops-health-check-booking",
    component: ListOpsBookingsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "payment-success-page",
    component: PaymentSuccessStaticComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "payment-fail-page",
    component: PaymentFailStaticComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "manage-dependents",
    component: DependentComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "manage-vaccines",
    component: VaccineComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "book-vaccination",
    component: PatientVaccineComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "my-vaccination-booking",
    component: ListMyVaccineBookingsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "ops-vaccination-booking",
    component: ListOpsVaccinationBookingsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "vaccine-location-map",
    component: VaccineLocationMapComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "lab-tests",
    component: LabsTestsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "roster-management",
    component: RosterUserManagementComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "**",
    component: FourNotFourStaticComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
