import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LocationService } from './location.service';
import { ResponseObject } from 'src/app/shared/model/response.model';
import { CompanyService } from '../company/company.service';
import { UserService } from '../user/user.service';

@Component({
  selector: 'app-user',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.css']
})
export class LocationComponent {

  public error_message: string = "";
  public success_message: string = "";

  public location_list: any = [];
  public company_list: any = [];
  public user_list: any = [];
  public op_type: string = "Create";
  public location_id: string = "";
  public company_id: string = "";

  public locationFormGroup = new FormGroup({
    alias: new FormControl("", [Validators.required]),
    company_id: new FormControl("", [Validators.required]),
    is_active: new FormControl(false),
    master_ops_resource: new FormControl("", [Validators.required]),
    location: new FormGroup({
      line1: new FormControl("", [Validators.required]),
      line2: new FormControl(),
      landmark: new FormControl(),
      state: new FormControl("", [Validators.required]),
      city: new FormControl("", [Validators.required]),
      pincode: new FormControl("", [Validators.required])
    }),
    services: new FormControl([], [Validators.required]),
    registers: new FormControl([], [Validators.required])
  });

  updateModal(location: any) {
    this.op_type = "Update";
    this.location_id = location._id;
    delete location._id;
    delete location.__v;
    location.master_ops_resource = location.master_ops_resource._id;
    this.locationFormGroup.patchValue(location);
  }

  unSetLocationIdContext() {
    this.op_type = "Create";
    this.location_id = "";
    this.error_message = "";
    this.success_message = "";
    this.locationFormGroup.reset({
      company_id: "",
      master_ops_resource: "",
      location: {
        state: ""
      }
    });
  }

  constructor(private locationService: LocationService,
    private companyService: CompanyService,
    private userService: UserService) {
  }

  private getLocations() {
    if (!this.company_id) return;
    this.locationService.getLocations(this.company_id)
      .subscribe((res: ResponseObject) => {
        this.error_message = "";
        if (res.statusCode === 200) {
          this.location_list = res.data;
        }
        else {
          this.error_message = res.message;
        }
      });
  }

  ngOnInit() {
    this.company_id = "";
    this.getLocations();
    this.companyService.getCompanies()
      .subscribe((res: ResponseObject) => {
        this.error_message = "";
        if (res.statusCode === 200) {
          this.company_list = res.data;
        }
      });

    this.userService.getCallCenterUsers()
      .subscribe((res: ResponseObject) => {
        this.error_message = "";
        if (res.statusCode === 200) {
          this.user_list = res.data;
        }
      });
  }

  private createLocation(data: any) {
    this.locationService.createLocation(data)
      .subscribe((res: ResponseObject) => {
        this.error_message = "";
        if (res.statusCode === 201) {
          this.success_message = "Location Created Successfuly."
          this.getLocations();
        }
        else {
          this.error_message = res.message;
        }
      }, err => {
        if (err.status === 400) this.error_message = err.error.message[0];
        else this.error_message = err.error.message;
      });
  }

  private updateLocation(id: string, data: any) {
    this.locationService.updateLocation(id, data)
      .subscribe((res: ResponseObject) => {
        this.error_message = "";
        if (res.statusCode === 200) {
          this.success_message = "Location Updated Successfuly."
          this.getLocations();
        }
        else {
          this.error_message = res.message;
        }
      });
  }

  save() {
    this.error_message = "";
    this.success_message = "";

    if (!this.locationFormGroup.valid) {
      this.error_message = "Invalid form inputs";
      return;
    }

    if (!this.locationFormGroup.value.is_active) this.locationFormGroup.value.is_active = false;

    let data = {
      ... this.locationFormGroup.value
    }

    if (this.op_type === "Create")
      this.createLocation(data);
    else if (this.op_type === "Update")
      this.updateLocation(this.location_id, data)

  }

  onChangeCompany(e: any) {
    this.company_id = e;
    this.getLocations();
  }

}
