
import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ResponseObject } from 'src/app/shared/model/response.model';
import { HealtchCheckPackageService } from './health-check-packages.service';
import { LabsService } from '../labs/labs.service';
import { JSLoader } from 'src/app/shared/js-loader.function';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { environment } from "src/environments/environment";
import { UserAPIService } from 'src/app/user/user.service';
import { LocationMasterService } from 'src/app/shared/location_master.service';
import { AuthService } from 'src/app/shared/auth.service';
import { saveAs } from 'file-saver';

@Component({
    selector: 'app-user',
    templateUrl: './health-check-packages.component.html',
    styleUrls: ['./health-check-packages.component.html']
})
export class HealtchCheckPackageComponent {

    public error_message: string = "";
    public success_message: string = "";
    public package_list: any = [];
    public tests_list: any = [];
    public op_type: string = "Create";
    public package_id: string = "";


    public packageFormGroup = new FormGroup({
        name: new FormControl("", [Validators.required]),
        description: new FormControl("", [Validators.required]),
        delivery_type: new FormControl("", [Validators.required]),
        is_active: new FormControl(false),
        test_id: new FormControl([], [Validators.required])
    });

    updateModal(lab: any) {
        this.op_type = "Update";
        this.package_id = lab._id
        this.packageFormGroup.patchValue(lab);
    }

    unSetCompanyIdContext() {
        this.op_type = "Create";
        this.error_message = "";
        this.success_message = "";
        this.package_id = "";
        this.packageFormGroup.reset();
    }

    constructor(private healthCheckService: HealtchCheckPackageService,
        private labTests: LabsService) { }

    private getRegisterContent() {
        this.healthCheckService.getPackages()
            .subscribe((res: ResponseObject) => {
                this.error_message = "";
                if (res.statusCode === 200) {
                    this.package_list = res.data;
                }
                else {
                    this.error_message = res.message;
                }
            });
    }

    ngOnInit() {
        this.getRegisterContent();

        this.labTests.getTests()
            .subscribe((res: ResponseObject) => {
                this.error_message = "";
                if (res.statusCode === 200) {
                    this.tests_list = res.data;
                }
                else {
                    this.error_message = res.message;
                }
            }, err => {
                if (err.status === 400) this.error_message = err.error.message[0];
                else this.error_message = err.error.message;
            });

        JSLoader.load("assets/js/pages/form-advanced.init.js");
    }

    private createPackage(data: any) {
        this.healthCheckService.createPackage(data)
            .subscribe((res: ResponseObject) => {
                this.error_message = "";
                if (res.statusCode === 201) {
                    this.getRegisterContent();
                }
                else {
                    this.error_message = res.message;
                }
                this.packageFormGroup.reset();
            }, err => {
                if (err.status === 400) this.error_message = err.error.message[0];
                else this.error_message = err.error.message;
            });
    }

    private update(data: any) {
        this.healthCheckService.update(this.package_id, data)
            .subscribe((res: ResponseObject) => {
                this.error_message = "";
                if (res.statusCode === 200) {
                    this.getRegisterContent();
                }
                else {
                    this.error_message = res.message;
                }
                this.packageFormGroup.reset();
            }, err => {
                if (err.status === 400) this.error_message = err.error.message[0];
                else this.error_message = err.error.message;
            });
    }

    save() {
        this.error_message = "";
        this.success_message = "";

        if (!this.packageFormGroup.valid) {
            this.error_message = "Invalid form inputs";
            return;
        }
        let data = {
            ... this.packageFormGroup.value,
        }

        if (this.op_type === "Create")
            this.createPackage(data);
        else if (this.op_type === "Update") {
            if (!this.package_id) {
                this.error_message = "Please select an appropriate lab center";
                return;
            }
            this.update(data)
        }
    }
}

@Component({
    selector: 'app-user',
    templateUrl: './patient-health-check-packages.component.html',
    styleUrls: ['./health-check-packages.component.html']
})
export class PatientHealtchCheckPackageComponent {

    public error_message: string = "";
    public success_message: string = "";
    public package_list: any = [];
    public package_map_list: any = [];
    public tests_list: any = [];
    public op_type: string = "Create";
    public package_selected: any;
    public date_booking: string = "";
    public package_booking: string = "";
    public dependent_list: Array<any> = [];
    public booking_users: Array<any> = [];
    public state_list: Array<string> = [];
    public city_list: Array<string> = [];
    public lab_list: Array<any> = [];
    public package_map_selected: any = "";
    public is_client_admin: boolean = false;
    public date_picker_min: string = "";
    public delivery_type: string = "";
    public is_me_included: boolean = false;


    public paymentFormGroup = new FormGroup({
        key: new FormControl(""),
        txnid: new FormControl(""),
        amount: new FormControl(""),
        productinfo: new FormControl(),
        firstname: new FormControl(),
        email: new FormControl(),
        phone: new FormControl(),
        surl: new FormControl(),
        furl: new FormControl(),
        udf1: new FormControl(),
        udf2: new FormControl(),
        hash: new FormControl()
    });

    constructor(private healthCheckService: HealtchCheckPackageService,
        private labService: LabsService,
        private userAPIService: UserAPIService,
        private activatedroute: ActivatedRoute,
        private router: Router,
        private authService: AuthService,
        private locationMasterService: LocationMasterService,
        @Inject(DOCUMENT) private document: Document) { }


    ngOnInit() {

        let cur_date = new Date();
        cur_date.setDate(cur_date.getDate() + 3);
        this.date_picker_min = cur_date.toISOString().split("T")[0];

        let user = this.authService.getLoggedInUserDetails();
        if (user) {
            if (JSON.parse(user).user_type.includes("Client Admin")
                &&
                JSON.parse(user).user_type.indexOf("Patient") < 0)
                this.is_client_admin = true;
        }

        this.state_list = this.locationMasterService.getStates();

        this.healthCheckService.getLocationPackage(this.activatedroute.snapshot.queryParams["l"])
            .subscribe((res: ResponseObject) => {
                this.package_list = res.data;
            });

        this.healthCheckService.getMap(this.activatedroute.snapshot.queryParams["l"])
            .subscribe((res: ResponseObject) => {
                this.error_message = "";
                if (res.statusCode === 200) {
                    this.package_map_list = res.data;
                }
                else {
                    this.error_message = res.message;
                }
            }, err => {
                if (err.status === 400) this.error_message = err.error.message[0];
                else this.error_message = err.error.message;
            });

        this.userAPIService.getDependent()
            .subscribe((res: ResponseObject) => {
                this.error_message = "";
                if (res.statusCode === 200) {
                    this.dependent_list = res.data;
                }
            });


    }

    getCities(state: any) {
        this.city_list = this.locationMasterService.getCities(state.target.value)
    }

    getLabs(city: any) {
        for (let i = 0; i < this.package_map_list.length; i++) {
            if (this.package_map_list[i].package_id._id ==
                this.package_selected._id
                &&
                this.package_map_list[i].lab_id.address.city ==
                city.target.value) {
                this.lab_list.push(this.package_map_list[i]);
            }
        }
    }

    openBookingForm(package_selected: any) {
        this.package_map_selected = "";
        this.package_selected = package_selected;
        if (package_selected.delivery_type == 'Lab and Home') {
            this.delivery_type = 'Lab';
        }
        else this.delivery_type = package_selected.delivery_type;

        this.labService.getMultipleTests(package_selected.test_id.join(","))
            .subscribe((res: ResponseObject) => {
                this.error_message = "";
                if (res.statusCode === 200) {
                    this.tests_list = res.data;
                }
                else {
                    this.error_message = res.message;
                }
            }, err => {
                if (err.status === 400) this.error_message = err.error.message[0];
                else this.error_message = err.error.message;
            });
    }

    onCheckBoxToggle(event: any) {
        if (event.target.value == "ME") this.is_me_included = !this.is_me_included;
        else {
            if (this.booking_users.indexOf(event.target.value) == -1)
                this.booking_users.push(event.target.value)
            else
                this.booking_users.splice(this.booking_users.indexOf(event.target.value), 1)
        }

    }


    book() {
        if (!this.package_map_selected) {
            return;
        }

        if (!this.delivery_type) {
            return;
        }

        let package_map_obj;
        for (let i = 0; i < this.package_map_list.length; i++) {
            if (this.package_map_list[i]._id == this.package_map_selected) {
                package_map_obj = this.package_map_list[i];
            }
        }
        if (!this.booking_users.length && !this.is_me_included) {
            return;
        }
        this.healthCheckService.book({
            company_id: this.activatedroute.snapshot.queryParams["c"],
            location_id: this.activatedroute.snapshot.queryParams["l"],
            proposed_date: this.date_booking,
            status: "Pending Payment",
            package_id: package_map_obj.package_id._id,
            lab_id: package_map_obj.lab_id._id,
            package_map_id: package_map_obj._id,
            dependent_ids: this.booking_users,
            delivery_type: this.delivery_type,
            is_me_included: this.is_me_included
        })
            .subscribe((res: ResponseObject) => {
                if (res.statusCode === 201) {
                    if (res.data.calculated_amount > 0) {
                        this.healthCheckService.attemptPayment({
                            purpose: "Health Checkup",
                            purpose_identifer: res.data._id
                        })
                            .subscribe((res2: ResponseObject) => {
                                this.error_message = "";
                                if (res2.statusCode === 201) {
                                    this.paymentFormGroup.patchValue(res2.data.gateway_request);
                                    this.document.getElementById("btnPaymentSubmit")?.click();
                                    this.document.forms[0].submit();
                                }
                                else {
                                    this.error_message = res.message;
                                }
                            });
                    } else this.router.navigate(['/my-health-check-booking']);
                }
                else {
                    this.error_message = res.message;
                }
            }, err => {
                if (err.status === 400) this.error_message = err.error.message[0];
                else this.error_message = err.error.message;
            });
    }
}


@Component({
    selector: 'app-user',
    templateUrl: './list-booked-packages.component.html',
    styleUrls: ['./health-check-packages.component.css']
})
export class ListMyHCBookingsComponent {

    public booking_list: any = [];
    public s3BaseUrl: string = environment.s3URL;
    public booking: any = {};
    public payment_list: any = [];
    public file: any;

    public page_size: number = 5;
    public page_number: number = 0;

    public paymentFormGroup = new FormGroup({
        key: new FormControl(""),
        txnid: new FormControl(""),
        amount: new FormControl(""),
        productinfo: new FormControl(),
        firstname: new FormControl(),
        email: new FormControl(),
        phone: new FormControl(),
        surl: new FormControl(),
        furl: new FormControl(),
        udf1: new FormControl(),
        udf2: new FormControl(),
        hash: new FormControl()
    });


    constructor(private healthCheckService: HealtchCheckPackageService,
        @Inject(DOCUMENT) private document: Document) { }


    get() {
        this.healthCheckService.getMyBooking(this.page_size, this.page_number)
            .subscribe((res: ResponseObject) => {
                if (res.statusCode === 200) {
                    this.booking_list = res.data;
                }
            });
    }

    getNextPage() {
        this.page_number += 1;
        this.get()
    }

    getPrevPage() {
        this.page_number -= 1;
        this.get()
    }

    ngOnInit() {
        this.get();
    }

    download() {

    }

    retryPayment(p: any) {
        this.healthCheckService.attemptPayment({
            purpose: "Health Checkup",
            purpose_identifer: p._id
        })
            .subscribe((res2: ResponseObject) => {
                if (res2.statusCode === 201) {
                    this.paymentFormGroup.patchValue(res2.data.gateway_request);
                    this.document.getElementById("btnPaymentSubmit")?.click();
                    this.document.forms[0].submit();
                }
            });
    }

    openReportUploadForm(booking: any) {
        this.booking = booking;
        this.file = null;
    }

    uploadReport() {
        let fd = new FormData();
        if (this.file) {
            fd.append('file', this.file, this.file.name);
            this.healthCheckService.uploadReport(this.booking._id, fd)
                .subscribe((res: ResponseObject) => {
                    if (res.statusCode === 201) {
                        this.get();
                    }

                });
        }
    }

    newFileSelect(e: any) {
        if (e.target.value) {
            this.file = <File>e.target.files[0];
        }
    }
}


@Component({
    selector: 'app-user',
    templateUrl: './list-bookings-ops.controller.html',
    styleUrls: ['./health-check-packages.component.css']
})
export class ListOpsBookingsComponent {

    public booking_list: any = [];
    public package_list: any = [];
    public payment_list: any = [];
    public dependent_list: any = [];
    public filter_status: string = "Requested";
    public filter_delivery_type: string = "Lab,Home";
    public user_details: any = {};

    public booking: any = {};
    public s3BaseUrl: string = environment.s3URL;
    public file: any;

    public page_size: number = 5;
    public page_number: number = 0;

    public updateBookingFormGroup = new FormGroup({
        counter_date: new FormControl(),
        status: new FormControl("", [Validators.required]),
        package_details: new FormControl("", [Validators.required]),
        change_reason: new FormControl("", [Validators.required])
    });


    constructor(private healthCheckService: HealtchCheckPackageService,
        private activatedroute: ActivatedRoute,
        private authService: AuthService,
        private userAPIService: UserAPIService) { }

    public get(status: string) {
        this.healthCheckService.getLocationBookingByStatus(this.activatedroute.snapshot.queryParams["l"],
            status,
            this.filter_delivery_type,
            this.page_size, this.page_number)
            .subscribe((res: ResponseObject) => {
                if (res.statusCode === 200) {
                    this.booking_list = res.data;
                }
            });
    }


    getNextPage() {
        this.page_number += 1;
        this.get(this.filter_status)
    }

    getPrevPage() {
        this.page_number -= 1;
        this.get(this.filter_status)
    }


    ngOnInit() {

        let user = this.authService.getLoggedInUserDetails();
        this.user_details = JSON.parse(user || "{}");

        this.get("Requested");

        this.healthCheckService.getMap(this.activatedroute.snapshot.queryParams["l"])
            .subscribe((res: ResponseObject) => {
                if (res.statusCode === 200) {
                    this.package_list = res.data;
                }
            });

    }

    openBookingForm(booking: any) {
        this.updateBookingFormGroup.reset();
        this.booking = booking;
        this.updateBookingFormGroup.patchValue({
            package_details: this.booking.package_id._id + "~~" +
                this.booking.lab_id._id + "~~" + this.booking.package_map_id,
            status: this.booking.status,
            counter_date: this.booking.proposed_date.split("T")[0]
        })
        if (this.booking.dependent_ids?.length > 0) {
            let dependent_ids: any = [];
            for (let i = 0; i < this.booking.dependent_ids?.length; i++) {
                dependent_ids.push(this.booking.dependent_ids[i].dependent_ids);
            }
            this.userAPIService.getDependentByIds(dependent_ids, this.booking.user_id._id)
                .subscribe((res: ResponseObject) => {
                    if (res.statusCode === 200) {
                        this.dependent_list = res.data;
                    }
                });
        }
    }

    update() {
        if (!this.updateBookingFormGroup.valid) return;

        let data = {
            ... this.updateBookingFormGroup.value,
            package_id: this.updateBookingFormGroup.value.package_details?.split("~~")[0],
            lab_id: this.updateBookingFormGroup.value.package_details?.split("~~")[1],
            package_map_id: this.updateBookingFormGroup.value.package_details?.split("~~")[2]
        }

        this.healthCheckService.updateBooking(this.booking._id, data)
            .subscribe((res: ResponseObject) => {
                if (res.statusCode === 200) {
                    this.get(this.filter_status);
                }
            });
    }

    openPaymentModal(p: any) {
        this.booking = p;
        this.healthCheckService.getPaymentsforPackage(p._id)
            .subscribe((res: ResponseObject) => {
                if (res.statusCode === 200) {
                    this.payment_list = res.data;
                }
            });
    }

    uploadReport() {
        let fd = new FormData();
        if (this.file) {
            fd.append('file', this.file, this.file.name);
            this.healthCheckService.uploadReport(this.booking._id, fd)
                .subscribe((res: ResponseObject) => {
                    if (res.statusCode === 200) {
                        this.booking_list = res.data;
                    }
                }, err => {
                });
        }
    }

    newFileSelect(e: any) {
        if (e.target.value) {
            this.file = <File>e.target.files[0];
        }
    }

    updateFilter(event: any) {
        this.filter_status = event.target.value;
        this.get(this.filter_status);
    }

    updateFilterDelivery(event: any) {
        this.filter_delivery_type = event.target.value;
        this.get(this.filter_status);
    }

    downloadFile() {
      this.healthCheckService.getLocationBookingByStatus(this.activatedroute.snapshot.queryParams["l"],
        this.filter_status,
        this.filter_delivery_type,
        0, 0)
        .subscribe((res: ResponseObject) => {
          if (res.statusCode === 200) {
            let data = res.data;
            let export_data = [];
            for (let i = 0; i < data.length; i++) {
              let j: number = 0;
              do {
                export_data.push({
                  BookingID: data[i]._id,
                  EmployeeName: data[i].user_id.name,
                  EmployeePhone: data[i].user_id.phone,
                  EmployeeID: data[i].user_id.organization_identifier,
                  EmployeeEmail: (data[i].user_id.email),
                  EmployeeAddress: (`${data[i].user_id?.address?.line1?.replace(",", " ") || "-"}
                  ${data[i].user_id?.address?.line2?.replace(",", " ") || "-"}
                  ${data[i].user_id?.address?.city}
                  ${data[i].user_id?.address?.state} - ${data[i].user_id?.address?.pincode}`),
                  DoB: data[i].user_id.dob,
                  Package: data[i].package_id.name,
                  Lab: data[i].lab_id?.name || "-- Home --",
                  LabAddress: (`${data[i].lab_id?.address?.line1?.replace(",", " ") || "-"}
                  ${data[i].lab_id?.address?.line2?.replace(",", " ") || "-"}
                  ${data[i].lab_id?.address?.city}
                  ${data[i].lab_id?.address?.state} - ${data[i].lab_id?.address?.pincode}`),
                  Status: data[i].status,
                  BookingDate: data[i].createdAt,
                  AppointmentDate: data[i].proposed_date,
                  BookingForSelf: (data[i].is_me_included) ? "Yes" : "No",
                  BookingType: (data[i].delivery_type),
                  DependentName: (data[i].dependent_ids[j]?.dependent_id?.name) || "--NA--",
                  DependentAddress: (`${data[i].dependent_ids[j]?.line1?.replace(",", " ") || "-"}
                  ${data[i].dependent_ids[j]?.line2?.replace(",", " ") || "-"}
                  ${data[i].dependent_ids[j]?.city}
                  ${data[i].dependent_ids[j]?.state} - ${data[i].dependent_ids[j]?.pincode}`),
                });
                j++;
              } while( j < data[i].dependent_ids.length )
            }
            const replacer = (key: string, value: string) => (value === null ? '' : value);
            // specify how you want to handle null values here
            const header = Object.keys(export_data[0]);
            const csv = export_data.map((row: any) =>
              header
                .map((fieldName) => JSON.stringify(row[fieldName], replacer))
                .join(',')
            );
            csv.unshift(header.join(','));
            const csvArray = csv.join('\r\n');

            const a = document.createElement('a');
            const blob = new Blob([csvArray], { type: 'text/csv' });
            const url = window.URL.createObjectURL(blob);

            a.href = url;
            a.download = 'health_check_data.csv';
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
          }
        });
    }
}

