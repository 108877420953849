import { Component, Inject } from '@angular/core';
import { AuthService } from './shared/auth.service';
import { JSLoader } from './shared/js-loader.function';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { ResponseObject } from './shared/model/response.model';
import { environment } from 'src/environments/environment';
import { CompanyService } from './admin/company/company.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  public title: string = 'Avyukt Healthcare';
  public s3BaseUrl: string = environment.s3URL;

  public logo_url: string = "";
  public user_details: any = {};
  public user_types: any = []
  public user_locations: any = {};
  public user_locations_arr: any = [];
  public is_parent_site: boolean = true;

  constructor(private authService: AuthService,
    private route: Router,
    private companyService: CompanyService,
    @Inject(DOCUMENT) private document: Document) { }

  ngOnInit() {
    JSLoader.load("assets/js/app.min.js");
    let user = this.authService.getLoggedInUserDetails();
    if (user) this.user_details = JSON.parse(user);

    this.user_locations = this.user_details.locations?.reduce(function (r: any, a: any) {
      r[a.company_id] = r[a.company_id] || [];
      r[a.company_id].push(a);
      return r;
    }, Object.create(null));
    for (let key in this.user_locations) {
      this.user_locations_arr.push(this.user_locations[key])
    }

    let company_id_branading = "";

    if (this.user_details.locations?.length == 1) {
      company_id_branading = this.user_details.locations[0].company_id;
    }

    this.companyService.getBranding(company_id_branading)
      .subscribe((res: ResponseObject) => {
        this.document.title = res.data.name;

        this.title = res.data.name;
        if (!res.data.logo.startsWith("assets")) {
          this.is_parent_site = false;
          this.logo_url = environment.s3URL + "/" + res.data.logo;
          this.document.getElementById("favicon")?.setAttribute("href", this.logo_url);
        }
      });
  }

  isLoggedIn(): boolean {
    if (this.authService.getLoginToken()) return true;
    return false;
  }

  checkActiveRoute(url: string): boolean {
    return this.route.url.startsWith(url);
  }

  logout() {
    this.authService.clearStorage();
    this.route.navigate(["/"]);
  }
}
