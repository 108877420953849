<!-- Start Content-->
<div class="container-fluid">

    <!-- start page title -->
    <div class="row">
        <div class="col-12">
            <div class="page-title-box page-title-box-alt">
                <h4 class="page-title">Handover Register</h4>
                <div class="page-title-right">
                    <ol class="breadcrumb m-0">

                        <li class="breadcrumb-item"><a href="javascript: void(0);">Clinic</a></li>
                        <li class="breadcrumb-item active">Handover Register</li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
    <!-- end page title -->

    <div class="row">
        <div class="col-xl-12 col-md-12">
            <div class="card">
                <div class="card-header">
                    <h5>Handover Register</h5>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-xs-12">
                            <form [formGroup]="handoverRegisterFormGroup" class="" (ngSubmit)="save()">
                                <div class="alert alert-danger" *ngIf="error_message">{{error_message}}</div>
                                <div class="alert alert-success" *ngIf="success_message">{{success_message}}</div>

                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="mb-2">
                                            <label for="given_to" class="form-label">Given To <span
                                                    style="color: red;">*</span></label>
                                            <select class="form-control" type="text" id="given_to"
                                                formControlName="given_to">
                                                <option value="">-- Choose One ---</option>
                                                <option *ngFor="let m of medical_user_list;" value="{{m._id}}">
                                                    {{m.name}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="mb-2">
                                            <label for="on_duty_nurse" class="form-label">On Duty Paramedic <span
                                                    style="color: red;">*</span></label>
                                            <select class="form-control" type="text" id="on_duty_nurse"
                                                formControlName="on_duty_nurse">
                                                <option value="">-- Choose One ---</option>
                                                <option *ngFor="let m of paramedic_list;" value="{{m._id}}">
                                                    {{m.name}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="mb-2">
                                            <label for="notes" class="form-label">Notes <span
                                                    style="color: red;">*</span></label>
                                            <input class="form-control" type="text" id="notes"
                                                placeholder="Enter notes for handover" formControlName="notes">
                                        </div>
                                    </div>
                                </div>

                                <div class="mb-2 text-left">
                                    <button class="btn btn-primary" type="submit"
                                        [disabled]="!handoverRegisterFormGroup.valid">Save
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-xl-12 col-md-12">
            <div class="card">
                <div class="card-body">
                    <div class="row table-responsive">
                        <table class="table table-striped table-hover table-bordered">
                            <thead>
                                <tr>
                                    <th>Sr. No.</th>
                                    <th>Given By</th>
                                    <th>Given To</th>
                                    <th>On Duty Nurse</th>
                                    <th>Notes</th>
                                    <th>Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style="text-align: center;" *ngIf="!handover_list.length">
                                    <td colspan="100">No Content</td>
                                </tr>
                                <tr *ngFor="let s of handover_list; let i = index">
                                    <td class="col-1">{{i + 1}}</td>
                                    <td>{{s.given_by.name}}</td>
                                    <td>{{s.given_to.name}}</td>
                                    <td>{{s.on_duty_nurse.name}}</td>
                                    <td>{{s.notes}}</td>
                                    <td>{{s.date | date: 'short'}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div><!-- end col -->
    </div>
    <!-- end row -->

</div> <!-- container -->
<!-- Dashboard init-->