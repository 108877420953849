import { Component } from '@angular/core';
import { HealtchCheckPackageService } from 'src/app/ahc/packages/health-check-packages.service';
import { AuthService } from 'src/app/shared/auth.service';
import { JSLoader } from 'src/app/shared/js-loader.function';
import { ResponseObject } from 'src/app/shared/model/response.model';
import { CompanyService } from '../company/company.service';
import { ChartConfiguration, ChartData } from 'chart.js';
import { WalkInService } from 'src/app/paramedic/walk-in/walk-in.service';
import { PharmacyService } from 'src/app/pharmacy/pharmacy.service';
import { PrescriptionService } from 'src/app/doctor/create-prescription/prescription.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.css']
})
export class AdminDashboardComponent {

  public awaitingReportCount: any = 0;
  public cancelledReportCount: any = 0;
  public scheduledReportCount: any = 0;
  public activePackageCount: any = 0;
  public totalBookingCount: any = 0;
  public title: string = "";
  public is_patient_only = true;
  public is_admin_only = true;
  public user_locations: any = [];
  public user_locations_arr: any = [];
  public expirey_med_list: any = [];

  public user_details: any = {};


  public SevenDayFootfallLineChartOptionsData: any = {};
  public SevenDayFootfallLineChartOptions: ChartConfiguration<'line'>['options'] = {
    responsive: true,
  };

  public SevenDayAvgTimeLineChartOptionsData: any = {};
  public SevenDayAvgTimeLineChartOptions: ChartConfiguration<'line'>['options'] = {
    responsive: true,
  };

  public ThirtyDayEmegencyyLineChartOptionsData: any = {};
  public ThirtyDayEmegencyyLineChartOptions: ChartConfiguration<'line'>['options'] = {
    responsive: true,
  };

  public ThirtyDaysDoctorBusyCountLineChartOptionsData: any = {};
  public ThirtyDaysDoctorBusyCountLineChartOptions: ChartConfiguration<'bar'>['options'] = {
    responsive: true,
  };

  public ThirtyDaysDepartmentBusyCountLineChartOptionsData: any = {};
  public ThirtyDaysDepartmentBusyCountLineChartOptions: ChartConfiguration<'bar'>['options'] = {
    responsive: true,
  };

  public ThirtyDaysPatientDemographicsBarChartStackedOptionsData: any = {};
  public ThirtyDaysPatientDemographicsBarChartStackedOptions: ChartConfiguration<'bar'>['options'] = {
    responsive: true,
  };

  public ThirtyDayDepartmentWiseInjuryData: any = {};
  public ThirtyDayDepartmentWiseInjuryOptions: ChartConfiguration<'bar'>['options'] = {
    responsive: true,
  };

  public Top5MedicationsPieOptionsData: any = {};
  public Top5MedicationsPieOptions: ChartConfiguration<'pie'>['options'] = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'top',
      }
    }
  };

  public Top10DiseaseLineChartOptionsData: any = {};
  public Top10DiseaseLineChartOptions: ChartConfiguration<'line'>['options'] = {
    responsive: true,
  };

  constructor(private packageService: HealtchCheckPackageService,
    private companyService: CompanyService,
    private walkInService: WalkInService,
    private prescriptionService: PrescriptionService,
    private pharmacyService: PharmacyService,
    private authService: AuthService) { }

  ngOnInit() {
    // JSLoader.load("assets/js/pages/dashboard-crm.init.js");
    let user = this.authService.getLoggedInUserDetails();
    this.user_details = JSON.parse(user || "{}");
    let location_ids = [];
    for (let i = 0; i < this.user_details.locations.length; i++) {
      location_ids.push(this.user_details.locations[i].location_id);
    }

    this.user_locations = this.user_details.locations?.reduce(function (r: any, a: any) {
      r[a.company_id] = r[a.company_id] || [];
      r[a.company_id].push(a);
      return r;
    }, Object.create(null));
    for (let key in this.user_locations) {
      this.user_locations_arr.push(this.user_locations[key])
    }

    if (!(this.user_details.user_type.indexOf("Patient") >= 0 && this.user_details.user_type.length == 1)) this.is_patient_only = false;
    if (!(this.user_details.user_type.indexOf("Admin") >= 0 && this.user_details.user_type.length == 1)) this.is_admin_only = false;
    else this.is_patient_only = false;

    let company_id_branading = "";

    if (this.user_details.locations?.length == 1) {
      company_id_branading = this.user_details.locations[0].company_id;
    }

    this.companyService.getBranding(company_id_branading)
      .subscribe((res: ResponseObject) => {
        this.title = res.data.name;
      });


    if (this.user_details.user_type.includes("Client Admin")
      || this.user_details.user_type.includes("Doctor")
      || this.user_details.user_type.includes("Paramedic")) {
      if (this.checkService('AHC')) {
        this.packageService.getCountByBookingStatus(location_ids.join(","))
          .subscribe((res: ResponseObject) => {
            let countByStatus = res.data;
            for (let i = 0; i < countByStatus.length; i++) {
              if (countByStatus[i]._id == "Awaiting Report") this.awaitingReportCount = countByStatus[i].count;
              if (countByStatus[i]._id == "Cancelled") this.cancelledReportCount = countByStatus[i].count;
              if (countByStatus[i]._id == "Confirmed" || countByStatus[i]._id == "Requested") this.scheduledReportCount += countByStatus[i].count;

              this.totalBookingCount += countByStatus[i].count
            }
          });

        this.packageService.getLocationPackage(location_ids.join(","))
          .subscribe((res: ResponseObject) => {
            this.activePackageCount = res.data.length;
          });
      }

      if (this.checkService('OHC') && this.user_details.user_type.includes("Client Admin")) {
        this.walkInService.getWalkIns7DayTrends(location_ids.join(","))
          .subscribe((res: ResponseObject) => {
            let x = [];
            let y = [];

            for (let i = 0; i < res.data.length; i++) {
              x.push(res.data[i]._id.day + "/"
                + res.data[i]._id.month + "/"
                + res.data[i]._id.year);

              y.push(res.data[i].count)
            }

            let chartData: ChartConfiguration<'line'>['data'] = {
              labels: x,
              datasets: [
                {
                  data: y,
                  label: '7 Days OPD Trend',
                  fill: true,
                  tension: 0.9,
                  borderColor: 'rgb(82, 109, 238)',
                  backgroundColor: 'rgba(212,218,252,0.6)'
                },
              ]
            };
            this.SevenDayFootfallLineChartOptionsData = chartData;
          });

        this.walkInService.getEmergency30Days(location_ids.join(","))
          .subscribe((res: ResponseObject) => {
            let x = [];
            let y = [];

            for (let i = 0; i < res.data.length; i++) {
              x.push(res.data[i]._id.day + "/"
                + res.data[i]._id.month + "/"
                + res.data[i]._id.year);

              y.push(res.data[i].count)
            }

            let chartData: ChartConfiguration<'line'>['data'] = {
              labels: x,
              datasets: [
                {
                  data: y,
                  label: 'Emergency Walk-in Trends: A 30-Day Overview',
                  fill: true,
                  tension: 0.9,
                  borderColor: 'rgb(82, 109, 238)',
                  backgroundColor: 'rgba(212,218,252,0.6)'
                },
              ]
            };
            this.ThirtyDayEmegencyyLineChartOptionsData = chartData;
          });

        this.walkInService.getWalkIns7DayOpdTimeTrends(location_ids.join(","))
          .subscribe((res: ResponseObject) => {
            let x = [];
            let y = [];

            for (let i = 0; i < res.data.length; i++) {
              x.push(res.data[i]._id.day + "/"
                + res.data[i]._id.month + "/"
                + res.data[i]._id.year);

              y.push((res.data[i].avg_time / 60000) * -1)
            }

            let chartData: ChartConfiguration<'bar'>['data'] = {
              labels: x,
              datasets: [
                {
                  data: y,
                  label: '7 Days OPD Average Time (Mins)',
                  borderColor: 'rgb(82, 109, 238)',
                  backgroundColor: 'rgba(212,218,252,0.6)'
                },
              ]
            };
            this.SevenDayAvgTimeLineChartOptionsData = chartData;
          });

        this.walkInService.getCountByDoctor30Days(location_ids.join(","))
          .subscribe((res: ResponseObject) => {
            let x = [];
            let y = [];
            for (let i = 0; i < res.data.length; i++) {
              x.push(res.data[i]._id.name);

              y.push(res.data[i].count);
            }

            let chartData: ChartConfiguration<'line'>['data'] = {
              labels: x,
              datasets: [
                {
                  data: y,
                  label: '30 Days Doctor Utilisation',
                  fill: true,
                  tension: 0.8,
                  borderColor: 'rgb(82, 109, 238)',
                  backgroundColor: 'rgba(212,218,252,0.6)'
                },
              ]
            };
            this.ThirtyDaysDoctorBusyCountLineChartOptionsData = chartData;
          });

        this.walkInService.getCountByDepartment30Days(location_ids.join(","))
          .subscribe((res: ResponseObject) => {
            let x = [];
            let y = [];
            for (let i = 0; i < res.data.length; i++) {
              x.push(res.data[i].department_details[0]?.name || "Unknown");

              y.push(res.data[i].count);
            }

            let chartData: ChartConfiguration<'bar'>['data'] = {
              labels: x,
              datasets: [
                {
                  data: y,
                  label: '30 Days OPD By Department',
                  borderColor: 'rgb(82, 109, 238)',
                  backgroundColor: 'rgba(212,218,252,0.6)'
                },
              ]
            };
            this.ThirtyDaysDepartmentBusyCountLineChartOptionsData = chartData;
          });

        this.walkInService.get30DayPatientDemographic(location_ids.join(","))
          .subscribe((res: ResponseObject) => {
            let x = [];
            let y_male = [];
            let y_female = [];
            for (let i = 0; i < res.data.length; i++) {
              x.push(res.data[i]._id.age_bracket_start + "s");

              if (res.data[i]._id.gender === 'Male') y_male.push(res.data[i].count)
              if (res.data[i]._id.gender === 'Female') y_female.push(res.data[i].count)
            }

            let chartData: ChartConfiguration<'bar'>['data'] = {
              labels: x,
              datasets: [
                {
                  data: y_male,
                  label: 'Male',
                  borderColor: 'rgb(82, 109, 238)',
                  backgroundColor: 'rgba(212,218,252,0.6)',
                  stack: "a"
                },
                {
                  data: y_female,
                  label: 'Female',
                  borderColor: 'rgb(0,0,0)',
                  backgroundColor: 'rgba(0,0,0,0.6)',
                  stack: "a"
                },
              ]
            };
            this.ThirtyDaysPatientDemographicsBarChartStackedOptionsData = chartData;
          });

        this.walkInService.get30DayInjuryDepartmentWise(location_ids.join(","))
          .subscribe((res: ResponseObject) => {
            console.log(res.data)
            let x = [];
            let y_1 = [];
            let y_2 = [];
            let y_3 = [];
            for (let i = 0; i < res.data.length; i++) {
              if (x.indexOf(res.data[i]._id.department) == -1)
                x.push(res.data[i]._id.department)

              if (res.data[i]._id.grade === '1') y_1.push(res.data[i].count)
              if (res.data[i]._id.grade === '2') y_2.push(res.data[i].count)
              if (res.data[i]._id.grade === '3') y_3.push(res.data[i].count)
            }

            let chartData: ChartConfiguration<'bar'>['data'] = {
              labels: x,
              datasets: [
                {
                  data: y_1,
                  label: 'Grade 1',
                  borderColor: 'rgb(82, 109, 238)',
                  backgroundColor: 'rgba(212,218,252,0.6)',
                  stack: "a"
                },
                {
                  data: y_2,
                  label: 'Grade 2',
                  borderColor: 'rgb(0,0,0)',
                  backgroundColor: 'rgba(0,0,0,0.6)',
                  stack: "a"
                },
                {
                  data: y_3,
                  label: 'Grade 3',
                  stack: "a"
                },
              ]
            };
            this.ThirtyDayDepartmentWiseInjuryData = chartData;
          });

        this.pharmacyService.getTopMedsLocation(location_ids.join(","))
          .subscribe((res: ResponseObject) => {
            let pieChartLabels = [];
            let pieChartData = [];

            for (let i = 0; i < res.data.length; i++) {
              pieChartLabels.push(res.data[i]._id.toUpperCase());
              pieChartData.push(res.data[i].count * -1);
            }

            let chartData: ChartData<'pie', number[], string | string[]> = {
              labels: pieChartLabels,
              datasets: [
                {
                  data: pieChartData,
                },
              ],
            };

            this.Top5MedicationsPieOptionsData = chartData;
          });

        this.pharmacyService.getExpiringMeds(location_ids.join(","))
          .subscribe((res: ResponseObject) => {
            this.expirey_med_list = res.data
          });

        this.prescriptionService.getLocationTopDiseases(location_ids.join(","))
          .subscribe((res: ResponseObject) => {
            let x = [];
            let y = [];
            for (let i = 0; i < res.data.length; i++) {
              x.push(res.data[i]._id);

              y.push(res.data[i].count);
            }

            let chartData: ChartConfiguration<'line'>['data'] = {
              labels: x,
              datasets: [
                {
                  data: y,
                  label: 'Ailment Occurrences: Top 10 Ailment in 30 Days',
                  fill: true,
                  tension: 0.8,
                  borderColor: 'rgb(82, 109, 238)',
                  backgroundColor: 'rgba(212,218,252,0.6)'
                },
              ]
            };
            this.Top10DiseaseLineChartOptionsData = chartData;
          });
      }
    }

  }

  checkService(service: string) {
    for (let i = 0; i < this.user_locations_arr.length; i++) {
      for (let j = 0; j < this.user_locations_arr[i].length; j++)
        if (this.user_locations_arr[i][j].services.indexOf(service) >= 0) return true;
    }
    return false;
  }
}
