<!-- Start Content-->
<div class="container-fluid">

    <!-- start page title -->
    <div class="row">
        <div class="col-12">
            <div class="page-title-box page-title-box-alt">
                <h4 class="page-title">View Prescription Detail</h4>
                <div class="page-title-right">
                    <ol class="breadcrumb m-0">

                        <li class="breadcrumb-item"><a href="javascript: void(0);">Doctor</a></li>
                        <li class="breadcrumb-item active">View Prescription</li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
    <!-- end page title -->

    <div class="row">
        <div class="col-xl-12 col-md-12">
            <div class="card">
                <div class="card-header">
                    <img src="assets/images/avyukt-healthcare-logo11.png" alt="" height="35">
                    <h5>Dr. {{p.prescriber_id?.name}}</h5>
                    <h6 class="card-subtitle">{{p.prescriber_id?.doctor_details?.specialization}}</h6>
                    <br />
                    <h6 class="card-subtitle text-muted">Registeration:
                        {{p.prescriber_id?.doctor_details?.registeration_number}}
                    </h6>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-xs-12">

                            <h5>Patient Details</h5>
                            <div class="row">
                                <div class="col-sm-3">
                                    <div class="mb-2">
                                        <strong for="name">Name: &nbsp; </strong>
                                        {{p.patient_id?.name}}
                                    </div>
                                </div>
                                <div class="col-sm-5" *ngIf="p.patient_id?.email">
                                    <div class="mb-2">
                                        <strong for="email">Email: &nbsp; </strong>
                                        {{p.patient_id?.email}}
                                    </div>
                                </div>
                                <div class="col-sm-2">
                                    <div class="mb-2">
                                        <strong for="gender">Gender: &nbsp; </strong>
                                        {{p.patient_id?.gender}}
                                    </div>
                                </div>
                                <div class="col-sm-2">
                                    <div class="mb-2">
                                        <strong for="age">Age: &nbsp; </strong>
                                        {{p.walkin_register_id?.age}}
                                    </div>
                                </div>
                            </div>

                            <h5>Vitals</h5>

                            <div class="row">
                                <div class="col-sm-3" *ngIf="p.walkin_register_id?.bp">
                                    <strong>Blood Pressure: &nbsp;</strong>
                                    <div class="mb-2">
                                        {{p.walkin_register_id?.bp}}
                                    </div>
                                </div>
                                <div class="col-sm-2" *ngIf="p.walkin_register_id?.spo2">
                                    <div class="mb-2">
                                        <strong>SpO2: &nbsp;</strong>
                                        {{p.walkin_register_id?.spo2}}
                                    </div>
                                </div>
                                <div class="col-sm-3" *ngIf="p.walkin_register_id?.temperature">
                                    <div class="mb-2">
                                        <strong>Temperature: &nbsp;</strong>
                                        {{p.walkin_register_id?.temperature}}
                                    </div>
                                </div>
                                <div class="col-sm-3" *ngIf="p.walkin_register_id?.hr">
                                    <div class="mb-2">
                                        <strong>Heart Rate: &nbsp;</strong>
                                        {{p.walkin_register_id?.hr}}
                                    </div>
                                </div>
                                <div class="col-sm-3" *ngIf="p.walkin_register_id?.rr">
                                    <div class="mb-2">
                                        <strong>Respiratory Rate: &nbsp;</strong>
                                        {{p.walkin_register_id?.rr}}
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-12" *ngIf="p.walkin_register_id?.description">
                                    <div class="mb-2">
                                        <strong for="description">Paramedic Notes: &nbsp; </strong>
                                        {{p.walkin_register_id?.description}}
                                    </div>
                                </div>
                            </div>

                            <h5>Prescription</h5>

                            <div class="row">
                                <div class="col-md-12" *ngIf="p.history">
                                    <div class="mb-2">
                                        <strong>History: &nbsp;</strong>
                                        {{p.history}}
                                    </div>
                                </div>
                                <div class="col-md-12" *ngIf="p.complaint_off">
                                    <div class="mb-2">
                                        <strong>C/o : &nbsp;</strong>
                                        {{p.complaint_off}}
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12" *ngIf="p.allergy">
                                    <div class="mb-2">
                                        <strong>Allergy(s): &nbsp;</strong>
                                        {{p.allergy}}
                                    </div>
                                </div>
                                <div class="col-md-12" *ngIf="p.investigations">
                                    <div class="mb-2">
                                        <strong>Investigation(s) : &nbsp;</strong>
                                        {{p.investigations}}
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                              <div class="col-lg-6" *ngIf="p.diagnosis">
                                <div class="mb-2">
                                  <strong>Diagnosis: &nbsp; </strong>
                                  {{p.diagnosis}}
                                </div>
                              </div>
                                <div class="col-lg-6" *ngIf="p.icd_code?.meaning_L4">
                                    <div class="mb-2">
                                        <strong>Diagnosis Code: &nbsp; </strong>
                                        {{p.icd_code?.meaning_L4}}
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-12" *ngIf="p.advice">
                                    <div class="mb-2">
                                        <strong>Advice: &nbsp;</strong>
                                        {{p.advice}}
                                    </div>
                                </div>
                                <div class="col-lg-12" *ngIf="p.prognosis">
                                    <div class="mb-2">
                                        <strong>Prognosis: &nbsp;</strong>
                                        {{p.prognosis}}
                                    </div>
                                </div>
                            </div>

                            <div class="row table-responsive">
                                <div class="col-md-12">
                                    <table class="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th>Sr. No.</th>
                                                <th>Medicine</th>
                                                <th>Frequency</th>
                                                <th>Duration (Days)</th>
                                                <th>Instructions</th>
                                                <th *ngIf="is_pharmacy && !p.is_dispensed"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr style="text-align: center;"
                                                *ngIf="!(p.medicine?.length && p.addon_medicine?.length)">
                                                <td colspan="100">No Medication</td>
                                            </tr>
                                            <tr *ngFor="let med of p.medicine; let i = index">
                                                <td>{{ i + 1 }}.</td>
                                                <td>{{med.medicine_id.type}} - {{med.medicine_id.name}}</td>
                                                <td>{{med.frequency}}</td>
                                                <td>{{med.duration}} days</td>
                                                <td>{{med.instructions}}</td>
                                                <td *ngIf="is_pharmacy && !p.is_dispensed">
                                                    <table class="table table-sm mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Batch</th>
                                                                <th scope="col">Expiry</th>
                                                                <th scope="col" class="col-1">Stock</th>
                                                                <td scope="col"></td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let b of med.batches; let indx = index;">
                                                                <th scope="row">{{b.batch_number.toUpperCase()}}</th>
                                                                <td>{{b.expirey | date: 'mediumDate'}}</td>
                                                                <td>{{b.stock}}</td>
                                                                <td>
                                                                    <input class="form-control form-control-sm"
                                                                        type="number" min="0" step="1"
                                                                        [(ngModel)]="b.new_stock" value="0"
                                                                        placeholder="Stock deduction value" />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>

                                            <tr *ngFor="let med of p.addon_medicine; let i = index">
                                                <td>{{ p.medicine.length + 1 }}.</td>
                                                <td>{{med.name}}</td>
                                                <td>{{med.frequency}}</td>
                                                <td>{{med.duration}} days</td>
                                                <td>{{med.instructions}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>


                            <div class="row">
                                <div class="col-xs-3">
                                    <div class="mb-2">
                                        <strong>OHC Rest Duration: </strong>
                                        {{p.recommend_rest}} (mins.)
                                    </div>
                                </div>
                                <div class="col-xs-3">
                                    <strong>Follow-up on: </strong>
                                    {{p.followup_on | date: 'shortDate'}}
                                </div>
                            </div>
                            <hr />
                            <u><b>Disclaimer:</b> Not intended for medico-Legal purposes.</u>
                            <hr />
                            <div class="row">
                                <div class="col-md-4">
                                    <button style="margin-right: 10px;" class="btn btn-danger d-print-none"
                                        type="button" *ngIf="is_pharmacy && !p.is_dispensed"
                                        (click)="dispense_all()">Dispense Medication
                                        &nbsp;</button>
                                    <button class="btn btn-info d-print-none" type="button"
                                        (click)="print()">Print</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> <!-- container -->
<!-- Dashboard init-->
