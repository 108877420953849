import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseObject } from 'src/app/shared/model/response.model';
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class WalkInService {

    constructor(private http: HttpClient) { }

    getWalkIns(location_id: string, date: string, doctor_id: string | null = null) {
        return this.http.get<ResponseObject>(environment.apiEndpoint + "/walk-in/" + location_id
            + "?date=" + date + "&doctor_id=" + doctor_id);
    }

    updateVitals(walkin_id: string, vitals: any) {
        return this.http.patch<ResponseObject>(environment.apiEndpoint + "/walk-in/vitals/" + walkin_id, vitals);
    }

    getWalkInsForDay(location_id: string, date: string) {
        return this.http.get<ResponseObject>(environment.apiEndpoint + "/walk-in/" + location_id
            + "?date=" + date);
    }

    getWalkInsLog(location_id: string,
        page_size: number = 0, page_number: number = 0) {
        return this.http.get<ResponseObject>(environment.apiEndpoint + "/walk-in/" + location_id
            + "?page_size=" + page_size
            + "&page_number=" + page_number
        );
    }

    createWalkIn(data: any) {
        return this.http.post<ResponseObject>(environment.apiEndpoint + "/walk-in", data);
    }

    updateWalkIn(id: string, data: any) {
        return this.http.put<ResponseObject>(environment.apiEndpoint + "/walk-in/" + id, data);
    }

    markOut(id: string) {
        return this.http.patch<ResponseObject>(environment.apiEndpoint + "/walk-in/mark-out/" + id, {});
    }

    getWalkIns7DayTrends(location_ids: string) {
        return this.http.get<ResponseObject>(environment.apiEndpoint + "/walk-in/get-7-days-trends?location_ids=" + location_ids);
    }

    getWalkIns7DayOpdTimeTrends(location_ids: string) {
        return this.http.get<ResponseObject>(environment.apiEndpoint + "/walk-in/get-7-opd-time-trends?location_ids=" + location_ids);
    }

    getCountByDoctor30Days(location_ids: string) {
        return this.http.get<ResponseObject>(environment.apiEndpoint + "/walk-in/get-count-by-doctor?location_ids=" + location_ids);
    }

    getCountByDepartment30Days(location_ids: string) {
        return this.http.get<ResponseObject>(environment.apiEndpoint + "/walk-in/get-count-by-department?location_ids=" + location_ids);
    }

    get30DayPatientDemographic(location_ids: string) {
        return this.http.get<ResponseObject>(environment.apiEndpoint + "/walk-in/get-patient-demographic-split?location_ids=" + location_ids);
    }

    get30DayInjuryDepartmentWise(location_ids: string) {
        return this.http.get<ResponseObject>(environment.apiEndpoint + "/walk-in/department-wise-injury?location_ids=" + location_ids);
    }

    getEmergency30Days(location_ids: string) {
        return this.http.get<ResponseObject>(environment.apiEndpoint + "/walk-in/get-30-emergency?location_ids=" + location_ids);
    }
}
