import { Component, Inject } from '@angular/core';
import { LoginService } from './login.service';
import { AuthService } from '../shared/auth.service';
import { ResponseObject } from '../shared/model/response.model';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LocationService } from '../admin/location/location.service';
import { DepartmentService } from '../client_admin/department/department.service';
import { CompanyService } from '../admin/company/company.service';
import { DOCUMENT } from '@angular/common';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {

  public error_message: string = "";
  public success_message: string = "";
  public email: string = "";
  public otp: string = "";
  public password: string = "";
  public is_otp_mode: boolean = false;
  public is_registeration_mode: boolean = false;
  public is_domain_verified: boolean = false;
  public location_list: any = [];
  public department_list: any = [];
  public type: string = "Password";
  public reverse_type: string = "Otp";

  public title: string = 'Avyukt Healthcare';
  public is_parent_site: boolean = true;
  public logo_url: string = "";

  private company_id: string = "";
  public location_id: string = "";

  public is_otp_mode_entended: boolean = false;
  public email_recovery: string = "";

  public loginFormGroup = new FormGroup({
    email: new FormControl(),
    password: new FormControl()
  });

  public registerationFormGroup = new FormGroup({
    email: new FormControl("", [Validators.email]),
    name: new FormControl("", [Validators.required]),
    dob: new FormControl("", [Validators.required]),
    gender: new FormControl("", [Validators.required]),
    phone: new FormControl("", [Validators.required]),
    resource_type: new FormControl("", [Validators.required]),
    organization_identifier: new FormControl("", [Validators.required]),
    department: new FormControl("", [Validators.required]),
    blood_group: new FormControl(""),
    is_active: new FormControl(true)
  });

  constructor(private loginService: LoginService,
    private authService: AuthService,
    private route: Router,
    private companyService: CompanyService,
    private locationService: LocationService,
    private departmentService: DepartmentService,
    @Inject(DOCUMENT) private document: Document
  ) {
    if (this.authService.getLoginToken()) this.route.navigate(["/dashboard"]);
  }

  ngOnInit() {
    this.companyService.getBranding("")
      .subscribe((res: ResponseObject) => {
        this.document.title = res.data.name;
        this.title = res.data.name;
        if (!res.data.logo.startsWith("assets")) {
          this.is_parent_site = false;
          this.logo_url = environment.s3URL + "/" + res.data.logo;
        }
      });
  }

  login() {
    this.loginService.login(this.loginFormGroup.value.email,
      this.loginFormGroup.value.password, this.type)
      .subscribe((res: ResponseObject) => {
        this.error_message = "";
        if (res.statusCode === 200) {
          this.authService.setLoginToken(res.data.token);
          this.authService.setUserDetails(res.data);
          this.route.navigate(["/dashboard"]);
          window.location.reload();
        }
        else {
          this.error_message = res.message;
        }
      }, err => {
        if (err.status === 400) this.error_message = err.error.message[0];
        else this.error_message = err.error.message;
      });
  }
  flip_login_type(type: string) {
    if (!this.loginFormGroup.value.email) {
      this.error_message = "Please enter your phone number to receive OTP.";
      return;
    }
    this.error_message = "";
    this.reverse_type = type;
    if (type === "Password") {
      this.type = "Otp";
      this.loginService.loginOTP(this.loginFormGroup.value.email)
        .subscribe((res: ResponseObject) => {
          this.error_message = "";
          if (res.statusCode === 200) {
            this.is_otp_mode_entended = true;
          }
          else {
            this.error_message = res.message;
          }
        }, err => {
          if (err.status === 400) this.error_message = err.error.message[0];
          else this.error_message = err.error.message;
        });
    }
    if (type === "Otp") this.type = "Password";
  }
  start_pwd_recover() {
    this.is_otp_mode = true;
  }

  activateRegisterMode() {
    this.is_registeration_mode = true;
    this.company_id = "";
    this.location_id = "";
    this.is_domain_verified = false;
  }

  processOTP() {
    if (!this.email) {
      this.error_message = "Please enter a valid email";
      return;
    }
    if (!this.is_otp_mode_entended) {
      this.loginService.sendOTP(this.email)
        .subscribe((res: ResponseObject) => {
          this.error_message = "";
          if (res.statusCode === 200) {
            this.is_otp_mode_entended = true;
          }
          else {
            this.error_message = res.message;
          }
        }, err => {
          if (err.status === 400) this.error_message = err.error.message[0];
          else this.error_message = err.error.message;
        });
    }
    else {
      if (!this.otp || !this.password) {
        this.error_message = "Please enter both OTP and Password.";
        return;
      }
      this.loginService.verifyOTP({
        phone: this.email,
        otp: this.otp,
        password: this.password
      })
        .subscribe((res: ResponseObject) => {
          this.error_message = "";
          if (res.statusCode === 200) {

            this.email = "";
            this.otp = "";
            this.password = "";

            this.is_otp_mode_entended = false;
            this.is_otp_mode = false;
          }
          else {
            this.error_message = res.message;
          }
        }, err => {
          if (err.status === 400) this.error_message = err.error.message[0];
          else this.error_message = err.error.message;
        });
    }
  }

  register() {
    if (!this.is_domain_verified) {
      this.error_message = "Please verify domain before proceeding.";
      return;
    }

    this.error_message = "";
    this.success_message = "";

    if (!this.registerationFormGroup.valid) {
      this.error_message = "Please enter all the information before proceeding.";
      return;
    }

    this.loginService.registerEmployee({ ...this.registerationFormGroup.value, password: "dsidhn89472", user_type: ["Patient"], locations: [{ company_id: this.company_id, location_id: this.location_id }] })
      .subscribe((res: ResponseObject) => {
        this.error_message = "";
        if (res.statusCode === 200) {
          this.success_message = "You have been successfully registered. Your password has been sent on your registered email."
        }
        else {
          this.error_message = res.message;
        }
      }, err => {
        if (err.status === 400) this.error_message = err.error.message[0];
        else this.error_message = err.error.message;
      });
  }

  verifyDomain() {
    if (!this.registerationFormGroup.value.email || !this.registerationFormGroup.controls.email.valid) {
      this.error_message = "Please enter a valid email address.";
      return;
    }
    this.loginService.verifyDomain(this.registerationFormGroup.value.email)
      .subscribe((res: ResponseObject) => {
        this.error_message = "";
        if (res.statusCode === 200) {
          this.company_id = res.data._id;

          this.locationService.getLocations(this.company_id)
            .subscribe((res: ResponseObject) => {
              this.error_message = "";
              if (res.statusCode === 200) {
                this.location_list = res.data;
                this.departmentService.get(this.company_id)
                  .subscribe((res: ResponseObject) => {
                    this.error_message = "";
                    if (res.statusCode === 200) {
                      this.department_list = res.data;
                      this.is_domain_verified = true;
                    }
                    else {
                      this.error_message = res.message;
                    }
                  });
              }
              else {
                this.error_message = res.message;
              }
            });
        }
        else {
          this.error_message = res.message;
        }
      }, err => {
        if (err.status === 400) this.error_message = err.error.message[0];
        else this.error_message = err.error.message;
      });
  }
}


@Component({
  selector: 'session-expire',
  templateUrl: './session-expire.component.html',
  styleUrls: ['./login.component.css']
})
export class SessionExpireComponent {
  constructor() { }
}
