
import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ResponseObject } from 'src/app/shared/model/response.model';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/admin/user/user.service';
import { BMWRegisterService } from './bmw-register.service';

@Component({
    selector: 'app-user',
    templateUrl: './bmw-register.component.html',
    styleUrls: ['./bmw-register.component.css']
})
export class BMWRegisterComponent {

    public error_message: string = "";
    public success_message: string = "";
    public bmw_list: any = [];
    public medical_user_list: any = [];


    public bmwRegisterFormGroup = new FormGroup({
        opening_stock_white_bag: new FormControl("", [Validators.required]),
        opening_stock_blue_bag: new FormControl("", [Validators.required]),
        opening_stock_yellow_bag: new FormControl("", [Validators.required]),
        opening_stock_red_bag: new FormControl("", [Validators.required]),
        number_used_white_bag: new FormControl("", [Validators.required]),
        number_used_blue_bag: new FormControl("", [Validators.required]),
        number_used_yellow_bag: new FormControl("", [Validators.required]),
        number_used_red_bag: new FormControl("", [Validators.required]),
        total_weight_white_bag: new FormControl("", [Validators.required]),
        total_weight_blue_bag: new FormControl("", [Validators.required]),
        total_weight_yellow_bag: new FormControl("", [Validators.required]),
        total_weight_red_bag: new FormControl("", [Validators.required]),
        location_id: new FormControl(this.activatedroute.snapshot.queryParams["l"], [Validators.required]),
        company_id: new FormControl(this.activatedroute.snapshot.queryParams["c"], [Validators.required]),
    });

    constructor(private bmwRegisterService: BMWRegisterService,
        private activatedroute: ActivatedRoute) { }

    private getRegisterContent() {
        this.bmwRegisterService.get(this.activatedroute.snapshot.queryParams["l"])
            .subscribe((res: ResponseObject) => {
                this.error_message = "";
                if (res.statusCode === 200) {
                    this.bmw_list = res.data;
                }
                else {
                    this.error_message = res.message;
                }
            }, err => {
                if (err.status === 400) this.error_message = err.error.message[0];
                else this.error_message = err.error.message;
            });
    }

    ngOnInit() {
        this.getRegisterContent();
    }

    private createBMWRegister(data: any) {
        this.bmwRegisterService.create(data)
            .subscribe((res: ResponseObject) => {
                this.error_message = "";
                if (res.statusCode === 201) {
                    this.getRegisterContent();
                    this.bmwRegisterFormGroup.reset();
                }
                else {
                    this.error_message = res.message;
                }
            }, err => {
                if (err.status === 400) this.error_message = err.error.message[0];
                else this.error_message = err.error.message;
            });
    }

    save() {
        this.error_message = "";
        this.success_message = "";

        if (!this.bmwRegisterFormGroup.valid) {
            this.error_message = "Invalid form inputs";
            return;
        }
        let data = {
            ... this.bmwRegisterFormGroup.value,
        }
        this.createBMWRegister(data);
    }
}