<!-- Start Content-->
<div class="container-fluid">

    <!-- start page title -->
    <div class="row">
        <div class="col-12">
            <div class="page-title-box page-title-box-alt">
                <h4 class="page-title">Dependent Management</h4>
                <div class="page-title-right">
                    <ol class="breadcrumb m-0">

                        <li class="breadcrumb-item"><a href="javascript: void(0);">User Management</a></li>
                        <li class="breadcrumb-item active">Dependents</li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
    <!-- end page title -->

    <div class="row">
        <div class="col-xl-12 col-md-12">
            <div class="card">
                <div class="card-header">
                    Dependents
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-xs-12">
                            <form [formGroup]="dependentFormGroup" class="" (ngSubmit)="save()">
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="mb-2">
                                            <label for="name" class="form-label">Name <span
                                                    style="color: red;">*</span></label>
                                            <input class="form-control" type="text" id="name"
                                                placeholder="Enter dependent name" formControlName="name">
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="mb-2">
                                            <label for="phone" class="form-label">Phone <span
                                                    style="color: red;">*</span></label>
                                            <input class="form-control" type="text" id="phone"
                                                placeholder="Enter phone number (without +91)" formControlName="phone">
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="mb-2">
                                            <label for="dob" class="form-label">Date of Birth <span
                                                    style="color: red;">*</span></label>
                                            <input class="form-control" type="date" id="dob"
                                                placeholder="Enter dob number" formControlName="dob">
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="mb-2">
                                            <label for="email" class="form-label">Email <span
                                                    style="color: red;">*</span></label>
                                            <input class="form-control" type="text" id="email"
                                                placeholder="Enter email address" formControlName="email">
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="mb-2">
                                            <label for="gender" class="form-label">Gender <span
                                                    style="color: red;">*</span></label>
                                            <select class="form-control" id="gender" formControlName="gender">
                                                <option selected value="">-- Choose One --</option>
                                                <option value="Male">Male</option>
                                                <option value="Female">Female</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="mb-2">
                                            <label for="relation" class="form-label">Relation <span
                                                    style="color: red;">*</span></label>
                                            <select class="form-control" id="relation" formControlName="relation">
                                                <option value="">-- Choose One --</option>
                                                <option value="Child">Child</option>
                                                <option value="Mother">Mother</option>
                                                <option value="Father">Father</option>
                                                <option value="Spouse">Spouse</option>
                                                <option value="Others">Others</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <h5>Address</h5>

                                <div formGroupName="address">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="mb-2">
                                                <label class="form-label" for="line1">Line 1 <span
                                                        style="color: red;">*</span></label>
                                                <input class="form-control" required type="text" id="line1"
                                                    placeholder="Enter Line 1" formControlName="line1">
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="mb-2">
                                                <label class="form-label" for="line2">Line 2</label>
                                                <input class="form-control" type="text" id="line2"
                                                    placeholder="Enter Line 2" formControlName="line2">
                                            </div>
                                        </div>
                                    </div>



                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-md-3">
                                                <div class="mb-2">
                                                    <label class="form-label" for="locality">Locality <span
                                                            style="color: red;">*</span></label>
                                                    <input class="form-control" required type="text" id="locality"
                                                        placeholder="Enter Locality" formControlName="landmark">
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="mb-2">
                                                    <label class="form-label" for="state">State <span
                                                            style="color: red;">*</span></label>
                                                    <select class="form-control" id="state" (change)="getCities($event)"
                                                        formControlName="state">
                                                        <option selected value="">-- Select --</option>
                                                        <option value="{{s}}" *ngFor="let s of state_list">{{s}}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="mb-2">
                                                    <label class="form-label" for="city">City <span
                                                            style="color: red;">*</span></label>
                                                    <select class="form-control" id="state" formControlName="city">
                                                        <option selected value="">-- Select --</option>
                                                        <option value="{{s}}" *ngFor="let s of city_list">{{s}}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="mb-2">
                                                    <label class="form-label" for="pincode">Pincode <span
                                                            style="color: red;">*</span></label>
                                                    <input class="form-control" type="text" id="pincode"
                                                        placeholder="Enter Pincode" formControlName="pincode">
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="mb-2">
                                            <label for="is_active" class="form-label">Is Active</label>
                                            <br />
                                            <input type="checkbox" id="is_active" formControlName="is_active">
                                        </div>
                                    </div>
                                </div>

                                <div class="mb-2 text-left">
                                    <button class="btn btn-primary" type="submit"
                                        [disabled]="!dependentFormGroup.valid">Save
                                    </button> &nbsp;
                                    <button class="btn btn-warning" (click)="unSetCompanyIdContext()"
                                        type="button">Reset
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-xl-12 col-md-12">
            <div class="card">
                <div class="card-header">
                    Dependent List
                </div>
                <div class="card-body">
                    <div class="row table-responsive">
                        <table class="table table-striped table-hover table-bordered">
                            <thead>
                                <tr>
                                    <th>Sr. No.</th>
                                    <th>Name</th>
                                    <th>Phone</th>
                                    <th>Email</th>
                                    <th>Gender</th>
                                    <th>DoB</th>
                                    <th>Relation</th>
                                    <th>Active</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style="text-align: center;" *ngIf="!dependent_list.length">
                                    <td colspan="100">No Content</td>
                                </tr>
                                <tr *ngFor="let s of dependent_list; let i = index">
                                    <td class="col-1">{{i + 1}}</td>
                                    <td>{{s.name}}</td>
                                    <td>{{s.phone}}</td>
                                    <td>{{s.email}}</td>
                                    <td>{{s.gender}}</td>
                                    <td>{{s.dob | date: 'shortDate'}}</td>
                                    <td>{{s.relation}}</td>
                                    <td>
                                        <span class="badge bg-success rounded-pill" *ngIf="s.is_active">Yes</span>
                                        <span class="badge bg-danger rounded-pill" *ngIf="!s.is_active">No</span>
                                    </td>
                                    <td>
                                        <button class="btn btn-danger btn-sm" type="button"
                                            (click)="updateModal(s)">Update</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div><!-- end col -->
    </div>
    <!-- end row -->

</div> <!-- container -->
<!-- Dashboard init-->